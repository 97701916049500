import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import Axios, { baseURL } from "../../../../config/axiosv2";
import handler from "../../../../redux/actions/handler";
import useAgendas from "../../../../swr/hooks/useAgendas";

const fetcher = (url) =>
  Axios.get(url, {
    baseURL: baseURL.concat("/v3"),
  });

const useAgendasHabilitadas = (id, onClose) => {
  const [agendas, setAgendas] = useState(null);
  const { original } = useAgendas();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const { data: response, mutate } = useSWR(
    () => id && `/agendas/habilitadas/usuario/${id}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );

  useEffect(() => {
    if (response && response.data && original) {
      const data = response.data.AgendasHabilitadas;
      const isAllChecked = data === "";
      const isAllUnchecked = data === ",";
      const agendasHabilitadas = data.split(",").map(Number);

      const dataAgendas = original.map((agenda) => ({
        ...agenda,
        checked: isAllChecked
          ? true
          : isAllUnchecked
          ? false
          : agendasHabilitadas.includes(agenda.idAgenda),
      }));

      setAgendas(dataAgendas);
    }
  }, [original, response]);

  const hasChanges = useMemo(() => {
    if (response && response.data && agendas) {
      const data = response.data.AgendasHabilitadas;
      const agendasHabilitadas = data.split(",").map(Number);
      return !agendas.every(
        (agenda) =>
          agenda.checked === agendasHabilitadas.includes(agenda.idAgenda)
      );
    }
    return false;
  }, [agendas, response]);

  const onCheckAgenda = (idAgenda) => {
    const newAgendas = agendas.map((agenda) =>
      agenda.idAgenda === idAgenda
        ? { ...agenda, checked: !agenda.checked }
        : agenda
    );
    setAgendas(newAgendas);
    setSuccess(false);
  };

  const onSubmit = async () => {
    try {
      setIsSaving(true);
      const isAllChecked = agendas.every((agenda) => agenda.checked);
      const isAllUnchecked = agendas.every((agenda) => !agenda.checked);
      const data = isAllChecked
        ? ""
        : isAllUnchecked
        ? ","
        : "," +
          agendas
            .filter((agenda) => agenda.checked)
            .map((agenda) => agenda.idAgenda)
            .join(",") +
          ",";
      await Axios.post(
        "/agendas/habilitadas",
        { idUsuario: id, AgendasHabilitadas: data },
        {
          baseURL: baseURL.concat("/v3"),
        }
      );
      setSuccess(true);
      onReset();
    } catch (error) {
      handler(dispatch)(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onReset = () => {
    mutate();
    onClose();
  };

  const onCloseSnackbar = () => setSuccess(false);

  return {
    agendas,
    isSaving,
    isSuccess,
    hasChanges,
    onCheckAgenda,
    onCloseSnackbar,
    onSubmit,
    onReset,
  };
};

export default useAgendasHabilitadas;

import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Hooks
import useGmail from '../hooks/useGmail.js';

// Components
import Loader from '../../util/circleLoading';
import Dialog from '../../util/dialogMessage.js';

// Images
import MailImage from '../../../assets/svgs/undraw_mail.png';
import MailDoneImage from '../../../assets/svgs/undraw_mail_done.png';

const CorreoGmail = () => {
    const { data, loading, testmodal, onSync, onTest, onDestroy } = useGmail();
    return (
        <div className="flex grow center h-100">
            <div>
            {
                loading ? <Loader text="Cargando Servicios Gmail." /> :
                <div className="flex column">
                    {!data ?
                        <>
                            <img src={MailImage} alt="Mail" height={200} className="bg-none" />
                            <Button variant="contained" color="primary" onClick={onSync}>Vincular</Button>
                        </>
                        :
                        <div className="flex column center">
                            <img src={MailDoneImage} alt="Mail Syncronized" height={200} className="bg-none" />
                            <Typography>Sincronizado: <span className="text-primary">{data.correo}</span></Typography>
                            <Button variant="contained" className="my-m" onClick={onDestroy}>Desvincular</Button>
                            <div style={{width: 320, height: 5}}>
                                <Divider />
                            </div>
                            <Button variant="contained" color="secondary" className="my-m" onClick={onTest}>Prueba de Configuración</Button>
                        </div>
                    }
                </div>
            }
            <Dialog
                open={testmodal.open}
                title="Atención!"
                description={<span>Parece que todo quedo pronto.<br/>Deberias recibir un correo con asunto 'Prueba de configuración Odontosys' en tu cuenta de correo</span>}
                handleClose={testmodal.handleClose}
            />
            </div>
        </div>
    );
}

export default CorreoGmail;
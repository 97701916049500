import React from 'react'
import { Grid, Typography, TextField } from '@mui/material';
import useMovil from '../../hooks/useMovil';

const Form = ({ state, select, handleChange, handleSubmit }) => {
    const length = state.trim().length;
    const isPhone = useMovil();
    return (
        <Grid component="form" container className="modal-form" onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
                    {select ? "Editar" : "Nuevo"} Cuestionario
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    type="search"
                    size="small"
                    name="name"
                    label="Nombre"
                    autoComplete="off"
                    value={state}
                    error={length > 100}
                    helperText={length > 100 ? "El largo permitido es 100 caracteres." : ""}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}

export default Form;
import React, { useMemo } from "react";

// UI
import { FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

// Components
import { localeDoctorSort } from "../../../../../components/grupos/util";
import useOpen from "../../../../../components/hooks/useOpen";
import { TipoDeUsuario } from "../util";
import Usuario from "./item";

export const INACTIVE_DEFAULT_VALUE = "INACTIVE_DEFAULT_VALUE";
const getDefaultValue = (defaultValue) => {
  const value = JSON.parse(localStorage.getItem(INACTIVE_DEFAULT_VALUE))
  return value === null ? defaultValue : value;
}

const Usuarios = ({ usuarios, isAdmin, administradores }) => {
  const unactive = useOpen(getDefaultValue(isAdmin));

  const onToggle = () => {
    unactive.handleToggle();
    localStorage.setItem(INACTIVE_DEFAULT_VALUE, !unactive.open);
  }

  const tipos = useMemo(() => {
    const dataPerType = {
      0: [],
      1: [],
      2: [],
      3: []
    };
    usuarios.map((user) => dataPerType[user.TipoDeUsuario].push(user));
    return dataPerType
  }, [usuarios]);

  return (
    <TableContainer>
      {
        Object.keys(tipos).map((tipo, index) => (!!tipos[tipo].length) && (
          <Table key={index}>
            <TableHead>
              <TableRow className="bg-primary">
                <TableCell className="text-white">
                  <Typography variant="body2">
                    <strong>{TipoDeUsuario[tipo]}</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tipos[tipo].sort(localeDoctorSort).filter(user => unactive.open ? true : user.Activo).map((user) => (
                <Usuario
                  user={user}
                  key={user.idUsuario}
                  disabled={!user.Activo}
                  administradores={administradores}
                />
              ))}
            </TableBody>
            <Box mb={6} />
          </Table>
        ))
      }

      {isAdmin && (
        <Box display="flex" m={4}>
          <FormControlLabel
            label="Ver inactivos"
            control={
              <Switch
                size="small"
                checked={unactive.open}
                onChange={onToggle}
              />
            }
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default Usuarios;

import React from 'react';

// Components
import Loading from '../../../../../components/util/circleLoading';
import ToolTip from '../../../../../components/util/tooltip';

import Header from './header';
import Modal from './modal';
import Table from './table';

// Hooks
import useOpen from '../../../../../components/hooks/useOpen';
import useGrupos from '../../../../../swr/hooks/useGrupos';

const Doctores = () => {
    const { gruposDoctores: doctores } = useGrupos();
    const modal = useOpen();
    return (
        <div className="flex grow column">
            <Header title="Doctores" onCreate={modal.handleOpen} />
            {
                doctores === null ? <Loading text="Cargando doctores." />
                    : <Table doctores={doctores} />
            }
            <Modal open={modal.open} handleClose={modal.handleClose} />
            <ToolTip handleOpen={modal.handleOpen} title="Nuevo grupo" color="primary" />
        </div>
    );
}

export default Doctores;
import Axios from '../../config/axios';
import {
    ARANCEL_LISTS,
    ARANCEL_LIST_CREATE,
    ARANCEL_LIST_DELETE,
    ARANCEL_LIST_EDIT,
    ARANCEL_LIST_LOADING,
    ARANCEL_LIST_SELECT,
    ARANCEL_LIST_UPDATE,
    ARANCEL_SINGLE,
    PACIENTE_UPDATE
} from '../constans';
import handler from './handler';

const loader = (payload, value) => ({
    type: payload,
    payload: value
});

export const getArancelesList = (cb) => (dispatch) => {
    dispatch(loader(ARANCEL_LIST_LOADING, true));
    Axios.get('/aranceles/list')
        .then((res) => {
            dispatch({
                type: ARANCEL_LISTS,
                payload: res.data
            });
            if (cb) cb();
        })
        .catch(handler(dispatch))
        .finally(() => dispatch(loader(ARANCEL_LIST_LOADING, false)));
};

export const setArancel = (idArancel) => (dispatch) => {
    dispatch({
        type: ARANCEL_SINGLE,
        payload: idArancel
    });
};

export const updateArancel = (paciente, idArancel, cb) => (dispatch) => {
    dispatch(loader(ARANCEL_LIST_LOADING, true));
    Axios.put(`/aranceles/${idArancel}/paciente/${paciente.idPaciente}`)
        .then((res) => {
            dispatch({
                type: PACIENTE_UPDATE,
                payload: { ...paciente, idArancel: idArancel }
            });
        })
        .then(cb)
        .catch(handler(dispatch))
        .finally(() => dispatch(loader(ARANCEL_LIST_LOADING, false)));
};

export const setArancelList = (arancel) => ({ type: ARANCEL_LIST_SELECT, payload: arancel });

export const setArancelListEdit = (list) => ({ type: ARANCEL_LIST_EDIT, payload: list });

export const updateListArancel = (id, body, cb) => (dispatch) => {
    dispatch(loader(ARANCEL_LIST_LOADING, true));
    Axios.put(`/aranceles/list/${id}`, body)
        .then((res) => {
            dispatch({
                type: ARANCEL_LIST_UPDATE,
                payload: res.data
            });
            cb();
        })
        .catch(handler(dispatch))
        .finally(() => dispatch(loader(ARANCEL_LIST_LOADING, false)));
};

export const createListArancel = (body, cb) => (dispatch) => {
    dispatch(loader(ARANCEL_LIST_LOADING, true));
    Axios.post(`/aranceles/list`, body)
        .then((res) => {
            dispatch({
                type: ARANCEL_LIST_CREATE,
                payload: res.data
            });
            cb();
        })
        .catch(handler(dispatch))
        .finally(() => dispatch(loader(ARANCEL_LIST_LOADING, false)));
};

export const deleteListArancel = (id, cb) => (dispatch) => {
    dispatch(loader(ARANCEL_LIST_LOADING, true));
    Axios.delete(`/aranceles/list/${id}`)
        .then(() => dispatch({ type: ARANCEL_LIST_DELETE, payload: id }))
        .catch(handler(dispatch))
        .finally(() => dispatch(loader(ARANCEL_LIST_LOADING, false)));
};

export const setArancelError = (data) => (dispatch) => {
    try {
        throw new Error(data);
    } catch (e) {
        handler(dispatch)(e);
    }
};

export const clearAranceles = () => (dispatch) => {
    dispatch({
        type: ARANCEL_SINGLE,
        payload: null
    });
};

import React from 'react';

// Components
import GruposContainer from '../../../../pages/gruposProveedor';

const Grupos = () => {
    return (
        <div className="flex grow column">
            <GruposContainer title="Grupos Proveedores" />
        </div>
    );
}

export default Grupos;
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updatePacienteEstado } from '../provider/actions';

export default function UseMenu() {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const onClick = (e) => setAnchorEl(e.target)
    const onClose = () => setAnchorEl(null)
    const onChange = (paciente, value) => dispatch(updatePacienteEstado(paciente, value))
    
    return {
        anchorEl,
        onClick,
        onClose,
        onChange
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { Hidden } from '@mui/material';

import {
  floatButton,
  floatButtonText,
  floatButtonAlt
} from './tooltip.module.css';

const Tool = ({ handleOpen, title, color, second, secondTitle, secondHandle, secondIcon }) => (
  <Box my={3}>
    <div className={floatButton}>
      {
        second ?
          <div className={floatButtonAlt}>
            <Tooltip title={secondTitle} aria-label={secondTitle} placement="left" arrow={true}>
              <Fab color="secondary" aria-label={secondTitle} onClick={secondHandle}>
                {
                  secondIcon ?
                    secondIcon
                    :
                    <AddIcon />
                }
              </Fab>
            </Tooltip>
          </div> : null
      }
      <Tooltip title={title} aria-label={title} placement={"left"} arrow={true}>
        <Fab color={color} aria-label={title} onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  </Box>
);

export const TextFloatConfigButton = ({
  children
}) => (
  <>
    <Hidden mdDown>
      <div className={floatButtonText}>
        <Box style={{ marginLeft: 300 }}>
          {children}
        </Box>
      </div>
    </Hidden>
    <Hidden mdUp>
      <Box style={{ marginTop: 30 }}>
        {children}
      </Box>
    </Hidden>
  </>
);

Tool.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  second: PropTypes.any,
  secondTitle: PropTypes.string,
  secondHandle: PropTypes.func,
  secondIcon: PropTypes.node
}

export default Tool;

import {
  PROVEEDOR_ERROR,
  PROVEEDOR_LOADING,
  PROVEEDOR_TABLE,
  PROVEEDOR_TABLE_LOADING,
  PROVEEDOR_LIST,
  PROVEEDOR_LIST_LOADING,
  PROVEEDOR_CREATE,
  PROVEEDOR_SINGLE,
  PROVEEDOR_UPDATE,
  PROVEEDOR_UPDATE_LIST,
  PROVEEDOR_CLEAN,
  PROVEEDOR_GRUPO
} from '../constans.js';

const initialState = {
  loading: false,
  loadingList: false,
  list: null,
  table: {},
  error: null,
  errorSingle: null,
  single: null,
  tableLoading: true
}

function updateList(list, newList){
  let oldList = [...newList]
  const array = [];

  bigLoop:
  for (let i = 0; i < list.length; i++) {
    const oldItem = list[i];

    for (let x = 0; x < oldList.length; x++) {
      if(oldItem.idProveedor === oldList[x].idProveedor){
        array.push(oldList[x]);
        oldList.splice(x, 1)
        continue bigLoop;
      }
    }

    array.push(oldItem)
  }

  return array
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case PROVEEDOR_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case PROVEEDOR_TABLE_LOADING:
      return {
        ...state,
        tableLoading: action.payload
      }
    case PROVEEDOR_CLEAN:
      return {
        ...state,
        table: {},
        list: null,
        single: null
      }
    case PROVEEDOR_TABLE:
      return {
        ...state,
        table: action.payload,
        error: null
      }
    case PROVEEDOR_GRUPO:
      return {
        ...state,
        table: { ...state.table, list: updateList(state.table.list, action.payload) },
        error: null
      }
    case PROVEEDOR_CREATE:
      return {
        ...state,
        table: {
          ...state.table,
          list: state.table.list ? [...state.table.list, action.payload] : [action.payload],
          count: (state.table.count || 0) + 1,
          limit: state.table.limit ? state.table.limit : 10
        },
        list: null,
        error: null
      }
    case PROVEEDOR_UPDATE:
      return {
        ...state,
        single: action.payload,
        table: {
          ...state.table,
          list: state.table.list ? state.table.list.map(item => (item.idProveedor === action.payload.idProveedor) ? action.payload : item) : state.table.list
        },
        list: state.list ? state.list.map(item => item.idProveedor === action.payload.idProveedor ? action.payload : item) : state.list,
        error: null
      }
    case PROVEEDOR_UPDATE_LIST:
      return {
        ...state,
        table: state.table.list ? {
          ...state.table,
          list: state.table.list.filter(item => item.idProveedor !== action.payload),
          count: state.table.count - 1
        } : {}
      }
    case PROVEEDOR_LIST:
      return {
        ...state,
        list: action.payload,
        error: null
      }
    case PROVEEDOR_LIST_LOADING:
      return {
        ...state,
        loadingList: action.payload
      }
    case PROVEEDOR_SINGLE:
      return {
        ...state,
        single: action.payload,
        error: null
      }
    case PROVEEDOR_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
}

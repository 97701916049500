import React from 'react'

// UI
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import TrashIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// Hooks
import useOpen from '../../hooks/useOpen';

// Components
import Appbar from '../../../configuracion/surfaces/appbar';
import { TextInputField } from './questions/textwrapper';
import ConfirmDialog from '../../util/confirmDialog';

const CuestionarioHeader = ({ single, length, onUpdate, onDestroy, onCreateQuestion, onSelectBack }) => {
    const { open, handleClose, handleOpen } = useOpen();
    const confirm = useOpen();
    const onBlur = ({ target }) => {
        handleClose();
        if(target.value.trim().length){
            onUpdate(single, target.value.trim())
        }
    }
    const onClick = () => {
        if(length) confirm.handleOpen();
        else onDestroy(single);
    } 

    return (
        <div className="flex">
            <Appbar
                leftIcon={<IconButton onClick={onSelectBack} size="large"><ChevronLeftIcon /></IconButton>}
                leftIconProps={{className: "min-width-50 mr-m"}}
                rightIconComponent={
                    <div className="middle">
                        <Hidden mdDown>
                            <Button color="primary" className="mx" startIcon={<LibraryAddIcon />} onClick={onCreateQuestion}>
                                Nueva pregunta
                            </Button>
                        </Hidden>
                        <IconButton onClick={onClick} size="large"><TrashIcon /></IconButton>
                    </div>
                }
            >
            {
                open ?
                    <TextInputField defaultValue={single.nombre} type="text" size="large" fullWidth autoFocus onBlur={onBlur} />
                :
                    <div className="flex grow middle">
                        <Hidden smUp>
                            <Typography variant="h6" onClick={handleOpen}>
                                {single.nombre}
                            </Typography>
                        </Hidden>
                        <Hidden mdDown>
                            <Typography variant="h6" onClick={handleOpen}>
                                Cuestionario: {single.nombre}
                            </Typography>
                        </Hidden>
                    </div>
            }
            </Appbar>
            <ConfirmDialog
                open={confirm.open}
                title="Para eliminar un cuestionario, debe eliminar primero todas las preguntas."
                handleClose={confirm.handleClose}
                handleClick={confirm.handleClose}
            />
        </div>
    );
}

export default CuestionarioHeader;
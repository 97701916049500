import React, { useState, useMemo } from 'react';
import Content from '../rowmanager';

const random = (min, max) => min + Math.floor(Math.random() * ((max - min) + 1))

const Preview = ({ state, bottom, height }) => {
    const [num] = useState(random(0, 1));
    return useMemo(function(){
        const items = [];
        for(let i = 0; i < state.lines; i++){
            items.push(
                <Content
                    key={i}
                    index={i}
                    preview
                    item={state.items[i]}
                    family={state.family}
                    height={height}
                    bottom={bottom}
                    num={num}
                />
            )
        }
        return items
    }, [ num, state, height, bottom ])
}

export default Preview;
import Container from '@mui/material/Container';
import React from 'react';
import Reminder from '../components/auth/reminder';
import Version from '../components/auth/version';
import SWRContainer from '../swr/config';

const MainLoader = ({ children }) => {
  return (
    <SWRContainer>
      <Container maxWidth="lg" className="app-overflow h-100">
        {children}
        <Reminder />
        <Version />
      </Container>
    </SWRContainer>
  )
}

export default MainLoader

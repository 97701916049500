import React from 'react';

import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

// Icons
import Appbar from '../../surfaces/appbar';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ReplayIcon from '@mui/icons-material/Replay';

const CuestionarioHeader = ({ onUpdate, onCreate }) => {
    return (
        <div>
            <Appbar
                title="Cuestionarios"
                rightIconComponent={
                    <div className="middle">
                        <Hidden mdDown>
                            <Button color="primary" className="mx" onClick={onCreate} startIcon={<LibraryAddIcon />}>
                                Nuevo Cuestionario
                            </Button>
                        </Hidden>
                        <IconButton onClick={onUpdate} size="large"><ReplayIcon /></IconButton>
                    </div>
                }
            />
        </div>
    );
}

export default CuestionarioHeader;
import React from 'react';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Components
import { toColor } from '../../../../components/util/tools';

const AgendaForm = ({ item, onSelect, onVisible }) => {
    const onClick = () => onSelect(item);
    const onToggleVisible = (e) => {
        e.stopPropagation();
        onVisible(item);
    }
    return (
        <ListItem className="pt-m pb-m" onClick={onClick}>
            <Avatar style={{ backgroundColor: toColor(item.Color), color: 'transparent', width: 25, height: 25 }} />
            <ListItemText className="ml" disableTypography>
                <Typography variant="body2">{item.Nombre}</Typography>
            </ListItemText>
            <ListItemIcon>
                <IconButton size="small" onClick={onToggleVisible}>
                    {item.Visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </ListItemIcon>
        </ListItem>
    );
}

export default AgendaForm;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, CircularProgress } from '@mui/material'

// Components
import Modal from '../util/modal';
import Dialog from '../util/confirmDialog';
import Form from '../control/form';

// Hooks
import useOpen from '../hooks/useOpen';

// actions
import { getFormatDate } from '../util/tools';
import { closeControl, updateControl, destroyControl } from '../control/provider/actions';

const ViewControl = ({ callback }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [state, setState] = useState(null);
  const { open, data, loading } = useSelector(state => state.control);
  const modal = useOpen();

  useEffect(function(){
    if(open && data ){
      setState(data)
    }
  }, [ open, data ])

  const handleClose = () => {
    setState(null);
    dispatch(closeControl())
  }
  const handleCallback = () => {
    handleClose();
    if(callback) callback();
  }
  const handleChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value })
    setError(null);
  }
  const handleDelete = () => {
    dispatch(destroyControl(data.idPaciente))
    modal.handleClose();
    handleCallback()
  };
  const handleSubmit = () => {
    if(!state.date){
      setError('No ha seleccionado una fecha.');
    } else if(state.description.trim().length > 150){
      setError('No puede exceder el límite de 150 caracteres.');
    } else {
      dispatch(updateControl(data.idPaciente, { date: getFormatDate(state.date, 1), description: state.description }, handleCallback))
    }
  }

  const Buttons = (
    <Grid container justifyContent="flex-end">
      {
        state && (
          <Grid container item xs justifyContent="flex-start" alignItems="center">
            {
              state.date ?
                <span onClick={modal.handleOpen} className="pointer text-danger">Eliminar
                  {window.innerWidth > 768 ? " Control" : null}
                </span>
                : null
            }
          </Grid>
        )
      }
      <Grid container item xs={8} justifyContent="flex-end" alignItems="center">
        <Button color="secondary" size="small" className="mx-m" onClick={handleClose}>
          Cerrar
        </Button>
        <div className="relative">
          <Button color="primary" variant="contained" size="small" onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
          {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
        </div>
      </Grid>
    </Grid>
  )

  return (
    <Modal
      open={open}
      buttons={Buttons}
      handleClose={handleClose}
    >
      <Form state={state} error={error} handleChange={handleChange} />
      <Dialog
        exit
        {...modal}
        title="Está a punto de eliminar el siguiente control."
        handleClick={handleDelete}
      />
    </Modal>
  )
}

export default ViewControl;

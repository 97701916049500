import React from "react";

// Components
import Modal from "../../../../../components/precios/modal";
import Tooltip from "../../../../../components/util/tooltip";
import Header from "../header";
import Search from "./search";
import Arancel from "./treatment";

// Hooks
import useTratamientosAranceles from "../../hooks/useTratamientosAranceles";

const PreciosTratamientos = ({ item, onSelect }) => {
  const {
    text,
    selected,
    aranceles,
    onChangeText,
    onSelectArancel,
    onOpenModal,
    onCloseModal,
  } = useTratamientosAranceles(item);

  const onSelectBack = () => onSelect(null);
  return (
    <div className="flex grow column h-100">
      <div>
        <Header select={item} data={aranceles} onSelectBack={onSelectBack} />
        <Search text={text} onChangeText={onChangeText} />
      </div>
      <div className="flex grow column">
        {
          aranceles.map(arancel =>
            <Arancel
              key={arancel.idTratamiento}
              arancel={arancel}
              onSelect={onSelectArancel}
            />
          )}
      </div>
      <div style={{ height: 1 }}>
        <Modal
          idArancel={item.idArancel}
          item={selected}
          open={!!selected}
          onClose={onCloseModal}
        />
        <Tooltip
          handleOpen={onOpenModal}
          title="Nuevo Arancel"
          color="primary"
        />
      </div>
    </div >
  );
};

export default PreciosTratamientos;

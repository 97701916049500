import {
  AGENDA_LOADING,
  AGENDA_LIST,
  CITAS_LIST,
  CITAS_CREATE,
  CITAS_UPDATE,
  CITAS_DESTROY,
  CITAS_UPDATE_STATE,
  CITAS_UPDATE_MEDIO,
  CITAS_UPDATE_CONFIRM,
  AGENDA_CLEAR_AFTER,
  AGENDA_CLEAR_ALL 
} from './constants.js';
import { getFormatDate } from '../../util/tools.js';
import { formatCita, filterList, updateList, clearList } from '../util.js'

const initialState = {
  list: null, 
  loading: false,
  citas: {}
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case AGENDA_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case AGENDA_LIST:
      return {
        ...state,
        list: action.payload
      }
    case CITAS_CREATE:
      return {
        ...state,
        citas: filterList('MAKE', state.citas, formatCita(action.payload))
      }
    case CITAS_LIST:
      return {
        ...state,
        citas: {
          ...state.citas,
          [""+getFormatDate(action.date)]: action.payload.map(formatCita)
        }
      }
    case CITAS_UPDATE_MEDIO:
      return {
        ...state,
        citas: updateList(state.citas, action.payload)
      }
    case CITAS_UPDATE:
      return {
        ...state,
        citas: filterList('UPDATE', state.citas, formatCita(action.payload), action.lastIndex)
      }
    case CITAS_DESTROY:
      return {
        ...state,
        citas: filterList('DELETE', state.citas, action.payload)
      }
    case CITAS_UPDATE_STATE:
    case CITAS_UPDATE_CONFIRM:
      return {
        ...state,
        citas: filterList('UPDATE', state.citas, action.payload, action.payload.idCita)
      }
    case AGENDA_CLEAR_AFTER:
      return {
        ...state,
        citas: clearList(state.citas)
      }
    case AGENDA_CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
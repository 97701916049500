import Axios from '../../config/axios';
import AxiosV2 from '../../config/axiosv2';
import {
  DOCTOR_CREATE,
  DOCTOR_UPDATE,
  GRUPO_CREATE,
  GRUPO_LIST,
  GRUPO_LOADING,
  GRUPO_PROVIDER_CREATE
} from '../constans';
import handler from './handler';

function loader(val) {
  return ({ type: GRUPO_LOADING, payload: val })
}

export const createGroup = (state, cb, alt) => dispatch => {
  dispatch(loader(true))
  Axios.post('/grupos/', state)
    .then(res => {
      dispatch({
        type: alt ? GRUPO_PROVIDER_CREATE : GRUPO_CREATE,
        payload: res.data
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const destroyGroup = (id, alt) => (dispatch, getState) => {
  const state = {
    List: getState().group.list.concat(getState().group.doctores),
    ListPro: getState().group.listPro,
  };

  if (alt) {
    state.ListPro = state.ListPro.filter(item => item.idGrupo !== id);
  } else {
    state.List = state.List.filter((item) => item.idGrupo !== id);
  }

  Axios.delete(`/grupos/${alt ? "proveedor/" : ""}${id}`)
    .catch(handler(dispatch))

  dispatch({
    type: GRUPO_LIST,
    payload: state,
  });
};

// Doctores
export const updateDoctor = (id, state, cb) => dispatch => {
  dispatch(loader(true))
  AxiosV2.put(`/doctores/${id}`, state)
    .then(res => dispatch({ type: DOCTOR_UPDATE, payload: res.data }))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateDoctorPIN = (id, state, cb) => dispatch => {
  dispatch(loader(true))
  AxiosV2.put(`/doctores/pin/${id}`, state)
    .then(res => dispatch({ type: DOCTOR_UPDATE, payload: res.data }))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateDoctorVisibility = (doctor) => dispatch => {
  dispatch(loader(true))
  dispatch({ type: DOCTOR_UPDATE, payload: { ...doctor, Visible: !doctor.Visible } });

  AxiosV2.put(`/doctores/visible/${doctor.idGrupo}`)
    .catch(e => {
      handler(dispatch)(e)
      dispatch({ type: DOCTOR_UPDATE, payload: doctor });
    })
    .finally(() => dispatch(loader(false)))
}

export const createDoctor = (state, cb) => dispatch => {
  dispatch(loader(true))
  AxiosV2.post(`/doctores`, state)
    .then(res => dispatch({ type: DOCTOR_CREATE, payload: res.data }))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

import React, { useMemo } from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CamposExtra from './camposextra';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import usePermisos from '../../hooks/usePermiso.js';
import { getFormatDate, getYears, parseStricDate } from '../../util/tools.js';

const InfoPaciente = ({ wsLink, paciente }) => {
    const permisos = usePermisos();
    const isDisabledToSee = permisos.is({ code: 1025 });
    
    const dateStr = useMemo(function(){
        const date = parseStricDate(paciente.FechaNacimiento);
        if (!paciente || paciente === true || date.getFullYear() === 2100 || isDisabledToSee) return null;
        return `${getFormatDate(date, 1, '/')} ${getYears(date)}`
    }, [isDisabledToSee, paciente ])

    return (
        <>
            <div className="flex grow center mt">
                <Typography variant="subtitle2" className="text-secondary" style={{ fontSize: 12 }}>
                    <strong>CONTACTO</strong>
                </Typography>
            </div>
            {
                dateStr &&
                    <div className="flex grow center">
                        <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                            <CalendarTodayIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="body2" align="center" noWrap>
                            {dateStr}
                        </Typography>
                    </div>
            }
            {
                paciente.TelefonoParaMostrar &&
                    <div className="flex grow center">
                        <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                            <PhoneIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="body2" align="center" noWrap>
                            {paciente.TelefonoParaMostrar}
                        </Typography>
                    </div>
            }
            {
                paciente.movilDeEnvio &&
                    <div className="flex grow center">
                        <a href={wsLink} target="_blank" className="flex center" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black'}}>
                            <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                                <PhoneAndroidIcon fontSize="inherit" />
                            </IconButton>
                            <Typography variant="body2" align="center" noWrap>
                                {paciente.movilDeEnvio} (Movil)
                            </Typography>
                        </a>
                    </div>
            }
            {
                paciente.correoDeEnvio &&
                    <div className="flex grow center">
                        <a href={`mailto:${paciente.correoDeEnvio}`} target="_blank" className="flex center" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                            <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                                <MailIcon fontSize="inherit" />
                            </IconButton>
                            <Typography variant="body2" align="center" noWrap>
                                {paciente.correoDeEnvio}
                            </Typography>
                        </a>
                    </div>
            }
            {
                paciente.DireccionParaMostrar &&
                    <div className="flex grow center">
                        <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                            <LocationOnIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="body2" align="center" noWrap>
                            {paciente.DireccionParaMostrar}
                        </Typography>
                    </div>
            }
            <CamposExtra campos={paciente.fields} nota={paciente.nota} />
        </>
    )
}

export default InfoPaciente;

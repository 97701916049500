import axios from 'axios';

const headers = { 'x-version': 100 };
const baseURL = 'https://appbeta.odontosys.com/api'
// const baseURL = 'https://app.odontosys.com/api'
// const baseURL = 'http://appios.odontosys.com/api'

const Axios = axios.create({ baseURL: baseURL.concat('/v2'), headers });
const AuthAxios = axios.create({ baseURL: baseURL.concat('/auth/google'), headers });
const ProxyURL = 'https://proxy.odontosys.com/api/odontosys.s3.us-east-2.amazonaws.com/';

function setCredentials(token) {
  if (token) {
    Axios.defaults.headers.common['x-auth'] = token;
    AuthAxios.defaults.headers.common['x-auth'] = token;
  } else {
    delete Axios.defaults.headers.common['x-auth'];
    delete AuthAxios.defaults.headers.common['x-auth'];
  }
}

export default Axios;
export { AuthAxios, ProxyURL, baseURL, setCredentials };


import useSWR from "swr";
import Axios from "../../config/axiosv2";

const usePacienteDentista = (open) => {
    const URL = open ? '/paciente/dentista' : null

    const {
        data: response,
        error,
        mutate,
    } = useSWR(URL, Axios.get);

    const idDentistaAutomatico = response ? response.data.idDentista : null;

    return {
        data: idDentistaAutomatico,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default usePacienteDentista;
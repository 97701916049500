import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { Laboratorio, localeDoctorSort } from "../../components/grupos/util";
import Axios, { baseURL } from "../../config/axiosv2";

const fetcher = (url) => Axios.get(url, {
    baseURL: baseURL.concat('/v1')
})

const useGrupos = (includeLab = false) => {
    const doctoresComoGrupo = useSelector(state => state.auth.user.MostrarDoctoresComoGrupo);
    const { data: response, error, mutate } = useSWR('/grupos', fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    const data = response ? response.data : null;

    const gruposProveedores = (data ? data["ListPro"] : []);

    const {
        gruposPacOriginal,
        gruposPacientes,
        gruposDoctores
    } = useMemo(() => {
        const gruposPacOriginal = (data ? data["List"] : []).sort(localeDoctorSort);
        const gruposPacientes = gruposPacOriginal.filter(item => +item.Visible > 0 && !item.idTipoDeGrupo)
        const gruposDoctores = gruposPacOriginal.filter(item => +item.Visible > 0 && item.idTipoDeGrupo)

        return {
            gruposPacOriginal,
            gruposPacientes: (doctoresComoGrupo ? [...gruposPacientes, ...gruposDoctores] : gruposPacientes).sort(localeDoctorSort),
            gruposDoctores: gruposDoctores.sort(localeDoctorSort)
        }
    }, [doctoresComoGrupo, data])


    return {
        gruposPacientes,
        gruposProveedores: includeLab ? [Laboratorio, ...gruposProveedores] : gruposProveedores,
        gruposDoctores,
        gruposPacOriginal,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useGrupos;
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Cropper from '../../crop/surfaces/modal';
import Modal from '../../util/modal';
import Form from '../forms';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import handler from '../../../redux/actions/handler';
import { updateProvider } from '../../../redux/actions/providerActions';
import useGrupos from '../../../swr/hooks/useGrupos';
import getScaledImg from '../../crop/util/normalize';
import useOpen from '../../hooks/useOpen';
import { getAvatarUri, validateImage } from '../../util/tools';

const ModalUpdateProveedor = ({ open, provider, handleClose }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.provider.loading);
    const { gruposProveedores } = useGrupos(true);
    const auth = useSelector(state => state.auth.user);
    const [state, setState] = useState()
    const [error, setError] = useState({})
    const [tempFile, setTempFile] = useState(null);
    const crop = useOpen();

    useEffect(function () {
        const grupos = provider.gruposproveedores.map(item => gruposProveedores.find(grupo => grupo.idGrupo === item.idGrupo));

        setState({
            name: provider.nombre || '',
            movil: provider.movilDeEnvio || '',
            phone: provider.TelefonoParaMostrar || '',
            email: provider.correoDeEnvio || '',
            note: provider.nota || '',
            direction: provider.DireccionParaMostrar || '',
            fields: provider.fields || {},
            groups: grupos.filter(item => item)
        })

        //eslint-disable-next-line
    }, [open, provider])

    const handleFile = e => {
        const file = e.target.files ? e.target.files[0] : null;

        if (!file) {
            return;
        } else if (!validateImage(file)) {
            return setError({ file: 'El formato del archivo no es soportado.' });
        }

        getScaledImg(file)
            .then(image => {
                setTempFile(image);
                crop.handleOpen();
                setError({ file: undefined });
            })
            .catch(handler(dispatch))
    }

    const handleChange = ({ target }) => {
        setError({});
        setState({ ...state, [target.name]: target.value })
    }

    const handleFileSubmit = (file) => {
        setState({ ...state, file })
    }

    const handleFileClose = () => {
        crop.handleClose();
        setTempFile(null);
    }

    const handleModalClose = () => {
        setState()
        handleClose();
    }

    const handleSubmit = e => {
        e.preventDefault();
        const { name, phone, movil, direction, email, note, file, fields, groups } = state;

        if (name.trim().length < 4) {
            setError({ name: 'El nombre es muy corto.' })
        } else if (name.trim().length > 150) {
            setError({ name: 'El nombre es muy largo.' })
        } else if (phone.trim().length > 40) {
            setError({ phone: 'El teléfono particular es muy largo.' })
        } else if (movil.trim().length > 50) {
            setError({ movil: 'El teléfono móvil es muy largo.' })
        } else if (email.trim().length > 50) {
            setError({ email: 'El correo electrónico excede el máximo permitido.' })
        } else if (direction.trim().length > 100) {
            setError({ direction: 'La descripción excede el máximo permitido.' })
        } else if (note.trim().length > 500) {
            setError({ note: 'La nota adicional excede el máximo permitido.' })
        } else {
            setError({})
            const proveedorData = new FormData()
            if (file) proveedorData.append("file", file.blob);
            proveedorData.append("name", name)
            proveedorData.append("phone", phone)
            proveedorData.append("movil", movil)
            proveedorData.append("direction", direction)
            proveedorData.append("email", email)
            proveedorData.append("note", note)
            proveedorData.append("file", file);
            proveedorData.append("fields", JSON.stringify(fields))
            proveedorData.append("groups", JSON.stringify(groups))
            dispatch(updateProvider(provider.idProveedor, proveedorData, state.file ? state.file.url : '', handleModalClose))
        }
    }

    const ButtonComponent = (
        <div className="btn-center">
            <Button
                color="secondary"
                className="mr-m"
                onClick={handleModalClose}
            >Cerrar</Button>
            <div className="relative">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                >Aceptar</Button>
                {
                    loading && <div className="re-loading"><CircularProgress size={22} /></div>
                }
            </div>
        </div>
    )
    
    const url = useMemo(() => {
        if(!provider) return '';
        return `url(${getAvatarUri(auth, provider, true) })`;
        // eslint-disable-next-line
    }, [ provider ])

    if (!state) return null;

    return (
        <Modal
            open={open}
            buttons={ButtonComponent}
            handleClose={handleModalClose}
        >
            <Form
                editable
                url={url}
                state={state}
                error={error}
                handleFile={handleFile}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <Cropper
                open={crop.open}
                file={tempFile}
                handleError={setError}
                handleSubmit={handleFileSubmit}
                handleClose={handleFileClose}
            />
        </Modal>
    )
}

export default ModalUpdateProveedor

export default function download({blob, link, fileName}){
  const url = link || URL.createObjectURL(blob);
  const ref = document.createElement('a');

  ref.target = "_blank";
  ref.href = url;
  ref.download = fileName;

  ref.click();

  setTimeout(function (){
    if(!link) URL.revokeObjectURL(url);
    ref.remove()
  }, 5000)
};

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  hidden: { flexGrow: 1, height: 64, backgroundColor: theme.palette.primary.main },
  root: { flexGrow: 1, height: 64 },
  menuButton: {marginRight: theme.spacing(2)},
  AppBar: {
    zIndex: theme.zIndex.appBar,
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    flexGrow: 1,
  },
  logo: {
    display: 'inline-flex',
    heigth: '100%',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    maxHeight: 40,
    maxWidth: 40
  },
  drawerPaper: {
    marginTop: 65,
    [theme.breakpoints.down('lg')]: {
      marginTop: 55,
    },
  }
}));
import React from 'react';

// Components
import GruposContainer from '../../../../pages/grupos';

const Grupos = () => {
    return (
        <div className="flex grow column">
            <GruposContainer title="Grupos Pacientes" />
        </div>
    );
}

export default Grupos;
import React from 'react'
// UI
import { IconButton, CircularProgress, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TopArrow from '@mui/icons-material/ExpandLess';
import BottomArrow from '@mui/icons-material/ExpandMore';

// UI
import { SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as HandIcon } from '../../../../assets/svgs/hand-paper.svg'
import useMovil from '../../../hooks/useMovil';

const Constrols = ({ loading, index, istitle, data, lastIndex, handleOpen, handleSwipe }) => {
    const onDirection = (direction) => () => handleSwipe(direction)
    const props = { sx: { color: istitle ? 'white' : 'inherit' } };
    const isPhone = useMovil();
    const size = isPhone ? "small" : "large"

    if (loading) {
        return (
            <Box>
                <CircularProgress size={20} {...props} />
            </Box>
        )
    }

    const showIcon = !!(data && data.importante);

    return (
        <Box display="flex" alignItems="center" flexDirection={isPhone ? "column" : "row"} height={"100%"} gap={6}>
            {
                showIcon && (
                    <Tooltip title="Pregunta importante" arrow="true" placement="top">
                        <SvgIcon fontSize={isPhone ? "small" : "medium"}>
                            <HandIcon />
                        </SvgIcon>
                    </Tooltip>
                )
            }
            {!index ? null : <IconButton onClick={onDirection(true)} {...props} size={size}><TopArrow /></IconButton>}
            {lastIndex ? null : <IconButton onClick={onDirection(false)} {...props} size={size}><BottomArrow /></IconButton>}
            <IconButton onClick={handleOpen} {...props} size={size}>
                <MoreVertIcon />
            </IconButton>
        </Box>
    );
}

export default Constrols;
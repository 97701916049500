import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { ColorIconPicker } from '../../../../../components/util/colorPicker.js';
import useMovil from '../../../../../components/hooks/useMovil.js';

const FormAgenda = ({ state, error, handleChange, handleColor, handleSubmit }) => {
    const isPhone = useMovil();
    return (
        <Grid component="form" container spacing={2} className="modal-form" onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography
                    variant={isPhone ? 'h6' : undefined}
                    align={isPhone ? 'center' : undefined}
                >
                    {`${state.id ? 'Editar' : 'Nueva'} Agenda`}
                </Typography>
            </Grid>
            <Grid item xs={12} className="my">
                <TextField
                    fullWidth
                    type="search"
                    size="small"
                    label="Nombre"
                    name="name"
                    autoComplete="off"
                    error={Boolean(error.name)}
                    helperText={error.name || ''}
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item container xs={12} justifyContent="center" alignItems="center">
                <ColorIconPicker
                    component
                    color={state.color}
                    handleColor={handleColor}
                />
            </Grid>
        </Grid>
    )
}

export default FormAgenda;

import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import TodayIcon from '@mui/icons-material/Today';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TimelineIcon from '@mui/icons-material/Timeline';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// SVG Font Awesome
import {ReactComponent as AlarmSnooze} from '../../assets/svgs/alarm-snooze.svg';
import {ReactComponent as ChartUser} from '../../assets/svgs/chart-user.svg';
import {ReactComponent as UserClock} from '../../assets/svgs/user-clock.svg';
import {ReactComponent as MoneyCheckDollarPen} from '../../assets/svgs/money-check-dollar-pen.svg';
//import FileMagnifyingGlass from '../../assets/svgs/file-magnifying-glass.svg';
//import { makeIcon } from '../util/tools.js';

const SubMenu = [
  {
    label: 'Generado',
    path: 'generado',
    icon: <EqualizerIcon fontSize="small"/>
  },
  {
    label: 'Ingresos',
    path: 'ingresos',
    icon: <EqualizerIcon fontSize="small"/>
  },
  {
    label: 'Egresos',
    path: 'egresos',
    icon: <EqualizerIcon fontSize="small"/>
  },
  {
    label: 'Pacientes Nuevos',
    path: 'pacientes/nuevos',
    icon: <AccessibilityIcon fontSize="small"/>
  },
  {
    label: 'Citas Agendadas',
    path: 'citas',
    icon: <TodayIcon fontSize="small"/>
  },
  {
    label: 'Prestaciones Realizadas',
    path: 'prestaciones',
    icon:
      <SvgIcon fontSize="small">
        <ChartUser />
      </SvgIcon>
  },/*
  {
    label: 'Buscar Prestacion',
    path: 'prestaciones/search',
    icon: makeIcon(FileMagnifyingGlass)
  },*/
  {
    label: 'Cuentas por Cobrar',
    path: 'cobrar',
    icon: <TimelineIcon fontSize="small"/>
  },
  {
    label: 'Pacientes Deudores',
    path: 'pacientes/deudores',
    icon: <AccountBalanceWalletIcon fontSize="small" />
  },
  {
    label: 'Cuentas por Pagar',
    path: 'pagar',
    icon: <TrendingDownIcon fontSize="small"/>
  },
  {
    label: 'Pago de Pacientes',
    path: 'pagos',
    icon:
      <SvgIcon fontSize="small">
        <ChartUser />
      </SvgIcon>
  },
  {
    label: 'Pago a Proveedores',
    path: 'pagos/proveedores',
    icon:
      <SvgIcon fontSize="small">
        <MoneyCheckDollarPen />
      </SvgIcon>
  },
  {
    label: 'Pacientes que Asistieron',
    path: 'pacientes/asistieron',
    icon:
      <SvgIcon fontSize="small">
        <UserClock />
      </SvgIcon>
  }
]

const FollowMenu = [
  {
    label: 'Control - Seguimiento',
    path: 'control',
    icon: <CenterFocusStrongIcon />
  },
  {
    label: 'Pacientes que Faltaron',
    path: 'pacientes/faltantes',
    icon:
      <SvgIcon fontSize="small">
        <AlarmSnooze />
      </SvgIcon>
  }
]

export {FollowMenu}
export {SubMenu}

import {
  PACIENTE_CREATE,
  PACIENTE_LIST,
  PACIENTE_LIST_LOADING,
  PACIENTE_LOADING,
  PACIENTE_UPDATE,
  PACIENTE_UPDATE_LIST,
  PACIENTE_TABLE,
  PACIENTE_SINGLE,
  PACIENTE_SINGLE_LOADING,
  PACIENTE_TABLE_LOADING,
} from '../constans';
import Axios from '../../config/axios';
import AxiosV2 from '../../config/axiosv2'
import handler from './handler';

const setTableLoading = (value) => ({ type: PACIENTE_TABLE_LOADING, payload: value })
const setLoading = (value) => ({ type: PACIENTE_LOADING, payload: value })

export const updateEstado = (data, cb) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true));
  Promise.all(data.list.map(paciente => AxiosV2.put(`/estados/${data.estado}/paciente/${paciente.idPaciente}`)))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(setLoading(false))
      dispatch(setTableLoading(false))
    })
}

export const updateGroups = (data, cb) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true));
  Axios.put('/paciente/groups', data)
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(setLoading(false))
      dispatch(setTableLoading(false))
    })
}

export const doRelation = (data, cb) => dispatch => {
  dispatch(setLoading(true))
  Axios.put('/paciente/groups', data)
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const createPacient = (data, uri, cb) => dispatch => {
  dispatch(setLoading(true))
  Axios.post('/paciente', data)
    .then(res => {
      dispatch({
        type: PACIENTE_CREATE,
        payload: { ...res.data, url: uri }
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updatePaciente = (data, id, uri, cb) => dispatch => {
  dispatch(setLoading(true))
  Axios.put('/paciente/' + id, data)
    .then(res => {
      dispatch({
        type: PACIENTE_UPDATE,
        payload: { ...res.data, url: uri }
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updateStatus = (id, state) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true))
  Axios.put('/paciente/' + id + '/status/' + state)
    .then(res =>
      dispatch({
        type: PACIENTE_UPDATE,
        payload: res.data
      })
    )
    .then(() => dispatch({ type: PACIENTE_UPDATE_LIST, payload: id }))
    .then(() => {
      dispatch(clearPaciente())
      if (state === 'deleted') dispatch(clear());
    })
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(setLoading(false))
      dispatch(setTableLoading(false))
    })
}

export const searchPaciente = (data) => dispatch => {
  dispatch({ type: PACIENTE_LIST_LOADING, payload: true })
  Axios.get('/paciente/search', { params: { label: data } })
    .then(res => {
      dispatch({
        type: PACIENTE_LIST,
        payload: res.data
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch({ type: PACIENTE_LIST_LOADING, payload: false }))
}

export const getPacienteList = (params) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true))
  AxiosV2.get('/paciente/list', { params })
    .then(res => dispatch({ type: PACIENTE_TABLE, payload: res.data }))
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(setLoading(false))
      dispatch(setTableLoading(false))
    })
}

export const updateGroupList = (paciente, groups) => dispatch => {
  dispatch(setLoading(true))
  AxiosV2.post(`/paciente/${paciente.idPaciente}/grupos`, groups)
    .then(res => {
      dispatch({
        type: PACIENTE_UPDATE,
        payload: {
          ...paciente,
          grupospacientes: res.data
        }
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const rotateImage = (paciente, mode) => dispatch => {
  dispatch(setLoading(true))
  AxiosV2.post(`/paciente/${paciente.idPaciente}/rotate/${mode}`)
    .then(res => {
      dispatch({
        type: PACIENTE_UPDATE,
        payload: {
          ...paciente,
          url: res.data
        }
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

const getId = (item) => {
  if (typeof item === "number" || item === "string") return +item;
  return (item['id'] || item['idPaciente'])
}

export const makeSingle = (item) => dispatch => {
  if (item == null) return;

  dispatch({ type: PACIENTE_SINGLE_LOADING, payload: true })
  Axios.get(`/paciente/single/${getId(item)}`)
    .then(res =>
      dispatch({
        type: PACIENTE_SINGLE,
        payload: {
          ...res.data,
          editable: item['editable']
        }
      })
    )
    .catch(e => handler(dispatch)(e))
    .finally(() => dispatch({ type: PACIENTE_SINGLE_LOADING, payload: false }))
}

export const loader = setLoading;
export const setSingle = (item) => item ? makeSingle(item) : clearPaciente();
export const clearPaciente = () => dispatch => dispatch({ type: PACIENTE_SINGLE, payload: null })
export const clear = () => dispatch => dispatch({ type: PACIENTE_LIST, payload: null })

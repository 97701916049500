import React from 'react';

import Appbar from '../../../../surfaces/appbar';

// UI
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const UsuariosHeader = ({ select, onSelectBack }) => {
    const leftIcon = select ? <IconButton onClick={onSelectBack} size="large"><ChevronLeftIcon /></IconButton> : null;
    return (
        <Appbar
            title={select ? select.label : "Permisos"}
            leftIcon={leftIcon}
            leftIconProps={{className: "min-width-50 mr-m"}}
        />
    )
}

export default UsuariosHeader;
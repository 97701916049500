import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Components
import { Box } from '@mui/material';
import Modal from '../../../../../components/util/modal';
import { generateToken } from '../../../../../config/axios';
import { setCredentials } from '../../../../../config/axiosv2';
import { USER_LOGIN } from '../../../../../redux/constans';
import { updateUserPassword } from '../provider/actions';

const defaultState = {
    original: '',
    password: '',
    copy: ''
}

const ModalUsuarioPassword = ({ select, open, onClose }) => {
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.auth.user);
    const loading = useSelector(state => state.users.loading);

    const [error, setError] = useState({});
    const [state, setState] = useState(defaultState);

    const labelInput = (select ? select.idUsuario === userLogin.idUsuario : false) ? "Contraseña Nueva" : `Contraseña nueva para ${select ? select.Nombre : ''}`;

    const handleChange = ({ target }) => {
        setState({ ...state, [target.name]: target.value })
        setError({})
    }

    const handleClose = () => {
        onClose();
        setState(defaultState);
        setError({})
    }

    const handleCallBack = (token) => {
        handleClose();
        if (token) {
            dispatch({ type: USER_LOGIN, payload: { user: userLogin, token } })

            // Set credentials
            generateToken(token);
            setCredentials(token);
        }
    }

    const handleSubmit = (e) => {
        let error = {};

        // Original
        if (!state.original.trim().length) {
            error.original = "Los datos no pueden estar vacios";
        } else if (state.original.trim().length < 6) {
            error.original = "Los datos deben ser mayor a 6 caracteres";
        }

        // Password
        if (!state.password.trim().length) {
            error.password = "Los datos no pueden estar vacios";
        } else if (state.password.trim().length < 6) {
            error.password = "Los datos deben ser mayor a 6 caracteres";
        } else if (state.password.trim() !== state.copy.trim()) {
            error.password = "Los contraseñas no coinciden";
        }

        if (error.original || error.password) {
            return setError(error);
        }

        dispatch(updateUserPassword(select.idUsuario, { original: state.original, password: state.password }, handleCallBack))
    }

    return (
        <Modal
            open={open}
            buttons={(
                <Grid item container justifyContent="flex-end">
                    <Button color="secondary" onClick={handleClose} className="mr-m">
                        Cancelar
                    </Button>
                    <div className="relative">
                        <Button color="primary" variant="contained" disabled={loading} onClick={handleSubmit}>
                            Modificar
                        </Button>
                        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                    </div>
                </Grid>
            )}
            handleClose={handleClose}
        >
            <Grid container gap={4}>
                <Grid item xs={12}>
                    <Typography>Modificar Contraseña</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        type="password"
                        size="small"
                        name="original"
                        label="Ingrese su contraseña actual"
                        variant="standard"
                        autoComplete="off"
                        value={state.original}
                        error={error.original}
                        helperText={error.original || ''}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} pt={8}>
                    <TextField
                        fullWidth
                        required
                        type="password"
                        size="small"
                        name="password"
                        label={labelInput}
                        autoComplete="new-password"
                        value={state.password}
                        error={error.password}
                        helperText={error.password || ''}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        type="password"
                        size="small"
                        name="copy"
                        label={labelInput + " (Repetir)"}
                        autoComplete="new-password"
                        value={state.copy}
                        error={error.password}
                        helperText={error.password || ''}
                        onChange={handleChange}
                    />
                </Grid>

                <Box display="flex" justifyContent="center">
                    <Typography variant="overline">
                        Al cambiar la contraseña de <strong>{select.Nombre}</strong>, deberá volver a iniciar sesión
                    </Typography>
                </Box>
            </Grid>
        </Modal >
    )
}

export default ModalUsuarioPassword
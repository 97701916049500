import {useState, useEffect} from 'react';

function UseWidth(){
  const [size, setSize] = useState(window.innerWidth);

  useEffect(function(){
    function handleResize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  return size;
}

export default UseWidth;

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Redir = ({location}) => {
  const auth = useSelector(state => state.auth.token);
  if(auth){
    return <Redirect to='/' />
  }
  return <Redirect to={{
    pathname: '/login',
    state: { continueTo: location.pathname }
  }} />
}

export default Redir;

import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Pages
import Configuracion from '../pages/configuracion.js';
import Home from '../pages/home.js';

import Boundary from '../pages/boundary.js';
import Redirect from '../pages/redir.js';
import Reset from '../pages/reset.js';
import TokenAuth from '../pages/tokenauth.js';

import Deuda from '../components/usuarios/pages/deuda.js';
import MainLoader from '../config/main.js';

// Carga sustancial
const Pacientes = React.lazy(() => import('../pages/pacientes.js'));
const AgendaGlobal = React.lazy(() => import('../pages/agendaglobal.js'))
const Historia = React.lazy(() => import('../pages/historia.js'));
const Imagenes = React.lazy(() => import('../pages/imagenes.js'));
const Cuestionario = React.lazy(() => import('../pages/cuestionario.js'));
const Cuentas = React.lazy(() => import('../pages/cuentas.js'));
const CuentasProveedor = React.lazy(() => import('../pages/cuentasProvider.js'));
const Proveedores = React.lazy(() => import('../pages/proveedores.js'));
const Reportes = React.lazy(() => import('../pages/reportes.js'));
const Found404 = React.lazy(() => import('../pages/404found.js'));
const Agenda = React.lazy(() => import('../pages/agenda.js'));
const Usuario = React.lazy(() => import('../pages/usuario.js'));
const PdfRouter = React.lazy(() => import('../pages/pdf.js'));

const AppRouter = ({ onCreatePaciente, onCreateProvider }) => {
    const user = useSelector(state => state.auth.user);
    const isAgendaAvailable = (user.idClinica === 5 || user.idClinica === 10);
    return (
        <MainLoader>
            <Boundary>
                {
                    user.estado === 6 ?
                        <Switch>
                            <Route path="*" component={Deuda} />
                        </Switch>
                        :
                        <Switch>
                            <Redirect from="/login" to="/" />
                            <Route exact path="/cuentas/proveedor/:id" component={CuentasProveedor} />
                            <Route exact path="/cuentas/:id" component={Cuentas} />

                            <Route exact path="/imagenes/:id" component={Imagenes} />
                            <Route exact path="/cuestionario/:id" component={Cuestionario} />
                            <Route exact path="/cuestionario/:id/:name" component={Cuestionario} />
                            <Route exact path="/historia/:id" component={Historia} />

                            {isAgendaAvailable ? <Route exact path="/agenda/global" render={(props) => <AgendaGlobal {...props} handleOpen={onCreatePaciente} />} /> : null}
                            <Route exact path="/agenda" render={(props) => <Agenda {...props} handleOpen={onCreatePaciente} />} />
                            {isAgendaAvailable ? <Route path="/agenda/*" render={(props) => <AgendaGlobal {...props} handleOpen={onCreatePaciente} />} /> : null}

                            <Route exact path="/pacientes" render={(props) => <Pacientes {...props} handleOpen={onCreatePaciente} />} />
                            <Route exact path="/proveedores" render={(props) => <Proveedores {...props} handleOpen={onCreateProvider} />} />
                            <Route exact path="/auth" component={TokenAuth} />
                            <Route path="/reportes" component={Reportes} />
                            <Route path="/usuario" component={Usuario} />
                            <Route path="/configuracion" component={Configuracion} />
                            <Route path="/reset/:token" component={Reset} />
                            <Route path="/create/:token" component={Reset} />
                            <Route path="/pdf" component={PdfRouter} />
                            <Route exact path="/" render={(props) => <Home {...props} handleOpen={onCreatePaciente} />} />
                            <Route path="*" component={Found404} />
                        </Switch>
                }
            </Boundary>
        </MainLoader>
    )
}

export default AppRouter

import React, { createContext, useState, useMemo } from 'react';

const Context = createContext();
const sizes = [
    { label: "Pequeña", value: "small" },
    { label: "Normal", value: "" },
    { label: "Grande", value: "big" },
    { label: "Extra Grande", value: "extra" }
]
const getDefaultValue = () => {
    const store = localStorage.getItem('fontsize')
    if(store && store.length){
        return store;
    }
    return sizes[1].label
}

function FontWrapper({ children }) {
    const [ fontSize, setFontSize ] = useState(getDefaultValue())
    const fontClassName = useMemo(function(){
        return `h-100 font-size-${sizes.find(item => item.label === fontSize).value}`
    }, [ fontSize ])

    return (
        <Context.Provider value={{ fontSize, fontClassName, setFontSize }}>
            <div className={fontClassName}>
                {children}
            </div>
        </Context.Provider>
    );
}

export const FontContext = Context;
export default FontWrapper;

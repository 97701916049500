import React from "react";

import Badge from "@mui/material/Badge";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LabelIcon from "@mui/icons-material/Label";
import { toColor } from "../util/tools.js";
import { MoreVert } from "@mui/icons-material";
import useAnchorEl from "../hooks/useAnchorEl.js";
import { useSelector } from "react-redux";
import GrupoItemMenu from "./menu.js";

function ItemGrupo({ item, handleDestroy, handleClick }) {
  const onClick = () => handleClick(item);
  const onDelete = () => handleDestroy(item);
  const menu = useAnchorEl();
  const isAdmin = useSelector((state) => !state.auth.user.TipoDeUsuario);
  return (
    <TableRow hover className="pt-m pb-m pointer">
      <TableCell style={{ width: 50 }}>
        <div
          style={{ color: toColor(item.Color, true) }}
          onClick={isAdmin ? menu.handleOpen : onClick}
        >
          {item.idTipoDeGrupo === 1 ? (
            <Badge
              color="secondary"
              variant="dot"
              className="badge-black"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <LabelIcon color="inherit" />
            </Badge>
          ) : (
            <LabelIcon color="inherit" />
          )}
        </div>
      </TableCell>
      <TableCell onClick={isAdmin ? menu.handleOpen : onClick}>
        <Typography variant="body2">{item.Nombre}</Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton size="small" onClick={isAdmin ? menu.handleOpen : onClick}>
          <MoreVert />
        </IconButton>
      </TableCell>
      <GrupoItemMenu menu={menu} onUpdate={onClick} onDelete={onDelete} />
    </TableRow>
  );
}

export default ItemGrupo;

import React from 'react';

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LabelIcon from '@mui/icons-material/Label';
import { toColor } from '../../util/tools.js';

const Grupo = ({ grupo, onSelect }) => {
  const onClick = () => onSelect(grupo);
  const isWhiteText = grupo.Color === -1;
  return (
    <Grid item xs={12} container alignItems="center" className="pointer" onClick={onClick}>
      <Grid item xs={2} justifyContent="center">
        <span className="flex middle" style={{ color: toColor(grupo.Color, true), stroke: isWhiteText ? "black" : "none" }}>
          <LabelIcon color="inherit" />
        </span>
      </Grid>
      <Grid item xs container justifyContent="flex-start" alignItems="center">
        <Typography variant="body1">
          {grupo.Nombre}
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
        <Checkbox color="primary" checked={grupo.checked || false} />
      </Grid>
    </Grid>
  )
}

export default Grupo
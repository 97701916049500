import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Grid, Button, CircularProgress } from '@mui/material';
import Modal from '../../util/modal.js'
import Form from '../form/index.js';

// Actions
import { createCuestionario, updateCuestionario } from '../../../components/cuestionario/provider/actions'

const ModalCuestionario = ({ open, select, onClose }) => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.cuestionario.loading);
    const [ name, setName ] = useState('')

    useEffect(function(){
        if(open && select){
            setName(select.nombre);
        }
    }, [ open, select ])
    
    const handleClose = () => {
        onClose();
        setName('');
    }
    const handleChange = ({ target }) => setName(target.value)
    const handleSubmit = (e) => {
        e.preventDefault();
        if(name.trim().length && name.trim().length < 100) {
            if(select){
                dispatch(updateCuestionario(select.idCuestionario, {name}, handleClose));
            } else {
                dispatch(createCuestionario({name}, handleClose))
            }
        }
    }

    const Buttons = (
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
            <Button color="secondary" className="mr-m" onClick={handleClose}>Cerrar</Button>
            <div className="relative">
                <Button color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>Guardar</Button>
                {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
            </div>
        </Grid>
    )

    return (
        <Modal open={open} buttons={Buttons} handleClose={handleClose}>
            <Form
                state={name}
                select={select}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
        </Modal>
    )
}

export default ModalCuestionario
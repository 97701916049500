import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import axios from '../../../config/axiosv2';
import handler from '../../../redux/actions/handler';

import { getCuestionarios, updateCuestionario, deleteCuestionario, createCuestionario, swipeCuestionario } from '../provider/actions';
import usePreguntas from './usePreguntas';
import { useLocation } from 'react-router-dom';

function UseCuestionario() {
    const dispatch = useDispatch();
    const location = useLocation();
    const defaultCuestionario = location.state ? location.state.cuestionario : null;

    const { list, loading } = useSelector(state => state.cuestionario);
    const [single, setSingle] = useState(defaultCuestionario);
    const questions = usePreguntas(single)

    useEffect(function(){
        dispatch(getCuestionarios());
        //eslint-disable-next-line
    }, [])

    const onSelect = (item) => setSingle(item);
    const onCreate = (name, cb) => dispatch(createCuestionario({name}, cb));
    const onUpdate = (cuestionario, name) => {
        if(name === cuestionario.nombre) return;
        const callback = () => setSingle(null);
        dispatch(updateCuestionario(cuestionario.idCuestionario, {name}, callback));
    }
    const onDestroy = (cuestionario, cb) => {
        const callback = () => {
            setSingle(null);
            if(cb) cb();
        }
        dispatch(deleteCuestionario(cuestionario.idCuestionario, callback));
    }
    const onSwipe = (isUp, cuestionario) => dispatch(swipeCuestionario(cuestionario.idCuestionario, isUp));
    const onDestroyCache = () => {
        axios.post('/cache', {url: "CUESTIONARIOS"})
        .then(() => dispatch(getCuestionarios(true)))
        .catch(handler(dispatch))
    }

    return {
        list,
        questions,
        single,
        loading,
        onSelect,
        onCreate,
        onUpdate,
        onDestroy,
        onDestroyCache,
        onSwipe
    }
}

export default UseCuestionario

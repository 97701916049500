import React from 'react';

// Components
import Container from '../components/login/surfaces/container'
import Header from '../components/login/surfaces/header'
import Form from '../components/login/surfaces/form'
import Footer from '../components/login/surfaces/footer'

const Login = () => {
  return (
    <Container>
      <Header />
      <Form />
      <Footer />
    </Container>
  )
}

export default Login

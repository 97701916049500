import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useOpen from '../hooks/useOpen.js';
import Header from './header.js';
import Modal from '../modals/viewPacient.js';
import { openControl } from '../control/provider/actions.js';
import { clearProvider } from '../../redux/actions/providerActions.js';

import TimelineIcon from '@mui/icons-material/Timeline';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import CreateIcon from '@mui/icons-material/Create';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const items = [
  {
    text: "cuentas",
    label: (<>
      <ListItemIcon>
        <TimelapseIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Cuentas" />
    </>),
  },
  {
    text: "historia",
    label: (<>
      <ListItemIcon>
        <TimelineIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Historia" />
    </>),
  },
  {
    text: "imagenes",
    label: (<>
      <ListItemIcon>
        <WallpaperIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Imágenes" />
    </>),
  },
  {
    text: "cuestionario",
    label: (<>
      <ListItemIcon>
        <MenuBookIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Cuestionario" />
    </>),
  },
  {
    text: "",
    resource: <Divider key={100} />
  },
  {
    text: 'Proximo control',
    label: (<>
      <ListItemIcon>
        <AssignmentIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Próximo control" />
    </>),
  },
  {
    text: "",
    resource: <Divider key={100} />
  },
  {
    text: 'Ficha',
    label: (<>
      <ListItemIcon>
        <CreateIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Ver Ficha" />
    </>),
  }
];

const HeaderSection = ({ title, item, display, sticky, displayLabel, handleBack, handleClickTitle }) => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const paciente = useSelector(state => state.pacient.single);
  const modal = useOpen(false);

  // Redireccionar y guardar el estado del paciente.
  const handleClick = (item, onCloseMenu) => () => {
    if(item.text === 'Ficha'){
      onCloseMenu();
      if(paciente) modal.handleOpen();
    } else if(item.text === 'Proximo control'){
      dispatch(openControl(paciente.idPaciente))
      onCloseMenu();
    } else {
      history.push('/'.concat(item.text,'/',paciente.idPaciente));
    }
  }

  const handleGoBack = () => {
    if(display) dispatch(clearProvider())
    history.push('/')
  }

  // Obtener el nombre cortado del paciente.
  const name = useMemo(function(){
    if(paciente !== true && paciente){
      return paciente.nombre.slice(0, 20).concat((paciente.nombre.length > 20 ? '...' : '.'));
    }
    return '';
  }, [paciente])

  const list = items.map(e => e.text === item ? {...e, active: true} : e);

  return (
    <>
      <Modal open={modal.open} onClose={modal.handleClose} />
      <Header
        list={list}
        handleBack={handleBack || handleGoBack}
        handleClick={handleClick}
        handleClickTitle={handleClickTitle}
        objectType
        app={display ? false : true}
        sticky={sticky}
        label={displayLabel ?? name}
        title={title}
      />
    </>
  )
}

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  displayLabel: PropTypes.string,
  display: PropTypes.any,
  sticky: PropTypes.bool,
  redirect: PropTypes.bool
}

export const Items = items;
export default HeaderSection

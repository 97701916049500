import React from 'react'

// Hooks
import useLogin from '../hooks/useLogin';
import useOpen from '../../hooks/useOpen';

// UI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Form = () => {
    const { state, error, loading, onChange, onSubmit } = useLogin();
    const { open, handleToggle } = useOpen();

    const InputProps = {
        endAdornment: (
            <InputAdornment className="pointer" position="end" onClick={handleToggle}>
                {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </InputAdornment>
        )
    }

    return (
        <form className="flex grow column mx px" onSubmit={onSubmit}>
            <TextField
                required
                fullWidth
                autoFocus
                label="Nombre de usuario"
                name="username"
                autoComplete="current-username"
                value={state.username}
                error={Boolean(error.username)}
                helperText={error.username || ''}
                onChange={onChange}
            />

            <TextField
                required
                fullWidth
                type={open ? "text" : "password"}
                name="password"
                label="Contraseña"
                className="my"
                autoComplete="current-password"
                InputProps={InputProps}
                value={state.password}
                error={Boolean(error.password)}
                helperText={error.password || ''}
                onChange={onChange}
            />
            
            <Button fullWidth variant="contained" className="relative" color="primary" type="submit" disabled={loading}>
                Iniciar Sesión
                {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
            </Button>
        </form>
    );
}

export default Form

import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useOpen from '../../hooks/useOpen';

import Groups from '../../modals/viewGroups';
import DatePicker from '../shared/datepicker';

// Util
import { DatePickerStyle } from '../../../config/theme';
import { setSingle } from '../../../redux/actions/pacienteActions';
import useMonedas from '../../../swr/hooks/useMonedas';
import { ranges } from '../shared/util';

// Context
const Context = createContext(null);

//state
const defaultState = {
  year: new Date().getFullYear(),
  month: 0,
  day: 0
}

const defaultViewState = {
  comparation: false,
  table: false,
  label: ''
}

const getDefaultDate = () => {
  const storedDate = JSON.parse(localStorage.getItem(REPORTE_FECHA));

  if (storedDate) {
    const date = new Date(storedDate);
    if (!isNaN(date.getTime())) return date;
  }

  return null;
}

export const REPORTE_MONEDA = "reporte-coin"
export const REPORTE_RANGO = "reporte-range"
export const REPORTE_METODO = "reporte-method"
export const REPORTE_GRUPO = "reporte-group"
export const REPORTE_ESTADO = "reporte-estado"
export const REPORTE_FECHA = "reporte-date"

const ReportContext = ({ children }) => {
  // Redux
  const history = useHistory();
  const dispatch = useDispatch();
  const picker = useOpen();
  const { data: monedas } = useMonedas();

  const [date, setDate] = useState(defaultState)
  const [view, setView] = useState(defaultViewState)
  const [moneda, setMoneda] = useState();
  const [range, setRange] = useState(localStorage.getItem(REPORTE_RANGO) || ranges[0]);
  const [rangeDate, setRangeDate] = useState(getDefaultDate);
  const [method, setMethod] = useState(JSON.parse(localStorage.getItem(REPORTE_METODO)) || 0)
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(JSON.parse(localStorage.getItem(REPORTE_GRUPO)) || null);
  const [estado, setEstado] = useState(localStorage.getItem(REPORTE_ESTADO) || -1);
  const [open, setOpen] = useState(false);
  const [isProvider, setProvider] = useState(false);

  useEffect(() => {
    if (monedas && !moneda) {
      setMoneda(JSON.parse(localStorage.getItem(REPORTE_MONEDA)) || monedas[0])
    }
  }, [monedas, moneda])

  useEffect(function () {
    dispatch(setSingle(null))
    //eslint-disable-next-line
  }, [])

  // Methods
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleSubmit = list => setGroups(list);
  const handleDeleteGroup = item => setGroups(old => old.filter(data => data.idGrupo !== item.idGrupo))

  // New Methods
  const handleGroup = value => {
    setGroup(value);
    localStorage.setItem(REPORTE_GRUPO, JSON.stringify(value))
  }
  const handleEstado = value => {
    setEstado(value);
    localStorage.setItem(REPORTE_ESTADO, value)
  }
  const handleMethod = (value) => {
    setMethod(value)
    localStorage.setItem(REPORTE_METODO, value)
  }
  const handleCoin = (coin) => {
    setMoneda(coin)
    localStorage.setItem(REPORTE_MONEDA, JSON.stringify(coin))
  }
  const handleRange = (value) => {
    setRange(value);
    setRangeDate(null);
    if (value.includes('Elegir')) {
      picker.handleOpen();
    } else {
      localStorage.setItem(REPORTE_RANGO, value)
    }
  }
  const handleRangeDate = (date) => {
    setRangeDate(date);
    picker.handleClose();
    localStorage.setItem(REPORTE_RANGO, range)
    localStorage.setItem(REPORTE_FECHA, JSON.stringify(date.getTime()))
  }
  const handlePushRange = (range, date) => {
    setDate(oldDate => ({ year: oldDate.year, month: 0, day: 0 }));
    setRange(range);
    setRangeDate(date);
    localStorage.setItem(REPORTE_RANGO, range)
    localStorage.setItem(REPORTE_FECHA, JSON.stringify(date.getTime()))
  }

  // Other Methods
  const handleEsc = (e) => {
    if (e.key === "Escape") {
      if (open) handleClose()
      else if (date.day) setDate({ ...date, day: 0 })
      else if (date.month) setDate({ ...date, day: 0, month: 0 })
      else history.push('/');
    }
  }

  const handleReset = (onlyResetDate) => {
    if (onlyResetDate) {
      setDate({ ...date, month: 0, day: 0 })
    } else {
      setProvider(false);
      setDate(defaultState);
      setView(defaultViewState);
      setGroups([]);
      setOpen(false);
      picker.handleClose();
    }
  }

  const handleComparation = title => {
    setView(function (view) {
      view.label = title;
      view.comparation = !!JSON.parse(localStorage.getItem(title))

      return view;
    })
  }

  const handleView = data => {
    if (view.label) localStorage.setItem(view.label, !view.comparation)
    setView(data);
  }

  const customBack = (func) => {
    if (!date.month && !date.day) {
      return func;
    }

    return handleBack;
  }

  const handleBack = () => {
    if (date.day) {
      setDate({ ...date, day: 0 })
    } else if (date.month) {
      setDate({ ...date, day: 0, month: 0 })
    } else {
      const year = new Date().getFullYear();
      if (date.year !== year) {
        setDate({ year, month: 0, day: 0 })
      }
    }
  }

  // Util
  const getType = () => {
    if (date.day) return 'day'
    else if (date.month) return 'month'

    return 'year'
  }

  const getLabel = () => {
    if (date.day) return 'Dia'
    else if (date.month) return 'Mes'

    return 'Ano'
  }

  return (
    <Context.Provider value={{
      date,
      coin: moneda,
      coins: monedas,
      view,
      group,
      groups: [],
      estado,
      range,
      rangeDate,
      method,
      picker,

      // Handle
      handleDate: setDate,
      handleCoin: handleCoin,
      handleView: handleView,
      handleReset: handleReset,
      handleGroupsProvider: setProvider,
      handleBack: handleBack,
      handleOpen: handleOpen,
      handleCustomBack: customBack,
      handleComparation: handleComparation,
      handleDeleteGroup: handleDeleteGroup,

      // News
      handleEstado: handleEstado,
      handleGroup: handleGroup,
      handleRange: handleRange,
      handleMethod: handleMethod,
      handleRangeDate: handleRangeDate,
      handlePushRange: handlePushRange,

      // util
      getType,
      getLabel
    }}>
      <Groups
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        addedList={groups}
        isProvider={isProvider}
      />
      <DatePicker
        range={range}
        picker={picker}
        date={rangeDate}
        handleDate={handleRangeDate}
        sx={DatePickerStyle}
      />
      {moneda && <div tabIndex="0" onKeyDown={handleEsc}>{children}</div>}
    </Context.Provider>
  )
};

export { Context };
export default ReportContext;

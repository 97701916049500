import { Chip } from '@mui/material';
import React, { useMemo } from 'react';
import useGrupos from '../../../swr/hooks/useGrupos.js';
import { toColor } from '../../util/tools.js';

const Grupo = ({ Color, Nombre, onClick }) => {
  const isWhiteText = Color === -1;
  return (
    <div className={isWhiteText ? "chip-letter chip-black" : "chip-letter"} onClick={onClick}>
      <Chip label={Nombre} style={{ background: toColor(Color, true) }} />
    </div>
  )
}

const GruposPaciente = ({ paciente, onClick }) => {
  const { gruposPacientes } = useGrupos();
  const grupos = useMemo(() => {
    return paciente.grupospacientes.map(grupopaciente => gruposPacientes.find(grupo => grupopaciente.idGrupo === grupo.idGrupo) || {});
  }, [paciente.grupospacientes, gruposPacientes])

  return (
    <div className="flex grow wrap pt-m">
      {grupos.map((grupo) => grupo.idGrupo && <Grupo {...grupo} key={grupo.idGrupo} onClick={onClick} />)}
    </div>
  )
}

export default GruposPaciente;

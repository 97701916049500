import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as FeedIcon } from "../../assets/svgs/undraw_new_notifications_re_xpcv.svg";

// Hooks
import useOpen from '../hooks/useOpen';

// Components
import useVersion from '../../swr/hooks/useVersion';
import useMovil from '../hooks/useMovil';
import Modal from '../util/modal';

const VersionAlert = () => {
    const [version, setVersion] = useState(localStorage.getItem(VERSION_JSON) || "");
    const [release, setRelease] = useState(null);
    const { data: serverVersion } = useVersion();
    const { open, handleOpen } = useOpen();
    const isPhone = useMovil();

    const onUpdateVersion = (newVersion) => {
        const type = getReleaseType(newVersion, version);
        setRelease(type);
        setVersion(newVersion);
        localStorage.setItem(VERSION_JSON, newVersion);
        handleOpen();
    }

    const onRefresh = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (serverVersion && serverVersion !== version) {
            onUpdateVersion(serverVersion)
        }

        // eslint-disable-next-line
    }, [serverVersion, version])

    if (!release || !open) return null;

    return (
        <Modal open buttons={(
            <Box display="flex" justifyContent="flex-end" xs={12}>
                <Button
                    onClick={onRefresh}
                    color="primary"
                    variant="contained"
                >
                    Aceptar
                </Button>
            </Box>
        )} handleClose={() => { }}>
            <Box gap={4} display="flex" flexDirection="column">
                <Box>
                    <Typography variant={isPhone ? "h6" : undefined} component="strong" align={isPhone ? 'center' : undefined}>
                        Hay una nueva versión disponible
                    </Typography>
                </Box>
                <Box display="flex" gap={4} flexDirection="column">
                    <Box display="flex" justifyContent="center">
                        <Box sx={{
                            "& svg": {
                                height: 260,
                                width: 260
                            }
                        }}>
                            <FeedIcon />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography><strong>¡<span className="text-primary">Odontosys</span> v{serverVersion}!</strong></Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography>{RELEASE_DESCRIPTION[release]}</Typography>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

const VERSION_JSON = 'APP_VERSION';
const RELEASE_TYPE = ['mayor', 'minor', 'patch']
const RELEASE_DESCRIPTION = {
    mayor: (
        <>
            <Typography align="center">
                Con esta actualización, los usuarios podrán disfrutar de un diseño más moderno y atractivo, con una interfaz más amigable y fácil de usar.
                <br />
                Además, hemos incorporado nuevas funcionalidades que permitirán a los usuarios acceder a información relevante de forma más rápida y sencilla.
            </Typography>
        </>
    ),
    minor: (
        <>
            <Typography align="center">
                Dentro esta actualización hemos añadido mejoras en la funcionalidad de la página, estas mejoras te permitirán disfrutar de nuevas funciones.
                <br />
                Aunque no es solo eso, también mejoramos la velocidad de carga de algunas secciones para que la experiencia de usuario sea más fluida.
            </Typography>
        </>
    ),
    patch: (
        <>
            <Typography align="center">
                Resolvimos algunos problemas técnicos menores y mejoramos la usabilidad de la página para hacerla más intuitiva y fácil de usar.
            </Typography>
        </>
    )
}

const getReleaseType = (newVersion, currentVersion) => {
    const newVersionData = newVersion.split('.').map(Number);
    const currentVersionData = (currentVersion || "0.0.0").split('.').map(Number);

    for (let i = 0; i < 3; i++) {
        if (newVersionData[i] !== currentVersionData[i]) {
            return RELEASE_TYPE[i];
        }
    }

    return null
}

export default VersionAlert

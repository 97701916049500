// Components
import { Menu as AgendaMenu, Container as AgendaContainer } from './components/agenda';
import { Menu as CuestionariosMenu, Container as CuestionariosContainer } from './components/cuestionarios';
// import { Menu as ImpresionMenu, Container as ImpresionContainer } from './components/impresion';
import { Menu as PreciosMenu, Container as PreciosContainer } from './components/precios';

// Pacientes
import { Menu as PacientesMenu } from './components/pacientes';
import { Container as PacientesCamposContainer } from './components/pacientes/campos';
import { Container as PacientesGruposContainer } from './components/pacientes/grupos';
import { Container as EstadosContainer } from './components/pacientes/estados';

// Proveedores
import { Menu as ProveedoresMenu } from './components/proveedores';
import { Container as ProveedoresGruposContainer }from './components/proveedores/grupos';
import { Container as ProveedorCamposContainer } from './components/proveedores/campos';

// Correos
import { Menu as NotificacionesCorreoMenu } from './components/correos';
import { Container as SMTPContainer } from './components/correos/saliente/smtp';
import { Container as GmailContainer } from './components/correos/saliente/gmail';

// Avanzado
import { Menu as AvanzadoMenu } from './components/avanzado';
import { Container as AvanzadoUsuarioContainer } from './components/avanzado/usuarios';
import { Container as AvanzadoPermisosContainer } from './components/avanzado/permisos';
import { Container as AvanzadoDoctoresContainer } from './components/avanzado/doctores';
import { Container as AvanzadoMonedasContainer } from './components/monedas';
import { Container as AvanzadoFuenteContainer } from  './components/fuente';
import { Container as AvanzadoPlus } from './components/avanzado/plus'

// Plantillas
import { Menu as PlantillasMenu} from './components/plantillas';
import { Container as PlantillasCorreoContainer } from './components/plantillas/correos';
import { Container as PlantillasSMSContainer } from './components/plantillas/sms';
import { Container as PlantillasWSContainer } from './components/plantillas/ws';
import { Container as PlantillasReciboContainer } from './components/plantillas/recibos';
import { Container as PlantillasIndicacionesContainer } from "./components/plantillas/historia";

// Menus
export const asideList = [
    { Component: AgendaMenu, title: 'agenda', label: 'agenda' },
    { Component: CuestionariosMenu, title: 'cuestionarios', label: 'cuestionarios' },
    { Component: PreciosMenu, title: 'precios', label: 'precios' },
    // { Component: ImpresionMenu, title: 'impresion', label: 'impresion' },
    { Component: NotificacionesCorreoMenu, title: 'noticorreos', label: 'noticorreos-gmail' },
    { Component: PacientesMenu, title: 'pacientes', label: 'pacientes-campos' },
    { Component: ProveedoresMenu, title: 'proveedores', label: 'proveedores-campos' },
    { Component: PlantillasMenu, title: 'plantillas', label: 'plantillas-correos' },
    { Component: AvanzadoMenu, title: 'avanzado', label: 'avanzado-usuarios' },
]

export const titleList = {
    "agenda": "Agendas",
    "cuestionarios": "Cuestionarios",
    "precios": "Listas de Precios",
    "noticorreos": "Configuración Correo",
    "pacientes": "Pacientes",
    "proveedores": "Proveedores",
    "plantillas": "Plantillas",
    "avanzado": "Avanzado",
    'noticorreos-gmail': "Gmail",
    'noticorreos-smtp': "SMTP",
    'pacientes-campos': "Campos Extras",
    'pacientes-estados': "Estados personalizados",
    'pacientes-grupos': "Grupos",
    'proveedores-campos': "Campos Extras",
    'proveedores-grupos': "Grupos",
    'plantillas-correos': "Correos",
    'plantillas-sms': "Mensajes de Texto",
    'plantillas-ws': "Whatsapp",
    'plantillas-indicaciones': "Indicaciones",
    'plantillas-recibos': "Recibos",
    'avanzado-usuarios': "Usuarios",
    'avanzado-permisos': "Permisos",
    'avanzado-doctores': "Doctores",
    'avanzado-fuente': "Fuente",
    'avanzado-monedas': "Monedas",
    'avanzado-plus': "Otras opciones",
}

// Containers
export const containerList = [
    { Component: AgendaContainer, label: 'agenda' },
    { Component: CuestionariosContainer, label: 'cuestionarios' },
    { Component: PreciosContainer, label: 'precios' },
    // { Component: MonedasContainer, title:'', label: 'monedas' },
    // { Component: ImpresionContainer, title:'', label: 'impresion' },

    // { Component: GmailContainer, title:'', label: 'noticorreos' },
    { Component: GmailContainer, title:'', label: 'noticorreos-gmail' },
    { Component: SMTPContainer, title:'', label: 'noticorreos-smtp' },

    // Pacientes
    { Component: PacientesCamposContainer, title:'', label: 'pacientes-campos' },
    { Component: EstadosContainer, title:'', label: 'pacientes-estados' },
    { Component: PacientesGruposContainer, title:'', label: 'pacientes-grupos' },

    // Proveedores
    { Component: ProveedorCamposContainer, title:'', label: 'proveedores-campos' },
    { Component: ProveedoresGruposContainer, title:'', label: 'proveedores-grupos' },
    
    // Plantillas
    { Component: PlantillasCorreoContainer, title:'', label: 'plantillas-correos' },
    { Component: PlantillasSMSContainer, title:'', label: 'plantillas-sms' },
    { Component: PlantillasWSContainer, title:'', label: 'plantillas-ws' },
    { Component: PlantillasReciboContainer, title:'', label: 'plantillas-recibos' },
    { Component: PlantillasIndicacionesContainer, title: '', label: 'plantillas-indicaciones' },

    // Avanzado
    { Component: AvanzadoUsuarioContainer, title:'', label: 'avanzado-usuarios' },
    { Component: AvanzadoPermisosContainer, title:'', label: 'avanzado-permisos' },
    { Component: AvanzadoDoctoresContainer, title:'', label: 'avanzado-doctores' },
    { Component: AvanzadoFuenteContainer, title:'', label: 'avanzado-fuente' },
    { Component: AvanzadoMonedasContainer, title:'', label: 'avanzado-monedas' },
    { Component: AvanzadoPlus, title:'', label: 'avanzado-plus' },
]
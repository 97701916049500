import React from 'react'
import { useSelector } from 'react-redux';

import {
  Grid,
  Radio,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Avatar from './file';
import Fields from './fields';
import Doctor from './doctor'

import useMovil from '../../hooks/useMovil';
import { DatePickerStyle } from '../../../config/theme';

const PacienteFormulario = ({ state, error, url, editable, idDentista, idDentistaAutomatico, checked, handleCheck, handleFile, handleChange, handleSubmit }) => {
  const auth = useSelector(state => state.auth.user);

  const handleChangeDate = (date) => {
    handleChange({
      target: {
        name: 'date',
        value: date
      }
    })
  }

  const handleFixDate = () => {
    if (state.date && state.date.getFullYear() === 2100) {
      handleChangeDate(new Date())
    }
  }

  const hnadleChangeFields = (fields) => {
    handleChange({
      target: {
        name: 'fields',
        value: fields
      }
    })
  }
  const handleChangeDoctor = (doctor) => {
    handleChange({
      target: {
        name: 'doctor',
        value: doctor
      }
    })
  }

  const isPhone = useMovil();

  return (
    <Grid component="form" gap={4} container onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
          {editable ? 'Editar Paciente' : 'Nuevo Paciente'}
        </Typography>
      </Grid>

      <Avatar
        url={url}
        file={state.file}
        error={error.file}
        handleFile={handleFile}
      />

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          type="search"
          name="name"
          size="small"
          autoComplete="off"
          label="Nombre del paciente"
          value={state.name}
          error={Boolean(error.name)}
          helperText={error.name || ''}
          onChange={handleChange}
        />
      </Grid>


      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="ID"
          name="dentist"
          autoComplete="off"
          className={idDentistaAutomatico ? "r-only" : ''}
          value={idDentistaAutomatico ? idDentista : state.dentist}
          error={idDentistaAutomatico ? false : Boolean(error.dentist)}
          helperText={idDentistaAutomatico ? "" : error.dentist || ''}
          onChange={idDentistaAutomatico ? null : handleChange}
          {...(
            idDentistaAutomatico ? {
              disabled: true,
              inputProps: {
                readOnly: true
              }
            } : {}
          )}
        />
      </Grid>

      {
        !auth.MostrarDoctoresComoGrupo && (
          <Grid item xs={12}>
            <Doctor value={state.doctor} onChange={handleChangeDoctor} />
          </Grid>
        )
      }

      <Grid item container alignItems="center" xs={12}>
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleCheck}
                name="checkedA"
                color="primary"
              />
            }
            label="Fecha de nacimiento"
          />
        </Grid>
        {checked && (
          <DatePicker
            disableFuture
            format="dd/MM/yyyy"
            id="date-picker-date"
            label="Fecha de nacimiento"
            name="date"
            value={state.date}
            maxDate={new Date(2100, 0, 2)}
            minDate={new Date(1900, 0, 1)}
            onOpen={handleFixDate}
            onChange={handleChangeDate}
            sx={DatePickerStyle}
          />
        )}
      </Grid>

      <Grid item container alignItems="center" xs={12}>
        <Typography className="mr">
          Sexo:
        </Typography>
        <FormControlLabel
          control={<Radio checked={state.sex === "F"} name="sex" value="F" onChange={handleChange} />}
          label="F"
          size="small"
        />
        <FormControlLabel
          control={<Radio color="primary" checked={state.sex === "M"} name="sex" value="M" onChange={handleChange} />}
          size="small"
          label="M"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          autoComplete="off"
          size="small"
          label="Teléfono móvil"
          name="movil"
          value={state.movil}
          error={Boolean(error.movil)}
          helperText={error.movil || ''}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          autoComplete="off"
          size="small"
          label={"Teléfono particular"}
          name="phone"
          value={state.phone}
          error={Boolean(error.phone)}
          helperText={error.phone || ''}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          autoComplete="off"
          size="small"
          label="Correo electrónico"
          name="email"
          value={state.email}
          error={Boolean(error.email)}
          helperText={error.email || ''}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          autoComplete="off"
          size="small"
          label={"Dirección"}
          name="direction"
          value={state.direction}
          error={Boolean(error.direction)}
          helperText={error.direction || ''}
          onChange={handleChange}
        />
      </Grid>

      <Fields
        state={state}
        idSuperCategoria={1}
        handleChange={hnadleChangeFields}
      />

      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          multiline
          minRows={4}
          maxRows={4}
          variant="outlined"
          size="small"
          label="Nota adicional"
          name="note"
          fullWidth
          value={state.note}
          error={Boolean(error.note)}
          helperText={error.note || ''}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default PacienteFormulario

import React from 'react'

// Hooks
import useOpen from '../../hooks/useOpen';

// Components
import Header from '../../../configuracion/components/cuestionarios/header';
import Loading from '../../util/circleLoading';
import ToolTip from '../../util/tooltip.js';
import List from '../surfaces/list';
import Modal from '../surfaces/modal';

const Container = ({ list, onSelect, onSwipe, onDestroyCache }) => {
    const modal = useOpen();
    return (
        <div>
            <Header onUpdate={onDestroyCache} onCreate={modal.handleOpen} />
            {
                list ?
                    <List title="Cuestionarios" data={list} onSwipe={onSwipe} onSelect={onSelect} />
                :
                    <Loading text="Cargando cuestionarios" />
            }
            <ToolTip handleOpen={modal.handleOpen} title="Nuevo Cuestionario" color="primary" />
            <Modal open={modal.open} onClose={modal.handleClose} />
        </div>
    )
}

export default Container;
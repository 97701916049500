import React from 'react';
import fallbackSrc from '../../assets/fallback.jpeg';

const ImageProvider = (props) => {
  const handleError = e => {
    e.target.onerror = null;
    if (!props.show) {
      e.target.style.display = 'none'
    }
    // console.clear();
  }

  return(
    <div className={props.center ? 'flex grow center' : ''}>
      <div
        style={{
          width: props.width,
          height: props.height,
          backgroundImage: `url(${fallbackSrc})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom',
          borderRadius: '50%'
        }}
      >
        <img
          {...props}
          alt={props.alt || ""}
          className="radio-50"
          onError={handleError}
        />
      </div>
    </div>
  )
};

export default ImageProvider;

import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Typography, Button, IconButton, CircularProgress } from '@mui/material';

// Hooks
import useEstados from '../hooks/useEstados';
import useOpen from '../../hooks/useOpen';
import { useHandleChange, useHandleColor } from '../../hooks/useChange';

// Components
import Dialog from '../../util/dialog'
import Modal from '../../util/modal'
import Form from '../form/index';

// Actions
import { toColor } from '../../util/tools';

const defaultState = { name: '', color: '#ffffff'};
export const dialogOptions = {close: true, title: `¿Estás seguro?`, body: 
    <div>
        <Typography variant="body2">Estas a punto de eliminar un Estado.</Typography>
        <Typography variant="body2">
            Si continua todos los pacientes que tengan ese estado asignado, quedarán sin estado.
        </Typography>
    </div>
}

const ModalEstados = ({ open, handleClose }) => {
    const dialog = useOpen();
    const { single, loading, onSelect, onUpdate, onCreate, onDestroy } = useEstados();
    const [state, setState] = useState(defaultState)

    useEffect(function(){
        if(open && single){
            setState({ name: single.nombre, color: toColor(single.color, true) })
        }
    }, [ open, single ])

    const handleSubmit = (e) => {
        e.preventDefault();
        let length = state.name.trim().length;
        if(length && length < 100) {
            if(single) onUpdate( single.idEstado, state, onClose )
            else onCreate(state, onClose)
        }
    }
    const handleDestroy = () => {
        dialog.handleClose();
        onDestroy(single.idEstado, onClose)
    }
    const handleChange = useHandleChange(setState)
    const handleColor = useHandleColor(setState)
    const onClose = () => {
        handleClose();
        onSelect(null)
        setState(defaultState);
    }

    const Buttons = (
        <Grid item container className="mt">
            <Grid container item xs={3} justifyContent="flex-start" alignItems="center">
                {single && <IconButton aria-label="delete" onClick={dialog.handleOpen} size="large"><DeleteIcon /></IconButton>}
            </Grid>
            <Grid container item xs={9} justifyContent="flex-end" alignItems="center" className="relative">
                <Button color="secondary" style={{ marginRight: 10 }} onClick={onClose}>Cerrar</Button>
                <div className="relative">
                    <Button color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>Guardar</Button>
                    {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                </div>
            </Grid>
        </Grid>
    )

    return (
        <>
            <Modal open={open} buttons={Buttons} handleClose={onClose}>
                <Form
                    state={state}
                    single={single}
                    handleColor={handleColor}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            </Modal>
            <Dialog
                open={dialog.open}
                data={dialog.open ? dialogOptions : {}}
                callback={handleDestroy}
                handleClose={dialog.handleClose}
             />
        </>
    )
}

export default ModalEstados
import React from "react";
import { Edit, LockOpen } from "@mui/icons-material";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import Menu from "../../../../../components/util/menu";

function GrupoItemMenu({ menu, onUpdate, onUpdatePIN }) {
    const handleClick = (cb) => () => {
        cb();
        menu.handleClose();
    };
    return (
        <Menu
            anchorEl={menu.anchorEl}
            open={!!menu.anchorEl}
            onClose={menu.handleClose}
        >
            <MenuItem onClick={handleClick(onUpdate)}>
                <ListItemIcon>
                    <Edit />
                </ListItemIcon>
                <ListItemText primary="Modificar" />
            </MenuItem>
            <MenuItem onClick={handleClick(onUpdatePIN)}>
                <ListItemIcon>
                    <LockOpen />
                </ListItemIcon>
                <ListItemText primary="Cambiar PIN" />
            </MenuItem>
        </Menu>
    );
}

export default GrupoItemMenu;

import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import SvgIcon from '@mui/material/SvgIcon';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ReactComponent as UserGear } from '../../../assets/svgs/user-gear.svg';

// Hooks
import useOpen from '../../../components/hooks/useOpen';

const Pacientes = ({ label, title, active, submenu, onSelect, onSelectPanel }) => {
    const list = useOpen();
    const estadosPersonalizados = useSelector(state => state.auth.user.UsarEstadosPersonalizados);

    const onClick = () => {
        onSelect(label, false)
        if (list.open && active) list.handleClose();
        else list.handleOpen();
    }
    const onClickPanel = (panel) => () => onSelectPanel(panel);
    return (
        <>
            <ListItemButton className={clsx({ active })} onClick={onClick}>
                <ListItemIcon>
                    <SvgIcon fontSize="small">
                        <UserGear />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography>Pacientes</Typography>
                </ListItemText>
                {(list.open && active) ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={(list.open && active)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="px-m">
                    <ListItemButton className={clsx({ active: submenu === `${title}-campos` })} onClick={onClickPanel(`${title}-campos`)}>
                        <ListItemText disableTypography>
                            <Typography>Campos Adicionales</Typography>
                        </ListItemText>
                    </ListItemButton>
                    {estadosPersonalizados && (
                        <ListItemButton className={clsx({ active: submenu === `${title}-estados` })} onClick={onClickPanel(`${title}-estados`)}>
                            <ListItemText disableTypography>
                                <Typography>Estados</Typography>
                            </ListItemText>
                        </ListItemButton>
                    )}
                    <ListItemButton className={clsx({ active: submenu === `${title}-grupos` })} onClick={onClickPanel(`${title}-grupos`)}>
                        <ListItemText disableTypography>
                            <Typography>Grupos</Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}

export default Pacientes;
import React from 'react';
import clsx from 'clsx';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const Precios = ({ label, active, onSelect }) => {
    const onClick = () => onSelect(label);
    return (
        <ListItemButton className={clsx({ active })} onClick={onClick}>
            <ListItemIcon><AttachMoneyIcon fontSize="small"/></ListItemIcon>
            <ListItemText disableTypography>
                <Typography>Precios</Typography>
            </ListItemText>
        </ListItemButton>
    );
}

export default Precios;
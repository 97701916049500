import axios, { baseURL } from '../../../config/axiosv2';
import handler from '../../../redux/actions/handler.js';
import { parseDateReverse } from '../../util/tools';
import {
  AGENDA_CLEAR_AFTER,
  AGENDA_CLEAR_ALL,
  AGENDA_LIST,
  AGENDA_LOADING,
  CITAS_CREATE,
  CITAS_DESTROY,
  CITAS_LIST,
  CITAS_UPDATE,
  CITAS_UPDATE_CONFIRM,
  CITAS_UPDATE_MEDIO,
  CITAS_UPDATE_STATE
} from './constants.js';

// Set loading
const setLoader = (payload, type = AGENDA_LOADING) => ({ type, payload })
export const setAgendas = agenda => ({type: AGENDA_LIST, payload: agenda})
export const clearAllDates = () => ({ type: AGENDA_CLEAR_ALL })

export const clearCache = (idAgenda, date) => {
  axios.get(`/citas/cache/${idAgenda}/${parseDateReverse(date, '/')}`)
  .catch(console.log)
}

export const getCitasPaciente = (idPaciente) => axios.get(`/citas/paciente/${idPaciente}/reporte`)

export const getCitas = (agendas, date, forceCache = 0) => (dispatch) => {
  dispatch(setLoader(true))
  const actions = [];
  agendas.filter(item => item.selected).map(agenda => {
    const copyDate = new Date(date.getTime());
    while(copyDate.getDay()){
      actions.push(axios.get(`/citas/${agenda.idAgenda}/${parseDateReverse(copyDate, '/')}`, {
        baseURL: baseURL.concat('/v3'),
        params: { noCache: forceCache }
      }))
      copyDate.setDate(copyDate.getDate() + 1);
    }
    return null;
  })

  Promise.all(actions)
  .then(res => {
    const citas = []
    res.map(response => citas.push(...response.data));
    dispatch({ type: CITAS_LIST, payload: citas, date: date })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoader(false)))
}

export const createCita = (idAgenda, body, cb) => (dispatch) => {
  dispatch(setLoader(true))
  axios.post(`/citas/${idAgenda}`, body)
  .then(res => {
    dispatch({
      type: CITAS_CREATE,
      payload: res.data
    })
  })
  .then(cb)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoader(false)))
}

export const updateCita = (idCita, body, cb, refresh) => (dispatch) => {
  dispatch(setLoader(true))
  axios.put(`/citas/${idCita}`, body)
  .then(res => {
    dispatch({
      type: CITAS_UPDATE,
      payload: res.data,
      lastIndex: idCita
    })
  })
  .catch((e) => {
    if (e.response && e.response.status === 405) refresh();
    handler(dispatch)(e);
  })
  .finally(() =>{
    dispatch(setLoader(false))
    cb()
  })
}

export const createNota = (idAgenda, body, cb) => (dispatch) => {
  dispatch(setLoader(true))
  axios.post(`/citas/notas/${idAgenda}`, body)
  .then(res => {
    dispatch({
      type: CITAS_CREATE,
      payload: res.data
    })
  })
  .then(cb)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoader(false)))
}

export const updateNota = (idCita, body, cb, refresh) => (dispatch) => {
  dispatch(setLoader(true))
  axios.put(`/citas/notas/${idCita}`, body)
  .then(res => {
    dispatch({
      type: CITAS_UPDATE,
      payload: res.data,
      lastIndex: idCita
    })
  })
  .catch((e) => {
    if (e.response && e.response.status === 405) refresh();
    handler(dispatch)(e);
  })
  .finally(() => {
    dispatch(setLoader(false))
    cb()
  })
}

export const updateCitaState = (cita, state, refresh) => (dispatch) => {
  dispatch(setLoader(true))
  axios.put(`/citas/${cita.idCita}/type/${state}`, null)
  .then(() => {
    dispatch({
      type: CITAS_UPDATE_STATE,
      payload: { ...cita, Concurrio: state }
    })
  })
  .catch((e) => {
    if (e.response && e.response.status === 405) refresh();
    handler(dispatch)(e);
  })
  .finally(() => dispatch(setLoader(false)))
}

export const updateCitaConfirm = (cita, accept, refresh) => (dispatch) => {
  dispatch(setLoader(true))
  axios.put(`/citas/${cita.idCita}/accept/${accept}`, null)
  .then(() => {
    dispatch({
      type: CITAS_UPDATE_CONFIRM,
      payload: { ...cita, Confirmada: accept ? 4 : 0 },
    })
  })
  .catch((e) => {
    if (e.response && e.response.status === 405) refresh();
    handler(dispatch)(e);
  })
  .finally(() => dispatch(setLoader(false)))
}

export const updateCitaMedio = (cita, medio) => (dispatch) => {
  dispatch(setLoader(true))
  axios.put(`/citas/${cita.idPaciente}/medio/${medio}`, null)
  .then(() => {
    dispatch({
      type: CITAS_UPDATE_MEDIO,
      payload: {
        idPaciente: cita.idPaciente,
        idCita: cita.idCita,
        paciente: {
          ...cita.paciente,
          MedioDeComunicacion: medio
        }
      }
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoader(false)))
}

export const destroyCita = (cita, cb, refresh) => (dispatch) => {
  dispatch(setLoader(true))
  axios.delete(`/citas/${cita.idCita}`)
  .then(() => {
    dispatch({
      type: CITAS_DESTROY,
      payload: cita,
    })
  })
  .then(cb)
  .catch((e) => {
    if (e.response && e.response.status === 405) refresh();
    cb();
    handler(dispatch)(e);
  })
  .finally(() => dispatch(setLoader(false)))
}

export const updateAllDates = () => dispatch => {
  dispatch({ type: AGENDA_CLEAR_AFTER })
  axios.post(`/cache`, {url: "CITAS"})
  .catch(handler(dispatch))
}
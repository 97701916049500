import React from 'react'
import useOpen from '../../../hooks/useOpen';
import TextElement, { TextInputField } from './textwrapper';

import { TextField, FormControlLabel, RadioGroup, Checkbox, Radio } from '@mui/material';

export const Multiple = ({ data, answers, onAdd, onUpdate, onDelete }) => {
    const text = useOpen();
    const onBlur = ({ target }) => {
        text.handleClose();
        if (target.value.trim().length) {
            onAdd(target.value.trim());
        }
    }
    return (
        <div className="flex grow column">
            {
                data.tipo === "radio" ?
                    <RadioGroup aria-label={data.title} name={data.title} className="flex grow">
                        {
                            answers.map((item, i) =>
                                <TextElement
                                    index={i}
                                    key={i}
                                    value={item}
                                    onUpdate={onUpdate}
                                    onDelete={onDelete}
                                >
                                    {(onClick) => <FormControlLabel value={item} control={<Radio color="primary" />} onClick={onClick} label={item} key={i} />}
                                </TextElement>
                            )
                        }
                    </RadioGroup>
                    :
                    answers.map((item, i) =>
                        <TextElement
                            index={i}
                            key={i}
                            value={item}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        >
                            {(onClick) => <FormControlLabel control={<Checkbox name={item} color="primary" />} onClick={onClick} label={item} key={i} />}
                        </TextElement>
                    )
            }
            {
                text.open ?
                    <div style={{ marginRight: 30 }}>
                        <TextInputField type="text" size="small" fullWidth autoFocus onBlur={onBlur} />
                    </div>
                    :
                    <FormControlLabel
                        control={data.tipo === "radio" ? <Radio checked={false} color="primary" /> : <Checkbox value="unchecked" color="primary" />}
                        label="Agregar..."
                        style={{ opacity: 0.5 }}
                        onClick={text.handleOpen}
                    />
            }
        </div>
    )
}

export const Single = () => (
    <div className="my-m">
        <TextField
            label="Respuesta"
            variant="outlined"
            size="small"
            fullWidth
            aria-readonly="true"
            InputProps={{
                readOnly: true,
            }}
        />
    </div>
)

export const YesNot = () => {
    return (
        <div className="flex grow">
            <FormControlLabel checked control={<Radio color="primary" size="small" />} label="Si" />
            <FormControlLabel checked={false} control={<Radio color="primary" size="small" />} label="No" />
        </div>
    )
}
import React from 'react';

// Styles
import './style/color.css';
import './style/flex.css';
import './style/material.css';
import './style/menu.css';
import './style/modal.css';
import './style/scroll.css';

// Router
import { BrowserRouter } from 'react-router-dom';
import Rol from './components/auth/rol.js';
import AppRouter from './config/router.js';

// Theme
import { CssBaseline } from '@mui/material';
import LocationContext from './components/hooks/useLocation';
import NavbarContext from './components/layout/context';
import FontWrapper from './config/font.js';
import AppTheme from './config/theme.js';

// Store
import { Provider } from 'react-redux';
import Store from './redux/store.js';

function App() {
  return (
    <FontWrapper>
      <BrowserRouter>
        <Provider store={Store}>
          <AppTheme>
            <CssBaseline />
            <NavbarContext>
              <LocationContext>
                <AppRouter />
              </LocationContext>
            </NavbarContext>
            <Rol />
          </AppTheme>
        </Provider>
      </BrowserRouter>
    </FontWrapper>
  );
}
export default App;

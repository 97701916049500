import React, { useCallback } from 'react';

// UI
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Hooks
import useOpen from '../../../../../components/hooks/useOpen';
import AgendaJourneyRest from './rest'

const AgendaJorney = ({ day, data, options, getOptionLabel, onChangeData, onChangeRest, onChangeRestDuration, onChangeCheckbox }) => {
    const rest = useOpen();

    let canBlurUpdate = true;
    const onChangeTime = (target, content, time) => {
        canBlurUpdate = false;
        onChangeData(day, content || {value: target.value}, time)
    }
    const onChangeTimeBlur = (target, time) => {
        if(!canBlurUpdate){
            canBlurUpdate = true;
            return;
        }
        canBlurUpdate = true;
        onChangeData(day, {value: target.value}, time)
    }

    // Start
    const onChangeStart = (e, select) => onChangeTime(e.target, select, 'start');
    const onChangeStartBlur = (e) => onChangeTimeBlur(e.target, 'start');

    // End
    const onChangeEnd = (e, select) => onChangeTime(e.target, select, 'end');
    const onChangeEndBlur = (e) => onChangeTimeBlur(e.target, 'end');
    

    // Checkbox
    const onChangeCheck = useCallback(function(){
        onChangeCheckbox(day);
        // eslint-disable-next-line
    }, [])

    return (
        <div className="my py-m">
            <div className="flex mx mb-m wrap">
                <Hidden mdUp>
                    <Grid container>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6}>
                            <Checkbox color="primary" checked={data.checked} onChange={onChangeCheck} />
                        </Grid>
                        <Grid item container alignItems="center" xs={6}>
                            <Typography><strong>{day}</strong>: </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                fullWidth
                                disabled={!data.checked}
                                name="start"
                                className="mx-m"
                                value={data.start}
                                options={options}
                                onChange={onChangeStart}
                                onBlur={onChangeStartBlur}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Comienza"
                                        error={data.errorType === "start"}
                                        helperText={data.errorType === "start" ? data.error : ''}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                fullWidth
                                disabled={!data.checked}
                                name="end"
                                className="mx-m"
                                value={data.end}
                                options={options}
                                onChange={onChangeEnd}
                                onBlur={onChangeEndBlur}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Termina"
                                        error={data.errorType === "end"}
                                        helperText={data.errorType === "end" ? data.error : ''}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" alignItems="flex-end">
                        {
                            rest.open ? null :
                                <Button color="primary" className="mt-m" onClick={rest.handleToggle} disabled={!data.checked} startIcon={<VisibilityIcon />}>
                                    Descanso
                                </Button>
                        }
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <div className="flex middle min-width-50">
                        <Checkbox color="primary" checked={data.checked} onChange={onChangeCheck} />
                    </div>
                    <div className="flex middle min-width-100">
                        <Typography><strong>{day}</strong>: </Typography>
                    </div>
                    <div className="flex grow right justify mx">
                        <Autocomplete
                            freeSolo
                            fullWidth
                            disabled={!data.checked}
                            name="start"
                            className="mx-m"
                            size="small"
                            value={data.start}
                            options={options}
                            onChange={onChangeStart}
                            onBlur={onChangeStartBlur}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Comienza"
                                    error={data.errorType === "start"}
                                    helperText={data.errorType === "start" ? data.error : ''}
                                />
                            }
                        />
                        <Autocomplete
                            freeSolo
                            fullWidth
                            disabled={!data.checked}
                            name="end"
                            className="mx-m"
                            size="small"
                            value={data.end}
                            options={options}
                            onChange={onChangeEnd}
                            onBlur={onChangeEndBlur}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Termina"
                                    error={data.errorType === "end"}
                                    helperText={data.errorType === "end" ? data.error : ''}
                                />
                            }
                        />
                    </div>
                    <div className="flex right end min-width-125">
                        {
                            rest.open ? null :
                                <Button color={"primary"} onClick={rest.handleToggle} disabled={!data.checked} startIcon={<VisibilityIcon />}>
                                    {'Descanso'}
                                </Button>
                        }
                    </div>
                </Hidden>
            </div>
            <AgendaJourneyRest
                day={day}
                data={data}
                options={options}
                rest={rest}
                getOptionLabel={getOptionLabel}
                onChangeRest={onChangeRest}
                onChangeRestDuration={onChangeRestDuration}
            />
            <div className="pt-m">
                <Divider light={false} />
            </div>
        </div>
    );
}

export default AgendaJorney;
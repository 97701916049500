import React from 'react';

// Icons
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import Appbar from '../../../../surfaces/appbar';

const UsuariosHeader = ({ isAdmin, onCreate }) => {
    const rightIcon = isAdmin && (
      <Hidden mdDown>
        <Button
          color="primary"
          onClick={onCreate}
          startIcon={<LibraryAddIcon />}
        >
          Nuevo Usuario
        </Button>
      </Hidden>
    );
    return (
        <Appbar
            title="Usuarios"
            rightIcon={rightIcon}
            rightIconProps={{className: "min-width-50"}}
        />
    );
}

export default UsuariosHeader;
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPermisos } from '../../usuarios/provider/actions';

const useUsuarios = () => {
    const dispatch = useDispatch();
    const list = useSelector(state => state.users.permisos);
    const [tipo, setTipo] = useState(null);
    const [permisos, setPermisos] = useState({})

    useEffect(function(){
        dispatch(getPermisos());
        // eslint-disable-next-line
    }, [])

    const onSelectTipo = (tipo) => {
        setTipo(tipo)

        if(tipo){
            let permisos = {};
            for(let code of list[tipo.type]){
                permisos[code] = 1
            }
            setPermisos(permisos)
        } else {
            setPermisos({})
        }
    }
    const onTogglePermiso = code => setPermisos({ ...permisos, [code]: !permisos[code] })

    return {
        list,
        tipo,
        permisos,
        onSelectTipo,
        onTogglePermiso
    }
}

export default useUsuarios;
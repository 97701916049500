import React from 'react';

import Appbar from '../../../surfaces/appbar';
import PlantillasRecibos from '../../../../components/recibos/template'

const Plantillas = () => {
    return (
        <div className="flex grow column">
            <Appbar title="Plantilla Recibo" className="mb" />
            <PlantillasRecibos />
        </div>
    );
}

export default Plantillas;
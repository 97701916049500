import React from 'react';

// Hooks
import useCuestionario from '../../../components/cuestionario/hooks/useCuestionario';

// Components
import Cuestionario from '../../../components/cuestionario/pages/form';
import Container from '../../../components/cuestionario/pages'

const Cuestionarios = () => {
    const { single, ...cuestionarios } = useCuestionario();
    const onSelectBack = () => cuestionarios.onSelect(null)
    return (
        <div className="flex grow column">
            {
                single ? <Cuestionario select={single} {...cuestionarios} onSelectBack={onSelectBack} />
                : <Container {...cuestionarios} />
            }
        </div>
    );
}

export default Cuestionarios;
import React from 'react'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <div className="flex column mx px">    
            <Link to="/forget" className="link mt-m mb">
                <Typography variant="body1">
                    No recuerdo mi contraseña
                </Typography>
            </Link>
            <div className="link my">
                <Typography variant="body1" color="textSecondary" className="mt" align="center">
                    Copyright © Odontosys {new Date().getFullYear()}.
                </Typography>
            </div>
        </div>
    );
}

export default Footer
import React from 'react';

// UI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Components
import Doctores from './item';

const DoctoresTable = ({ doctores }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow className="bg-primary">
                        <TableCell className="text-white" style={{width: 50}}></TableCell>
                        <TableCell className="text-white">
                            <Typography variant="body2"><strong>Nombre</strong></Typography>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {doctores.map(doctor => <Doctores doctor={doctor} isMany={doctores.length > 1} key={doctor.idGrupo} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DoctoresTable;
import React from 'react';
import { Grid, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';
import useMovil from '../hooks/useMovil'

const FormPrecio = ({ state, error, handleChange, handleCheck, handleSubmit }) => {
  const isPhone = useMovil();
  return (
    <Grid component="form" className="modal-form" container spacing={2} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
          {`${state.id ? 'Editar' : 'Nueva'} lista de Precios`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          size="small"
          label="Nombre"
          className="mb-m"
          name="name"
          autoComplete="off"
          onBlur={handleChange}
          error={Boolean(error.name)}
          helperText={error.name || ''}
          defaultValue={state.name}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.predeterminate}
              onChange={handleCheck}
              name="checkedB"
              color="primary"
            />
          }
          label="Marcar como predeterminada"
        />
      </Grid>
    </Grid >
  )
}

export default FormPrecio;

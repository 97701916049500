import React from 'react';
import data from '../../data/options.json';

// UI
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';

const From = ({ isEdit, disabled, message, selectInputProps, orderInputProps, textInputProps, onAdd, onComplete, onRemove, onClose }) => {
    return (
        <div className="flex grow between column">
            <Typography>Insertar contenido</Typography>
            <div className="my">
                <div className="flex gap-2">
                    <TextField
                        select
                        fullWidth
                        label="Posición"
                        helperText="Posición de la palabra"
                        {...selectInputProps}
                    >
                        <MenuItem value="left">Izquierda</MenuItem>
                        <MenuItem value="center">Centro</MenuItem>
                        <MenuItem value="right">Derecha</MenuItem>
                        <MenuItem value="full">Toda la línea</MenuItem>
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Orientación"
                        helperText="Orientación del texto"
                        {...orderInputProps}
                    >
                        <MenuItem value="start">Inicio</MenuItem>
                        <MenuItem value="center">Centrada</MenuItem>
                        <MenuItem value="end">Final</MenuItem>
                        <MenuItem value="justify">Justificada</MenuItem>
                    </TextField>
                </div>
            </div>
            <div>
                <TextField
                    label="Contenido"
                    {...textInputProps}
                />
                <div className="flex wrap mb-m">
                    {
                        data
                            .map(item =>
                                item.name ?
                                    <Tooltip key={item.name} title={item.description} arrow>
                                        <Typography variant="body2" onClick={onAdd(item.name)} className="option">
                                            {item.name}
                                        </Typography>
                                    </Tooltip>
                                    :
                                    <strong key={item.title} className="title mt">
                                        {item.title}
                                    </strong>
                            )
                    }
                </div>
            </div>
            <div>
                <span className="text-danger">{message}</span>
            </div>
            <div className="flex grow between">
                <div className="flex">
                    <div>
                        {
                            isEdit ?
                                <Tooltip title="Eliminar" arrow placement="right">
                                    <IconButton aria-label="delete" onClick={onRemove}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                : null
                        }
                    </div>
                </div>
                <div className="flex middle">
                    <div>
                        <Button
                            color="secondary"
                            size="small"
                            className="mx-m"
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                    </div>
                    <div>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            disabled={disabled}
                            onClick={onComplete}
                        >
                            Confirmar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default From
import React, { useEffect, useState } from 'react';

// Components
import Modal from '../../../util/modal.js'
import PreguntaForm from '../../form/question.js';

const defaultState = { title: "", mode: "yesnot", important: false };
const ModalPregunta = ({ open, single, loading, handleClose, handleUpdate, handleCreate }) => {
    const [ state, setState ] = useState(defaultState);

    useEffect(function(){
        if(open && single){
            setState({ title: single.titulo, mode: single.tipo, important: !!single.importante })
        }
    }, [open, single])
    
    const handleCheck = () => setState({...state, important: !state.important })
    const handleChange = ({ target }) => setState({ ...state, [target.name]: target.value })
    const handleModalClose = () => {
        handleClose();
        setState(defaultState);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!state.title.trim().length) return;
        
        if(single){
            handleUpdate(single, state, handleModalClose);
        } else {
            handleCreate(state, handleModalClose);
        }
    }

    return (
        <Modal open={open} handleClose={handleModalClose}>
            <PreguntaForm
                state={state}
                loading={loading}
                single={single}
                handleCheck={handleCheck}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleModalClose}
            />
        </Modal>
    )
}

export default ModalPregunta
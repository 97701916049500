import { combineReducers } from "redux";
import AgendaGlobal from '../../components/agendaglobal/provider/reducer';
import Control from '../../components/control/provider/reducer';
import Cuestionario from '../../components/cuestionario/provider/reducer';
import Estados from '../../components/estados/provider/reducer';
import Usuarios from '../../configuracion/components/avanzado/usuarios/provider/reducer';
import Agenda from './agendaReducer';
import Arancel from './arancelReducer';
import Auth from './authReducer';
import Cuentas from './cuentasReducer';
import Grupos from './grupoReducer';
import History from './historiaReducer';
import Imagen from './imagenReducer';
import Paciente from './pacienteReducer';
import ProveedorCuentas from './prodocumentReducer';
import Proveedor from './proveedorReducer';
import Reporte from './reporteReducer';

export default combineReducers({
  auth: Auth,
  pacient: Paciente,
  history: History,
  group: Grupos,
  image: Imagen,
  account: Cuentas,
  provider: Proveedor,
  prodocument: ProveedorCuentas,
  report: Reporte,
  agenda: Agenda,
  arancel: Arancel,
  control: Control,
  states: Estados,
  users: Usuarios,
  cuestionario: Cuestionario,
  agendaglobal: AgendaGlobal
});

import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import useOpen from '../../hooks/useOpen';

const CamposExtra = ({ campos, nota }) => {
    const collapse = useOpen();

    const { fields, keys } = useMemo(() => {
        if(!campos && !nota) return { keys: [] };
        if(campos && !Object.keys(campos).length && !nota) return { keys: [] };

        const fields = { ...(campos || {}), Nota: { value: nota } };
        return {
            fields,
            keys: Object.keys(fields).filter(key => fields[key].value.trim())
        };
    }, [ campos, nota ])

    if(!keys.length) return null;

    return (
        <>
            <div className="flex grow center">
                <Typography variant="body2" className="pointer text-secondary" onClick={collapse.handleToggle}>
                    {collapse.open ? 'Ver menos' : 'Ver mas'}
                </Typography>
            </div>
            {collapse.open && keys.map((key, index) => (
                <Typography variant="body2" align="center" key={index} style={key === "Nota" ? {"white-space": "pre"} : undefined}>
                    <strong>{key}</strong>: {fields[key].value}
                </Typography>
            ))}
        </>
    )
}

export default CamposExtra;

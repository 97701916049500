import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI
import { Button, CircularProgress, Grid } from '@mui/material';

// Components
import useOpen from '../../../../../components/hooks/useOpen';
import Modal from '../../../../../components/util/modal';
import ModalAgendasHabilitadas from '../../../agendaHabilitadas/surfaces/modal';
import { createUser, updateUser } from '../provider/actions';
import Form from './form';

const defaultState = {
  name: '',
  password: '',
  type: 2,
}

const ModalUsuario = ({ open, select, disabled, onClose }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.users.loading);
    const [error, setError] = useState({});
    const [state, setState] = useState(defaultState);
    const modalAgendaHabilitadas = useOpen();
    
    useEffect(function(){
        if(open && select){
            setState({
                name: select.Nombre,
                type: select.TipoDeUsuario,
                password: ''
            })
        }
    }, [ open, select ])

    const handleChange = ({ target }) => {
        setState({...state, [target.name]: target.value })
        setError({})
    }

    const handleClose = () => {
        onClose();
        setState(defaultState);
        setError({})
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let error = {};
        if(!state.name.trim().length){
            error.name = "El nombre no puede estar vacio"
        }
        if(!state.password.trim().length && !select){
            error.password = "La clave no puede estar vacia"
        }
        if(state.password.trim().length && state.password.trim().length < 6){
            error.password = "La contraseña debe ser mayor a 6 caracteres.";
        }

        if(error.name || error.password){
            return setError(error);
        }

        if(select){
            dispatch(updateUser(select.idUsuario, state, handleClose));
        } else {
            dispatch(createUser(state, handleClose))
        }
    }

    const Buttons = (
        <Grid item container justifyContent="flex-end">
            <Button color="secondary" onClick={handleClose} className="mr-m">
                Cancelar
            </Button>
            <div className="relative">
                <Button type="submit" color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>
                    {select ? 'Modificar' : 'Guardar'}
                </Button>
                {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
            </div>
        </Grid>
    )

    return (
        <>
            <Modal open={open} buttons={Buttons} handleClose={handleClose}>
                <Form
                    state={state}
                    error={error}
                    select={select}
                    disabled={disabled}
                    handleOpenAgenda={modalAgendaHabilitadas.handleOpen}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            </Modal>
            <ModalAgendasHabilitadas open={modalAgendaHabilitadas.open} usuario={select} handleClose={modalAgendaHabilitadas.handleClose} />
        </>
    )
}

export default ModalUsuario

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '../../../../../components/util/menu'
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

// Components
import useOpen from '../../../../../components/hooks/useOpen';
import ModalDialog from '../../../../../components/util/confirmDialog';
import ModalData from './modal';
import ModalPassword from './modalPassword';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import Delete from '@mui/icons-material/Delete';

// Actions
import { activateUser, destroyUser } from '../provider/actions';
import { NotInterested, RadioButtonChecked } from '@mui/icons-material';

const MenuUsuario = ({ user, administradores, anchorEl, handleClose }) => {
    const modal = useOpen();
    const modalPassword = useOpen();
    const modalDestroy = useOpen();
    const modalStatus = useOpen();
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.auth.user);
    const isAdmin = !userLogin.TipoDeUsuario;

    const onDestroy = () => {
        dispatch(destroyUser(user.idUsuario));
        modalDestroy.handleClose();
    }
    const onActivate = () => {
        dispatch(activateUser(user, !user.Activo))
        modalStatus.handleClose();
    }
    const onCloseMenu = func => () => {
        func();
        handleClose();
    }

    const options = useMemo(() => {
        const isSameUser = user.idUsuario === userLogin.idUsuario;
        
        if (!userLogin.TipoDeUsuario) {
          return (
            <>
              <MenuItem onClick={onCloseMenu(modal.handleOpen)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Editar" />
              </MenuItem>
              <MenuItem onClick={onCloseMenu(modalPassword.handleOpen)}>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primary="Modificar Contraseña" />
              </MenuItem>
              {!isSameUser && (
                <>
                  <MenuItem onClick={onCloseMenu(modalStatus.handleOpen)}>
                    <ListItemIcon>
                      {user.Activo ? <NotInterested /> : <RadioButtonChecked />}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${
                        user.Activo ? "Desactivar" : "Activar"
                      } usuario`}
                    />
                  </MenuItem>
                  <MenuItem onClick={onCloseMenu(modalDestroy.handleOpen)}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText primary="Eliminar usuario" />
                  </MenuItem>
                </>
              )}
            </>
          );
        }
        
        if (isSameUser) {
          return (
            <MenuItem onClick={onCloseMenu(modalPassword.handleOpen)}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Modificar Contraseña" />
            </MenuItem>
          );
        }
        // eslint-disable-next-line
      }, [user, userLogin]);

    return (
      <>
        <Menu
          open={anchorEl !== null}
          anchorReference="anchorPosition"
          anchorPosition={anchorEl}
          transformOrigin={{ horizontal: 0, vertical: -15 }}
          onClose={handleClose}
        >
          <ListSubheader>Acciones</ListSubheader>
          {options}
        </Menu>
        <ModalData
          open={modal.open}
          select={user}
          disabled={isAdmin && administradores === 1}
          onClose={modal.handleClose}
        />
        <ModalPassword
          open={modalPassword.open}
          select={user}
          onClose={modalPassword.handleClose}
        />
        <ModalDialog
          exit
          open={modalStatus.open}
          title={`Esta a punto de ${user.Activo ? "Desativar" : "Activar"} el usuario ${user.Nombre}`}
          text="Aceptar"
          handleClose={modalStatus.handleClose}
          handleClick={onActivate}
        />
        <ModalDialog
          exit
          open={modalDestroy.open}
          title={`Esta a punto de eliminar el usuario ${user.Nombre}`}
          text="Aceptar"
          handleClose={modalDestroy.handleClose}
          handleClick={onDestroy}
        />
      </>
    );
};

export default MenuUsuario;
import React from 'react';
import { useHistory } from 'react-router';

import Typography from '@mui/material/Typography';
import Menu from '../../util/menu'
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdjustIcon from '@mui/icons-material/Adjust';
import PlusIcon from '@mui/icons-material/PlusOne';

// Hooks
import useEstados from '../hooks/useEstados'
import { toColor } from '../../util/tools';

const EstadoSelect = ({ anchorEl, paciente, handleClose, handleChange }) => {
    const { list, getComputedStyle } = useEstados();
    const history = useHistory();

    const handleCreate = () => history.push('/estados')
    const onClick = (key) => () => {
        handleChange(key);
        handleClose();
    }

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} style={{zIndex: 100000}}>
            {
                list.map((option, key) => 
                    <MenuItem key={key} onClick={ onClick(option.idEstado) }>
                    {
                        option.idEstado === paciente.idEstadoPersonalizado ?
                            <ListItemIcon style={{color: toColor(option.color)}}>
                                <CheckCircleIcon fontSize="small" color="inherit" />
                            </ListItemIcon>
                        :
                            <ListItemIcon>
                                <Avatar style={getComputedStyle(option.color)}>.</Avatar>
                            </ListItemIcon>
                    }
                        <Typography variant="inherit">{option.nombre}</Typography>
                    </MenuItem>
                )
            }
            {
                list && !list.length ?
                <MenuItem onClick={handleCreate}>
                    <ListItemIcon>
                        <PlusIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Nuevo estado</Typography>
                </MenuItem>
                : null
            }
            {
                paciente.idEstadoPersonalizado ?
                    <>
                        <Divider />
                        <MenuItem onClick={ onClick(0) }>
                            <ListItemIcon>
                                <AdjustIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Sin estado</Typography>
                        </MenuItem>
                    </>
                : null
            }
        </Menu>
    )
}

export default EstadoSelect;
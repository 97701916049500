import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

// Components
import ToolTip from '../../../../../components/util/tooltip';
import Loading from '../../../../../components/util/circleLoading';

import Header from './header';
import Table from './table';
import Modal from './modal';

// Hooks
import useUsuarios from '../hooks/useUsuarios';
import useOpen from '../../../../../components/hooks/useOpen';

const Usuarios = () => {
    const usuarios = useUsuarios();
    const isAdmin = useSelector((state) => !state.auth.user.TipoDeUsuario);
    const administradores = useMemo(() => (usuarios || []).filter(item => !item.TipoDeUsuario).length, [ usuarios ])
    const modal = useOpen();
    return (
      <div className="flex grow column">
        <Header isAdmin={isAdmin} onCreate={modal.handleOpen} />
        {usuarios === null ? (
          <Loading text="Cargando usuarios." />
        ) : (
          <Table isAdmin={isAdmin} usuarios={usuarios} administradores={administradores} />
        )}
        <Modal open={modal.open} onClose={modal.handleClose} />
        {isAdmin && (
          <ToolTip
            handleOpen={modal.handleOpen}
            title="Nuevo Usuario"
            color="primary"
          />
        )}
      </div>
    );
}

export default Usuarios;
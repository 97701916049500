import React, { useMemo } from 'react';
import { orders, strongify } from '../util';

const ComplexItem = ({ item, height, preview, num, font, onSelect }) => {

    const items = useMemo(function(){
        return [
            item.find(item => item.align === "left"),
            item.find(item => item.align === "center"),
            item.find(item => item.align === "right"),
        ]
    }, [ item ])

    const onClick = (item) => () => onSelect(item);

    return (
        <div className="flex filled">
            {
                items.map((item, index) =>
                    item ?
                        <span
                            key={index}
                            className="filled-item"
                            onClick={preview ? undefined : onClick(item)}
                            style={{ textAlign: item.order, fontSize: height-2, fontFamily: font }}
                            dangerouslySetInnerHTML={{ __html: strongify(item.text, preview, num) }}
                        />
                    :
                        <span
                            key={index}
                            className="filled-item"
                            onClick={preview ? undefined : onClick(orders[index])}
                            style={{ height: '100%', minHeight: height+2.5 }}
                        />
                )
            }
        </div>
    )
}

export default ComplexItem;
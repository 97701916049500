import React from 'react'

import { SpinnerGround, SpinnerBody, SpinnerAlt, skChase, skChaseDot } from './spinner.module.css';

const PageLoader = () => {
  return(
    <div className={SpinnerGround+' '+SpinnerAlt}>
      <div className={SpinnerBody}>
        <div className={skChase}>
          <div className={skChaseDot}></div>
          <div className={skChaseDot}></div>
          <div className={skChaseDot}></div>
          <div className={skChaseDot}></div>
          <div className={skChaseDot}></div>
          <div className={skChaseDot}></div>
        </div>
      </div>
    </div>
  )
}

export { PageLoader }

import React from 'react';

import Typography from '@mui/material/Typography';
import Menu from '../../util/menu'
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';

import TodayIcon from '@mui/icons-material/Today';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import EventNoteIcon from '@mui/icons-material/EventNote';

import useOpen from '../../hooks/useOpen';
import ModalSendMail from '../../util/email.js';
import AlertDialog from '../../util/dialogMessage.js';
import AlertConfirm from '../../util/confirmDialog';

const PacienteMenu = ({ anchorEl, wsLink, paciente, handleRedirect, handleDelete, handleEdit, handleCitas, handleClose }) => {
    const modal = useOpen();
    const warningDelete = useOpen();
    const success = useOpen();

    const onAction = (func) => () => {
        handleClose();
        func();
    }
    const onWhatsappMessage = () => window.open(wsLink, "_blank");
    const onDelete = () => {
        warningDelete.handleClose();
        handleDelete();
    }
    return (
        <>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <ListSubheader component="li" style={{height: 42}}>
                    Agenda
                </ListSubheader>
                <MenuItem onClick={onAction(handleCitas)}>
                    <ListItemIcon>
                        <TodayIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Citas Pendientes</Typography>
                </MenuItem>
                <MenuItem onClick={onAction(handleRedirect('/reportes/citas/paciente/'))}>
                    <ListItemIcon>
                        <EventNoteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Historial de Citas</Typography>
                </MenuItem>

                <ListSubheader component="li" style={{height: 42}}>
                    Contacto
                </ListSubheader>
                <MenuItem onClick={onAction(modal.handleOpen)}>
                    <ListItemIcon>
                        <MailIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Enviar Correo</Typography>
                </MenuItem>
                <MenuItem onClick={onAction(onWhatsappMessage)}>
                    <ListItemIcon>
                        <WhatsAppIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Enviar WhatsApp</Typography>
                </MenuItem>

                <ListSubheader component="li" style={{height: 42}}>
                    Acciones
                </ListSubheader>
                <MenuItem onClick={onAction(handleEdit)}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Editar</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={onAction(warningDelete.handleOpen)}>
                    <ListItemIcon>
                        <TrashIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">Eliminar</Typography>
                </MenuItem>
            </Menu>
            <ModalSendMail {...modal} paciente={paciente} handleSuccess={success.handleOpen} />
            <AlertDialog {...success} title="¡Atención!" description="¡El Correo se ha enviado con éxito!" />
            <AlertConfirm
                exit
                title={`Estas a punto de eliminar a ${paciente.nombre}`}
                text="Eliminar"
                open={warningDelete.open}
                handleClick={onAction(onDelete)}
                handleClose={warningDelete.handleClose}
            />
        </>
    )
}

export default PacienteMenu;
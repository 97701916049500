import { CircularProgress, ListItemButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SvgIcon from "@mui/material/SvgIcon";
import { setAgenda } from "../../../redux/actions/agendaActions";

import { ReactComponent as CalendarDays } from "../../../assets/svgs/calendar-days.svg";
import handler from "../../../redux/actions/handler";
import useAgendas from "../../../swr/hooks/useAgendas";
import useOpen from "../../hooks/useOpen";
import { iconStyle, toColor } from "../../util/tools";

const AgendaList = ({ item, onSelect }) => {
  const onClick = () => onSelect(item);
  const circle = useMemo(() => {
    return {
      height: 15,
      width: 15,
      marginRight: 20,
      borderRadius: 50,
      backgroundColor: toColor(item.Color),
    };
    // eslint-disable-next-line
  }, []);
  return (
    <ListItemButton style={{ paddingLeft: 26 }} onClick={onClick}>
      <span style={circle}></span>
      <ListItemText primary={item.Nombre.toUpperCase()} />
    </ListItemButton>
  );
};

function AgendaNavbar({ handleClose, handlePush }) {
  const dispatch = useDispatch();
  const { data: agendas, isLoading } = useAgendas();
  const user = useSelector((state) => state.auth.user);
  const { open, handleToggle } = useOpen();

  const multiple = !!(agendas && agendas.length > 1);
  const isAgendaGlobalAvailable = user.idClinica === 5 || user.idClinica === 10;

  const onAgendas = () => {
    if (!agendas) return;
    if (multiple) handleToggle();
    else onSelectAgenda(agendas[0]);
  };
  const onAgendaGlobal = () => {
    const isAgendaHidden = !agendas.some((item) => item.Visible);
    const isAgendaAllHidden = !agendas.length;

    if (isAgendaHidden || isAgendaAllHidden) {
      return dispatch(handler)({
        message: isAgendaAllHidden
          ? "Actualmente no tiene ninguna agenda activa.  Solicite al administrador que acceda a 'Configuración > Usuarios' para activar las agendas a las que usted tiene acceso"
          : 'Actualmente no tiene ninguna agenda activa, vaya a "Configuración > Agendas" para activar alguna',
      });
    }

    handlePush("/agenda/global");
    handleClose();
  };
  const onSelectAgenda = (agenda) => {
    const isAgendaHidden = !agendas.some((item) => item.Visible);
    const isAgendaAllHidden = !agendas.length;

    if (isAgendaHidden || isAgendaAllHidden) {
      return dispatch(handler)({
        message: isAgendaAllHidden
          ? "Actualmente no tiene ninguna agenda activa.  Solicite al administrador que acceda a 'Configuración > Usuarios' para activar las agendas a las que usted tiene acceso"
          : 'Actualmente no tiene ninguna agenda activa, vaya a "Configuración > Agendas" para activar alguna',
      });
    }
    dispatch(setAgenda(agenda));
    handlePush("/agenda");
    handleClose();
  };

  return (
    <Fragment>
      <ListItemButton onClick={isLoading ? undefined : onAgendas}>
        <ListItemIcon>
          <SvgIcon fontSize="small" style={iconStyle}>
            <CalendarDays />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={"Agenda" + (multiple ? "s" : "")} />
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          multiple && (open ? <ExpandLess /> : <ExpandMore />)
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Divider />
          {multiple
            ? agendas.map((item) => (
              <AgendaList
                item={item}
                key={item.idAgenda}
                onSelect={onSelectAgenda}
              />
            ))
            : null}
        </List>
      </Collapse>
      {isAgendaGlobalAvailable && agendas && (
        <>
          <Divider />
          <ListItemButton onClick={onAgendaGlobal}>
            <ListItemIcon>
              <SvgIcon fontSize="small" style={iconStyle}>
                <CalendarDays />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Agenda global" />
          </ListItemButton>
        </>
      )}
    </Fragment>
  );
}

export default AgendaNavbar;
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    modal: {
        overflow: 'hidden',
    },
    root: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: '100%',
        maxWidth: 620,
        minHeight: 400,
        maxHeight: 650
    },
    paper: {
        height: '100%',
        maxHeight: 650,
        borderRadius: 6,
        overflow: 'auto !important',
        backgroundColor: 'white',
        border: '2px solid white',
        [theme.breakpoints.down('md')]: {
            padding: 10,
        },
        [theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
}));

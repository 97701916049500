import React from 'react';

// Components
import CamposContainer from './surfaces/table';

const Campos = () => {
    return (
        <div className="flex grow column">
            <CamposContainer title="Campos Pacientes" idSuperCategoria={1} />
        </div>
    );
}

export default Campos;
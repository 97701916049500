import { IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Picker from "./picker";

const IndicacionesHeader = ({
  plantilla,
  plantillas,
  editable,
  children,
  onUpdate,
  onChangePlantilla,
}) => {
  const onChange = (evt) => {
    onChangePlantilla(plantillas.find((item) => item.id === +evt.target.value));
  };
  return (
    <div className="flex pt grow between middle wrap">
      <div className="flex grow wrap">
        <TextField
          select
          fullWidth
          size="small"
          label="Seleccionar Plantilla"
          value={plantilla ? plantilla.id : 0}
          onChange={onChange}
        >
          {!plantilla && <MenuItem value={0}>Seleccionar</MenuItem>}
          {plantillas.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </TextField>
        {editable && (
          <div className="mx-m">
            <Tooltip arrow placement="right" title="Editar Nombre">
              <IconButton color="primary" onClick={onUpdate} size="large">
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      {editable && <Picker />}
      {children}
    </div>
  );
};

export default IndicacionesHeader;

import canvasSize from 'canvas-size';
import Compressor from 'compressorjs';
import { isIphone } from '../../util/tools';

function getImageDimensions(file) {
    const imgObjectUrl = URL.createObjectURL(file);
    const img = new Image();

    return new Promise((resolve, reject) => {
        img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            URL.revokeObjectURL(imgObjectUrl);
            resolve({ width, height });
        };
        img.onerror = reject;
        img.src = imgObjectUrl;
    });
}

function compressImage(imgObjectUrl, maxImageDimension) {
    return new Promise((resolve, reject) => {
        new Compressor(imgObjectUrl, {
            maxWidth: maxImageDimension,
            maxHeight: maxImageDimension,
            success(normalizedFile) {
                resolve(normalizedFile);
            },
            error(error) {
                reject(error);
            }
        });
    });
}

export default async function getScaledImg(file) {
    if(!isIphone()){
        return URL.createObjectURL(file);
    }

    let fileToUse = file;
    const imageDimensions = await getImageDimensions(file);
    const maxCanvasSize = await canvasSize.maxArea({
        usePromise: true,
        useWorker: true
    });

    // Reverse of safeArea = 2 * ((maxSize / 2) * Math.sqrt(2)) in getCroppedImg.js
    const maxImageDimension = Math.floor(
        Math.max(maxCanvasSize.width, maxCanvasSize.height) / Math.sqrt(2)
    );

    if (
        imageDimensions.width > maxImageDimension ||
        imageDimensions.height > maxImageDimension
    ) {
        fileToUse = await compressImage(file, maxImageDimension);
    }

    return URL.createObjectURL(fileToUse);
}
import React from 'react'
// UI
import { Grid } from '@mui/material';

// Components
import { Multiple as MultipleChoices, Single as SingleChoice, YesNot as YesNotchoice } from './inputs';

const Question = ({ data, answers, onAddAnswer, onUpdateAnswer, onDeleteAnswer }) => {
    return (
        <Grid item xs={12}>
            {
                data.tipo === "checkbox" || data.tipo === "radio" ?
                    <MultipleChoices
                        data={data}
                        answers={answers}
                        onAdd={onAddAnswer}
                        onUpdate={onUpdateAnswer}
                        onDelete={onDeleteAnswer}
                    />
                    :
                    data.tipo === "yesnot" ?
                        <YesNotchoice />
                        :
                        <SingleChoice />
            }
        </Grid>
    )
}

export default Question;
import { getFormatDate, getMonday, months } from '../../util/tools';

export const methods = [
  {label: "Todos", value: 0},
  {label: "Efectivo", value: 1},
  {label: "Transferencia", value: 2},
  {label: "Tarjeta de Credito", value: 3},
]

export const methodFilter = (method) => item => {
  if (method === 1) return !item.pago;
  if (method === 2) return item.pago === 4;
  if (method === 3) return item.pago === 2 || item.pago === 3;

  return true;
}

const dayToLabel = (date) => `(${getFormatDate(date || new Date(), 1, '/')})`;
const weekToLabel = (start, end) => `(${getFormatDate(start, 1, '/')} - ${getFormatDate(end, 1, '/')})`
const monthToLabel = (date) => {
  date = date || new Date();
  return `(${months[+date.getMonth()]} - ${date.getFullYear()})`
};

const labels = {
  'Elegir Dia': (date) => date ? dayToLabel(date) : "",
  'Hoy': dayToLabel,
  'Ayer': () => {
    const date = new Date();
    date.setDate(date.getDate()-1)
    return dayToLabel(date);
  },
  'Elegir Mes': (date) => date ? monthToLabel(date) : "",
  'Mes Actual': monthToLabel,
  'Mes Pasado': () => {
    const date = new Date();
    date.setMonth(date.getMonth()-1)
    return monthToLabel(date)
  },
  'Semana Actual': () => {
    const date = getMonday(new Date());
    const week = new Date(date.getTime());
    week.setDate(week.getDate() + 6)
    return weekToLabel(date, week);
  },
  'Semana Pasada': () => {
    const date = getMonday(new Date());
    date.setDate(date.getDate() - 7);
    const week = new Date(date.getTime());
    week.setDate(week.getDate() + 6)
    return weekToLabel(date, week);
  },
}

export const formatTime = (range, date) => labels[range](date)

export const ranges = [
  'Mes Actual',
  'Mes Pasado',
  'Elegir Mes',
  'Semana Actual',
  'Semana Pasada',
  'Hoy',
  'Ayer',
  'Elegir Dia'
]

export const models = {
    'Elegir Mes': { openTo: 'month', views: ['year', 'month'] },
  'Elegir Dia': { openTo: 'month', views: ['year', 'month', 'day'] },
    0: { openTo: 'month', views: ['year', 'month'] }
}
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MuiAccordion from '@mui/material/Accordion';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Hooks
import useOpen from '../../../../../components/hooks/useOpen';

// Util
import { Permisos } from '../../usuarios/util';
import { updatePermisos } from '../../usuarios/provider/actions';

const ListPermisos = ({ state, tipo, title, onTogglePermiso }) => {
    const dispatch = useDispatch();
    const expanded = useOpen();
    const loading = useSelector(state => state.users.loading);
    const [copy, setCopy] = useState(null);

    const hasChanges = useMemo(function(){
        if(!copy) return false;

        let changes = false;
        for(let permiso of Permisos[title]){
            if((!!state[permiso.code]) !== (!!copy[permiso.code])){
                changes = true;
                break
            }
        }

        return changes;
    }, [ title, state, copy ]);

    const onChangeCode = (code) => () => onTogglePermiso(code)
    const onToggleExpanded = () => {
        if(expanded.open && !hasChanges){
            expanded.handleClose();
            setCopy(null);
        } else if(!expanded.open){
            expanded.handleOpen();
            setCopy(state);
        }
    }
    const onSubmit = () => {
        const array = [];
        for(let permiso of Permisos[title]){
            if((!!state[permiso.code]) !== (!!copy[permiso.code])){
                array.push({ code: permiso.code, value: !!state[permiso.code] })
            }
        }

        if(array.length){
            const callback = () => {
                expanded.handleClose();
                setCopy(null);
            }
            dispatch(updatePermisos(tipo.type, array, callback));
        }
    }
    return (
      <Accordion
        expanded={expanded.open}
        onChange={onToggleExpanded}
        square
        className="my-m mx"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="body1"
            className="px-m"
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex grow column px-m">
            {Permisos[title].map((item) => (
              <div key={item.code}>
                <FormControlLabel
                  label={item.label}
                  color="primary"
                  control={
                    <Checkbox
                      checked={!!state[item.code]}
                      size="small"
                      color="primary"
                      onChange={onChangeCode(item.code)}
                    />
                  }
                />
              </div>
            ))}
            <div className="flex grow end">
              <div className="relative">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!hasChanges || loading}
                  onClick={onSubmit}
                >
                  Guardar
                </Button>
                {loading && (
                  <div className="re-loading">
                    <CircularProgress size={22} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
}

const Accordion = withStyles({
    root: {
        border: '1px solid #d9d9d9',
        borderRadius: 14,
        boxShadow: 'none',
        '&:not': { border: '1px solid #d9d9d9' },
        '&:before': {  display: 'none', },
        '&$expanded': { margin: 'auto' },
    },
    expanded: {}
})(MuiAccordion);

export default ListPermisos;
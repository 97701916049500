import { Button, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Axios from '../../config/axios';
import handler from '../../redux/actions/handler.js';
import useGrupos from '../../swr/hooks/useGrupos.js';
import NuevoGrupo from '../grupos/newForm.js';
import { useHandleChange, useHandleColor } from '../hooks/useChange.js';
import Modal from '../util/modal.js';
import { toColor } from '../util/tools.js';

const defaultState = {
  name: '',
  color: '#ffffff',
  hide: false
}

const EditarGrupos = ({ open, handleClose, single, alternative }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(defaultState);
  const [error, setError] = useState(null)
  const { mutate, isLoading } = useGrupos();
  const [isSaving, setIsSaving] = useState(false);
  const loading = isLoading || isSaving;

  useEffect(function () {
    if (single) {
      setState({
        name: single.Nombre,
        color: toColor(single.Color, true),
        hide: single.Visible
      })
    }
  }, [single])

  const handleChange = useHandleChange(setState)
  const handleColor = useHandleColor(setState);

  const handlePreClose = () => {
    setState(defaultState)
    handleClose();
  }
  const handleSubmit = async e => {
    e.preventDefault();
    if (state.name.trim().length <= 1) {
      return setError('El nombre del grupo es muy corto.')
    }

    setError(null);

    try {
      setIsSaving(true)
      await Axios.put(`/grupos/${alternative ? 'proveedor/' : ''}${single.idGrupo}`, state)
      handlePreClose();
      mutate();
    } catch (e) {
      handler(dispatch)(e);
    } finally {
      setIsSaving(false)
    }
  }

  const Buttons = (
    <Grid item container justifyContent="flex-end" className="mb-m mt">
      <Button color="secondary" onClick={handleClose} className="mr-m">
        Cancelar
      </Button>
      <div className="relative">
        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>
          Modificar
        </Button>
        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
      </div>
    </Grid>
  )

  return (
    <Modal open={open} buttons={Buttons} handleClose={handlePreClose}>
      <NuevoGrupo
        edit
        state={state}
        error={error}
        alternative={alternative}
        handleColor={handleColor}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default EditarGrupos

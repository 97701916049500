import {
    ESTADOS_LIST,
    ESTADOS_SINGLE,
    ESTADOS_LOADING,
    ESTADOS_CREATE,
    ESTADOS_UPDATE,
    ESTADOS_DELETE
} from './constants.js';

const initialState = {
  list: null,
  loading: false,
  single: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case ESTADOS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ESTADOS_SINGLE:
      return {
        ...state,
        single: action.payload
      }
    case ESTADOS_LIST:
      return {
        ...state,
        list: action.payload ? action.payload : action.payload,
      }
    case ESTADOS_CREATE:
      return {
        ...state,
        list: (state.list ? [...state.list, action.payload] : [action.payload])
      }
    case ESTADOS_DELETE:
      return {
        ...state,
        list: state.list.filter(item => item.idEstado !== action.payload)
      }
    case ESTADOS_UPDATE:
      return {
        ...state,
        list: state.list.map(item => (item.idEstado === action.payload.idEstado) ? action.payload : item)
      };
    default:
      return state;
  }
}
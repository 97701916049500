import React from 'react';

// Components
import EstadosContainer from '../../../../components/estados/surfaces/container'

const Estados = () => {
    return (
        <div className="flex grow column">
            <EstadosContainer title="Estados" />
        </div>
    );
}

export default Estados;
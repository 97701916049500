import React from 'react';
import clsx from 'clsx';

import List from '@mui/material/List';
import SvgIcon from '@mui/material/SvgIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { ReactComponent as PlantillaIcon } from '../../../assets/svgs/plantillas.svg';

// Hooks
import useOpen from '../../../components/hooks/useOpen';
import { useFormaDeEnvio } from '../../../components/agendaglobal/surfaces/menu/medios';

const Plantillas = ({ label, active, title, submenu, onSelect, onSelectPanel }) => {
  const list = useOpen();
  const { isSMSEnabled } = useFormaDeEnvio();

  const onClick = () => {
    onSelect(label, false)
    if (list.open && active) list.handleClose();
    else list.handleOpen()
  }
  const onClickPanel = (panel) => () => onSelectPanel(panel);
  return (
    <>
      <ListItemButton className={clsx({ active })} onClick={onClick}>
        <ListItemIcon>
          <SvgIcon fontSize="small">
            <PlantillaIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography>Plantillas</Typography>
        </ListItemText>
        {list.open && active ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={list.open && active} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className="px-m">
          <ListItemButton
            className={clsx({ active: submenu === `${title}-correos` })}
            onClick={onClickPanel(`${title}-correos`)}
          >
            <ListItemText disableTypography>
              <Typography>Correos</Typography>
            </ListItemText>
          </ListItemButton>

          {isSMSEnabled && <ListItemButton
            className={clsx({ active: submenu === `${title}-sms` })}
            onClick={onClickPanel(`${title}-sms`)}
          >
            <ListItemText disableTypography>
              <Typography>SMS</Typography>
            </ListItemText>
          </ListItemButton>}
          <ListItemButton
            className={clsx({ active: submenu === `${title}-ws` })}
            onClick={onClickPanel(`${title}-ws`)}
          >
            <ListItemText disableTypography>
              <Typography>WhatsApp</Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            className={clsx({ active: submenu === `${title}-indicaciones` })}
            onClick={onClickPanel(`${title}-indicaciones`)}
          >
            <ListItemText disableTypography>
              <Typography>Indicaciones</Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            className={clsx({ active: submenu === `${title}-recibos` })}
            onClick={onClickPanel(`${title}-recibos`)}
          >
            <ListItemText disableTypography>
              <Typography>Recibos</Typography>
            </ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
}

export default Plantillas;
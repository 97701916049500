import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleChange, useHandleColor } from '../../../../../components/hooks/useChange';
import { Grid, Button, CircularProgress } from '@mui/material';

// Components
import Modal from '../../../../../components/util/modal';
import ModalForm from './form';

// Actions
import { updateDoctor, createDoctor } from '../../../../../redux/actions/grupoActions';

// Util
import { toColor } from '../../../../../components/util/tools';

const defaultState = {
    name: '',
    hiden: false,
    color: '#ffffff'
}

const ModalDoctor = ({ open, doctor, handleClose }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.group.loading);
    const [state, setState] = useState(defaultState);
    const [error, setError] = useState({})

    const onChange = useHandleChange(setState)
    const onChangeColor = useHandleColor(setState);
    // const onChangeCheck = () => setState({ ...state, hiden: state.hiden ? false : true });

    useEffect(function () {
        if (doctor && open) {
            setState({
                name: doctor.Nombre,
                hiden: !doctor.Visible,
                color: toColor(doctor.Color, true)
            })
        }
    }, [doctor, open])

    const onClose = () => {
        setState(defaultState)
        handleClose();
    }

    const onSubmit = e => {
        e.preventDefault();

        let error = {};
        if (state.name.trim().length <= 1) {
            error.name = 'El nombre del doctor es muy corto.'
        }
        if (state.name.trim().length >= 150) {
            error.name = 'El nombre del doctor es muy largo.'
        }

        if (error.name) {
            setError(error)
        } else {
            if (doctor) {
                dispatch(updateDoctor(doctor.idGrupo, state, onClose))
            } else {
                dispatch(createDoctor(state, onClose))
            }
        }
    }

    return (
        <Modal
            open={open}
            handleClose={onClose}
            buttons={(
                <Grid item container justifyContent="flex-end">
                    <Button color="secondary" onClick={onClose} className="mr-m">
                        Cancelar
                    </Button>
                    <div className="relative">
                        <Button color="primary" variant="contained" disabled={loading} onClick={onSubmit}>
                            {doctor ? 'Modificar' : 'Guardar'}
                        </Button>
                        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                    </div>
                </Grid>
            )}>
            <ModalForm
                state={state}
                error={error}
                doctor={doctor}
                onChange={onChange}
                onChangeColor={onChangeColor}
                onSubmit={onSubmit}
            />
        </Modal>
    )
}

export default ModalDoctor

import React from 'react';

import Permisos from './list';
import Header from './header';
import PermisosTabla from './table';
import Loading from '../../../../../components/util/circleLoading';

// Hooks
import usePermisos from '../hooks/usePermisos';

const UsuariosPermisos = () => {
    const { list, permisos, tipo, onTogglePermiso, onSelectTipo } = usePermisos();
    const onSelectBack = () => onSelectTipo(null);
    return (
        <div className="flex grow column">
            <Header select={tipo} onSelectBack={onSelectBack} />
            {
                !list ? <Loading text="Cargando permisos." />
                : tipo ?
                    <Permisos tipo={tipo} permisos={permisos} onTogglePermiso={onTogglePermiso} />
                : 
                    <PermisosTabla onSelectTipo={onSelectTipo} />
            }
        </div>
    );
}

export default UsuariosPermisos;
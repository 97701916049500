import React from 'react';

// Icons
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import Modal from './modals/index';
import Appbar from '../../../surfaces/appbar';
import useOpen from '../../../../components/hooks/useOpen';

const AgendasHeader = ({ select, onCreate, onSelectBack }) => {
    const modal = useOpen();

    const leftIcon = select ? <IconButton onClick={onSelectBack} size="large"><ChevronLeftIcon /></IconButton> : null;
    const rightIcon = (
        select ? <IconButton onClick={modal.handleOpen} size="large"><EditIcon /></IconButton> :
        <Hidden mdDown>
            <Button color="primary" onClick={onCreate} startIcon={<LibraryAddIcon />}>Nueva Agenda</Button>
        </Hidden>
    );

    return (
        <div className="flex">
            <Appbar
                title={select ? select.Nombre : 'Agendas'}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                leftIconProps={{className: "min-width-50 mr-m"}}
                rightIconProps={{className: "min-width-50"}}
            />
            <Modal item={select} open={modal.open} onSubmit={onSelectBack} onClose={modal.handleClose} />
        </div>
    );
}

export default AgendasHeader;
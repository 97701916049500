import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import useCamposExtras from '../../../swr/hooks/useCamposExtras';

const FieldsFormulario = ({ state, idSuperCategoria, handleChange }) => {
  const { data, isLoading } = useCamposExtras();
  const fields = (data || []).filter(item => item.idSuperCategoria === idSuperCategoria).sort((a, b) => b.indiceDeOrden - a.indiceDeOrden).reverse();

  const eventChange = ({ target }) => {
    handleChange({
      ...state.fields,
      [target.name]: {
        value: target.value,
        id: target.id
      }
    })
  }

  if (isLoading) return 'Cargando...';

  return fields.map(function (item) {
    const defaultItemValue = state.fields[item.subCategoria] ? state.fields[item.subCategoria].value : '';
    return (
      <Grid item xs={12} key={item.id}>
        <TextField
          type="text"
          size="small"
          autoComplete="off"
          id={String(item.idTipoDeCampo)}
          label={item.subCategoria}
          name={item.subCategoria}
          defaultValue={defaultItemValue}
          onBlur={eventChange}
          fullWidth
        />
      </Grid>
    )
  });
}

export default FieldsFormulario;

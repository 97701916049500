import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../util/confirmDialog';
import Modal from '../util/modal';
import Form from './formList.js';
import Footer from './modalListFooter';

import { createListArancel, deleteListArancel, setArancelError, updateListArancel } from '../../redux/actions/arancelActions.js';
import { useAranceles } from '../../swr/hooks/useAranceles.js';
import { useHandleChange } from '../hooks/useChange.js';
import useOpen from '../hooks/useOpen.js';

const defaultState = {
  name: '',
  predeterminate: false,
  id: 0
}

const ModalArancel = ({ item, open, onSubmmit, onClose }) => {
  const [state, setState] = useState(defaultState)
  const [error, setError] = useState({})
  const dispatch = useDispatch();
  const modal = useOpen();
  const all = useSelector(state => state.arancel.all)
  const { mutate } = useAranceles();

  useEffect(function () {
    if (open && item) {
      setState({
        name: item.Nombre,
        predeterminate: item.Predeterminado,
        id: item.idArancel
      })
    }
    //eslint-disable-next-line
  }, [open, item])

  const handleClose = () => {
    onClose();
    setState(defaultState)
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (!state.name.trim().length) {
      return setError({ name: 'El nombre no puede estar vacio.' })
    }

    if (state.id) {
      dispatch(updateListArancel(state.id, state, onSubmmit))
    } else {
      dispatch(createListArancel(state, onSubmmit))
    }
  }

  const handleDelete = () => {
    modal.handleClose();

    if (all.length > 1) dispatch(deleteListArancel(state.id, onSubmmit))
    else dispatch(setArancelError("No puedes eliminar tu unica lista."))

    mutate();
  }

  const handleChange = useHandleChange(setState)
  const handleCheck = () => {
    if (item && item.Predeterminado) return;

    setState({ ...state, predeterminate: !state.predeterminate })
  }

  return (
    <Modal
      open={open}
      buttons={<Footer state={state} modal={modal} handleClose={handleClose} handleSubmit={handleSubmit} />}
      handleClose={handleClose}
    >
      <Form
        state={state}
        error={error}
        handleCheck={handleCheck}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <Dialog
        exit={true}
        open={modal.open && state.id}
        title={`Estas a punto de eliminar la lista '${state.name}' y todos los tratamientos de esta lista.`}
        handleClose={modal.handleClose}
        handleClick={handleDelete}
      />
    </Modal>
  )
}

export default ModalArancel;

import { parseDate, formatNumber, TicksToUTC } from '../../../util/tools.js';

export const headers = [
  { label: 'Pacientes', long: true, alt: true },
  { label: 'Deuda', size: 100 },
  { label: 'Ult. Pago', size: 100 },
  { label: 'Ultm. Correo', size: 100 },
  { label: 'Ultm. SMS', size: 100 }
];

export const makeTemplate = function(item){
  return [
    {label: item.Nombre.slice(0, 55), long: true, alt: true},
    {label: formatNumber(item.Deuda), size: 100},
    {label: +item.Fecha !== 0 ? parseDate(item.Fecha, true) : '-------------', size: 100},
    {label: item.tickEmail ? TicksToUTC(item.tickEmail, false) : '-------------', size: 100},
    {label: item.tickPhone ? TicksToUTC(item.tickPhone, false) : '-------------', size: 90}
  ]
}
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ColorIconPicker } from '../util/colorPicker.js';
import useMovil from '../hooks/useMovil.js';
import { Box } from '@mui/material';

const NuevoGrupo = ({ state, alternative, handleChange, handleSubmit, handleColor, edit, error }) => {
  const isPhone = useMovil();
  return (
    <Grid component="form" className="modal-form" container onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
          {edit ? 'Editar grupo' : 'Nuevo grupo'}{alternative ? ' proveedores' : ''}
        </Typography>
      </Grid>
      <Box display="flex" flexDirection="column" flexGrow={1} style={{ gap: 20 }}>
        <TextField
          fullWidth
          size="small"
          name="name"
          type="search"
          autoComplete="off"
          label="Nombre del grupo"
          value={state.name}
          error={Boolean(error)}
          helperText={error || ""}
          onChange={handleChange}
        />
        <div>
          <ColorIconPicker
            component
            color={state.color}
            handleColor={handleColor}
          />
        </div>
      </Box>
    </Grid>
  )
}

NuevoGrupo.propTypes = {
  state: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheck: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleColor: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  err: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.string
  ]),
  error: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.string
  ])
}

export default NuevoGrupo;

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Row from './item.js';
import ListLoading from './loading.js';

const ListaP = (props) => {
  const { handlePacient, checked, handleProvider } = props;

  const auth = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.pacient.loadingList);
  const loading2 = useSelector(state => state.provider.loadingList);
  const ListaPac = useSelector(state => state.pacient.list);
  const ListaPro = useSelector(state => state.provider.list);

  if(loading || loading2){
    return (
      <Grid container>
        <List style={{flexGrow: 1}}>
          <ListLoading />
        </List>
      </Grid>
    );
  }
  
  if((checked && ListaPac === null) || (!checked && ListaPro === null)){
    return null;
  }
  
  if((checked && !ListaPac.length) || (!checked && !ListaPro.length)){
    return <span>No se encontraron resultados </span>
  }

  return (
    <Grid container>
      <List style={{flexGrow: 1}}>
        {
          checked ?
            ListaPac.map((item) =>
              <Row
                auth={auth}
                data={item}
                handlePacient={handlePacient}
                handleProvider={handleProvider}
                key={item.idPaciente}
              />
            )
          :
            ListaPro.map((item) =>
              <Row
                auth={auth}
                data={item}
                isProvider
                handlePacient={handlePacient}
                handleProvider={handleProvider}
                key={item.idProveedor}
              />
            )
        }
      </List>
    </Grid>
  )
}

ListaP.propTypes = {
  checked: PropTypes.bool.isRequired,
  handlePacient: PropTypes.func.isRequired,
  handleProvider: PropTypes.func.isRequired,
}

export default ListaP;

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOADING = "USER_LOADING";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_ERROR = "USER_ERROR";
export const USER_CHANGE = "USER_CHANGE"
export const USER_BALANCE = "USER_BALANCE";
export const PERMISSION_ERROR = "PERMISSION_ERROR";
export const ALERT_ERROR = "ALERT_ERROR";
//export const USER_RESET = "USER_RESET"

export const PACIENTE_CREATE = "PACIENTE_CREATE";
export const PACIENTE_TABLE = "PACIENTE_TABLE";
export const PACIENTE_LOADING = "PACIENTE_LOADING";
export const PACIENTE_LIST = "PACIENTE_LIST";
export const PACIENTE_LIST_LOADING = "PACIENTE_LIST_LOADING";
export const PACIENTE_ERROR = "PACIENTE_ERROR";
export const PACIENTE_CLEAR_ALL = "PACIENTE_CLEAR_ALL";
export const PACIENTE_SINGLE = "PACIENTE_SINGLE";
export const PACIENTE_ERROR_SINGLE = "PACIENTE_ERROR_SINGLE"
export const PACIENTE_SINGLE_LOADING = "PACIENTE_SINGLE_LOADING"
export const PACIENTE_UPDATE = "PACIENTE_UPDATE";
export const PACIENTE_UPDATE_LIST = "PACIENTE_UPDATE_LIST";
export const PACIENTE_FILTERS = "PACIENTE_FILTERS"
export const PACIENTE_GRUPO = 'PACIENTE_GRUPO';
export const PACIENTE_CONTROL = 'PACIENTE_CONTROL';
export const PACIENTE_CONTROL_UPDATE = "PACIENTE_CONTROL_UPDATE";
export const PACIENTE_CONTROL_CREATE = "PACIENTE_CONTROL_CREATE";
export const PACIENTE_ESTADO = "PACIENTE_ESTADO";
export const PACIENTE_TABLE_LOADING = "PACIENTE_TABLE_LOADING";

export const HISTORIA_LOADING = "HISTORIA_LOADING";
export const HISTORIA_LIST = "HISTORIA_LIST";
export const HISTORIA_ERROR = "HISTORIA_ERROR";
export const HISTORIA_CREATE = "HISTORIA_CREATE";
export const HISTORIA_DELETE = "HISTORIA_DELETE";
export const HISTORIA_UPDATE = "HISTORIA_UPDATE";

export const GRUPO_CREATE = "GRUPO_CREATE";
export const GRUPO_UPDATE = "GRUPO_UPDATE";
//export const GRUPO_SINGLE = "GRUPO_SINGLE";
export const GRUPO_LIST = "GRUPO_LIST";
export const GRUPO_LOADING = "GRUPO_LOADING";
export const GRUPO_ERROR = "GRUPO_ERROR";
export const GRUPO_PROVIDER_CREATE = "GRUPO_PROVIDER_CREATE";
export const GRUPO_PROVIDER_UPDATE = "GRUPO_PROVIDER_UPDATE";

export const IMAGEN_LOADING = "IMAGEN_LOADING";
export const IMAGEN_ERROR = "IMAGEN_ERROR";
export const IMAGEN_LIST = "IMAGEN_LIST";
export const IMAGEN_CREATE = "IMAGEN_CREATE";
export const IMAGEN_CREATE_MULTI = "IMAGEN_CREATE_MULTI";
export const IMAGEN_SORT = "IMAGEN_SORT";
export const IMAGEN_UPDATE = "IMAGEN_UPDATE";
export const IMAGEN_REMOVE = "IMAGEN_REMOVE";
export const IMAGEN_SINGLE = "IMAGEN_SINGLE"

export const CUENTAS_LIST = "CUENTAS_LIST";
export const CUENTAS_LOADING = "CUENTAS_LOADING";
export const CUENTAS_ERROR = "CUENTAS_ERROR";

export const TRATAMIENTO_LIST = "TRATAMIENTO_LIST";
export const TRATAMIENTO_CREATE = "TRATAMIENTO_CREATE";
export const TRATAMIENTO_UPDATE = "TRATAMIENTO_UPDATE";
export const TRATAMIENTO_UPDATE_STATE = "TRATAMIENTO_UPDATE_STATE";
export const TRATAMIENTO_DELETE = "TRATAMIENTO_DELETE"

export const PAGO_CREATE = "PAGO_CREATE";
export const PAGO_UPDATE = "PAGO_UPDATE";
export const PAGO_NUM = "PAGO_NUM";
export const PAGO_CANCEL = "PAGO_CANCEL";

export const PROVEEDOR_LOADING = "PROVEEDOR_LOADING";
export const PROVEEDOR_LIST = "PROVEEDOR_LIST";
export const PROVEEDOR_LIST_LOADING = "PROVEEDOR_LIST_LOADING";
export const PROVEEDOR_TABLE = "PROVEEDOR_TABLE";
export const PROVEEDOR_ERROR = "PROVEEDOR_ERROR";
export const PROVEEDOR_SINGLE = "PROVEEDOR_SINGLE";
export const PROVEEDOR_CREATE = "PROVEEDOR_CREATE";
export const PROVEEDOR_UPDATE = "PROVEEDOR_UPDATE"
export const PROVEEDOR_GRUPO = "PROVEEDOR_GRUPO"
export const PROVEEDOR_CLEAN = "PROVEEDOR_CLEAN"
export const PROVEEDOR_ERROR_SINGLE = "PROVEEDOR_ERROR_SINGLE"
export const PROVEEDOR_UPDATE_LIST = "PROVEEDOR_UPDATE_LIST";
export const PROVEEDOR_TABLE_LOADING = "PROVEEDOR_TABLE_LOADING";

export const PROVEEDOR_CUENTAS_LIST = "PROVEEDOR_CUENTAS_LIST";
export const PROVEEDOR_CUENTAS_LOADING = "PROVEEDOR_CUENTAS_LOADING";
export const PROVEEDOR_CUENTAS_ERROR = "PROVEEDOR_CUENTAS_ERROR";
export const PROVEEDOR_CREATE_RECIBO = "PROVEEDOR_CREATE_RECIBO";
export const PROVEEDOR_CANCEL_RECIBO = "PROVEEDOR_CANCEL_RECIBO";
export const PROVEEDOR_CREATE_DOCUMENT = "PROVEEDOR_CREATE_DOCUMENT";
export const PROVEEDOR_UPDATE_DOCUMENT = "PROVEEDOR_UPDATE_DOCUMENT";
export const PROVEEDOR_DELETE_DOCUMENT = "PROVEEDOR_DELETE_DOCUMENT"

export const CUENTAS_COBRAR = "CUENTAS_COBRAR";
export const CUENTAS_COBRAR_LOADING = "CUENTAS_COBRAR_LOADING";
export const CUENTAS_COBRAR_ERROR = "CUENTAS_COBRAR_ERROR";

export const CUENTAS_PAGOS = "CUENTAS_PAGOS";
export const CUENTAS_PAGOS_LOADING = "CUENTAS_PAGOS_LOADING";
export const CUENTAS_PAGOS_ERROR = "CUENTAS_PAGOS_ERROR";

export const PROVEEDORES_PAGOS = "PROVEEDORES_PAGOS";
export const PROVEEDORES_PAGOS_LOADING = "PROVEEDORES_PAGOS_LOADING";
export const PROVEEDORES_PAGOS_ERROR = "PROVEEDORES_PAGOS_ERROR";

export const CUENTAS_PAGAR = "CUENTAS_PAGAR";
export const CUENTAS_PAGAR_LOADING = "CUENTAS_PAGAR_LOADING";
export const CUENTAS_PAGAR_ERROR = "CUENTAS_PAGAR_ERROR";

export const CUENTAS_INGRESOS_LOADING = "CUENTAS_INGRESOS_LOADING";
export const CUENTAS_INGRESOS = "CUENTAS_INGRESOS";
export const CUENTAS_INGRESOS_ERROR = "CUENTAS_INGRESOS_ERROR";

export const CUENTAS_PRESTACIONES_LOADING = "CUENTAS_PRESTACIONES_LOADING";
export const CUENTAS_PRESTACIONES = "CUENTAS_PRESTACIONES";
export const CUENTAS_PRESTACIONES_ERROR = "CUENTAS_PRESTACIONES_ERROR";

export const PRESTACIONES_SEARCH_LOADING = "PRESTACIONES_SEARCH_LOADING"
export const PRESTACIONES_SEARCH = "PRESTACIONES_SEARCH"

export const PACIENTES_NUEVOS = "PACIENTES_NUEVOS";
export const PACIENTES_NUEVOS_ERROR = "PACIENTES_NUEVOS_ERROR";
export const PACIENTES_NUEVOS_LOADING = "PACIENTES_NUEVOS_LOADING";

export const PACIENTES_FALTARON = "PACIENTES_FALTARON";
export const PACIENTES_FALTARON_ERROR = "PACIENTES_FALTARON_ERROR";
export const PACIENTES_FALTARON_LOADING = "PACIENTES_FALTARON_LOADING";

export const AGENDA_LOADING = "AGENDA_LOADING";
export const AGENDA_LIST = "AGENDA_LIST";
export const AGENDA_ERROR = "AGENDA_ERROR";
export const AGENDA_SINGLE = "AGENDA_SINGLE";
export const AGENDA_SELECT = "AGENDA_SELECT"
export const AGENDA_LIST_HIDEN = "AGENDA_LIST_HIDEN"
export const AGENDA_LIST_UPDATE = "AGENDA_LIST_UPDATE";
export const AGENDA_LIST_CREATE = "AGENDA_LIST_CREATE";
export const CLEAR_AFTER = "CLEAR_AFTER";
export const CITA_CHANGE = "CITA_CHANGE";
export const CITA_NOTE = "CITA_NOTE";

export const CITAS_LIST = "CITAS_LIST";
export const CITAS_CLEAR = "CITAS_CLEAR";

export const CITA_ACTIVE = "CITA_ACTIVE";
export const CITA_HOURS = "CITA_HOURS";
export const CITA_CONTEXT = "CITA_CONTEXT";
export const CITA_MEDIOS = "CITA_MEDIOS"
export const CITA_MENU_CLOSE = "CITA_MENU_CLOSE"

export const CITAS_MAKE = "CITAS_MAKE"
export const CITAS_UPDATE = "CITAS_UPDATE"
export const CITAS_DELETE = "CITAS_DELETE"
export const CITAS_STATE_UPDATE = "CITAS_STATE_UPDATE"
export const CITAS_UPDATE_MEDIO = "CITAS_UPDATE_MEDIO"

export const PACIENTES_CITAS = "PACIENTES_CITAS";
export const PACIENTES_CITAS_ERROR = "PACIENTES_CITAS_ERROR";
export const PACIENTES_CITAS_LOADING = "PACIENTES_CITAS_LOADING";

export const ARANCEL_LIST = "ARANCELES_LIST";
export const ARANCEL_LISTS = "ARANCEL_LISTS"
export const ARANCEL_LIST_SELECT = "ARANCEL_LIST_SELECT"
export const ARANCEL_LIST_LOADING = "ARANCELES_LIST_LOADING";
export const ARANCEL_LIST_ERROR = "ARANCELES_LIST_ERROR";
export const ARANCEL_SINGLE = "ARANCEL_SINGLE";
export const ARANCEL_UPDATE = "ARANCEL_UPDATE";
export const ARANCEL_CREATE = "ARANCEL_CREATE";
export const ARANCEL_DELETE = "ARANCEL_DELETE";
export const ARANCEL_ITEM_SELECT = "ARANCEL_ITEM_SELECT";
export const ARANCEL_LIST_EDIT = "ARANCEL_LIST_EDIT";
export const ARANCEL_LIST_DELETE = "ARANCEL_LIST_DELETE"
export const ARANCEL_LIST_CREATE = "ARANCEL_LIST_CREATE";
export const ARANCEL_LIST_UPDATE = "ARANCEL_LIST_UPDATE";

export const PACIENTES_VINIERON_LOADING = "PACIENTES_VINIERON_LOADING";
export const PACIENTES_VINIERON = "PACIENTES_VINIERON";
export const PACIENTES_VINIERON_ERROR = "PACIENTES_VINIERON_ERROR";

export const CONTROL_REPORTE = "CONTROL_REPORTE";
export const CONTROL_LOADING = "CONTROL_REPORTE_LOADING";

export const PACIENTES_DEUDORES = "PACIENTES_DEUDORES";
export const PACIENTES_DEUDORES_LOADING = "PACIENTES_DEUDORES_LOADING"

export const PACIENTES_NUEVOS_LIST = "PACIENTES_NUEVOS_LIST";
export const PACIENTES_NUEVOS_LIST_LOADING = "PACIENTES_NUEVOS_LIST_LOADING"

export const PACIENTES_INGRESOS = "PACIENTES_INGRESOS";
export const PACIENTES_INGRESOS_LOADING = "PACIENTES_INGRESOS_LOADING"

export const PACIENTES_EGRESOS_LOADING = "PACIENTES_EGRESOS_LOADING";
export const PACIENTES_EGRESOS = "PACIENTES_EGRESOS";

export const DOCTOR_UPDATE = "DOCTOR_UPDATE";
export const DOCTOR_CREATE = "DOCTOR_CREATE";

export const CLEAN_ALL_REPORTS = "CLEAN_ALL_REPORTS";
import useSWR from "swr";
import Axios, { baseURL } from "../../config/axiosv2";


const fetcher = (url) => Axios.get(url, {
    baseURL: baseURL.concat('/v3')
});

const useAgendasJornada = () => {
    const { data: response, error, mutate } = useSWR(`/agendas?jornada=1`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    const data = response ? response.data : [];

    return {
        data: data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useAgendasJornada;

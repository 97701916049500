import React, { useState, useCallback } from 'react';
import useMovil from '../../hooks/useMovil.js';

// Components
import { Grid, Typography, Button } from '@mui/material';
import Modal from '../../util/modal'
import Preview from './preview'
import Cropper from './cropper';

// Util
import getCroppedImg, { createImage } from '../util/canvas';

const ModalCropper = ({ open, file, handleError, handleSubmit, handleClose }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [image, setImage] = useState(null)
    const isPhone = useMovil();

    const onClose = () => {
        setImage(null)
        setCrop({ x: 0, y: 0 })
        setZoom(1)
        handleClose();
    }

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onCropImage = useCallback(async () => {
        try {
            const image = await createImage(file)
            const blob = await getCroppedImg(image, croppedAreaPixels)
            setImage(blob)
        } catch (e) {
            handleError({ file: e.message });
            onClose();
        }
        // eslint-disable-next-line
    }, [croppedAreaPixels])

    const onZoomChange = (_e, zoom) => setZoom(zoom)

    const onReset = () => {
        setImage(null);
    }

    const onSubmit = () => {
        handleSubmit(image)
        onClose();
    }

    const Buttons = (
        <Grid item container justifyContent="flex-end" xs={12}>
            <Button
                color="secondary"
                size="small"
                className="mr-m"
                onClick={image ? onReset : onClose}
            >
                {image ? 'Otra vez' : 'Cancelar'}
            </Button>
            <Button
                onClick={image ? onSubmit : onCropImage}
                color="primary"
                variant="contained"
                size="small"
            >
                {image ? 'Guardar' : 'Cortar'}
            </Button>
        </Grid>
    )

    return (
        <Modal open={open} buttons={Buttons} handleClose={onClose}>
            <Grid container className="p-m">
                <Grid item xs={12} className="pb"> 
                    <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
                        {image ? 'Imagen Lista' : 'Recortar imagen'}
                    </Typography>
                </Grid>
                {
                    image ? 
                        <Preview
                            src={image.url}
                            onClose={onClose}
                            onSubmit={onSubmit}
                        />
                    :
                        <Cropper
                            file={file}
                            crop={crop}
                            zoom={zoom}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                        />
                }
            </Grid>
        </Modal>
    )
}

export default ModalCropper
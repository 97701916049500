import React from 'react';
import { sublist } from '../agenda/menu.js';
import { Link } from 'react-router-dom'
import Menu from '../util/menu'
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CreateIcon from '@mui/icons-material/Create';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';

const SubMenu = ({ anchorEl, data, handleEdit, handleControl, handleClose }) => {
  if(!data) return null;
  return(
    <Menu
      id="menu-paciente-acciones"
      autoFocus={true}
      open={anchorEl !== null}
      anchorReference="anchorPosition"
      anchorPosition={anchorEl}
      onClose={handleClose}
    >
      <ContentMenu data={data} handleEdit={handleEdit} handleControl={handleControl} handleClose={handleClose} />
    </Menu>
  )
};

export const SubMenuAnchor = ({ anchorEl, data, handleEdit, handleControl, handleClose }) => {
  if(!data) return null;
  return(
    <Menu
      autoFocus
      id="menu-paciente-acciones"
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{zIndex: 9001}}
    >
      <ContentMenu data={data} handleEdit={handleEdit} handleControl={handleControl} handleClose={handleClose} />
    </Menu>
  )
};



const ContentMenu = ({ data, handleEdit, handleControl, handleClose }) => {
  const onClick = (handleFunc) => () => {
    handleFunc();
    handleClose();
  }

  if(!data) return null;

  return [
    ...sublist.map((item, i) => (
        item.row ?
          <Link tabIndex="0" key={i} onClick={handleClose} to={`/${(item.link || item.label)}/${data.idPaciente}`} style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
            </MenuItem>
          </Link>
        : item.resource
    )),
    <Divider tabIndex="-1" key={100} />,
    <MenuItem tabIndex="0" key={101} onClick={onClick(handleControl)}>
      <ListItemIcon>
        <AssignmentIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={"Próximo control"} />
    </MenuItem>,
    <Divider tabIndex="-1" key={102} />,
    <MenuItem tabIndex="0" key={103} onClick={onClick(handleEdit)}>
      <ListItemIcon>
        <CreateIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={"Ver Ficha"} />
    </MenuItem>
  ]
};

export default SubMenu;

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useOpen from '../../hooks/useOpen';
import { createPregunta, updatePregunta, deletePregunta } from '../provider/actions';

function UseCuestionario(cuestionario) {
    const dispatch = useDispatch();
    const modal = useOpen();
    const [single, setSingle] = useState(null);
    
    const onSelect = (item) => {
        setSingle(item);
        modal.handleOpen();
    }

    const onClose = () => { modal.handleClose(); setSingle(null) }
    const onCreate = (data, cb) => {
        const callback = () => { onClose(); if(cb) cb() };
        dispatch(createPregunta(cuestionario.idCuestionario, data, callback))
    }
    const onUpdate = (pregunta, data, cb) => {
        const callback = () => { onClose(); if(cb) cb() };
        dispatch(updatePregunta(pregunta, data, callback))
    }
    const onDestroy = (pregunta) => dispatch(deletePregunta(pregunta, onClose))

    return {
        modal,
        question: single,
        onSelect,
        onCreate,
        onUpdate,
        onDestroy,
        onClose
    }
}

export default UseCuestionario
import {
  DOCTORES_LIST,
  PERMISOS_LIST,
  USUARIOS_CREATE,
  USUARIOS_DESTROY,
  USUARIOS_LIST,
  USUARIOS_LOADING,
  USUARIOS_UPDATE
} from './constants.js';

import axios from '../../../../../config/axiosv2';
import handler from '../../../../../redux/actions/handler.js';

const setLoading = (value) => ({ type: USUARIOS_LOADING, payload: value })

export const getDoctores = () => (dispatch) => {
  dispatch(setLoading(true))
  axios.get("/doctores")
    .then(res => dispatch({ type: DOCTORES_LIST, payload: res.data }))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const getPermisos = () => (dispatch) => {
  dispatch(setLoading(true))
  axios.get("/permisos")
    .then(res => dispatch({ type: PERMISOS_LIST, payload: res.data }))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updatePermisos = (id, state, cb) => (dispatch) => {
  dispatch(setLoading(true))
  axios.put(`/permisos/${id}`, state)
    .then(() => {
      cb();
      dispatch(getPermisos())
    })
    .catch(e => {
      handler(dispatch)(e)
      dispatch(setLoading(false))
    })
}

export const getUsuarios = () => (dispatch) => {
  dispatch(setLoading(true))
  axios.get("/usuarios")
    .then(res => dispatch({ type: USUARIOS_LIST, payload: res.data }))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updateUser = (id, state, cb) => (dispatch) => {
  dispatch(setLoading(true))
  axios.put(`/usuarios/${id}`, state)
    .then(res => {
      dispatch({ type: USUARIOS_UPDATE, payload: res.data })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const destroyUser = (id) => (dispatch) => {
  dispatch(setLoading(true))
  axios.delete(`/usuarios/${id}`)
    .then(() => dispatch({ type: USUARIOS_DESTROY, payload: id }))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const activateUser = (user, active) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .put(`/usuarios/status/${user.idUsuario}`, { active })
    .then(() => dispatch({ type: USUARIOS_UPDATE, payload: { ...user, Activo: active } }))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)));
};

export const updateUserPassword = (id, state, cb) => (dispatch) => {
  dispatch(setLoading(true))
  axios.put(`/usuarios/password/${id}`, state)
    .then(res => cb(res.data))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const createUser = (state, cb) => (dispatch) => {
  dispatch(setLoading(true))
  axios.post("/usuarios", state)
    .then(res => {
      dispatch({ type: USUARIOS_CREATE, payload: res.data })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}
import React from 'react';

import { Container, Tooltip, MenuItem } from '@mui/material';
import Menu from '../../util/menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Modules
import StyckyStyle from '../../util/sticky.module.css';
import useAnchorEl from '../../hooks/useAnchorEl';
import { Items } from '../../util/headerSection'

const StickyHeader = ({ Typography, Icon, onRedirect, onControl, onClose }) => {
    const menu = useAnchorEl();
    const onClickStickyOption = (item) => () => {
        menu.handleClose();
        if (item === "Proximo control"){
            return onControl();
        }
        onRedirect(`/${item}/`)();
    }
    return (
        <>
            <div className={StyckyStyle.main}>
                <Container className="h-100 relative" maxWidth="lg">
                    <div className="flex grow middle h-100">
                        <div className="flex grow middle text-white pointer">
                            <Tooltip title="Volver atrás" aria-label="Volver atrás" placement="bottom" arrow>
                                <ArrowBackIcon className="pointer mr" color="inherit" onClick={onClose} />
                            </Tooltip>
                            <div className="flex grow column text-white text-ellipsis" onClick={menu.handleOpen}>
                                {Typography}
                            </div>
                        </div>
                        <div>
                            <Tooltip title="Menú de módulos" aria-label="Menú de módulos" placement="right" arrow>
                                {Icon}
                            </Tooltip>
                        </div>
                    </div>
                </Container>
            </div>
            <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={menu.handleClose}
            >
                {
                    Items.slice(0, Items.length-2).map((item, i) => (
                        item.resource ? item.resource :
                            <MenuItem key={i} onClick={onClickStickyOption(item.text)}>
                                {item.label}
                            </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}

export default StickyHeader
import React from 'react';

// UI
import Button from '@mui/material/Button';

// Components
import Appbar from '../../../surfaces/appbar';
import Fuente from './item';

// Hooks
import usefontsize from '../hooks/useFontsize';

const FuenteContainer = () => {
    const { fontSize, hasChanges, onSubmit, onChange } = usefontsize();
    return (
        <div className="flex grow h-100 column">
            <div>
                <Appbar title="Fuente" />
                <div className="flex grow">
                    <Fuente fontSize={fontSize} onChange={onChange} />
                </div>
            </div>
            <div className="flex grow end right p">
                <div className="relative">
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={!hasChanges}>
                        Guardar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FuenteContainer;
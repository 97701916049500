import { useState } from 'react';

const useOpen = (value = false) => {
  const [open, setOpen] = useState(value);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen( e => !e );

  return {
    open,
    handleOpen,
    handleClose,
    handleToggle
  }
}

export default useOpen;

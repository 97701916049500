import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const FooterSection = ({ error, text }) => {
  const history = useHistory();

  const handleBack = e => history.push('/')
  if(!error) return null;

  return (
    <div>
      <div className="header-section">
        <div className="request-error request-align error-section">
          <Typography>{error}</Typography>
        </div>
      </div>
      {
        !text &&
        <div className="header-section">
          <Button color="secondary" variant="contained" onClick={handleBack} style={{display: 'block', margin: 'auto'}}>
            Volver atrás
          </Button>
        </div>
      }
    </div>
  )
}

FooterSection.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.string
  ]),
  text: PropTypes.bool
}

export default FooterSection

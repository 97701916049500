import React from 'react'
import Grid from '@mui/material/Grid';

const PacienteAvatarInput = ({ file, url, error, handleFile }) => {
    return (
        <Grid item container xs={12} className="my-m">
            <Grid item xs={12}>
                <div className="flex grow center">
                    <label
                        className="flex center radio-50 text-ground pointer"
                        htmlFor="file"
                        style={{
                            height: 100,
                            width: 100,
                            backgroundSize: 'cover',
                            backgroundColor: '#d9d9d9',
                            backgroundImage: file ? 'url(' + file.url + ')' : url,
                        }}
                    >
                        <strong>{file ? 'CARGADA' : 'ELEGIR'}</strong>
                    </label>
                </div>
                <input
                    type="file"
                    id="file"
                    name="file"
                    accept='image/*'
                    className="hide-file-input"
                    onChange={handleFile}
                />
            </Grid>
            <Grid item xs={12} className="pt-m">
                {
                    error ? <p className="label-error">{error}</p>
                    : file ? <p className="label-center">Imagen cargada correctamente.</p>
                    : null
                }
            </Grid>
        </Grid>
    )
}

export default PacienteAvatarInput
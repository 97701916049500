import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'
import Loading from '../../util/circleLoading.js';
import ErrorSection from '../../util/errorSection';
import { getDeuda } from '../../../redux/actions/authActions.js'

const Deuda = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const error = useSelector(state => state.auth.error);
  const balance = useSelector(state => state.auth.balance);

  const [alert, setAlert] = useState(null);

  useEffect(function() {
    dispatch(getDeuda())
    //eslint-disable-next-line
  }, [])

  useEffect(function(){
    if(error && !balance){
      setAlert(error);
    }
    //eslint-disable-next-line
  }, [error])

  return (
    <div>
      <ErrorSection error={alert} text={1} />
      {
        user.estado === 3 ?
        <div className="mt flex grow center justify">
          <Typography>Su cuenta ha sido temporalmente inhabilitada por estar pendiente un pago. </Typography>
        </div>
        : null
      }
      {
        balance ?
          <div dangerouslySetInnerHTML={{ __html: balance }}></div>
        :
          <Loading text="Preparando informe" />
      }
    </div>
  )
}

export default Deuda;

import { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Context } from '../../util/context';

import { setSingle } from '../../../../redux/actions/pacienteActions';
import { setCuentasCobrar, getCuentasCobrar } from '../../../../redux/actions/reporteActions';

export const COBRAR_DATA = `Cuentas-cobrar-data`;
export const COBRAR_LABEL = `Cuentas-cobrar-item`;
export const COBRAR_PAGE = `Cuentas-cobrar-page`;
const useCuentasCobrar = (history) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(+(localStorage.getItem(COBRAR_PAGE) || 0));
    const [active, setActive] = useState(localStorage.getItem(COBRAR_LABEL));
    const { group, estado, coins, handleReset } = useContext(Context);
    const data = useSelector(state => state.report.cobrar)
    const loading = useSelector(state => state.report.loading);
    const pages = useMemo(() => (data.Limit && data.Count) ? Math.ceil(data.Count / data.Limit) : 1, [ data.Count, data.Limit ]);

    useEffect(function () {
        const storedData = localStorage.getItem(COBRAR_DATA);
        if (storedData && storedData.length) {
            dispatch(setCuentasCobrar(JSON.parse(storedData)))
        }
        return () => handleReset();
        // eslint-disable-next-line
    }, [])

    useEffect(function(){
        dispatch(getCuentasCobrar(page+1, group, estado));
        // eslint-disable-next-line
    }, [group, page, estado]);
    
    const handleSelect = (id) => {
        setActive(id)
        localStorage.setItem(COBRAR_LABEL, id);
    }
    const handleChangePage = (_, newPage) => {
        setPage(newPage)
        localStorage.setItem(COBRAR_PAGE, page);
    };
    const handleClickPaciente = item => dispatch(setSingle(item));
    const handleClickMoneda = (id, money) => history.push('/cuentas/'+id, {coin: money});

    return {
        ...data,
        page,
        pages,
        coins,
        active,
        loading,
        estado,
        group,
        handleSelect,
        handleChangePage,
        handleClickMoneda,
        handleClickPaciente,
    }
}

export default useCuentasCobrar;

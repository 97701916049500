import React from 'react';

// UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Components
import Loading from '../../util/circleLoading';
import Picker from './picker.js'
import useMovil from '../../hooks/useMovil';

const ControlForm = ({ state, error, handleChange }) => {
  const handleDate = (date) => handleChange({ target: { name: 'date', value: date } });
  const isPhone = useMovil();
  return (
    <Grid container className="modal-form">
      {
        (!state) ? <Loading text="Cargando control" minHeight={300} /> :
          <Grid item container>
            <Grid item container justifyContent="center">
              <Typography variant={isPhone ? "h6" : undefined} align={isPhone ? 'center' : undefined} className="mb">
                Proximo Control
              </Typography>
            </Grid>

            <Picker data={state} handleChange={handleDate} />

            <Grid item xs={12} className="my">
              <TextField
                autoComplete="new-control-input"
                label="Nota"
                name="description"
                variant="outlined"
                multiline
                fullWidth
                minRows={4}
                value={state.description}
                onChange={handleChange}
              />
            </Grid>

            {
              error ? <Grid item className="my-m text-danger">{error}</Grid> : null
            }
          </Grid>
      }
    </Grid>
  );
}

export default ControlForm;

import data from '../data/fake.json';

export const orders = [
    {text: '', align:'left', order: 'start'},
    {text: '', align:'center', order: 'start'},
    {text: '', align:'right', order: 'start'}
]

function toData(str, num){
    for(let i = 0; i < data[num].length; i++){
        str = str.replace(data[num][i].name, data[num][i].value)
    }
    return str
}

export function getData(body, func){
    const convert = (item) => ({ ...item, text: func(item.text) })
    const getItems = (items) => {
        const obj = {};
        Array.from({length: body.lines})
        .map((_, key) => {
            if(items[key]){
                obj[key] = items[key].length ? items[key].map(convert) : convert(items[key])
            }
            return null
        })
        return obj;
    }
    body.items = getItems(body.items);
    return body;
}

export function strongify(str, preview, v){
    if(!preview) return str;

    const start = str.indexOf('*');
    if(start < 0) return toData(str, v);
    const end = str.indexOf('*', start+1);
    if(end < 0) return toData(str, v);

    return strongify(str.slice(0, start)+"<strong>"+str.slice(start+1, end)+"</strong>"+str.slice(end+1), preview, v)
}

export function strongerify(str){
    const start = str.indexOf('*');
    const end = str.indexOf('*', start+1);

    if(end < 0 || start < 0) return str
    return strongify(str.slice(0, start)+"<strong>"+str.slice(start+1, end)+"</strong>"+str.slice(end+1))
}


export function unstrongify(obj){
    try {
        const items = {};
        Object.keys(obj.items).map(key => {
            if(obj.items[key].length){
                items[key] = obj.items[key].map(item => ({
                    ...item,
                    text: item.text.replaceAll('<strong>', '*').replaceAll('</strong>', '*')
                }))
            } else {
                items[key] = {
                    ...obj.items[key],
                    text: obj.items[key].text.replaceAll('<strong>', '*').replaceAll('</strong>', '*')
                }
            }
            return key;
        })
        return { ...obj, items }
    } catch(e){
        return obj;
    }  
}


function Unidades(num){
    switch(num)
    {
        case 1: return "UN";
        case 2: return "DOS";
        case 3: return "TRES";
        case 4: return "CUATRO";
        case 5: return "CINCO";
        case 6: return "SEIS";
        case 7: return "SIETE";
        case 8: return "OCHO";
        case 9: return "NUEVE";
        default:
            return ""
    }
}

function Decenas(num){
    let decena = Math.floor(num/10);
    let unidad = num - (decena * 10);

    switch(decena)
    {
        case 1:
            switch(unidad)
            {
                case 0: return "DIEZ";
                case 1: return "ONCE";
                case 2: return "DOCE";
                case 3: return "TRECE";
                case 4: return "CATORCE";
                case 5: return "QUINCE";
                default: return "DIECI" + Unidades(unidad);
            }
        case 2:
            switch(unidad)
            {
                case 0: return "VEINTE";
                default: return "VEINTI" + Unidades(unidad);
            }
        case 3: return DecenasY("TREINTA", unidad);
        case 4: return DecenasY("CUARENTA", unidad);
        case 5: return DecenasY("CINCUENTA", unidad);
        case 6: return DecenasY("SESENTA", unidad);
        case 7: return DecenasY("SETENTA", unidad);
        case 8: return DecenasY("OCHENTA", unidad);
        case 9: return DecenasY("NOVENTA", unidad);
        case 0: return Unidades(unidad);
        default:
    }
}

function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
    return strSin + " Y " + Unidades(numUnidades)

    return strSin;
}//DecenasY()

function Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);

    switch(centenas)
    {
        case 1:
            if (decenas > 0)
                return "CIENTO " + Decenas(decenas);
            return "CIEN";
        case 2: return "DOSCIENTOS " + Decenas(decenas);
        case 3: return "TRESCIENTOS " + Decenas(decenas);
        case 4: return "CUATROCIENTOS " + Decenas(decenas);
        case 5: return "QUINIENTOS " + Decenas(decenas);
        case 6: return "SEISCIENTOS " + Decenas(decenas);
        case 7: return "SETECIENTOS " + Decenas(decenas);
        case 8: return "OCHOCIENTOS " + Decenas(decenas);
        case 9: return "NOVECIENTOS " + Decenas(decenas);
        default:
            return Decenas(decenas);
    }
}

function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}

function Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if(strMiles === "")
        return strCentenas;

    return strMiles + " " + strCentenas;
}

function Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    let strMiles = Miles(resto);

    if(strMillones === "")
        return strMiles;

    return strMillones + " " + strMiles;
}

export function toLetter(num) {
    let data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
    };

    const centavosStr = data.centavos === 0 ? "" : " con "+data.centavos+"/100"

    if(data.enteros === 0)
        return "cero"+centavosStr
    else
        return Millones(data.enteros).toLowerCase().trim()+centavosStr;
}
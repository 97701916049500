import { Label } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import useOpen from '../../../../components/hooks/useOpen';
import DialogModal from '../../../../components/util/dialog';
import { toColor } from '../../../../components/util/tools';
import Axios, { baseURL as AxiosBaseURL } from '../../../../config/axiosv2';
import handler from '../../../../redux/actions/handler';
import useGrupos from '../../../../swr/hooks/useGrupos';

export const inactiveGruposURL = '/grupos/inactivos';
export const inactiveGruposProURL = '/grupos/proveedores/inactivos';
const baseURL = AxiosBaseURL.concat('/v3');
const fetcher = (URL) => Axios.get(URL, { baseURL });

export const useInactiveGroups = (URL = inactiveGruposURL) => {
    const dialog = useOpen();
    const dispatch = useDispatch();
    const { open: isDeletingInactiveGroups, handleToggle: setIsDeleting } = useOpen();
    const { mutate } = useGrupos();

    const onError = (e) => {
        dialog.handleClose();
        handler(dispatch)(e);
    }

    const {
        data: response,
        mutate: mutateInactive,
    } = useSWR(dialog.open ? URL : null, fetcher, { onError })

    const handleDestroyInactive = async () => {
        try {
            dialog.handleClose();
            setIsDeleting(true);
            await Axios.delete(URL, { baseURL })
            mutate();
        } catch (error) {
            handler(dispatch)(error);
        } finally {
            mutateInactive({ data: null });
            setIsDeleting(false)
        }
    }
    const handleDialogClose = () => {
        dialog.handleClose();
        mutateInactive({ data: null });
    }
    const inactivos = response ? response.data : null;
    const isLoading = dialog.open && !response;

    return {
        inactivos,
        open: inactivos && dialog.open,
        isLoading: isLoading || isDeletingInactiveGroups,
        handleOpen: dialog.handleOpen,
        handleClose: handleDialogClose,
        handleDestroyInactive,
    }
}

const GrupoInactiveModal = ({ open, inactivos, isLoading, handleDestroyInactive, handleClose }) => {
    return (
        <DialogModal
            open={open}
            data={{
                title: "¡Atención!",
                body: inactivos && (
                    <Box>
                        <Typography>
                            Estos grupos no estan siendo utilizados:
                        </Typography>
                        <Box display="flex" flexDirection="column" style={{ marginTop: 20, marginBottom: 20, fontWeight: "bold" }}>
                            {inactivos.map(item => (
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <span
                                        style={{ color: toColor(item.Color, true), margin: 5 }}
                                    >
                                        <Label color="inherit" />
                                    </span>
                                    <Typography variant="subtitle2">
                                        {item.Nombre[0].toUpperCase() + item.Nombre.slice(1)}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Typography style={{ marginBottom: 50 }}>
                            ¿Estás seguro que deseas eliminarlos?
                        </Typography>
                    </Box>
                ),
                close: true,
                loading: isLoading,
                button: "Eliminar",
            }}
            callback={handleDestroyInactive}
            handleClose={handleClose}
        />
    )
}

export default GrupoInactiveModal;
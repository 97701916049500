import { useEffect, useState, useRef } from 'react';

const defaultState = {
    align: 'full',
    order: 'justify',
    text: '',
}

const useReciboModalForm = ({ open, target, item, onClose, onSubmit }) => {
    const [state, setState] = useState(defaultState)
    const [message, setMessage] = useState(null);
    const ref = useRef();

    useEffect(function(){
        if(open && target){
            setState(target)
        }
    }, [ open, target ])

    const onAdd = (item) => () => {
        const replaceText = (txt) => {
            const { selectionStart: start, selectionEnd: end } = ref.current;
            if(start === end){
                if(!start){
                    return item + " " + txt;
                } else {
                    return txt.slice(0, start).trim() +" "+item+" "+ txt.slice(start).trim()
                }
            } else {
                return txt.slice(0, start).trim() +" "+item+" "+ txt.slice(end).trim()
            }
        }
        setState(prevState => ({...state, text: replaceText(prevState.text)}))
    }

    const onComplete = () => {
        const obj = {
            ...state,
            id: target.id || Date.now()
        }

        if(item && item.length){
            const items = item.filter(item => item.id !== obj.id)
            if(state.align === "full"){
                if(!items.length){
                    onSubmit(obj);
                    onAbort();
                } else {
                    setMessage('No se puede ocupar toda la línea')
                }
            } else if(items.some(item => item.align === state.align)){
                setMessage('Ya hay un elemento ocupando esa posición')
            } else {
                onSubmit([...items, obj]);
                onAbort();
            }
        } else {
            onSubmit(state.align === "full" ? obj : [obj]);
            onAbort();
        }
    }
    const onChange = ({ target }) => {
        setState({...state, [target.name]: target.value});
        setMessage(null)
    }
    const onRemove = () => {
        if(item.length){
            const rest = item.filter(item => item.id !== target.id);
            onSubmit(rest.length ? rest : undefined)
        } else {
            onSubmit(undefined)
        }
        onAbort();
    }
    const onAbort = () => {
        onClose();
        setState(defaultState)
    }
    
    return {
        inputRef: ref,
        state,
        message,
        onAdd,
        onChange,
        onRemove,
        onComplete,
        onAbort 
    }
}

export default useReciboModalForm

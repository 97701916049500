import { Chip, List, ListItem, ListItemText, Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import { ArrowDropDown, Person, Today } from "@mui/icons-material";
// import { Timelapse } from "@mui/icons-material";
import Menu from "../../../../../components/util/menu";
import useAnchorEl from "../../../../../components/hooks/useAnchorEl";
import variables from "./variables.json";
import { useState } from "react";
import { copyToClipboard } from "../../../../../components/util/tools";
import { useDispatch } from "react-redux";
import useOpen from "../../../../../components/hooks/useOpen";
import handler from "../../../../../redux/actions/handler";

const OPTIONS = {
  PACIENTE: "PACIENTE",
  FECHA: "FECHA",
  HISTORIA: "HISTORIA",
};

const Picker = () => {
  const dispatch = useDispatch();
  const menu = useAnchorEl();
  const snack = useOpen();
  const [mode, setMode] = useState(OPTIONS.PACIENTE);
  const onOpen = (mode) => (evt) => {
    setMode(mode);
    menu.handleOpen(evt);
  };
  const onSelect = (code) => async (_evt) => {
    try {
      await copyToClipboard(code);
      snack.handleOpen();
    } catch (e) {
      handler(dispatch)(e);
    } finally {
      menu.handleClose();
    }
  };
  return (
    <div className="flex end gap-1 wrap-reverse">
      <Chip
        clickable
        icon={<Person />}
        size="medium"
        label="Paciente"
        color="primary"
        className="px-m"
        deleteIcon={<ArrowDropDown />}
        onDelete={onOpen(OPTIONS.PACIENTE)}
        onClick={onOpen(OPTIONS.PACIENTE)}
      />
      <Chip
        clickable
        icon={<Today />}
        size="medium"
        label="Fecha"
        color="primary"
        className="px-m"
        deleteIcon={<ArrowDropDown />}
        onDelete={onOpen(OPTIONS.FECHA)}
        onClick={onOpen(OPTIONS.FECHA)}
      />
      {/* <Chip
        clickable
        icon={<Timelapse />}
        size="medium"
        label="Historia"
        color="primary"
        className="px-m"
        deleteIcon={<ArrowDropDown />}
        onDelete={onOpen(OPTIONS.HISTORIA)}
        onClick={onOpen(OPTIONS.HISTORIA)}
      /> */}
      <Menu
        open={!!menu.anchorEl}
        anchorEl={menu.anchorEl}
        onClose={menu.handleClose}
      >
        <List component="nav">
          {variables[mode].map(({ code, description }) => (
            <ListItem dense key={code}>
              <ListItemText
                primary={code}
                secondary={description}
                onClick={onSelect(code)}
                primaryTypographyProps={{
                  color: "primary",
                }}
                secondaryTypographyProps={{
                  color: "textPrimary",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Menu>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={snack.handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snack.handleClose}
          severity="success"
        >
          Copiado al portapapeles!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Picker;

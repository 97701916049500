export const AGENDA_LOADING = "AGENDA_GLOBAL_LOADING";
export const AGENDA_LIST = "AGENDA_GLOBAL_LIST";
export const AGENDA_SELECT = "AGENDA_GLOBAL_SELECT";
export const CITAS_LIST = "CITAS_GLOBAL_LIST";
export const CITAS_CREATE = "CITAS_GLOBAL_CREATE";
export const CITAS_UPDATE = "CITAS_GLOBAL_UPDATE";
export const CITAS_DESTROY = "CITAS_GLOBAL_DESTROY";
export const CITAS_UPDATE_STATE = "CITAS_GLOBAL_UPDATE_STATE";
export const CITAS_UPDATE_MEDIO = "CITAS_GLOBAL_UPDATE_MEDIO";
export const AGENDA_CLEAR_AFTER = "CITAS_GLOBAL_CLEAR_AFTER";
export const AGENDA_CLEAR_ALL = "CITAS_GLOBAL_CLEAR_ALL";
export const CITAS_UPDATE_CONFIRM = "CITAS_GLOBAL_UPDATE_CONFIRM";
import React, { useContext } from 'react';
import Content from './content';
import { Paper, Button } from '@mui/material';

import { Context } from '../../hooks/context';
import PDFButton from '../../hooks/usePdf'
import useOpen from '../../../hooks/useOpen';

import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import { useSelector } from 'react-redux';

const Preview = ({ onBack }) => {
    const { state, style, onChangeLogo, getPositiveValue } = useContext(Context);
    const image = useOpen();
    const id = useSelector(state => String(state.auth.user.idClinica).padStart(5,'0'))

    const onStopDrag = (_e, data) => {
        onChangeLogo({
            ...state.logo,
            x: data.x,
            y: data.y,
        })
    }
    const onStopResize = (_e, _dir, _ref, dimension) => {
        onChangeLogo({
            ...state.logo,
            width: state.logo.width + dimension.width,
            height: state.logo.height + dimension.height,
        })
    }
    const onLoadImage = (evt) => {
        onChangeLogo({
            x: 0,
            y: 0,
            width: evt.target.width,
            height: evt.target.height
        })
    }

    return (
        <div className="px">
            {
                !state.logo && (
                    <img
                        src={`https://odontosys-resized.s3.us-east-2.amazonaws.com/${id}/logorecibo.png`}
                        className="hide-file-input"
                        alt="recibo logo"
                        onLoad={onLoadImage}
                        onError={image.handleClose}
                    />
                )
            }
            <div className="flex grow center my">
                <Paper id="parent-paper" className="flex column" style={{...style, paddingBottom: 0, position: 'relative' }}>
                    {
                        state.logo && (
                            <Draggable bounds="parent" position={state.logo} onStop={onStopDrag}>
                                <Resizable
                                    className="resizable-image"
                                    size={state.logo}
                                    onResizeStop={onStopResize}
                                    style={{
                                        background: `url(https://odontosys-resized.s3.us-east-2.amazonaws.com/${id}/logorecibo.png)`,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        position: 'absolute'
                                    }}
                                    lockAspectRatio={true}
                                />
                            </Draggable>
                        )
                    }
                    <Content state={state} height={getPositiveValue(state.font, 10)} bottom={style.paddingBottom} />
                </Paper>
            </div>
            <div className="flex between py-m">
                <div className="flex grow start">
                    <Button size="small" variant="contained" onClick={onBack}>
                        Atras
                    </Button>
                </div>
                <div className="flex grow end">
                    <PDFButton data={state} />
                </div>
            </div>
        </div>
    )
}

export default Preview;
import {
  IMAGEN_LIST,
  IMAGEN_CREATE,
  IMAGEN_CREATE_MULTI,
  IMAGEN_ERROR,
  IMAGEN_LOADING,
  IMAGEN_UPDATE,
  IMAGEN_REMOVE,
  IMAGEN_SINGLE
 } from '../constans.js';

const initialState = {
  list: null,
  single: null,
  count: null,
  limit: null,
  loading: true,
  error: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case IMAGEN_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case IMAGEN_SINGLE:
      return {
        ...state,
        single: action.payload
      }
    case IMAGEN_LIST:
      return {
        ...state,
        list: action.payload,
        error: null
      }
    case IMAGEN_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case IMAGEN_CREATE:
      return {
        ...state,
        list: state.list ? [action.payload, ...state.list] : [action.payload],
        error: null
      }
    case IMAGEN_CREATE_MULTI:
      return {
        ...state,
        list: state.list ? [...action.payload, ...state.list] : action.payload,
        error: null
      }
    case IMAGEN_UPDATE:
      return {
        ...state,
        list: state.list.map( item => item.idImagen === action.payload.idImagen ? action.payload : item ),
        single: state.single ? action.payload : null,
        error: null
      }
    case IMAGEN_REMOVE:
      return {
        ...state,
        list: state.list.filter( item => item.idImagen !== action.payload),
        error: null
      }
    default:
      return state;
  }
}

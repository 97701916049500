import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAnchorEl from '../hooks/useAnchorEl';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

import Menu from '../util/menu';
import { makeLogout } from '../../redux/actions/authActions.js';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const BadgeContainer = ({ isDeudor, children }) => isDeudor ? <Badge color="secondary" variant="dot">{children}</Badge> : children

const Userbar = ({ user, drawer }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menu = useAnchorEl();
  const classes = useStyles();
  const isDeudor = (user.estado === 1 || user.estado === 2 || user.estado === 4);

  const handleConfiguration = () => {
    drawer.handleClose()
    history.push("/configuracion")
  }
  const handleProfile = () => {
    drawer.handleClose()
    history.push(isDeudor ? "/usuario/deuda" : "/usuario")
  }
  const handleLogOut = () => {
    drawer.handleClose()
    dispatch(makeLogout())
  }
  const handleAction = (func) => () => {
    func();
    menu.handleClose()
    drawer.handleClose();
  }

  return (
    <div className="flex middle">
      <Button color="inherit" className="hide-sm">
        {user.nombre.toUpperCase()}
      </Button>

      <IconButton color="inherit" onClick={handleAction(handleConfiguration)} size="large">
        <SettingsIcon />
      </IconButton>

      <IconButton color="inherit" onClick={menu.handleOpen} size="large">
      <BadgeContainer isDeudor={isDeudor}>
        {
          user.fotoDePerfil ?
            <Avatar
              src={user.url ? user.url : `https://odontosys-resized.s3.us-east-2.amazonaws.com/${String(user.idClinica).padStart(5,'0')}/fotoperfil.png`}
              alt="Foto del perfil"
              className={classes.small}
            />
          :
            <AccountCircle />
        }
        </BadgeContainer>
      </IconButton>

      <Menu anchorEl={menu.anchorEl} open={!!menu.anchorEl} onClose={menu.handleClose}>
        <MenuItem onClick={handleAction(handleProfile)}>
          <BadgeContainer isDeudor={isDeudor}>
            Perfil
          </BadgeContainer>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAction(handleLogOut)}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  );
}

export default Userbar

import React from 'react'
// UI
import Grid from '@mui/material/Grid';

// Layout
import Header from './header';
import Menu from './menu';

const Question = ({ data, isTitle, menu, onChangeTitle, handleUpdate, handleDestroy }) => {
    return (
        <Grid item xs={12} container alignItems="center" className={isTitle ? "text-primary" : ''}>
            <Header istitle={isTitle} title={data.titulo} onChange={onChangeTitle} />
            <Menu
                anchorEl={menu.anchorEl}
                istitle={isTitle}
                handleUpdate={handleUpdate}
                handleDestroy={handleDestroy}
                handleClose={menu.handleClose}
            />
        </Grid>
    )
}

export default Question;
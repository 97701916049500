import React, { Fragment } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Images
import MailImage from '../../../assets/svgs/undraw_mail.png';
import services from '../smtp.json';

const options = ["25", "465", "587", "2525"];
const FormSMTP = ({ state, editable, onChange, onComplete, onChangeCheck, onDestroy, onCancel, onSubmit }) => {
    const onChangeService = ({ target }) => onComplete(target.value)
    return (
        <Fragment>
            <img src={MailImage} alt="Mail" height={250} className="bg-none" />
            <form onSubmit={onSubmit} className="flex column my-m">
                {
                    editable ? null :
                    <div className="flex grow mb">
                        <TextField select label="Configuracion predeterminada" fullWidth onChange={onChangeService}>
                            {services.map(item => <MenuItem key={item.name} value={item}>{item.name}</MenuItem>)}
                        </TextField>
                    </div>
                }
                <TextField label="Host" name="host" autoComplete="off" value={state.host} onChange={onChange} />
                <TextField label="Usuario" name="email" autoComplete="off" value={state.email} className="my-m" onChange={onChange} />
                <TextField label="Contraseña" autoComplete="new-password" name="password" type="password" value={state.password} onChange={onChange} />
                <hr />
                <div className="flex my-m">
                    <div className="flex grow">                                         
                        <FormControlLabel
                            control={<Checkbox checked={state.secure} onChange={onChangeCheck} name="secure" />}
                            label="Conexión Segura"
                        />
                    </div> 
                    <div className="flex grow">
                        <TextField select label="Puerto" name="port" value={state.port} onChange={onChange}>
                            {options.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </TextField>
                    </div>
                </div>
                {state.error && <div className="request-error text-danger">{state.error}</div>}
                <Button type="submit" variant="contained" color="primary" className="mt-m">{editable ? "Modificar" : "Vincular"}</Button>
            </form>
            {editable &&
                <div className="flex between">
                    <div className="flex grow mr-m">
                        <Button variant="contained" color="secondary" fullWidth onClick={onDestroy}>Desvincular</Button>
                    </div>
                    <div className="flex grow ml-m">
                        <Button variant="contained" fullWidth onClick={onCancel}>Cancelar</Button>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default FormSMTP;
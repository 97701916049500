import React from 'react'

import Typography from '@mui/material/Typography';
import Logo from '../../../assets/icon.png';

const Header = () => {
    return (
        <div className="flex center column my">

            <div className="my">
                <img src={Logo} width={100} heigth={100} alt="Logo Odontosys" className="bg-white" />
            </div>

            <Typography variant="h5">
                Bienvenido a Odontosys
            </Typography>
        </div>
    );
}

export default Header

import { useState } from 'react';

const useAnchorEl = (usePosition = false) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    if(usePosition){
      setAnchorEl({ left: e.clientX - 2, top: e.clientY - 2 })
    } else {
      setAnchorEl(e.target)
    }
  }
  const handleClose = () => setAnchorEl(null);

  return {
    anchorEl,
    handleOpen,
    handleClose
  }
}

export default useAnchorEl;

import React, { useContext } from 'react';

import Drawer from '@mui/material/Drawer';

// Components
import { Aside } from './index';
import { ConfiguracionContext } from '../hooks/context';
import useStyles from './styles';

const AsideDrawer = () => {
    const styles = useStyles();
    const { drawer } = useContext(ConfiguracionContext);
    return (
        <Drawer classes={{paper: styles.drawerAsidePaper}} style={{ zIndex: 1250 }}  anchor="left" open={drawer.open} onClose={drawer.handleClose}>
            <Aside />
        </Drawer>
    );
}

export default AsideDrawer;
import { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Util
import { getObjectHour } from '../../../../components/util/date';
import { week, getDistance, getWrappedDifference, mappingValues } from '../../../../components/util/tools';
import { updateAgendaJourney } from '../../../../redux/actions/agendaActions';

const useAgendaJornada = (item, onCallback) => {
    const dispatch = useDispatch();
    const [ jornada ] = useState([...week.slice(1), week[0]]);
    const [ agenda, setAgenda ] = useState({ min: item.DuracionCita, pre: item.IndiceDuracionCitaPre });

    const getProp = (day, label) => {
        const dayStr = day.slice(0, 2);
        return item[label+dayStr];
    }

    const [ state, setState ] = useState(
        jornada.reduce((acc, day) => {
            const restStart = getProp(day, 'HoraDescanso');
            const restEnd = restStart >= 700 ? getProp(day, 'DuracionDescanso') : ''
            return {
                ...acc,
                [day]: {
                    checked: getProp(day, 'Trabaja'),
                    start: getObjectHour(getProp(day, 'InicioJornada')),
                    end: getObjectHour(getProp(day, 'FinJornada')),
                    restStart: restStart >= 700 ? getObjectHour(restStart) : '',
                    restEnd: restEnd
                }
            }
        }, {})
    )

    const options = useMemo(() => mappingValues(10), []);
    const getOptionLabel = useCallback((option) => option.title || '', []);
    
    const onChangeData = (day, data, time) => {
        const value = `${(data.value || data)}`.split('').filter(item => !isNaN(item)).join('');
        if(value < 700){
            setState({...state, [day]: {...state[day], [time]: '', error: `La hora debe ser mayor de las 7:00am`, errorType: time }})
        } else if(value > 2200){
            setState({...state, [day]: {...state[day], [time]: '', error: `La hora debe ser menor de las 22:00pm`, errorType: time }})
        } else {
            if(time === "start" && state[day].end.value){
                let start = getObjectHour(value);
                let end = (getDistance(start, state[day]) || getWrappedDifference(value, item.DuracionCita));
                if(+end.value.replace(':', '') > 2200){
                    end = getObjectHour(2200);
                }
                setState({ ...state, [day]: {...state[day], start, end, error: null, errorType: undefined } })
            } else {
                setState({ ...state, [day]: {...state[day], [time]: getObjectHour(value), error: null, errorType: undefined } })
            }
        }
    }
    const onChangeRest = (day, data) => {
        if(!`${(data.value || data)}`.trim().length){
            return setState({ ...state, [day]: {...state[day], restStart: '', error: null, errorType: undefined } })
        }
        
        const value = `${(data.value || data)}`.split('').filter(item => !isNaN(item)).join('');
        if(value > 1 && value < 700){
            setState({...state, [day]: {...state[day], restStart: '', error: `La hora debe ser mayor de las 7:00am`, errorType: 'restStart' }})
        } else if(value > 1 && value > 2200){
            setState({...state, [day]: {...state[day], restStart: '', error: `La hora debe ser menor de las 22:00pm`, errorType: 'restStart' }})
        } else {
            setState({ ...state, [day]: {...state[day], restStart: getObjectHour(value), error: null, errorType: undefined } })
        }
    }
    const onChangeRestDuration = (day, target) => {
        setState(oldState => ({ ...oldState, [day]: { ...oldState[day], restEnd: target.value } }))
    }
    const onChangeCheckbox = (day) => {
        setState(oldState => ({ ...oldState, [day]: { ...oldState[day], checked: !oldState[day].checked } }))
    }

    const hasChanges = useMemo(function(){
        let changes = false;
        let index = -1;

        for(const day in state){
            index++;
            const { start, end, restStart, restEnd, checked } = state[day];

            if(!start.value || !end.value){
                changes = true;
                break;
            }

            const startState = +(start.value.replace(':', ''));
            const endState = +(end.value.replace(':', ''));
            const restStartValue = restStart.value ? (+restStart.value.replace(':', '')) : -1;
            if(
                +startState !== +getProp(jornada[index], 'InicioJornada') ||
                +endState !== +getProp(jornada[index], 'FinJornada') ||
                checked !== getProp(jornada[index], 'Trabaja') ||
                restStartValue !== getProp(jornada[index], 'HoraDescanso') ||
                +restEnd !== getProp(jornada[index], 'DuracionDescanso')
            ){
                changes = true;
                break;
            }
        }
        if(agenda.min !== item.DuracionCita || agenda.pre !== item.IndiceDuracionCitaPre){
            changes = true;
        }

        return changes;
        // eslint-disable-next-line
    }, [ agenda, item, state, jornada ])

    const onChangeDuration = ({ name, value }) => {
        if(name === "min"){
            setAgenda({...agenda, min: value, pre: '' })
        } else {
            setAgenda({...agenda, pre: value })
        }
    }
    const onSubmit = () => {
        let hasError = false;
        let data = {};
        for(const day in state){
            if(state[day].errorType || !state[day].start.value || !state[day].end.value){
                hasError = true;
                break;
            } else if(state[day].restStart.value && (+state[day].restEnd) < 10){
                hasError = true;
                break;
            } else if(state[day].restEnd && !state[day].restStart.value){
                hasError = true;
                break;
            }
            data[day] = {
                checked: state[day].checked,
                start: +state[day].start.value.replace(':', ''),
                end: +state[day].end.value.replace(':', ''),
                restStart: state[day].restStart.value ? +state[day].restStart.value.replace(':', '') : -1,
                restEnd: (+state[day].restEnd || 0),
            } 
        }
        if(agenda.min === '' || agenda.pre === ''){
            hasError = true;
        }
        if(!hasError){
            dispatch(updateAgendaJourney(item, agenda, data, onCallback))
        }
    }

    return {
        state,
        agenda,
        options,
        jornada,
        hasChanges,
        getOptionLabel,
        onSubmit,
        onChangeData,
        onChangeRest,
        onChangeRestDuration,
        onChangeCheckbox,
        onChangeDuration
    }
}

export default useAgendaJornada;
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import Menu from "../util/menu.js";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import useOpen from "../hooks/useOpen.js";
import Dialog from "../util/confirmDialog.js";

function GrupoItemMenu({ menu, onUpdate, onDelete }) {
  const isAdmin = useSelector((state) => !state.auth.user.TipoDeUsuario);
  const dialog = useOpen();
  const onCloseAction = (cb) => () => {
    cb();
    menu.handleClose();
  }
  return (
    <>
      <Menu
        anchorEl={menu.anchorEl}
        open={!!menu.anchorEl}
        onClose={menu.handleClose}
      >
        <MenuItem onClick={onCloseAction(onUpdate)}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Modificar" />
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={onCloseAction(dialog.handleOpen)}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
      <Dialog
        exit
        text="Eliminar"
        title="¿Esta seguro de que desea eliminar ese grupo?"
        open={dialog.open}
        handleClose={dialog.handleClose}
        handleClick={onDelete}
      />
    </>
  );
}

export default GrupoItemMenu;

import React from 'react'

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import TopArrow from '@mui/icons-material/ExpandLess';
import BottomArrow from '@mui/icons-material/ExpandMore';

// Hooks
import useAnchorEl from '../../hooks/useAnchorEl';
import useOpen from '../../hooks/useOpen';

// Components
import Modal from './modal';
import Menu from './menu';

function Cuestionario({ data, firstIndex, lastIndex, onSelect, onSwipe }) {
    const menu = useAnchorEl(true);
    const modal = useOpen();

    const onOpen = (e) => menu.handleOpen(e)
    const onSelectEdit = () => {
        menu.handleClose();
        onSelect(data);
    }
    const onSelectRename = () => {
        menu.handleClose();
        modal.handleOpen();
    }
    const onDirection = (direction) => (e) => {
        e.stopPropagation();
        onSwipe(direction, data)
    }

    return (
        <>
            <ListItem className="pt-m pb-m" onClick={data.idCuestionario ? onOpen : undefined}>
                <ListItemText disableTypography>
                    <Typography variant="body2">{data.nombre}</Typography>
                </ListItemText>
                {(!data.idCuestionario || firstIndex) ? null :
                    <ListItemIcon>
                        <IconButton size="small" onClick={onDirection(true)}><TopArrow /></IconButton>
                    </ListItemIcon>
                }
                {(!data.idCuestionario || lastIndex) ? null :
                    <ListItemIcon>
                        <IconButton size="small" onClick={onDirection(false)}><BottomArrow /></IconButton>
                    </ListItemIcon>
                }
            </ListItem>
            <Menu {...menu} handleUpdate={onSelectEdit} handleRename={onSelectRename} />
            <Modal {...modal} select={data} onClose={modal.handleClose} />
        </>
    )
}

export default Cuestionario;

import React from 'react';

// UI
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { durationOptions } from '../../util';

const AgendaJorneyFooter = ({ agenda, onChangeDuration }) => {
    const onChange = ({ target }) => onChangeDuration(target);
    return (
        <div className="mt">
            <Hidden mdDown>
                <div className="pl"> 
                    <div className="flex center">
                        <div className="flex middle min-width-250">
                            <Typography>Duración minima:</Typography>
                        </div>
                        <div className="flex middle min-width-250">
                            <TextField
                                select
                                fullWidth
                                className="ml-m mr"
                                label="Duración Mínima"
                                size="small"
                                name="min"
                                value={agenda.min}
                                onChange={onChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                            </TextField>                            
                        </div>
                    </div>
                    <div className="flex my-m center">
                        <div className="flex middle min-width-250">
                            <Typography>Duración predeterminada:</Typography>
                        </div>
                        <div className="flex middle min-width-250">
                            <TextField
                                select
                                fullWidth
                                type="number"
                                className="ml-m mr"
                                label="Duración Predeterminada"
                                size="small"
                                name="pre"
                                value={agenda.pre}
                                error={agenda.pre === ''}
                                helperText={agenda.pre === '' ? 'Es necesario escoger la duracion predeterminada' : ''}
                                onChange={onChange}
                            >
                                {(durationOptions[agenda.min] || durationOptions[15]).map((item) => (
                                    <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                ))}
                            </TextField>                            
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden mdUp>
                <Grid container>
                    <Grid item container justifyContent="center" className="mx py-m" xs={12}>
                        <Typography>Duración minima:</Typography>
                    </Grid>
                    <Grid item container justifyContent="center" className="mx" xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Duración Mínima"
                            size="small"
                            name="min"
                            value={agenda.min}
                            onChange={onChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item container justifyContent="center" className="mx py-m" xs={12}>
                        <Typography>Duración predeterminada:</Typography>
                    </Grid>
                    <Grid item container justifyContent="center" className="mx" xs={12}>
                        <TextField
                            select
                            fullWidth
                            type="number"
                            label="Duración Predeterminada"
                            size="small"
                            name="pre"
                            value={agenda.pre}
                            error={agenda.pre === ''}
                            helperText={agenda.pre === '' ? 'Es necesario escoger la duracion predeterminada' : ''}
                            onChange={onChange}
                        >
                            {(durationOptions[agenda.min] || durationOptions[15]).map((item) => (
                                <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    );
}

export default AgendaJorneyFooter;
import {
  PACIENTE_CREATE,
  PACIENTE_LOADING,
  PACIENTE_LIST,
  PACIENTE_LIST_LOADING,
  PACIENTE_ERROR,
  PACIENTE_CLEAR_ALL,
  PACIENTE_TABLE,
  PACIENTE_TABLE_LOADING,
  PACIENTE_SINGLE,
  PACIENTE_SINGLE_LOADING,
  PACIENTE_UPDATE,
  PACIENTE_UPDATE_LIST,
  PACIENTE_GRUPO
} from '../constans.js';

const initialState = {
  list: null,
  single: null,
  singleLoading: false,
  error: null,
  loading: false,
  loadingList: false,
  table: {},
  tableLoading: true,
}

function sort(a, b){
  return (a.id - b.id);
}

function updateList(list, newList){
  let oldList = [...newList]
  const array = [];

  bigLoop:
  for (let i = 0; i < list.length; i++) {
    const oldItem = list[i];

    for (let x = 0; x < oldList.length; x++) {
      if(oldItem.idPaciente === oldList[x].idPaciente){
        array.push(oldList[x]);
        oldList.splice(x, 1)
        continue bigLoop;
      }
    }

    array.push(oldItem)
  }

  return array;
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case PACIENTE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case PACIENTE_LIST_LOADING:
      return {
        ...state,
        loadingList: action.payload
      }
    case PACIENTE_TABLE_LOADING:
      return {
        ...state,
        tableLoading: action.payload
      }
    case PACIENTE_CREATE:
      return {
        ...state,
        list: state.list ? [...state.list, action.payload].sort(sort) : [action.payload],
        table: state.table.list ? {...state.table, list: [...state.table.list, action.payload]} : { list: [action.payload], count: 1, limit: 1 },
        error: null
      }
    case PACIENTE_TABLE:
      return {
        ...state,
        table: action.payload
      }
    case PACIENTE_GRUPO:
      return {
        ...state,
        table: {
          ...state.table,
          list: updateList(state.table.list, action.payload)
        }
      }
    case PACIENTE_LIST:
      return {
        ...state,
        list: action.payload,
        error: null
      }
    case PACIENTE_UPDATE_LIST:
      return {
        ...state,
        table: state.table.list ? {
          ...state.table,
          list: state.table.list.filter(item => item.idPaciente !== action.payload),
          count: state.table.count - 1
        } : {}
      }
    case PACIENTE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case PACIENTE_SINGLE:
      return {
        ...state,
        single: action.payload,
        error: null
      }
    case PACIENTE_SINGLE_LOADING:
      return {
        ...state,
        single: action.payload === true ? true : state.single,
        singleLoading: action.payload
      }
    case PACIENTE_UPDATE:
      return {
        ...state,
        single: action.payload,
        list: state.list ? state.list.map(item => item.idPaciente === action.payload.idPaciente ? action.payload : item) : state.list,
      }
    case PACIENTE_CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}

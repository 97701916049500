// UI
import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 320;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'fixed',
        zIndex: 0
    },
    drawerAsidePaper: {
        marginTop: 58,
    },
    drawerPaper: {
        marginTop: 10,
        width: drawerWidth-20,
        overflow: 'scroll'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    contentHeight: {
        paddingTop: 10,
        height: '100%',
        minHeight: 600,
    }
}));

export default useStyles
import React from 'react';

// UI
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

const Appbar = ({ title, children, className, leftIcon, rightIcon, leftIconCompopnent, leftIconProps, rightIconProps, rightIconComponent }) => {
    return (
        <div className={clsx("appbar", className)}>
            {leftIcon ? <div {...leftIconProps}>{leftIcon}</div> : leftIconCompopnent}
            {children || <div className="flex grow middle"><Typography variant="h6">{title}</Typography></div>}
            {rightIcon ? <div {...rightIconProps}>{rightIcon}</div> : rightIconComponent}
        </div>
    );
}

Appbar.defaultProps = {
    className: "",
    leftIcon: null,
    rightIcon: null,
    leftIconProps: {},
    rightIconProps: {},
    leftIconCompopnent: null,
    rightIconComponent: null,
}


export default Appbar;
import React from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const FuenteForm = ({ fontSize, onChange }) => {
    const onChangeText = ({ target }) => onChange(target.value);
    return (
        <div className="flex grow column">
            <div className="flex grow px">
                <Typography className=" py-m">
                    <strong>Tamaño de la fuente</strong>
                </Typography>
            </div>
            <div className="flex grow px">
                <TextField
                    select
                    label="Tamaño"
                    name="fontSize"
                    size="small"
                    value={fontSize}
                    onChange={onChangeText}
                >
                    <MenuItem value="Pequeña">Pequeña</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Grande">Grande</MenuItem>
                    <MenuItem value="Extra Grande">Extra Grande</MenuItem>
                </TextField>
           </div>
        </div>
    );
}

export default FuenteForm;
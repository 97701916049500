import React from 'react'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';



const options = [
    { value: "title", label: "Titulo" },
    { value: "yesnot", label: "Si/No" },
    { value: "textarea", label: "Pregunta simple" },
    { value: "radio", label: "Selección simple" },
    { value: "checkbox", label: "Selección múltiple" }
]

const PreguntaForm = ({ state, single, loading, handleChange, handleCheck, handleClose, handleSubmit }) => {
    const titleLength = state.title.trim().length;
    const isTitle = single && single.tipo === "title";
    return (
        <form onSubmit={handleSubmit}>
            <Grid className="pt-m" container>

                <Grid item xs={12}>
                    <Typography>{isTitle ? "Editar Titulo" : single ? "Editar Pregunta" : "Nueva Pregunta"}</Typography>
                    <Grid item xs={12} className="my">
                        <TextField
                            size="small"
                            name="title"
                            label="Titulo"
                            value={state.title}
                            error={(single && !titleLength) || titleLength > 150}
                            helperText={titleLength > 150 ? "El largo permitido es 150 caracteres." : ""}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className="my">
                        <TextField
                            select
                            fullWidth
                            label="Tipo"
                            name="mode"
                            value={state.mode}
                            disabled={isTitle}
                            onChange={handleChange}
                        >
                            {
                                options.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>

                {
                    state.mode === "title" ? null :
                        <Grid>
                            <FormControlLabel
                                control={<Checkbox checked={state.important} color="primary" />}
                                label="Pregunta importante"
                                onChange={handleCheck}
                            />
                        </Grid>
                }

                <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className="mt">
                    <Button color="secondary" className="mr-m" onClick={handleClose}>Cerrar</Button>
                    <div className="relative">
                        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>Guardar</Button>
                        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default PreguntaForm;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// axios
import axios from 'axios';
import handler from '../../../redux/actions/handler';

// UI
import Button from '@mui/material/Button'

// util
import { getData, strongify } from '../components/util'
import download from '../../util/download';
import { baseURL } from './context';

function wrap(body){
    return getData(body, (txt) => strongify(txt, true, 0))
}

const useButtonPdf = ({ data }) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const [state, setState] = useState({ loading: false });
    
    const onClick = () => {
        setState({...state, loading: true })
        
        axios.defaults.headers.common['x-auth'] = token;
        axios.post(`${baseURL}/preview`, wrap(data), { responseType: 'blob' })
        .then(response => {
            download({ blob: response.data, fileName: "Vista previa - Recibo.pdf"})
        })
        .catch(handler(dispatch))
        .finally(() => {
            setState({...state, loading: false})
        })
    }
    return (
        <Button color="primary" size="small" variant="contained" disabled={state.loading} onClick={onClick}>
            Descargar
        </Button>
    )
}

export default useButtonPdf;
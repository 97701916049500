import { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Context } from '../../util/context.js';

// Hooks
import useFetchPdf from '../../../hooks/useFetchPdf.js';
import useFetchCsv from '../../../hooks/useFetchCsv.js';

// Actions
import { setSingle } from '../../../../redux/actions/pacienteActions.js';
import { getDeudores, setDeudores } from '../../../../redux/actions/reporteActions.js';
import { headers, makeTemplate } from '../../pacientes/deudores/template';

export const DEUDORES_RANGE = `Pacientes-deudores-range`;
export const DEUDORES_LABEL = `Pacientes-deudores-item`;
export const DEUDORES_DATA = 'Pacientes-deudores-data';

const usePacientesDeudores = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0)
    const [range, setRange] = useState(JSON.parse(localStorage.getItem(DEUDORES_RANGE)) || 30);
    const [active, setActive] = useState(localStorage.getItem(DEUDORES_LABEL));
    const { group, coin, estado, handleReset } = useContext(Context);
    const data = useSelector(state => state.report.deudores.list)
    const loading = useSelector(state => state.report.loading);
    const token = useSelector(state => state.auth.token);

    const title = useMemo(() => `Pacientes Deudores en ${coin ? coin.Nombre : ''}`, [ coin ]);
    const pdf = useFetchPdf({name: title})
    const csv = useFetchCsv()

    const [state, setState] = useState({ data: [], list: [], count: 0 });

    useEffect(function () {
        const storedData = localStorage.getItem(DEUDORES_DATA);
        if (storedData && storedData.length) {
            dispatch(setDeudores(JSON.parse(storedData)))
        }
        return () => handleReset();
        // eslint-disable-next-line
    }, [])

    useEffect(function(){
        dispatch(getDeudores(group, estado, coin, range));
        setPage(0);
        // eslint-disable-next-line
    }, [estado, coin, group, range]);

    useEffect(function(){
        setState({
            data: (data || []),
            list: (data || []).slice(page * 12, (page * 12) + 12),
            count: Math.ceil((data || []).length / 12),
        })
    }, [ data, page ])

    const handleRange = range => {
        setRange(range);
        localStorage.setItem(DEUDORES_RANGE, range)
        handleChangePage(null, 0)
    }
    const handleSelect = (id) => {
        setActive(id)
        localStorage.setItem(DEUDORES_LABEL, id);
    };
    const handleClick = item => dispatch(setSingle(item));
    const handleChangePage = (_, page) => loading ? null : setPage(page);
    const handleUpdate = () => dispatch(getDeudores(group, estado, coin, range))

    const handleDataPDF = (allpages) => {
        const titleStr = allpages ? title : `${title} pag. ${page+1}`;
        return [{ list: (allpages ? state.data : state.list).map(makeTemplate), title: titleStr, token, headers }, titleStr]
    }
    const handleDataCSV = (allpages) => [{ list: (allpages ? state.data : state.list).map(makeTemplate), headers }, (allpages ? title : `${title} pag. ${page+1}`)]

    return {
        ...state,
        limit: 12,
        pdf,
        csv,
        page,
        title,
        range,
        active,
        loading,
        handleSelect,
        handleRange,
        handleClick,
        handleChangePage,
        handleUpdate,
        handleDataPDF,
        handleDataCSV,
    }
}

export default usePacientesDeudores;

import { useContext } from 'react'
import { FontContext } from '../../config/font';

import MaterialMenu from '@mui/material/Menu';

function Menu({children, ...props}) {
    const { fontClassName } = useContext(FontContext)
    return <MaterialMenu {...props} className={fontClassName}>{children}</MaterialMenu>
}

export default Menu

import React, { useContext } from 'react';

import List from '@mui/material/List';

// Components
import { asideList } from '../components';
import FontWrapper from '../../config/font'
import { ConfiguracionContext } from '../hooks/context';

const Aside = () => {
    const { state, onSelectMenu, onSelectContainer } = useContext(ConfiguracionContext);
    return (
        <List component="nav" className="list">
            <FontWrapper>
                <List className="px-m">
                    {
                        asideList.map(({ Component, label, title }) => 
                            <Component
                                label={label}
                                title={title}
                                key={title}
                                active={label === state.menu}
                                submenu={state.container}
                                onSelect={onSelectMenu}
                                onSelectPanel={onSelectContainer}
                            />
                        )
                    }
                </List>
            </FontWrapper>
        </List>
    );
}

export default Aside;
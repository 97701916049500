import React from 'react';

// Hooks
import useOpen from '../../hooks/useOpen.js';
import useGrupos from '../hooks/useGrupos';

// Components
import List from './list';
import Modal from '../../util/modal.js';
import ModalNuevo from '../../modals/newGroups';
import FooterButton from './modalFooterButtons';

const ModalSelectGroups = ({ open, title, addedList, isProvider, handleClose, handleSubmit }) => {
  const { list, loading, onClean, onSelect } = useGrupos(open, addedList, isProvider);
  const modal = useOpen();

  const onSubmit = () => {
    handleSubmit(list.filter(item => item.checked));
    onClose();
  }

  const onClose = () => {
    handleClose();
    onClean();
  }

  const Buttons = (<FooterButton loading={loading} handleOpen={modal.handleOpen} handleClose={onClose} handleSubmit={onSubmit} />)

  return (
    <>
      <Modal open={open} buttons={Buttons} handleClose={onClose}>
        <List title={title} list={list} onSelect={onSelect} />
      </Modal>
      <ModalNuevo {...modal} alternative={isProvider} />
    </>
  )
}

export default ModalSelectGroups;

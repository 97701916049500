import {
  HISTORIA_LOADING,
  HISTORIA_LIST,
  HISTORIA_ERROR,
  HISTORIA_CREATE,
  HISTORIA_DELETE,
  HISTORIA_UPDATE
} from '../constans.js';
import {
  INDICACIONES_LIST
} from '../../components/historia/provider/constants.js';

const initialState = {
  list: null,
  indicaciones: null,
  loading: false,
  error: null
}

function dateOrder(a, b){
  return (Date.parse(a.Fecha) - Date.parse(b.Fecha))
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case HISTORIA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case HISTORIA_LIST:
      return {
        ...state,
        list: action.payload,
        error: null,
      };
    case HISTORIA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case HISTORIA_UPDATE:
      return {
        ...state,
        list: state.list
          ? state.list.map((item) =>
              item.idAnotacion === action.payload.idAnotacion
                ? action.payload
                : item
            )
          : [],
        error: null,
      };
    case HISTORIA_DELETE:
      return {
        ...state,
        list: state.list
          ? state.list.filter((item) => item.idAnotacion !== action.payload)
          : [],
        error: null,
      };
    case HISTORIA_CREATE:
      return {
        ...state,
        list: [...state.list, action.payload].sort(dateOrder),
        error: null,
      };
    case INDICACIONES_LIST: {
      return {
        ...state,
        indicaciones: action.payload,
      };
    }
    default:
      return state;
  }
}

import React from 'react';

// Components
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import AgendasHeader from '../header';
import StickyAgendaHeader from "./header";
import AgendaFooter from './footer';
import AgendaDia from './journey';
import ConfirmDialog from '../../../../../components/util/confirmDialog';

import useOpen from '../../../../../components/hooks/useOpen';
import useAgendaJornada from '../../hooks/useJornada'
import { Hidden } from '@mui/material';

const AgendaJorney = ({ item, loading, onSelectBack }) => {
    const {
        state,
        agenda,
        options,
        jornada,
        hasChanges,
        getOptionLabel,
        onSubmit,
        onChangeData,
        onChangeRest,
        onChangeRestDuration,
        onChangeCheckbox,
        onChangeDuration
    } = useAgendaJornada(item, onSelectBack)
    const modalBack = useOpen();
    return (
      <div className="relative" style={{ position: "static" }}>
        <Hidden mdDown>
          <AgendasHeader
            select={item}
            onSelectBack={hasChanges ? modalBack.handleOpen : onSelectBack}
          />
        </Hidden>
        <Hidden mdUp>
          <StickyAgendaHeader
            select={item}
            onSelectBack={hasChanges ? modalBack.handleOpen : onSelectBack}
          />
        </Hidden>
        {jornada.map((item) => (
          <AgendaDia
            key={item}
            day={item}
            options={options}
            data={state[item]}
            getOptionLabel={getOptionLabel}
            onChangeData={onChangeData}
            onChangeRest={onChangeRest}
            onChangeCheckbox={onChangeCheckbox}
            onChangeRestDuration={onChangeRestDuration}
          />
        ))}
        <AgendaFooter agenda={agenda} onChangeDuration={onChangeDuration} />
        <ConfirmDialog
          exit
          open={modalBack.open}
          title="Aún tiene cambios sin guardar,
                ¿Está seguro de salir sin confirmar los cambios?"
          text="Confirmar"
          handleClose={modalBack.handleClose}
          handleClick={onSelectBack}
        />
        <div className="flex grow end right p mt">
          <div className="relative">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !hasChanges}
              onClick={onSubmit}
            >
              Guardar
            </Button>
            {loading && (
              <div className="re-loading">
                <CircularProgress size={22} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default AgendaJorney;
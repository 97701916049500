import React from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

const MonedaForm = ({ index, item, onChange }) => {
    const error = item.Activa ? (!item.Nombre.trim().length || !item.Simbolo.trim().length) : false;
    const onChangeText = ({ target }) => onChange(item.id_moneda, { ...item, [target.name]: target.value })
    const onChangeState = () => onChange(item.id_moneda, { ...item, Activa: !item.Activa })
    return (
        <div className="flex grow column">
            <Hidden mdUp>
                <Grid container>
                    <Grid item container xs={12} justifyContent="center" className={"px pt "+(index ? '' : 'mt-m')}>
                        <Typography align="center">
                            <strong>Moneda {index === 0 ? 'Principal' : index+1}</strong>
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="center" className="py-m" xs={12}>
                        <TextField
                            label="Nombre"
                            name="Nombre"
                            size="small"
                            className="pb-m"
                            type="search"
                            autoComplete="off"
                            error={error}
                            value={item.Nombre}
                            disabled={!item.Activa}
                            onChange={onChangeText}
                            helperText={error ? (item.Nombre.length > 100 ? 'El Nombre no puede superar los 100 caracteres' : 'El Nombre no puede estar vacio') : ''}
                        />
                        <TextField
                            label="Simbolo"
                            name="Simbolo"
                            size="small"
                            type="search"
                            autoComplete="off"
                            error={error}
                            value={item.Simbolo}
                            disabled={!item.Activa}
                            onChange={onChangeText}
                            helperText={error ? (item.Simbolo.length > 10 ? 'El Simbolo no puede superar los 10 caracteres' : 'El Simbolo no puede estar vacio') : ''}
                        />
                    </Grid>
                    {
                        index ? 
                        <Grid item container justifyContent="center" xs={12}>
                            <RadioGroup aria-label="gender" name="estado" value={item.Activa ? 'Activa' : 'Desactiva'} onChange={onChangeState}>
                                <div className="flex grow end middle">
                                    <FormControlLabel value="Activa" control={<Radio size="small" color="primary" />} label={"Activa"+(item.Activa ? 'da' : 'r')} />
                                    <FormControlLabel value="Desactiva" control={<Radio size="small" color="primary" />} label={"Desactiva"+(item.Activa ? 'r' : 'da')} />
                                </div>
                            </RadioGroup>
                        </Grid> : null
                    }
                </Grid>
            </Hidden>
            <Hidden mdDown>
                <div className={"flex grow px pt "+(index ? '' : 'mt-m')}>
                    <div className={"flex middle "+(index ? 'my-m' : 'left mb-m')}>
                        <Typography>
                            <strong>Moneda {index === 0 ? 'Principal' : index+1}</strong>
                        </Typography>
                        {
                            index ? 
                            <div className="flex grow end px mx">
                                <RadioGroup aria-label="gender" name="estado" value={item.Activa ? 'Activa' : 'Desactiva'} onChange={onChangeState}>
                                    <div className="flex grow end middle">
                                        <FormControlLabel value="Activa" control={<Radio size="small" color="primary" />} label={"Activa"+(item.Activa ? 'da' : 'r')} />
                                        <FormControlLabel value="Desactiva" control={<Radio size="small" color="primary" />} label={"Desactiva"+(item.Activa ? 'r' : 'da')} />
                                    </div>
                                </RadioGroup>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="flex grow px">
                    <TextField
                        label="Nombre"
                        name="Nombre"
                        size="small"
                        type="search"
                        autoComplete="off"
                        error={error}
                        value={item.Nombre}
                        disabled={!item.Activa}
                        onChange={onChangeText}
                        helperText={error ? (item.Nombre.length > 100 ? 'El Nombre no puede superar los 100 caracteres' : 'El Nombre no puede estar vacio') : ''}
                    />
                    <TextField
                        label="Simbolo"
                        className="mx-m"
                        name="Simbolo"
                        size="small"
                        type="search"
                        autoComplete="off"
                        error={error}
                        value={item.Simbolo}
                        disabled={!item.Activa}
                        onChange={onChangeText}
                        helperText={error ? (item.Simbolo.length > 10 ? 'El Simbolo no puede superar los 10 caracteres' : 'El Simbolo no puede estar vacio') : ''}
                    />
                </div>
           </Hidden>
            <div className="w-100 mt pt-m">
                <Divider light />
            </div>
        </div>
    );
}

export default MonedaForm;
import Axios, { AuthAxios } from '../../../config/axiosv2';

export const getSyncSmtp = () => Axios.get('/smtp');
export const testSyncSmtp = () => Axios.get('/smtp/test');
export const createSyncSmtp = (data) => Axios.post('/smtp', data);
export const updateSyncSmtp = (data) => Axios.put('/smtp', data);
export const destroySyncSmtp = () => Axios.delete('/smtp')

export const getLinkGmail = () => AuthAxios.get('/')
export const destroySyncGmail = () => AuthAxios.delete('/sync')
export const testSyncGmail = () => AuthAxios.post('/test', null)

import React from 'react';

import Appbar from '../../../../surfaces/appbar';
import Gmail from '../../../../../components/correos/pages/gmail';

const CorreosGmail = () => {
    return (
        <div className="flex grow column h-100">
            <Appbar title="Gmail" />
            <Gmail />
        </div>
    );
}

export default CorreosGmail;
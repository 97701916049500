import {
    PREGUNTAS_LIST,
    PREGUNTAS_CREATE,
    PREGUNTAS_UPDATE,
    PREGUNTAS_DELETE,
    CUESTIONARIO_LIST,
    CUESTIONARIO_CREATE,
    CUESTIONARIO_UPDATE,
    CUESTIONARIO_DELETE,
    CUESTIONARIO_LOADING,
    CUESTIONARIO_CLEAR,
} from './constants.js';

const initialState = {
  list: null,
  loading: false,
  questions: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case CUESTIONARIO_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CUESTIONARIO_LIST:
      return {
        ...state,
        list: action.payload.sort((a,b) => a.idOrden - b.idOrden)
      }
    case CUESTIONARIO_CREATE:
      return {
        ...state,
        list: [...(state.list || []), action.payload].sort((a,b) => a.idOrden - b.idOrden)
      }
    case CUESTIONARIO_UPDATE:
      return {
        ...state,
        list: state.list.map(item => item.idCuestionario === action.payload.idCuestionario ? action.payload : item).sort((a,b) => a.idOrden - b.idOrden)
      }
    case CUESTIONARIO_DELETE:
      return {
        ...state,
        list: state.list.filter(item => item.idCuestionario !== action.payload)
      }
    case PREGUNTAS_LIST:
      return {
        ...state,
        questions: action.payload
      }
    case PREGUNTAS_CREATE:
      return {
        ...state,
        questions: [...(state.questions || []), action.payload]
      }
    case PREGUNTAS_UPDATE:
      return {
        ...state,
        questions: state.questions.map(item => item.idPregunta === action.payload.idPregunta ? action.payload : item)
      }
    case PREGUNTAS_DELETE:
      return {
        ...state,
        questions: state.questions.filter(item => item.idPregunta !== action.payload)
      }
    case CUESTIONARIO_CLEAR:
      return initialState
    default:
      return state;
  }
}
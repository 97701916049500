import React from 'react'

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Hooks
import usePlantillaSms from '../hooks/usePlantillaSms';

// Components
import Header from '../surfaces/correo/header';
import Body from '../surfaces/sms/body';
import AlertDialog from '../../util/dialogMessage';

const Sms = () => {
    const { state, options, ref, nochanges, success, plantilla, plantillas, loading, onChange, onSelect, onSubmit, onChangePlantilla } = usePlantillaSms();
    const isError = state ? (state.body.length >= 250) : false
    return (
        <div className="flex column px">
            <Header
                plantilla={plantilla}
                plantillas={plantillas}
                loading={loading}
                onChangePlantilla={onChangePlantilla}
            />
            <Body
                isError={isError}
                inputRef={ref}
                state={state}
                options={options}
                onChange={onChange}
                onSelect={onSelect}
            />
            <AlertDialog
                {...success}
                title="¡Atención!"
                description={`La plantilla "${plantilla.label}" fue actualizada con éxito.`}
            />
            <div className="flex grow end mb-m">
                <div className="relative">
                    <Button color="primary" variant="contained" disabled={isError  || loading || nochanges} onClick={onSubmit}>
                        Guardar
                    </Button>
                    {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                </div>
            </div>
        </div>
    )
}

export default Sms;
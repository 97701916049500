import React from 'react';
import { strongify } from '../util';

const SingleItem = ({ item, height, preview, num, font, onSelect }) => (
    <div className="flex filled">
        <span
            className="filled-item"
            onClick={preview ? undefined : onSelect}
            style={{ textAlign: item.order, fontSize: height-2, fontFamily: font }}
            dangerouslySetInnerHTML={{ __html: strongify(item.text, preview, num) }}
        />
    </div>
)

export default SingleItem;
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import useMovil from "../hooks/useMovil";

const FormPrecio = ({
  state,
  error,
  monedas,
  moneda,
  handleMoneda,
  handleChange,
  handleCheck,
  handleNumber,
  handleSubmit,
}) => {
  const isPhone = useMovil();
  return (
    <Grid component="form" container gap={6} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography
          variant={isPhone ? "h6" : undefined}
          align={isPhone ? "center" : undefined}
        >
          {state.id ? "Editar" : "Nuevo"} Item
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          type="text"
          size="small"
          label="Codigo"
          onChange={handleChange}
          name="code"
          error={Boolean(error.code)}
          helperText={error.code || ""}
          value={state.code}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          type="text"
          size="small"
          label="Tratamiento"
          onChange={handleChange}
          name="description"
          error={Boolean(error.description)}
          helperText={error.description || ""}
          value={state.description}
          fullWidth
        />
      </Grid>
      <Box display="flex" flexGrow={1} gap={6}>
        <TextField
          autoComplete="off"
          type="number"
          size="small"
          label="Precio"
          onChange={handleNumber}
          name="cost"
          error={Boolean(error.cost)}
          helperText={error.cost || ""}
          value={state.cost}
          fullWidth
        />
        <TextField
          select
          fullWidth
          size="small"
          label="Moneda"
          name="coin"
          value={moneda}
          onChange={handleMoneda}
        >
          {monedas.map((item) => (
            <MenuItem key={item.id_moneda} value={item.id_moneda}>
              {item.Nombre}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Grid container item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.title}
              onChange={handleCheck}
              name="checkedB"
              color="primary"
            />
          }
          label="Marcar como título"
        />
      </Grid>
    </Grid>
  );
};

export default FormPrecio;

import React from 'react'
import TextField from '@mui/material/TextField';

// Components
import Spinner from '../../../util/circleLoading';
import Option from '../options';

const Sms = ({ state, options, isError, inputRef, onChange, onSelect }) => {
    const onChangeData = ({ target }) => onChange(target.value);
    const onSubmit = e => e.preventDefault();
    return (
        <div className="flex column pt-m grow">
            {
                (state === null) ?
                    <Spinner text="Cargando plantilla." />
                    :
                    <form onSubmit={onSubmit}>
                        <div className="flex grow mb-m">
                            <TextField
                                inputRef={inputRef}
                                label="Contenido"
                                variant="outlined"
                                name="body"
                                required
                                fullWidth
                                multiline
                                minRows={8}
                                value={state.body}
                                onChange={onChangeData}
                                error={isError}
                                helperText={isError ? 'La plantilla de SMS debe tener menos de 250 carcateres' : ''}
                            />
                        </div>
                        <div className="flex column">
                            {options.map(item => <Option item={item} key={item.title} onSelect={onSelect} />)}
                        </div>
                    </form>
            }
        </div>
    )
}

export default Sms;
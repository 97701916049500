import React, { useMemo } from 'react';

// UI
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { getObjectHour, getPlusHour } from '../../../../../components/util/date';

const restsOptions = ['10', '15', '20', '25', '30', '40', '45', '50', '60', '90', '120']
const AgendaJourneyRest = ({ day, data, options, rest, getOptionLabel, onChangeRest, onChangeRestDuration }) => {
    // Rest
    let canBlurRestUpdate = true;
    const onChangeTimeRest = (target, content) => {
        canBlurRestUpdate = false;
        onChangeRest(day, content || {value: target.value})
    }
    const onChangeTimeRestBlur = (target) => {
        if(!canBlurRestUpdate){
            canBlurRestUpdate = true;
            return;
        }
        canBlurRestUpdate = true;
        onChangeRest(day, {value: target.value})
    }
    
    // Rest Start
    const onChangeRestStart = (e, select) => onChangeTimeRest(e.target, select)
    const onChangeRestStartBlur = (e) => onChangeTimeRestBlur(e.target)

    // Select
    const onChangeDuration = ({ target }) => onChangeRestDuration(day, target)
    const onDeleteRest = (e) => {
        e.stopPropagation();
        onChangeRest(day, '')
        onChangeRestDuration(day, {value: ''})
    }

    const descansoStr = useMemo(function(){
        if(data.restStart.value && data.restEnd > 0){
            const { title } = getObjectHour(getPlusHour(data.restStart.value, data.restEnd));
            return <span>Descanso a las <strong>{data.restStart.title}</strong> hasta las <strong>{title}</strong></span>
        }
        return ''
    }, [ data.restStart, data.restEnd ])

    return rest.open ?
        <div>
            <Hidden mdDown>
                <div className="flex grow mx mb pb">
                    <div className="flex middle min-width-50" />
                    <div className="flex right min-width-100">
                        <Typography>Descanso: </Typography>
                    </div>
                    <div className="flex grow right justify mx">
                        <Autocomplete
                            freeSolo
                            fullWidth
                            name="start"
                            className="mx-m"
                            size="small"
                            value={data.restStart}
                            options={options}
                            disabled={!data.checked}
                            onChange={onChangeRestStart}
                            onBlur={onChangeRestStartBlur}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => <TextField {...params} label="Descanso Comienza" />}
                        />
                        <TextField
                            select
                            fullWidth
                            className="mx-m"
                            size="small"
                            label="Duración"
                            name="end"
                            value={data.restEnd}
                            onChange={onChangeDuration}
                        >
                            {restsOptions.map((item) =>
                                <MenuItem value={item} key={item}>{item} minutos</MenuItem>
                            )}
                        </TextField>
                    </div>
                    <div className="flex right end min-width-125">
                        <Button variant="outlined" color="secondary" endIcon={<VisibilityOffIcon />} onClick={rest.handleClose}>
                            Ocultar
                        </Button>
                    </div>
                </div>
            </Hidden>
            <Hidden mdUp>
                <Grid container className="px">
                    <Grid item container xs={12} justifyContent="center" className="mt">
                        <Typography><strong>Descanso</strong></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            name="start"
                            className="mx-m"
                            size="small"
                            value={data.restStart}
                            options={options}
                            disabled={!data.checked}
                            onChange={onChangeRestStart}
                            onBlur={onChangeRestStartBlur}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => <TextField {...params} label="Comienza" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            className="mx-m"
                            size="small"
                            label="Duración"
                            name="end"
                            value={data.restEnd}
                            onChange={onChangeDuration}
                        >
                            {restsOptions.map((item) =>
                                <MenuItem value={item} key={item}>{item} minutos</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item container justifyContent="center" xs={12} className="my-m">
                        <Button variant="outlined" color="secondary" endIcon={<VisibilityOffIcon />} onClick={rest.handleClose}>
                            Ocultar
                        </Button>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    : (
        (data.restStart.value && data.restEnd > 0) ?
            <div className="flex grow center pointer mb-m" onClick={rest.handleOpen}>
                {descansoStr} <IconButton size="small" className="mx-m" color="secondary" onClick={onDeleteRest}><CloseIcon /></IconButton>
            </div>
        :
            (data.restStart.value || data.restEnd) ?
            <div className="flex grow center pointer mb-m text-danger" onClick={rest.handleOpen}>
                El descanso no se ha configurado por completo
            </div>
        : <div className="mb" />
    );
}

export default AgendaJourneyRest;
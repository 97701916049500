import React from 'react';

// UI
import { Box, Divider, Hidden, Typography } from '@mui/material';

// Actions
import { formatNumber } from '../../../../../components/util/tools';

const PreciosArancel = ({ arancel, onSelect }) => {
    const onClick = () => onSelect(arancel);
    return (
        <div className={arancel.EsTitulo ? "flex end px" : "px"} onClick={onClick}>
            {
                arancel.EsTitulo ?
                    <div className="flex grow text-primary mt">
                        <div className="flex grow middle">
                            <Typography noWrap variant="body2"><strong>{arancel.Codigo}</strong></Typography>
                            <Typography noWrap variant="body2" className="px"><strong>{arancel.Tratamiento}</strong></Typography>
                        </div>
                    </div>
                :
                    <Box display="flex" flexGrow={1} my={1} className="pointer">
                        <Hidden mdDown>
                            <div className="flex grow middle" style={{overflow: "hidden"}}>
                                <Typography variant="body2">{arancel.Codigo}</Typography>
                                <div className="flex grow middle" style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                    <Typography noWrap variant="body2" className="px">{arancel.Tratamiento}</Typography>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="flex grow middle">
                                <Typography variant="body2">{arancel.Codigo}</Typography>
                                <Typography variant="body2" className="px">{arancel.Tratamiento}</Typography>
                            </div>
                        </Hidden>
                        <div className="flex grow middle end">
                            <Typography variant="body2">{arancel.moneda.Simbolo}</Typography>
                            <Typography variant="body2" className="min-width-75" align="right">{formatNumber(arancel.Precio)}</Typography>
                        </div>
                    </Box>
            }
            {!arancel.EsTitulo && <Divider light={true} />}
        </div>
    );
}

export default PreciosArancel;
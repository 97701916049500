import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({ text, minHeight }) => (
  <Grid item xs={12}>
    <div className="center-content flex column" style={{minHeight}}>
      <div>
        <CircularProgress />
      </div>
      <div className="mt">
        <Typography>
          <strong>{text}</strong>
        </Typography>
      </div>
    </div>
  </Grid>
);

Loading.defaultProps = {
  minHeight: 400
}

export default Loading;

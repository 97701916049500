import React from 'react';

import useModal from '../../hooks/useModal'

// Components
import Modal from '../../../util/modal';
import Form from './form';

const ModalTemplate = (props) => {
    const { state, inputRef, onChange, message, onAdd, onRemove, onComplete, onAbort } = useModal(props);
    return (
        <Modal open={props.open && props.target} handleClose={onAbort}>
            <Form
                disabled={!state.text.trim().length}
                message={message}
                isEdit={props.target && props.target.id}
                selectInputProps={{ error: !!message, name: 'align', value: state.align, onChange: onChange }}
                orderInputProps={{ error: !!message, name: 'order', value: state.order, onChange: onChange }}
                textInputProps={{
                    fullWidth: true,
                    multiline: true,
                    variant: "outlined",
                    rows: 3,
                    name: 'text',
                    value: state.text,
                    onChange: onChange,
                    inputRef: inputRef
                }}
                onAdd={onAdd}
                onRemove={onRemove}
                onComplete={onComplete}
                onClose={onAbort}
            />
        </Modal>
    )
}

export default ModalTemplate

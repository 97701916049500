import useSWR from "swr";
import Axios, { baseURL } from "../../config/axiosv2";


const fetcher = (URL) => Axios.get(URL, { baseURL: baseURL.concat('/v3') });

export const useAranceles = () => {
    const { data: response, error, mutate } = useSWR('/aranceles', fetcher)

    const data = response ? response.data : []

    return {
        data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

import {
  CUENTAS_COBRAR,
  CUENTAS_COBRAR_LOADING,
  CUENTAS_PAGOS_LOADING,
  CUENTAS_PAGOS,
  CUENTAS_PAGAR_LOADING,
  CUENTAS_PAGAR,
  PROVEEDORES_PAGOS,
  PROVEEDORES_PAGOS_LOADING,
  CUENTAS_INGRESOS_LOADING,
  CUENTAS_INGRESOS,
  PRESTACIONES_SEARCH_LOADING,
  PRESTACIONES_SEARCH,
  PACIENTES_NUEVOS_LIST_LOADING,
  PACIENTES_NUEVOS_LIST,
  CUENTAS_PRESTACIONES_LOADING,
  CUENTAS_PRESTACIONES,
  PACIENTES_NUEVOS_LOADING,
  PACIENTES_NUEVOS,
  PACIENTES_CITAS,
  PACIENTES_CITAS_LOADING,
  PACIENTES_FALTARON,
  PACIENTES_FALTARON_LOADING,
  PACIENTES_VINIERON_LOADING,
  PACIENTES_VINIERON,
  CONTROL_LOADING,
  CONTROL_REPORTE,
  PACIENTES_DEUDORES,
  PACIENTES_DEUDORES_LOADING,
  PACIENTES_INGRESOS_LOADING,
  PACIENTES_INGRESOS,
  PACIENTES_EGRESOS_LOADING,
  PACIENTES_EGRESOS,
  CLEAN_ALL_REPORTS
} from '../constans.js';
import Axios from '../../config/axios.js';
import handler from './handler.js';
import { DEUDORES_DATA } from '../../components/reportes/pacientes/hooks/useDeudores.js';
import { COBRAR_DATA } from '../../components/reportes/cuentas/hooks/useCuentasCobrar.js'
import { NUEVOS_DATA } from '../../components/reportes/pacientes/hooks/usePacientesNuevos.js';

// Loading
const loader = (payload, type) => ({ type, payload });

export const setCuentasCobrar = (data) => ({ type: CUENTAS_COBRAR, payload: data })

export const getCuentasCobrar = (page, group, estado) => dispatch => {
  dispatch(loader(true, CUENTAS_COBRAR_LOADING))
  Axios.get('/reportes/cuentas/cobrar', {
    params:{
      page: page,
      groups: (group > 0) ? [group] : [],
      estado: estado
    }
  })
  .then(res =>{
    localStorage.setItem(COBRAR_DATA, JSON.stringify(res.data));
    dispatch({
      type: CUENTAS_COBRAR,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CUENTAS_COBRAR_LOADING)))
}

export const getCuentasPagos = ({ date, coin, range, estado, group }) => dispatch => {
  dispatch(loader(true, CUENTAS_PAGOS_LOADING))
  Axios.get('/reportes/pagos/pacientes', {
    params:{
      date,
      range,
      estado,
      coin: coin.id_moneda,
      groups: (group > 0) ? [group] : [],
      year: (date ? new Date(date) : new Date()).getFullYear() }
  })
  .then(res => dispatch({ type: CUENTAS_PAGOS, payload: res.data }))
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CUENTAS_PAGOS_LOADING)))
}


export const getProveedoresPagos = ({ range, coin, date, group }) => dispatch => {
  dispatch(loader(true, PROVEEDORES_PAGOS_LOADING))
  Axios.get('/reportes/pagos/proveedores', {
    params:{
      range,
      date,
      groups: (group > 0) ? [group] : [],
      coin: coin.id_moneda,
      year: (date ? new Date(date) : new Date()).getFullYear()
    }
  })
  .then(res =>{
    dispatch({
      type: PROVEEDORES_PAGOS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PROVEEDORES_PAGOS_LOADING)))
}

export const getCuentasPagar = (page, group) => dispatch => {
  dispatch(loader(true, CUENTAS_PAGAR_LOADING))
  Axios.get('/reportes/cuentas/pagar', {
    params:{ page, group }
  })
  .then(res =>{
    dispatch({
      type: CUENTAS_PAGAR,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CUENTAS_PAGAR_LOADING)))
}

export const getCuentasPrestaciones = (range, date, money, label) => dispatch =>{
  dispatch(loader(true, CUENTAS_PRESTACIONES_LOADING))
  Axios.get('/reportes/cuentas/prestaciones', {
    params:{
      type:range,
      year: date.year,
      month: date.month,
      day: date.day,
      money,
      label
    }
  })
  .then(res =>{
    dispatch({
      type: CUENTAS_PRESTACIONES,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CUENTAS_PRESTACIONES_LOADING)))
}

export const searchPrestaciones = (label, page) => dispatch =>{
  dispatch(loader(true, PRESTACIONES_SEARCH_LOADING))
  Axios.get("/cuentas/tratamiento/search", {
    params: { label: label, page: page },
  })
    .then((res) => {
      dispatch({
        type: PRESTACIONES_SEARCH,
        payload: res.data,
      });
    })
    .catch(handler(dispatch));
}


export const clearPrestaciones = () => dispatch => dispatch({
  type: PRESTACIONES_SEARCH,
  payload: null
})

export const getPacientesNuevos = (range, date, money) => dispatch =>{
  dispatch(loader(true, PACIENTES_NUEVOS_LOADING))
  Axios.get('/reportes/pacientes/nuevos', {
    params:{
      type:range,
      year: date.year,
      month: date.month,
      day: date.day,
      money: money
    }
  })
  .then(res =>{
    dispatch({
      type: PACIENTES_NUEVOS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_NUEVOS_LOADING)))
}

export const getPacientesCitas = (range, date, compare) => dispatch =>{
  dispatch(loader(true, PACIENTES_CITAS_LOADING))
  Axios.get('/reportes/citas', {
    params:{
      type: range,
      year: date.year,
      month: date.month,
      day: date.day,
      money: 1,
      compare: compare
    }
  })
  .then(res =>{
    dispatch({
      type: PACIENTES_CITAS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_CITAS_LOADING)))
}

export const getPacientesFaltaron = (days, group, estado) => dispatch =>{
  dispatch(loader(true, PACIENTES_FALTARON_LOADING))
  Axios.get('/reportes/pacientes/faltaron', {
    params:{
      days,
      estado,
      groups: (group > 0) ? [group] : [],
    }
  })
  .then(res =>
    dispatch({
      type: PACIENTES_FALTARON,
      payload: res.data
    })
  )
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_FALTARON_LOADING)))
}

export const getPacientesVinieron = (days, group, estado) => dispatch =>{
  dispatch(loader(true, PACIENTES_VINIERON_LOADING))
  Axios.get('/reportes/pacientes/vinieron', {
    params:{
      days,
      estado,
      groups: (group > 0) ? [group] : [],
    }
  })
  .then(res =>
    dispatch({
      type: PACIENTES_VINIERON,
      payload: res.data
    })
  )
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_VINIERON_LOADING)))
}

export const getControl = (page, date, until, group, estado) => dispatch => {
  dispatch(loader(true, CONTROL_LOADING))
  Axios.get('/reportes/control', {
    params:{
      page,
      date,
      until,
      estado,
      groups: (group > 0) ? [group] : [],
    }
  })
  .then(res =>{
    dispatch({
      type: CONTROL_REPORTE,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CONTROL_LOADING)))
}

export const getIngresos = ({ coin, range, date, estado, group }) => dispatch => {
  dispatch(loader(true, PACIENTES_INGRESOS_LOADING))
  Axios.get('/reportes/cuentas/ingresos', {
    params: {
      range,
      date,
      estado,
      groups: (group > 0) ? [group] : [],
      coin: coin.id_moneda,
      year: (date ? new Date(date) : new Date()).getFullYear()
    }
  })
  .then(res =>{
    dispatch({
      type: PACIENTES_INGRESOS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_INGRESOS_LOADING)))
}

export const getEgresos = ({ coin, range, date, group }) => dispatch => {
  dispatch(loader(true, PACIENTES_EGRESOS_LOADING))
  Axios.get('/reportes/cuentas/egresos', {
    params: {
      range,
      date,
      groups: (group > 0) ? [group] : [],
      coin: coin.id_moneda,
      year: (date ? new Date(date) : new Date()).getFullYear()
    }
  })
  .then(res =>{
    dispatch({
      type: PACIENTES_EGRESOS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_EGRESOS_LOADING)))
}

export const getCuentasGenerado = ({ range, coin, estado, doctor, date }) => dispatch =>{
  dispatch(loader(true, CUENTAS_INGRESOS_LOADING))
  Axios.get('/reportes/cuentas/generado', {
    params:{
      range,
      date,
      estado,
      doctor,
      coin: coin.id_moneda,
      year: (date ? new Date(date) : new Date()).getFullYear()
    }
  })
  .then(res =>{
    dispatch({
      type: CUENTAS_INGRESOS,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, CUENTAS_INGRESOS_LOADING)))
}

export const setDeudores = (list) => ({ type: PACIENTES_DEUDORES, payload: { list } })

export const getDeudores = (group, estado, coin, diff) => dispatch => {
  dispatch(loader(true, PACIENTES_DEUDORES_LOADING))
  Axios.get('/reportes/pacientes/deudores', {
    params:{
      estado: estado,
      diff: diff,
      coin: coin.id_moneda,
      groups: (group > 0) ? [group] : [],
    }
  })
  .then(res => {
    localStorage.setItem(DEUDORES_DATA, JSON.stringify(res.data.list))
    dispatch({
      type: PACIENTES_DEUDORES,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_DEUDORES_LOADING)))
}

export const setNuevosList = (list) => ({ type: PACIENTES_NUEVOS_LIST, payload: list })

export const getNuevosList = ({ range, date }) => dispatch => {
  dispatch(loader(true, PACIENTES_NUEVOS_LIST_LOADING))
  Axios.get('/reportes/pacientes/nuevos/lista', {
    params:{
      range,
      date,
      year: (date ? new Date(date) : new Date()).getFullYear()
    }
  })
  .then(res => {
    dispatch({
      type: PACIENTES_NUEVOS_LIST,
      payload: res.data
    })
    localStorage.setItem(NUEVOS_DATA, JSON.stringify(res.data))
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false, PACIENTES_NUEVOS_LIST_LOADING)))
}

export const cleanAllReports = () => ({ type: CLEAN_ALL_REPORTS })

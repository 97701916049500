export const style = {
  container: {
    width: '90%',
    maxWidth: 500,
    minHeight: 200,
    backgroundColor: 'white',
    borderRadius: 8,
    border: '1px solid rgba(0,0,0,.15)',
    boxShadow: '0px 2px 3px 1px rgb(0, 0, 0, .10)'
  },
  text: {
    textAlign: 'center',
    margin: 0
  }
}

export const validNumber = (text) => {
      for (let i = 0; i < text.length; i++) {
        if(!isNaN(Number(text[i]))){
          return true;
        }
      }

      return false;
}

export const validEmail = (mail) => {
  let aux = false;
  let point = false;

  for (let i = 0; i < mail.length; i++) {
    if(mail[i] === "@"){
      if(aux){
        // double @@
        break;
      } else {
        aux = true;
      }
    } else if(mail[i] === "." && aux){
      if(point){
        // double point after @ like @gmail..com
        break;
      } else {
        point = true;
      }
    } else if(mail[i] === " "){
      break;
    }
  }

  if(aux && point && mail.length > 5) return true;

  return false;
}

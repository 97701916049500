import React from 'react';
import clsx from 'clsx';

import SvgIcon from '@mui/material/SvgIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { ReactComponent as AgendaIcon } from '../../../../assets/svgs/agenda.svg'

const Agenda = ({ label, active, onSelect }) => {
    const onClick = () => onSelect(label);
    return (
        <ListItemButton className={clsx({ active })} onClick={onClick}>
            <ListItemIcon>
                <SvgIcon fontSize="small">
                    <AgendaIcon />
                </SvgIcon>
            </ListItemIcon>
            <ListItemText disableTypography>
                <Typography>Agenda</Typography>
            </ListItemText>
        </ListItemButton>
    );
}

export default Agenda;
import React, { useCallback, useMemo } from 'react';
import useMenu from '../hooks/useMenu';
import useEstados from '../hooks/useEstados';

// UI
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HealingIcon from '@mui/icons-material/Adjust';

// Components
import Menu from './menu';

// Tools
import { toColor } from '../../util/tools';
import { useSelector } from 'react-redux';

const estadoStyle = { display: "flex", flex: 0, flexDirection: 'row', alignItems: "center", border: "1px solid #d9d9d9", borderRadius: 5, padding: "5px 12px", cursor: "pointer", marginLeft: 5 }
export const EstadoIcon = ({ paciente, label }) => {
    const { list } = useEstados();
    const menu = useMenu();
    const estado = useMemo(() => (list || []).find(item => item.idEstado === paciente.idEstadoPersonalizado), [paciente, list]);
    const onChange = (value) => menu.onChange(paciente, value)
    const getComputedStyle = useCallback(color => {
        return {
            width: 16,
            height: 16,
            borderRadius: 10,
            marginRight: 5,
            backgroundColor: toColor(color)
        }
    }, [])
    return <>
        {
            label && estado ?
                <div style={estadoStyle} onClick={menu.onClick}>
                    <span style={getComputedStyle(estado.color)}></span>
                    <Typography variant="body2" noWrap>
                        {estado.nombre}
                    </Typography>
                </div>
            :
                <IconButton aria-label="delete" onClick={menu.onClick} size="large">
                    <HealingIcon />
                </IconButton>
        }
        <Menu anchorEl={menu.anchorEl} paciente={paciente} handleChange={onChange} handleClose={menu.onClose} />
    </>;
}

export const EstadoColor = ({ className, paciente }) => {
    const estadosPersonalizados = useSelector(state => state.auth.user.UsarEstadosPersonalizados);
    const { list } = useEstados();
    const menu = useMenu();
    const estado = useMemo(() => (list || []).find(item => item.idEstado === paciente.idEstadoPersonalizado), [paciente.idEstadoPersonalizado, list]);
    const getComputedStyle = useCallback(color => ({color: toColor(color), backgroundColor: toColor(color)}), [])
    const onChange = (value) => menu.onChange(paciente, value)
    const onClick = (e) => {
        if (window.innerWidth > 700) {
            e.stopPropagation();
        };
    }
    const onOpen = (e) => {
        if (window.innerWidth > 700) {
            e.stopPropagation();
            menu.onClick(e);
        };
    }
    if (!estado || !estadosPersonalizados) return null;
    return (
        <div onClick={onClick}>
            <span onClick={onOpen} className={className} style={getComputedStyle(estado.color)}>.</span>
            <Menu anchorEl={menu.anchorEl} paciente={paciente} handleChange={onChange} handleClose={menu.onClose} />
        </div>
    )
}

export const EstadoAgenda = ({ className, paciente, handleClose }) => {
    const menu = useMenu();
    const onChange = (value) => menu.onChange(paciente, value)
    const onOpen = (e) => {
        e.stopPropagation();
        menu.onClick(e);
    }
    const onClose = () => {
        menu.onClose();
        handleClose();
    }
    return (
        <>
            <MenuItem key={300} onClick={onOpen} className={className} >
                <ListItemIcon>
                    <HealingIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Estado"/>
            </MenuItem>
            <Menu anchorEl={menu.anchorEl} paciente={paciente} handleChange={onChange} handleClose={onClose} />
        </>
    )
}
export const TipoDeUsuario = {
  0: "Administrador",
  1: "Avanzado",
  2: "Común",
  3: "Limitado",
}

export const PERMISOS = {
  IMAGENES: {
    ABRIR_IMAGENES: { code: 5010, label: "Abrir Imágenes o Archivos" },
    DESCARGAR_IMAGENES: { code: 5020, label: "Descargar Imágenes o Archivos" },
    ELIMINAR_IMAGENES: { code: 5030, label: "Eliminar Imágenes o Archivos" },
  },
  PACIENTES: {
    AGREGAR_PACIENTES: { code: 1010, label: "Agregar Pacientes" },
    ACTIVAR_ELIMINAR_PACIENTES: {
      code: 1020,
      label: "Activar, Desactivar, Eliminar Pacientes, Restaurar Pacientes",
    },
    VER_PACIENTES: { code: 1025, label: "Ver datos del paciente" },
    MODIFICAR_PACIENTES: { code: 1030, label: "Modificar datos del paciente" },
    EXPORTAR_CSV_PACIENTES: {
      code: 1240,
      label: "Exportar datos de los pacientes a Excel o CSV",
    },
  },
  HISTORIA: {
    ACCEDER_HISTORIA: {
      code: 1510,
      label: "Acceder a la historia de un paciente",
    },
    MODIFICAR_HISTORIA: {
      code: 1520,
      label: "Modificar datos de la historia del paciente",
    },
    MODIFICAR_ODONTOGRAMA: {
      code: 1560,
      label: "Modificar el odontograma de un paciente",
    },
    MODIFICAR_PERIODONTOGRAMA: {
      code: 1570,
      label: "Modificar el periodontograma de un paciente",
    },
  },
  CUENTAS_PACIENTES: {
    ACCEDER_CUENTAS: {
      code: 1305,
      label: "Acceder a las cuentas de un Paciente."
    },
    AGREGAR_TRATAMIENTOS: {
      code: 1310,
      label: "Agregar presupuestos o tratamientos"
    },
    MODIFICAR_PIEZA_NOMBRE_TRATAMIENTOS: {
      code: 1315,
      label: "Modificar nombre o pieza de (presupuestos o tratamientos).",
    },
    MODIFICAR_COSTO_TRATAMIENTOS: {
      code: 1317,
      label: "Modificar costo de (presupuestos o tratamientos)"
    },
    ELIMINAR_TRATAMIENTOS: {
      code: 1320,
      label: "Eliminar presupuestos o tratamientos"
    },
    AGREGAR_PAGOS: {
      code: 1330,
      label: "Agregar un pago"
    },
    ELIMINAR_PAGOS: {
      code: 1340,
      label: "Eliminar un pago. (Caso de ejemplo)"
    },
    CAMBIAR_ESTADO_TRATAMIENTO: {
      code: 1350,
      label: "Cambiar el estado de un tratamiento"
    },
    CAMBIAR_ESTADO_ANTERIOR_TRATAMIENTO: {
      code: 1360, label:
        "Pasar tratamiento a un estado anterior (Osea si esta en tratamiento pasarlo a presupuesto)",
    },
    MODIFICAR_FECHA_DOCUMENTOS: {
      code: 1460,
      label: "Modificar Fecha de Documentos"
    },
  },
  PROVEEDORES: {
    AGREGAR_PROVEEDORES: { code: 2010, label: "Agregar proveedores" },
    ACTIVAR_ELIMINAR_PROVEEDORES: {
      code: 2015,
      label: "Ver datos del proveedor",
    },
    VER_PROVEEDORES: { code: 2020, label: "Modificar datos del proveedor" },
    MODIFICAR_PROVEEDORES: {
      code: 2030,
      label: "Activar, Desactivar, Eliminar, Restaurar proveedores",
    },
    EXPORTAR_CSV_PROVEEDORES: {
      code: 2040,
      label: "Exportar datos de los proveedores a Excel o CSV",
    },
  },
  REPORTES: {
    VER_GENERADO_AGENDADAS_PAGO_PACIENTES_PROVEEDORES: {
      code: 3710,
      label:
        "Ver Generado, Citas Agendadas, Pago de pacientes, Pago a Proveedores, Pacientes que asistieron",
    },
    VER_CUENTAS_COBRAR_PAGER: {
      code: 3720,
      label: "Ver Cuentas por Cobrar o Cuentas por Pagar",
    },
    VER_PRESTACIONES_PACIENTES_NUEVOS: {
      code: 3730,
      label: "Ver Prestaciones realizadas, Pacientes nuevos",
    },
    VER_PACIENTES: {
      code: 3740,
      label: "Ver Lista de Pacientes (Administrar pacientes)",
    },
    VER_PROVEEDORES: {
      code: 3750,
      label: "Ver Lista de Proveedores (Administrar proveedores)",
    },
    EXPORTAR_REPORTE: {
      code: 3760,
      label: "Exportar todos los registros de un reporte",
    },
  },
};

export const Permisos = {
  Pacientes: [
    PERMISOS.PACIENTES.AGREGAR_PACIENTES,
    PERMISOS.PACIENTES.ACTIVAR_ELIMINAR_PACIENTES,
    PERMISOS.PACIENTES.VER_PACIENTES,
    PERMISOS.PACIENTES.MODIFICAR_PACIENTES,
    PERMISOS.PACIENTES.EXPORTAR_CSV_PACIENTES,
  ],
  "Cuentas Pacientes": [
    PERMISOS.CUENTAS_PACIENTES.ACCEDER_CUENTAS,
    PERMISOS.CUENTAS_PACIENTES.AGREGAR_TRATAMIENTOS,
    PERMISOS.CUENTAS_PACIENTES.MODIFICAR_PIEZA_NOMBRE_TRATAMIENTOS,
    PERMISOS.CUENTAS_PACIENTES.MODIFICAR_COSTO_TRATAMIENTOS,
    PERMISOS.CUENTAS_PACIENTES.ELIMINAR_TRATAMIENTOS,
    PERMISOS.CUENTAS_PACIENTES.AGREGAR_PAGOS,
    PERMISOS.CUENTAS_PACIENTES.ELIMINAR_PAGOS,
    PERMISOS.CUENTAS_PACIENTES.CAMBIAR_ESTADO_TRATAMIENTO,
    PERMISOS.CUENTAS_PACIENTES.CAMBIAR_ESTADO_ANTERIOR_TRATAMIENTO,
    PERMISOS.CUENTAS_PACIENTES.MODIFICAR_FECHA_DOCUMENTOS,
  ],
  Historia: [
    PERMISOS.HISTORIA.ACCEDER_HISTORIA,
    PERMISOS.HISTORIA.MODIFICAR_HISTORIA,
    PERMISOS.HISTORIA.MODIFICAR_ODONTOGRAMA,
    PERMISOS.HISTORIA.MODIFICAR_PERIODONTOGRAMA,
  ],
  "Proximo Control (Seguimiento) ": [
    { code: 1610, label: "Agregar Control" },
    { code: 1620, label: "Modificar Control" },
    { code: 1630, label: "Eliminar Control" },
  ],
  Proveedores: [
    PERMISOS.PROVEEDORES.AGREGAR_PROVEEDORES,
    PERMISOS.PROVEEDORES.ACTIVAR_ELIMINAR_PROVEEDORES,
    PERMISOS.PROVEEDORES.VER_PROVEEDORES,
    PERMISOS.PROVEEDORES.MODIFICAR_PROVEEDORES,
    PERMISOS.PROVEEDORES.EXPORTAR_CSV_PROVEEDORES,
  ],
  "Cuentas Proveedores": [
    { code: 2210, label: "Agregar un Documento" },
    { code: 2220, label: "Eliminar un Documento" },
    { code: 2250, label: "Ingresar un pago" },
    { code: 2260, label: "Eliminar un pago" },
    { code: 2280, label: "Modificar Fecha de Documentos" },
  ],
  "Lista de Precios": [
    { code: 3505, label: "Ver Lista de precios" },
    { code: 3510, label: "Agregar una nueva Lista" },
    { code: 3520, label: "Modificar una Lista" },
    { code: 3530, label: "Eliminar una Lista" },
  ],
  Reportes: [
    {
      code: 3710,
      label:
        "Ver Generado, Citas Agendadas, Pago de pacientes, Pago a Proveedores, Pacientes que asistieron",
    },
    { code: 3720, label: "Ver Cuentas por Cobrar o Cuentas por Pagar" },
    { code: 3730, label: "Ver Prestaciones realizadas, Pacientes nuevos" },
    { code: 3740, label: "Ver Lista de Pacientes (Administrar pacientes)" },
    { code: 3750, label: "Ver Lista de Proveedores (Administrar proveedores)" },
    { code: 3760, label: "Exportar todos los registros de un reporte" },
  ],
  Agenda: [
    { code: 4010, label: "Agregar citas" },
    { code: 4020, label: "Modificar citas" },
    { code: 4030, label: "Eliminar citas" },
    { code: 4100, label: "Marcar si el paciente concurríó o no, a la cita" },
    { code: 4110, label: "Eliminar marca de asistencia" },
  ],
  "Imágenes y Archivos": [
    PERMISOS.IMAGENES.ABRIR_IMAGENES,
    PERMISOS.IMAGENES.DESCARGAR_IMAGENES,
    PERMISOS.IMAGENES.ELIMINAR_IMAGENES,
  ],
};
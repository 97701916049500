import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Modal from '../../../../../components/util/modal';

// Util
import { Button, CircularProgress, Grid, Box, TextField, Typography, Divider, IconButton } from '@mui/material';
import { updateDoctorPIN } from '../../../../../redux/actions/grupoActions';
import { GRUPO_LOADING } from '../../../../../redux/constans';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const defaultState = {
    oldPin: "",
    newPin: "",
    confirmPin: ""
}

const PINModal = ({ open, doctor, handleClose }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.group.loading);
    const user = useSelector(state => state.auth.user);
    const isAdmin = !user.TipoDeUsuario;
    const [state, setState] = useState(defaultState);
    const [isPinWrong, setIsPinWrong] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const onToggleVisibility = () => setIsVisible(!isVisible);
    const onChangeText = (evt) => {
        const { name, value } = evt.target;
        const ignore = name === "oldPin" && isAdmin;
        setState({ ...state, [name]: ignore ? value : value.substring(0, 4) });
    }
    const onClose = () => {
        setState(defaultState)
        handleClose();
    }
    const onSubmit = e => {
        e.preventDefault();

        if (!isAdmin && state.oldPin !== doctor.Pin) {
            dispatch({ type: GRUPO_LOADING, payload: true });
            return setTimeout(() => {
                setIsPinWrong(true)
                dispatch({ type: GRUPO_LOADING, payload: false });
            }, 2000);
        }

        dispatch(updateDoctorPIN(doctor.idGrupo, { pin: state.newPin, oldPin: state.oldPin }, onClose));
    }

    const isInValidNumber = state.newPin.split().some(n => isNaN(+n));
    const isNotEqual = state.confirmPin.length === 4 && state.newPin !== state.confirmPin;
    const isDisabled = state.oldPin.length < 4 || state.newPin.length !== 4 || state.confirmPin.length !== 4 || isNotEqual || isInValidNumber;

    return (
        <Modal open={open} handleClose={onClose}>
            <Box>
                <Typography>
                    Modificar PIN de {doctor.Nombre}
                </Typography>
                <Box display="flex" flexDirection="column" gap={3}>
                    {
                        isAdmin ? (
                            <TextField
                                required
                                label={`Ingrese Contraseña del usuario ${user.nombre || user.Nombre}`}
                                name="oldPin"
                                className="my"
                                size="small"
                                type="password"
                                value={state.oldPin}
                                onChange={onChangeText}
                            />
                        ) : (
                            <TextField
                                required
                                label="Ingrese PIN actual"
                                name="oldPin"
                                variant="filled"
                                className="my"
                                size="small"
                                type="password"
                                value={state.oldPin}
                                error={isPinWrong}
                                helperText={isPinWrong ? "PIN incorrecto" : ""}
                                onChange={onChangeText}
                            />
                        )
                    }
                    <Divider />
                    <TextField
                        required
                        label="Nuevo PIN"
                        name="newPin"
                        size="small"
                        className="mt"
                        type={isVisible ? "text" : "password"}
                        value={state.newPin}
                        error={isNotEqual || isInValidNumber}
                        helperText={isInValidNumber ? "El PIN solo debe contener números" : isNotEqual ? "Los PIN no coinciden" : ""}
                        onChange={onChangeText}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={onToggleVisibility} size="small">
                                    {isVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            )
                        }}
                    />
                    <TextField
                        required
                        label="Confirme Nuevo PIN"
                        name="confirmPin"
                        size="small"
                        className="my-m"
                        type={isVisible ? "text" : "password"}
                        value={state.confirmPin}
                        error={isNotEqual || isInValidNumber}
                        helperText={isInValidNumber ? "El PIN solo debe contener números" : isNotEqual ? "Los PIN no coinciden" : ""}
                        onChange={onChangeText}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={onToggleVisibility} size="small">
                                    {isVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            )
                        }}
                    />
                </Box>
            </Box>
            <Grid item container justifyContent="flex-end" className="mt">
                <Button color="secondary" onClick={onClose} className="mr-m">
                    Cancelar
                </Button>
                <div className="relative">
                    <Button color="primary" variant="contained" disabled={loading || isDisabled} onClick={onSubmit}>
                        Modificar
                    </Button>
                    {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                </div>
            </Grid>
        </Modal>
    )
}

export default PINModal

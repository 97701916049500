import React from 'react';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PencilIcon from '@mui/icons-material/Create';
import { CircularProgress } from '@mui/material';

const DialogModal = ({ open, data, callback, handleClose }) => {
  const handleCallback = () => callback(data);

  if (!data) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-custom-title"
      aria-describedby="alert-dialog-custom-description"
      style={{ minWidth: 300 }}
    >
      <DialogTitle id="alert-dialog-custom-title">{data.title}</DialogTitle>
      <DialogContent>
        <div>
          {data.body}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container style={{ padding: '0px 10px' }} justifyContent="space-between">
          {
            data.buttonRight ?
              <Grid xs={2} md item>
                <Button onClick={data.buttonRightHandle} size="small" variant="outlined">
                  {
                    window.innerWidth < 768 ? <PencilIcon fonsize="small" />
                      : "EDITAR"
                  }
                </Button>
              </Grid>
              : null}
          <Grid xs={10} md item container justifyContent="flex-end" style={{ gap: "8px" }}>
            {
              data.close ?
                <Button onClick={handleClose} size="small" color="secondary">
                  Cancelar
                </Button>
                : null
            }
            <div className="relative">
              <Button onClick={handleCallback} size="small" variant="contained" color="primary" autoFocus>
                {data.button || 'Eliminar'}
              </Button>
              {data.loading && <div className="re-loading"><CircularProgress size={22} /></div>}
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default DialogModal;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeLogout, changePasswordFromToken } from '../redux/actions/authActions.js';
import { style, validNumber } from '../components/auth/reset.js';
import { isAllowed } from '../components/util/tools.js';

import Logo from '../assets/logoAlt.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const defaultState = {
  password: '',
  password2: '',
  error: false,
  error2: false
}

const Reset = ({ history }) => {
  const [session, setSession] = useState(JSON.parse(localStorage.getItem('sessionLast')));
  const [state, setState] = useState(defaultState)
  const { password, password2, error, error2 } = state;
  const auth = useSelector(state => state.auth.token);
  const loading = useSelector(state => state.auth.loading);
  const mainError = useSelector(state => state.auth.error);
  const dispatch = useDispatch()
  const { token } = useParams();

  useEffect(function(){
    if(auth){
      localStorage.setItem('sessionLast', true);
      dispatch(makeLogout());
    } else {
      localStorage.setItem('sessionLast', false);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(function(){
    let time;
    if(session){
      time = setTimeout(function(){
        setSession(false)
      }, 3000)
    }
    return () => {
      if(time){
        clearTimeout(time);
      }
    }
  }, [session])

  const handleBack = () => history.push('/')
  const handleChange = e => {
    const { name, value } = e.target;

    let errorName = (name === "password" ? 'error' : 'error2'), errorValue = '';

    const allow = isAllowed(value);
     if(value.length < 6){
      errorValue = "La contraseña debe ser mayor a 6 caracteres.";
    } else if(!allow.sucess){
      errorValue = allow.error;
    } else if(!validNumber(value)){
      errorValue = "La contraseña debe contener al menos 1 numero.";
    }

    setState({
      ...state,
      [name]: value,
      [errorName]: errorValue
    })
  }
  const handleSubmit = e => {
    e.preventDefault();

    if(!error && !error2 && password === password2){
      const callback = () => history.push('/login')
      dispatch(changePasswordFromToken({password, token}, callback))
      setState(defaultState)
    }
  }

  return (
    <div className="flex grow center" style={{height: '100%'}}>
      <div style={style.container}>
        <div className="flex grow column">
          <div style={style.text} className="mt-m">
            <img src={Logo} width={50} heigth={50} alt="Logo Odontosys" style={{backgroundColor: 'white'}} />
          </div>
          <div style={style.text}>
            <h3 style={{margin: 0}}>Nueva contraseña</h3>
          </div>
          {
            mainError?
              <div style={style.text} className="text-danger">
                <strong>{mainError}</strong>
              </div>
            : session ?
              <div style={style.text} className="text-danger">
                <strong>La sesion anterior fue cerrada.</strong>
              </div>
            : password.length && (password !== password2) ?
              <div style={style.text} className="text-danger">
                <strong>Las contraseñas no son iguales.</strong>
              </div>
            :
              null
          }
          <form style={{padding: '15px 30px'}} className="flex column" autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              required
              type="password"
              size="small"
              label="Contraseña"
              name="password"
              autoComplete="new-password"
              error={!!error}
              helperText={error || ''}
              value={password}
              disabled={loading}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              type="password"
              size="small"
              label="Contraseña (REPETIR)"
              name="password2"
              autoComplete="new-password"
              error={!!error2}
              helperText={error2 || ''}
              value={password2}
              disabled={loading}
              onChange={handleChange}
              className="mt-m"
            />
            <div className="flex grow center end mt-m">
              <Button size="small" color="secondary" style={{marginRight: 10}} onClick={handleBack} disabled={loading}>CANCELAR</Button>
              <div className="relative">
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  recuperar
                </Button>
                {
                  loading && <div className="re-loading"><CircularProgress size={22} /></div>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Reset;

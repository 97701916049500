import {
  DOCTOR_UPDATE,
  DOCTOR_CREATE,
  GRUPO_LIST,
  GRUPO_CREATE,
  GRUPO_ERROR,
  GRUPO_UPDATE,
  GRUPO_LOADING,
  GRUPO_PROVIDER_CREATE,
  GRUPO_PROVIDER_UPDATE
} from '../constans.js';

const initialState = {
  list: null,
  listPro: null,
  doctores: null,
  loading: false,
  error: null
}

const nameFilter = (itemA, itemB) => {
  if(itemA.Nombre < itemB.Nombre) return -1;
  else if(itemA.Nombre > itemB.Nombre) return 1;
  else return 0;
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case GRUPO_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case DOCTOR_UPDATE:
      return {
        ...state,
        doctores: state.doctores.map(item => item.idGrupo === action.payload.idGrupo ? action.payload : item),
        error: null
      }
    case DOCTOR_CREATE:
      return {
        ...state,
        doctores: state.doctores ? [...state.doctores, action.payload].sort(nameFilter) : [action.payload],
        error: null
      }
    case GRUPO_LIST:
      return {
        ...state,
        doctores: action.payload ? action.payload.List.filter(item => item.idTipoDeGrupo).sort(nameFilter) : null,
        list: action.payload ? action.payload.List.filter(item => !item.idTipoDeGrupo).sort(nameFilter) : null,
        listPro: action.payload ? action.payload.ListPro.sort(nameFilter) : null,
        error: null
      }
    case GRUPO_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case GRUPO_CREATE:
      return {
        ...state,
        list: state.list ? [...state.list, action.payload].sort(nameFilter) : [action.payload],
        error: null
      }
    case GRUPO_UPDATE:
      return {
        ...state,
        list: state.list.map(item => item.idGrupo === action.payload.idGrupo ? action.payload : item),
        error: null
      }
    case GRUPO_PROVIDER_CREATE:
      return {
        ...state,
        listPro: state.listPro ? [...state.listPro, action.payload].sort(nameFilter) : [action.payload],
        error: null
      }
    case GRUPO_PROVIDER_UPDATE:
      return {
        ...state,
        listPro: state.listPro.map(item => item.idGrupo === action.payload.idGrupo ? action.payload : item),
        error: null
      }
    default:
      return state;
  }
}

import {getFormatDate, getInitialDate, getHoursAndMinutes, parseStricDate } from '../util/tools.js';

export function filterList(action, dates, payload, lastIndex){
  const day = ""+getFormatDate(getInitialDate(payload.start))
  const list = {...dates};
  let wrap;

  switch(action){
    case 'MAKE':
      wrap = list[day] ? [...list[day], payload] : [payload]
      break;

    case 'UPDATE':
      wrap = list[day].map(item => item.idCita === lastIndex ? payload : item)
      break;

    case 'DELETE':
      wrap = list[day].filter(item => item.idCita !== payload.idCita)
      break;

    default: wrap = null
  }
  list[day] = wrap;

  return list;
}

export function updateList(dates, payload){
  const list = {...dates};

  const keys = Object.keys(list);
  for (let i = 0; i < keys.length; i++) {
    list[keys[i]] = list[keys[i]].map(function(item){
      if(item.idPaciente === payload.idPaciente){
        item.paciente = payload.paciente;
      }
      return item;
    })
  }

  return list;
}

export function clearList(dates){
  const citas = {};
  const target = getInitialDate(new Date());
  target.setDate(target.getDate() - 7);
  const parse = Date.parse(target);
  
  Object.keys(dates).map(function(key){
    const str = key.split('-');
    const keydate = new Date(str[2], +str[1], +str[0] + 1);
    if(Date.parse(keydate) < parse){
      citas[key] = dates[key];
    }
    return null;
  })

  return citas;
}

export const formatCita = (item) => {
  const {hour, min} = getHoursAndMinutes(item.Hora);
  const date = parseStricDate(item.Fecha);

  const data = {
    ...item,
    title: item.InfoExtra,
    paciente: {...item.paciente, idPaciente: item.idPaciente},
    start: new Date( date.getFullYear(), date.getMonth(), date.getDate(), hour, min, 0 ),
    end: new Date( date.getFullYear(), date.getMonth(), date.getDate(), hour, min, 0 ),
    allDay: false
  }

  data.end.setMinutes(data.end.getMinutes() + data.Duracion);
  delete data.InfoExtra;
  delete data.Fecha;

  return data
}

export const biggerThanToday = (item) => item.start.getTime() >= Date.now()

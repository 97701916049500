import {
  IMAGEN_LOADING,
  IMAGEN_LIST,
  IMAGEN_CREATE,
  IMAGEN_CREATE_MULTI,
  IMAGEN_SORT,
  IMAGEN_UPDATE,
  IMAGEN_REMOVE,
  IMAGEN_SINGLE
 } from '../constans.js';
import Axios from '../../config/axios.js';
import AxiosV2 from '../../config/axiosv2.js';
import handler from './handler.js';
import { loader as setPacienteLoading } from './pacienteActions';
// import { isIphone, toBase64 } from '../../components/util/tools.js';

function loader(val){
  return ({
    type: IMAGEN_LOADING,
    payload: val
  })
}

export const setSingle = item => dispatch => dispatch({type: IMAGEN_SINGLE, payload: item});

export const getImagenes = (id) => dispatch => {
  dispatch(loader(true))
  Axios.get(`/imagenes/paciente/${id}`)
  .then(res =>{
    dispatch({
      type: IMAGEN_LIST,
      payload: res.data //.map(image => ({...image, Extension: image.Extension.toLowerCase()}))
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const setOrden = (id, images, list, cb) => (dispatch, getState) => {
  const imagenes = getState().image.list;
  dispatch(loader(true))
  AxiosV2.post(`/imagenes/${id}/orden`, images)
  .then(() => {
    dispatch({
      type: IMAGEN_LIST,
      payload: imagenes.map(item => ({...item, idOrden: list[item.idImagen] || 9999 }))
    })
    cb();
  })
  .catch(handler(dispatch))
  .finally(e => dispatch(loader(false)))
}


export const makeImagen = (state, id, cb, uri) => dispatch => {
  dispatch(loader(true))
  Axios.post('/imagenes/paciente/'+id, state)
  .then(res => {
    dispatch({type: IMAGEN_CREATE, payload: {...res.data, url: uri}})
    cb();
  })
  .catch(handler(dispatch))
  .finally(e => dispatch(loader(false)))
}

export const makeImagenAlt = (state, id, cb, uris) => dispatch => {
  dispatch(loader(true))
  Axios.post('/imagenes/gallery/paciente/'+id, state)
  .then(res =>{
    cb();
    if(!Array.isArray(res.data)){
      res.data = [res.data];
    }
    dispatch({
      type: IMAGEN_CREATE_MULTI,
      payload: res.data.map(function(item, index){
        item.url = uris[index];
        return item;
      })
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const updateImagen = (state, id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.put('/imagenes/'+id, state)
  .then(res =>{
    cb(res.data);
    dispatch({
      type: IMAGEN_UPDATE,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const deleteImagen = (id, cb, alert) => dispatch => {
  dispatch(loader(true))
  Axios.delete('/imagenes/'+id)
  .then(() => {
    cb();
    dispatch({
      type: IMAGEN_REMOVE,
      payload: id
    })
  })
  .catch(handler(dispatch))
  .finally(() => {
    dispatch(loader(false))
    alert();
  })
}

export const rotateImagen = (imagen, mode, cb) => dispatch => {
  dispatch(loader(true))
  AxiosV2.put(`/imagenes/${imagen.idImagen}/mode/${mode}`)
  .then((res) => {
    dispatch({
      type: IMAGEN_UPDATE,
      payload: {
        ...imagen,
        url: res.data
      }
    })
    cb(res.data);
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const orderImagenes = (type) => dispatch => {
  dispatch({ type: IMAGEN_SORT, payload: type })
}

export const getImageConvert = async (file, dispatch) => {
  try {
    dispatch(setPacienteLoading(true))
    // if (isIphone()) {
      const body = new FormData();
      body.append('file', file);
      const { data } = await AxiosV2.post('/imagenes/iphone', body)
      return data
    // } else {
    //   return await toBase64(file);
    // }
  } catch (error) {
    handler(dispatch)(error);
    return null;
  } finally {
    dispatch(setPacienteLoading(false))
  }
}


export const clearImagenes = () => dispatch => {
  dispatch({
    type: IMAGEN_LIST,
    payload: null
  })
}

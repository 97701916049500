import { getVars, template } from '../../components/recibos/components/download';
import download from "../../components/util/download";
import Axios from '../../config/axios';
import AxiosV2 from '../../config/axiosv2';
import {
  CUENTAS_LIST,
  CUENTAS_LOADING,
  PAGO_CANCEL,
  PAGO_CREATE,
  PAGO_NUM,
  PAGO_UPDATE,
  TRATAMIENTO_CREATE,
  TRATAMIENTO_DELETE,
  TRATAMIENTO_LIST,
  TRATAMIENTO_UPDATE,
  TRATAMIENTO_UPDATE_STATE
} from '../constans.js';
import handler from './handler.js';

function loader(val) {
  return ({
    type: CUENTAS_LOADING,
    payload: val
  })
}

export const getCuentas = (id) => dispatch => {
  dispatch(loader(true))
  Axios.get('/cuentas/paciente/' + id)
    .then(res => {
      dispatch({
        type: CUENTAS_LIST,
        payload: res.data
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const createMultipleTratamiento = (items, id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post(`/cuentas/tratamiento/multiple/paciente/${id}`, items)
    .then(res => {
      res.data.forEach((payload) => dispatch({ type: TRATAMIENTO_CREATE, payload }))
      cb()
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}
export const createTratamiento = (state, id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post(`/cuentas/tratamiento/paciente/${id}`, state)
    .then(res => {
      dispatch({
        type: TRATAMIENTO_CREATE,
        payload: res.data
      })
      cb()
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}
export const createDescuento = (state, cb) => (dispatch, getState) => {
  dispatch(loader(true))
  Promise.all(state.map(t =>
    AxiosV2.post(`/tratamientos/descuentos/${t.idTratamiento}`, { cost: t.Importe })
  ))
    .then(() => {
      dispatch({
        type: TRATAMIENTO_LIST,
        payload: getState().account.tratamientos.map(item => state.find(t => t.idTratamiento === item.idTratamiento) || item)
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}
export const removeDescuento = (state, cb) => (dispatch, getState) => {
  dispatch(loader(true))
  Promise.all(state.map(t =>
    AxiosV2.delete(`/tratamientos/descuentos/${t.idTratamiento}`)
  ))
    .then(() => {
      dispatch({
        type: TRATAMIENTO_LIST,
        payload: getState().account.tratamientos.map(item => state.find(t => t.idTratamiento === item.idTratamiento) || item)
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateTratamiento = (state, id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.put('/cuentas/tratamiento/' + id, state)
    .then(res => {
      dispatch({
        type: TRATAMIENTO_UPDATE,
        payload: res.data
      })
      cb()
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateStateTratamiento = (state, id) => dispatch => {
  dispatch(loader(true))
  Axios.put('/cuentas/tratamiento/state/' + id, state)
    .then(res => {
      dispatch({
        type: TRATAMIENTO_UPDATE_STATE,
        payload: res.data
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateStateMultiple = (state, items) => dispatch => {
  dispatch(loader(true))
  Promise.all(items.map(
    item => Axios.put(`/cuentas/tratamiento/state/${item.idTratamiento}`, state)
  ))
    .then(data => {
      data.forEach(res => {
        dispatch({
          type: TRATAMIENTO_UPDATE_STATE,
          payload: res.data
        })
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const getReciboPDF = (item, monedas) => (dispatch, getState) => {
  document.body.style.cursor = 'wait';
  dispatch(loader(true))
  getVars({ item, state: getState(), monedas })
    .then(res => download({ blob: res.data, fileName: `Recibo N-${item.NroRecibo}.pdf` }))
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(loader(false))
      document.body.style.cursor = 'default';
    })
}

export const sendReciboPDF = (item, monedas, cb) => (dispatch, getState) => {
  document.body.style.cursor = 'wait';
  dispatch(loader(true))
  AxiosV2.post(`/mail/recibos/${item.idRecibo}`, template({ item, state: getState(), monedas }))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => {
      dispatch(loader(false))
      document.body.style.cursor = 'default';
    })
}

export const createRecibo = (state, id, cb) => (dispatch) => {
  dispatch(loader(true))
  Axios.post(`/cuentas/pago/paciente/${id}`, state)
    .then(res => {
      dispatch({
        type: PAGO_CREATE,
        payload: res.data
      })
      cb(res.data)
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updaterecibo = (state, id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.put('/cuentas/pago/' + id, state)
    .then(res => {
      dispatch({
        type: PAGO_UPDATE,
        payload: res.data
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const getLastRecibo = (cb) => dispatch => {
  Axios.get('/cuentas/pago/last')
    .then(res => {
      dispatch({ type: PAGO_NUM })
      cb(res.data);
    })
    .catch(handler(dispatch))
}

export const cancelPayment = (item) => dispatch => {
  dispatch(loader(true))
  Axios.delete('/cuentas/pago/' + item.idRecibo)
    .then(res => {
      dispatch({
        type: PAGO_CANCEL,
        payload: item.idRecibo
      });
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const cancelTreatments = (items) => dispatch => {
  dispatch(loader(true))
  Promise.all(items.map(item => AxiosV2.delete(`/tratamientos/${item.idTratamiento}`)))
    .then(() => items.forEach(item => dispatch({ type: TRATAMIENTO_DELETE, payload: item.idTratamiento })))
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const cancelTreatment = (id) => dispatch => {
  dispatch(loader(true))
  AxiosV2.delete('/tratamientos/' + id)
    .then(() => {
      dispatch({
        type: TRATAMIENTO_DELETE,
        payload: id
      });
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const clearCuentas = () => dispatch => {
  dispatch({
    type: CUENTAS_LIST,
    payload: { treatment: null, receipts: null }
  })
}

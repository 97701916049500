import { useSelector } from "react-redux";
import useSWR from "swr";
import Axios from "../../config/axiosv2";


const fetcher = ([url, token]) => Axios.get(url, {
    headers: {
        "x-auth": token,
    },
});

const useRoles = () => {
    const token = useSelector(state => state.auth.token);
    const { data: response, error, mutate } = useSWR(token ? ['/roles', token] : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    })
    const data = response ? response.data : null;

    return {
        data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useRoles;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { updateCitaMedio } from '../../provider/actions';

export const SMS_DISABLED = "no_enviar"

export const useFormaDeEnvio = () => {
    const { formaDeEnvio } = useSelector(state => state.auth.user);
    return {
        isSMSEnabled: formaDeEnvio.toLowerCase() !== SMS_DISABLED
    };
}

const MenuMedios = ({ event, onClose }) => {
    const dispatch = useDispatch();
    const { isSMSEnabled } = useFormaDeEnvio();

    const isActive = n =>  event.paciente.MedioDeComunicacion === n ? 'active-medio' : '';
    const handleUpdate = n => () => {
        if(n !== event.paciente.MedioDeComunicacion){
            dispatch(updateCitaMedio(event, n))
        }
        onClose();
    }
    return <MediosList isSMSEnabled={isSMSEnabled} isActive={isActive} onClick={handleUpdate} />
};

export const MediosList = ({ isSMSEnabled, isActive, onClick }) => [
    isSMSEnabled && (<MenuItem key={3} className={isActive(3)} onClick={onClick(3)}>
        <ListItemIcon>
            <PhonelinkIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Se recuerda por SMS y Correo" />
    </MenuItem>),
    isSMSEnabled && (<MenuItem key={2} className={isActive(2)} onClick={onClick(2)}>
        <ListItemIcon>
            <PhoneAndroidIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Se recuerda por SMS" />
    </MenuItem>),
    <MenuItem key={1} className={isActive(1)} onClick={onClick(1)}>
        <ListItemIcon>
            <MailIcon fontSize="small" />,
        </ListItemIcon>
        <ListItemText primary="Se recuerda por Correo" />
    </MenuItem>,
    <Divider />,
    <MenuItem key={0} className={isActive(0)} onClick={onClick(0)}>
        <ListItemIcon>
            <PhonelinkOffIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="No se envia Recordatorio" />
    </MenuItem>
].filter(Boolean);

export default MenuMedios;
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {ReactComponent as UserGear} from '../../assets/svgs/user-gear.svg';
import { iconStyle } from '../util/tools.js';

const data = [
  {
    url: "/pacientes",
    icon: <SvgIcon fontSize="small" style={iconStyle}><UserGear /></SvgIcon>,
    label: "Administrar Pacientes"
  },
  {
    url: "/proveedores",
    icon: <SvgIcon fontSize="small" style={iconStyle}><UserGear /></SvgIcon>,
    label: "Administrar Proveedores"
  },
  // {
  //   url: "/grupos/", 
  //   icon: <PeopleAltIcon fontSize="small"/>,
  //   label: "Grupos Pacientes"
  // },
  // {
  //   url: "/grupos/proveedores", 
  //   icon: <PeopleAltIcon fontSize="small"/>,
  //   label: "Grupos Proveedores"
  // },
  // {
  //   url: "/precios", 
  //   icon: <AttachMoneyIcon fontSize="small"/>,
  //   label: "Listas de Precios"
  // }
]

export default data;
import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '../../util/menu';
import MenuItem from '@mui/material/MenuItem';
import { months } from '../../util/tools.js';

const formatTime = ({ date }) => {
  if(!date){
    return `Sin Asignar`
  }
  return `${months[date.getMonth()]} de ${date.getFullYear()}`
}

const formatStyle = {
  marginLeft: 20,
  minWidth: 150,
  display: 'inline-block',
  maxHeight: 400
};
const baseDate = new Date();

const ControlDate = ({ data, handleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = e => setAnchorEl(e.target);
  const handleClose = e => setAnchorEl(null);

  const convertValue = n => {
    if(isNaN(n)) return new Date();
    return new Date(baseDate.getFullYear(), (n+1), baseDate.getDate());
  }
  const handleChangeDate = value => e => {
    handleClose();
    handleChange(convertValue(value))
  }

  return (
    <Grid item xs={12} container justifyContent="center">
      <Typography style={{display: 'inline-block'}}>Siguiente control: </Typography>
      <div style={formatStyle}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpen}
        >{formatTime(data)}</Button>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleChangeDate("Este mes")}>Este mes</MenuItem>
        <MenuItem onClick={handleChangeDate(Number(baseDate.getMonth()))}>En 1 mes</MenuItem>
        {
          Array.from({length: 11}).map(
            (_, key) => <MenuItem
              key={key}
              onClick={handleChangeDate(Number(baseDate.getMonth()) + key + 1)}
            >En {key+2} meses</MenuItem>
          )
        }
      </Menu>
    </Grid>
  )
}

export default ControlDate;

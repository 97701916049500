import Button from '@mui/material/Button';
import React from 'react';
import ErrorLogo from '../assets/error.svg';

class ReportBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  handleClick(){
    this.setState({ hasError: false });
    window.location.href= "/"
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex grow column center" style={{minHeight: 400}}>
          <div>
            <img src={ErrorLogo} alt="Logo error" width={300} height={300} style={{backgroundColor: 'transparent'}} />
          </div>
          <div>
            <h4>Algo salio mal...</h4>
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={this.handleClick}>Pagina principal</Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ReportBoundary;

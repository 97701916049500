import React from 'react';

// Components
import CamposContainer from '../../pacientes/campos/surfaces/table';

const Campos = () => {
    return (
        <div className="flex grow column">
            <CamposContainer title="Campos Proveedores" idSuperCategoria={2} />
        </div>
    );
}

export default Campos;
import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf" },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 700 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300, fontStyle: 'italic' },
  ]
})


// Create styles
const PresupuestoPDFStyles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 12,
        paddingTop: 100,
        paddingBottom: 100,
    },
    brand: {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        height: 40,
    },
    bold: { fontWeight: 'bold' },
    row: { flexDirection: 'row' },
    grow: { flex: 1 },
    logo: {
        height: 40
    },
    body: {
        flexGrow: 1,
        paddingHorizontal: 20,
    },
    title: {
        marginVertical: 10,
        fontSize: 15,
        fontWeight: 700
    },
    subtitle: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        textAlign: 'right',
    },
    itemTotal: {
        fontSize: 12.5,
        flexDirection: 'row',
        paddingTop: 10,
        paddingBottom: 8,
    },
    discount: {
        paddingLeft: 12,
    },
    code: {
        minWidth: 50,
    },
    amount: {
        minWidth: 100,
        alignItems: 'flex-end',
        textAlign: 'right'
    },
    item: {
        fontSize: 11.5,
        flexDirection: 'row',
        marginTop: 3
    },
    maintitle: {
        marginTop: 20,
        paddingBottom: 5,
        marginBottom: 5,
        flexGrow: 1,
        fontWeight: 700,
        borderBottom: '1px solid #014990',
    },
    header: {
        position: 'absolute',
        height: 100,
        width: '100%',
        left: 0,
        top: 0
    },
    headerContent: {
        flexGrow: 1,
        marginTop: 15,
        marginHorizontal: 20,
    },
    prefooter: {
        flex: 1,
        flexGrow: 1,
        paddingHorizontal: 20,
        justifyContent: "flex-end",
    },
    prefooterText: {
        fontSize: 9,
    },
    footer: {
        position: 'absolute',
        width: '100%',
        height: 100,
        bottom: 0,
        left: 0,
        paddingTop: 20
    },
    footerContent: {
        flexGrow: 1,
        paddingTop: 10,
        marginHorizontal: 20,
    },
    footerText: {
        color: 'grey',
        fontSize: 9.5,
        fontWeight: 300,
        fontStyle: "italic"
    },
    separator: {
        height: 1.8,
        flexGrow: 1,
        borderRadius: 6,
        backgroundColor: '#e1e1e1',
    }
});

export default PresupuestoPDFStyles;
import React from 'react'
import TextField from '@mui/material/TextField';

// Components
import Spinner from '../../../util/circleLoading';
import Option from '../options';

const Correo = ({ state, options, inputRef, onChange, onSelect }) => {
    const onChangeData = ({ target }) => onChange(target.name, target.value);
    const onSubmit = e => e.preventDefault();
    return (
        <div className="flex column grow">
        {
            (state === null) ?
                <Spinner text="Cargando plantilla." />
            :
                <form onSubmit={onSubmit}>
                    <div className="flex grow mb-m">
                        <TextField label="Asunto" size="small" name="subject" required fullWidth value={state.subject} onChange={onChangeData} />
                    </div>
                    <div className="flex grow py-m">
                        <TextField inputRef={inputRef} label="Contenido" variant="outlined" name="body" required fullWidth multiline minRows={8} value={state.body} onChange={onChangeData} />
                    </div>
                    <div className="flex column">
                        {options.map(item => <Option item={item} key={item.title} onSelect={onSelect} />)}
                    </div>
                </form>
        }
        </div>
    )
}

export default Correo;
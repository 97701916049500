export const durationOptions = {
    10: [
        {label: '10 minutos', value: '0'},
        {label: '20 minutos', value: '1'},
        {label: '30 minutos', value: '2'},
        {label: '40 minutos', value: '3'},
        {label: '50 minutos', value: '4'},
        {label: '1 hora', value: '5'}
    ],
    15: [
        {label: '15 minutos', value: '0'},
        {label: '30 minutos', value: '1'},
        {label: '45 minutos', value: '2'},
        {label: '1 hora', value: '3'}
    ],
    20: [
        {label: '20 minutos', value: '0'},
        {label: '40 minutos', value: '1'},
        {label: '1 hora', value: '2'}
    ],
    30: [
        {label: '30 minutos', value: '0'},
        {label: '1 hora', value: '1'}
    ],
}
import {
  CUENTAS_LIST,
  CUENTAS_ERROR,
  CUENTAS_LOADING,
  TRATAMIENTO_CREATE,
  TRATAMIENTO_UPDATE,
  TRATAMIENTO_UPDATE_STATE,
  TRATAMIENTO_DELETE,
  PAGO_CREATE,
  PAGO_UPDATE,
  PAGO_NUM,
  PAGO_CANCEL,
  TRATAMIENTO_LIST
} from '../constans.js';

const initialState = {
  tratamientos: null,
  recibos: null,
  loading: false,
  config: null,
  printer: null,
  error: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case CUENTAS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CUENTAS_LIST:
      return {
        ...state,
        tratamientos: action.payload.treatment,
        recibos: action.payload.receipts,
        error: null
      }
    case TRATAMIENTO_CREATE:
      return {
        ...state,
        tratamientos: [...state.tratamientos, action.payload],
        error: null
      }
    case TRATAMIENTO_LIST:
      return {
        ...state,
        tratamientos: action.payload,
        error: null
      }
    case TRATAMIENTO_UPDATE_STATE:
    case TRATAMIENTO_UPDATE:
      return {
        ...state,
        tratamientos: state.tratamientos ? state.tratamientos.map(item => item.idTratamiento === action.payload.idTratamiento ? action.payload : item) : state.tratamientos,
        error: null
      }
    case TRATAMIENTO_DELETE:
      return {
        ...state,
        tratamientos: state.tratamientos ? state.tratamientos.filter(item => item.idTratamiento !== action.payload) : state.tratamientos,
        error: null
      }
    case PAGO_CREATE:
      return {
        ...state,
        recibos: [...state.recibos, action.payload],
        error: null
      }
    case PAGO_UPDATE:
      return {
        ...state,
        recibos: state.recibos ? state.recibos.map(item => item.idRecibo === action.payload.idRecibo ? action.payload : item) : state.recibos,
        error: null
      }
    case PAGO_NUM:
      return {
        ...state,
        error: null
      }
    case PAGO_CANCEL:
      return {
        ...state,
        recibos: state.recibos ? state.recibos.filter(item => item.idRecibo !== action.payload) : state.recibos,
        error: null
      }
    case CUENTAS_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
}

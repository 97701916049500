import React, { useContext } from 'react';

import { FontContext } from '../../config/font';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        height: '100%',
        overflow: 'auto',
        padding: 10,
    },
});

const FullModal = ({ open, children, buttons, handleClose }) => {
    const classes = useStyles();
    const { fontClassName } = useContext(FontContext);

    const onKeyDown = (e) => {
        if (e.key === "escape") {
            e.preventDefault();
            e.stopPropagation();
            handleClose();
        }
    }

    return (
        <Modal open={open} className={fontClassName} onClose={handleClose}>
            <div className={classes.paper} onKeyDown={onKeyDown}>
                {children}
                {
                    buttons &&
                    <div className="modal-footer">
                        {buttons}
                    </div>
                }
            </div>
        </Modal>
    )
}

export default FullModal
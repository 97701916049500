import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { openControl } from '../../control/provider/actions.js';

import { Apps, Timelapse, Timeline, Wallpaper, MenuBook } from '@mui/icons-material';
import { Grid, Button, IconButton, MenuItem } from '@mui/material';

import Menu from '../../util/menu'
import Tooltip from '@mui/material/Tooltip';
import { Items } from '../../util/headerSection'

import {
  headerAppButton,
  headerActive
} from '../../util/header.module.css';

const MenuModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const single = useSelector(state => state.pacient.single);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // Redireccionar y guardar el estado del paciente.
  const handleRedirect = item => e => {
    if(item.text === 'Proximo control'){
      dispatch(openControl(single.idPaciente))
    } else {
      history.push('/'.concat(item.text,'/',single.idPaciente));
    }
  }

  const list = Items.map(e => e.text === props.item ? {...e, active: true} : e);

  return (
    <div className={headerAppButton}>
      <Tooltip title="Menú de módulos" aria-label="Menú de módulos" placement="right" arrow>
        <IconButton
          aria-label="Opciones del menú del usuario"
          aria-controls="menu-modules"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          size="large">
          <Apps color="primary"/>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-modules"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          list.map((item, i) => (
            item.resource ? item.resource :
            <MenuItem className={item.active ? headerActive : undefined} key={i} onClick={handleRedirect(item)}>
              {item.label}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

const modules = [
  { icon: <Timeline fontSize="small" />, label: 'Cuentas', path: '/cuentas/' },
  { icon: <Timelapse fontSize="small" />, label: 'Historia', path: '/historia/' },
  { icon: <Wallpaper fontSize="small" />, label: 'Imágenes', path: '/imagenes/' },
  { icon: <MenuBook fontSize="small" />, label: 'Cuestionario', path: '/cuestionario/' },
]

export const ButtonModules = ({ onClick }) => modules.map(item => (
  <Grid item xs={12} md={6} key={item.path} className="px-m pt-m">
    <Button
      fullWidth
      variant="contained"
      size="small"
      color="primary"
      startIcon={item.icon}
      onClick={onClick(item.path)}
    >
      {item.label}
    </Button>
  </Grid>
))

export default MenuModule;

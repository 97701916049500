// Trunc hour into a number;
export const getParseHour = (hour) => +(`${hour}`.replace(':', '') - 0);

// Trunc number to militar hour
export const getMilitarHour = (number) => `${+number < 1000 ? '0'+number : +number}`;

export const getObjectHour = (hour) => {
    let str = `${getMilitarHour(hour)}`
    return {
        title: `${+str.slice(0, 2)}:${str.slice(2,4)} ${(+str.slice(0,2)) >= 12 ? 'PM' : 'AM'}`,
        value: `${+str.slice(0, 2)}:${str.slice(2,4)}`
    }
}

// Add minutes to hour
export const getPlusHour = (hour, plus) => {
    let value = +getParseHour(hour);
    let duration = +plus;
    while(duration >= 60){
        value += 100; // Add hours
        duration -= 60; // Less hours
    }
    value += duration; // Add minutes
    
    if((value - (Math.trunc(value / 100) * 100)) >= 60){
        value += 40;
    }

    return value;
}

// Get time between two hours
export const getTimeBetween = (start, end) => {
    let diff = ((getParseHour(end) - getParseHour(start)) / 100);
    let hours = Math.trunc(diff);
    let minutes = Math.trunc((diff - hours) * 100);

    return (hours*100) + minutes
}
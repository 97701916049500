import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from '../../assets/logo.png';
import Menu from './menu.js';
import Userbar from './userbar.js';

import useStyles from './navbar.style'
import { DrawerContext } from './context';

function NavBar({ onCreatePaciente, onCreateProvider }) {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector(state => state.auth.user);
  const menu = useContext(DrawerContext)

  const hidden = useMemo(() => {
    const path = location.pathname.toLowerCase();
    return (
        path.includes('agenda') ||
        path.includes('historia') ||
        path.includes('cuentas') ||
        path.includes('imagenes') ||
        path.includes('cuestionario') ||
        path.includes('reportes') ||
        path.includes('configuracion')
      ) && !path.includes('pdf')
  }, [ location.pathname ])

  // Prevenir la barra en el login/registro
  if (!user){
    return null;
  }

  return (
    <div className={hidden ? classes.hidden : classes.root}>
      <AppBar position="fixed" className={classes.AppBar} style={{ opacity: hidden ? 0 : 1, zIndex: 1300 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            onClick={menu.handleOpen}
            size="large">
            <MenuIcon />
          </IconButton>
          <Link to="/" className={classes.link}>
            <div className={classes.logo}>
              <img src={Logo} alt="Odontosys logotipo" width={35} heigth={35} />
            </div>
            <Typography variant="h6" className="flex grow text-white h-100">
              Odontosys
            </Typography>
          </Link>
          <Userbar user={user} drawer={menu} />
        </Toolbar>
      </AppBar>
      <Menu handleOpenPaciente={onCreatePaciente} handleOpenProvider={onCreateProvider} />
    </div>
  );
}

export default NavBar;
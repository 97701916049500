import React from 'react';

import Grid from '@mui/material/Grid';
// import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FormControlLabel from '@mui/material/FormControlLabel';

import { ColorIconPicker } from '../../../../../components/util/colorPicker';
import useMovil from '../../../../../components/hooks/useMovil';

const FormModalDoctor = ({ state, error, doctor, onChange, onChangeColor, onSubmit }) => {
    const isPhone = useMovil();
    return (
        <Grid component="form" className="modal-form" gap={6} container onSubmit={onSubmit}>

            <Grid item xs={12}>
                <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
                    {doctor ? 'Editar doctor' : 'Nuevo doctor'}
                </Typography>
            </Grid>

            <Grid container display="flex" flexDirection="column" gap={6}>
                <TextField
                    fullWidth
                    size="small"
                    name="name"
                    type="search"
                    label="Nombre del doctor"
                    autoComplete="off"
                    value={state.name}
                    error={error.name}
                    helperText={error ? error.name : ''}
                    onChange={onChange}
                />
                <Grid container alignItems="start">
                    <div>
                        <ColorIconPicker
                            component
                            color={state.color}
                            handleColor={onChangeColor}
                        />
                    </div>
                    {/* <FormControlLabel
                        size="small"
                        label="Visible"
                        control={<Radio color="primary" checked={!Boolean(state.hiden)} />}
                        onClick={onChangeCheck}
                    /> */}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormModalDoctor;

import React from 'react';


import Appbar from '../../../surfaces/appbar';
import Plantilla from '../../../../components/plantillas/pages/sms';

const PlantillasSMS = () => {
    return (
        <div className="flex grow column">
            <Appbar title="Plantillas SMS" className="mb-m" />
            <Plantilla />
        </div>
    );
}

export default PlantillasSMS;
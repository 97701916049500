import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

// Components
import Modal from './modal.js'
import handler from '../../redux/actions/handler';
import axios from '../../config/axiosv2';

const defaultState = { body: "", to: "", subject: "" };
const EmailModal = ({ open, paciente, handleSuccess, handleClose }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [state, setState] = useState(defaultState);

    useEffect(function(){
        if(open){
            setState(state => ({ ...state, to: paciente.correoDeEnvio || '' }))
        }
    }, [open, paciente])

    const onClose = () =>{
        handleClose();
        setState(defaultState);
    }
    const onChange = ({ target }) => {
        setState({...state, [target.name]: target.value})
        setError(null);
    }
    const onSubmit = () => {
        let error = null;
        if(!state.subject.length){ error = "Es necesario un asunto." };
        if(!state.body.length){ error = "Es necesario un mensaje." };
        if(!state.to.length || !state.to.includes('@')){ error = "Es necesario un correo destino." };

        if(error){
            setError(error)
        } else {
            setLoading(true);
            axios.post(`/mail/paciente/${(paciente && paciente.idPaciente)}`, state)
            .then(() => {
                handleSuccess();
                onClose();
            })
            .catch(handler(dispatch))
            .finally(() => setLoading(false))
        }
    }

    return (
        <Modal open={open && (paciente && paciente.idPaciente) > 0} handleClose={onClose}>
            <Grid container className="relative">
                <Grid item xs={12}>
                    <Typography>Enviar correo</Typography>
                </Grid>
                <Grid item xs={12} className="my-m">
                    <TextField
                        fullWidth
                        type="text"
                        size="small"
                        label="Para"
                        name="to"
                        value={state.to}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} className="my-m">
                    <TextField
                        fullWidth
                        type="text"
                        size="small"
                        label="Asunto"
                        name="subject"
                        value={state.subject}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} className="my-m">
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Mensaje"
                        name="body"
                        minRows={4}
                        value={state.body}
                        onChange={onChange}
                    />
                </Grid>
                {error && <div className="request-error text-danger mb-m">{error}</div>}
                <Grid item container justifyContent="flex-end">
                    <Button color="secondary" className="mr-m" onClick={onClose}>Cerrar</Button>
                    <div className="relative">
                        <Button color="primary" variant="contained" onClick={onSubmit} disabled={loading}>Enviar</Button>
                        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                    </div>
                </Grid>
            </Grid>
        </Modal>
    )
};

export default EmailModal;
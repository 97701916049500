import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Grupos
import Grupo from './item.js';
import useMovil from '../../hooks/useMovil.js';

const ViewGroups = ({ list, title, onSelect }) => {
    const isPhone = useMovil();
    return (
        <div className={list.length < 7 ? "modal-form" : ""}>
            <Grid item xs={12}>
                <Typography variant={isPhone ? "h6" : undefined} align={isPhone ? 'center' : undefined} className="mb">
                    {title || "Grupos"}
                </Typography>
            </Grid>
            <Grid item container xs={12}>
                {
                    list.length ?
                        list.map(grupo => <Grupo grupo={grupo} onSelect={onSelect} key={grupo.idGrupo} />)
                        :
                        <Grid item container justifyContent="center" alignItems="center" style={{ minHeight: 280 }}>
                            <strong>No tienes ningun grupo</strong>
                        </Grid>
                }
            </Grid>
        </div>
    )
}

export default ViewGroups;
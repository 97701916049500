import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChevronLeft, Edit, LabelOutlined as Label, Timeline } from '@mui/icons-material';
import { IconButton, Grid, Typography, Divider, Button, Box, Hidden } from '@mui/material'

import Modal from '../util/modal';
import ModalUpdateProveedor from '../proveedor/modals/edit';
import ImgProvider from '../util/image';
import ModalGruposProveedores from '../proveedor/surfaces/groups';
import GruposProveedores from '../proveedor/surfaces/grupos';
import InfoProvider from '../proveedor/surfaces/info'
import Loading from '../util/circleLoading';

// actions
import { getAvatarUri } from '../util/tools';
import { clearProvider } from '../../redux/actions/providerActions';
import StickyHeader from '../proveedor/surfaces/sticky';
import useOpen from '../hooks/useOpen';
import useMovil from '../hooks/useMovil';

const ModalProveedor = ({ open, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const single = useSelector(state => state.provider.single);
  const auth = useSelector(state => state.auth.user);
  const groups = useOpen();
  const isPhone = useMovil();
  const editable = useOpen();

  const handleRedirect = dir => () => history.push(dir.concat(single.idProveedor));
  
  const handleClose = () => {
    if(groups.open){
      groups.handleClose()
    } else if (editable.open) {
      editable.handleClose();
    } else {
      if(onClose){
        onClose();
      } else {
        dispatch(clearProvider())
      }
    }
  }

  if(!single) return null;

  if (single === true){
    return (
      <Modal open={open} handleClose={handleClose}>
        <Loading text="Buscando proveedor" />
      </Modal>
    );
  }

  if (isPhone) {
    if (editable.open) {
      return <ModalUpdateProveedor {...editable} provider={single} />
    }
    if (groups.open) {
      return <ModalGruposProveedores {...groups} provider={single} />
    }
  }
  
  return (
    <Modal open={Boolean(single && open)} handleClose={handleClose}>
      <ModalGruposProveedores {...groups} provider={single}  />
      <ModalUpdateProveedor {...editable} provider={single} />

      <Grid container className="app-x-overflow" spacing={2}>
        <Hidden mdUp>
          <StickyHeader
            Typography={<Typography display="inline" className="mx-m">{single.nombre}</Typography>}
            Icon={
              <IconButton className="text-white" onClick={editable.handleOpen} size="large">
                <Edit />
              </IconButton>
            }
            onClose={handleClose}
          />
          <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end" style={{ height: 100 }}>
            <Box className="flex right" style={{ marginRight: -12 }}>
              <IconButton color="primary" onClick={groups.handleOpen} size="large">
                <Label />
              </IconButton>
            </Box>
          </Grid>
        </Hidden>

        <Hidden mdDown>
          <Grid item container justifyContent="space-between" alignItems="center" className="relative mb-m">
            <Grid item xs>
              <Typography display="inline" className="pl mx-m">
                {single.nombre}
              </Typography>
              
            </Grid>
            <Grid item container xs justifyContent="flex-end">
              <IconButton color="primary" onClick={groups.handleOpen} size="large">
                <Label />
              </IconButton>
              <IconButton onClick={editable.handleOpen} size="large">
                <Edit />
              </IconButton>
            </Grid>
            <div className="lineborder">
              <IconButton size="small">
                <ChevronLeft onClick={handleClose} />
              </IconButton>
            </div>
          </Grid>
        </Hidden>

        <Grid item xs={12} container>
          <Grid item container justifyContent="center" alignItems="center" xs={12}>
            <ImgProvider
              src={single.url || getAvatarUri(auth, single, true)}
              alt={"Imagen de proveedor " + single.nombre}
              width={90}
              height={90}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoProvider proveedor={single} />
          </Grid>
          <Grid item container justifyContent="center" className="my-m">
            <GruposProveedores proveedor={single} onClick={groups.handleOpen} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider className="my-m" />
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                startIcon={<Timeline fontSize="small" />}
                onClick={handleRedirect('/cuentas/proveedor/')}
              >
                Cuentas
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ModalProveedor;

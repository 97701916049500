import React from 'react';

// UI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';

const PreciosSearch = ({ text, onChangeText }) => {
  return (
    <Box sx={{ px: 6, py: 3 }}>
      <TextField
        fullWidth
        size="small"
        label="Buscar"
        variant="standard"
        value={text}
        onChange={onChangeText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="pointer">
              <SearchIcon style={{ color: '#707070' }} />
            </InputAdornment>
          ),
        }} />
    </Box>
  );
};

export default PreciosSearch;
import React from "react";
import { Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const IndicacionesEmpty = ({ handleOpen }) => {
  const history = useHistory();
  const onCreate = (evt) => {
    if(handleOpen) {
      return handleOpen(evt);
    }
    history.push({
      pathname: "/configuracion",
      state: { path: "plantillas-indicaciones", modal: true },
    });
  };
  return (
    <div className="min-height-250">
      <div className="flex grow center min-height-250">
        <Typography>Aun no tienes ninguna plantilla creada</Typography>
      </div>
      <div className="flex grow center mt">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onCreate}
        >
          Nueva Plantilla
        </Button>
      </div>
    </div>
  );
};

export default IndicacionesEmpty;

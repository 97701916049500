import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openControl } from '../control/provider/actions.js';
import usePermisos from '../hooks/usePermiso.js';
import ImgProvider from '../util/image.js';
import { formatPhone, getAvatarUri } from '../util/tools.js';
import Menu from './subMenu.js';

const styles = {
  borderRadius: 8,
  border: '1px solid #ddd',
  marginBottom: 10,
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: 'white',
  cursor: 'pointer'
}

const ItemP = (props) => {
  const { data, handlePacient, handleProvider, isProvider, auth } = props;
  const permisos = usePermisos();
  const isDisabledToSee = permisos.is({ code: 1025 });
  const dispatch = useDispatch();

  const handleClick = e => {
    if (isProvider) handleProvider(data);
    else handlePacient(data);
  }

  const [anchorEl, setOpen] = React.useState(null);
  const handleMenu = e => {
    e.preventDefault();
    if (!isProvider) {
      if (anchorEl) setOpen(null)
      else setOpen({ left: e.clientX - 2, top: e.clientY - 2 })
    }
  }

  const handleClose = e => setOpen(null);
  const handleEnter = e => (e.keyCode === 13) ? handleClick() : null
  const handleEdit = e => handlePacient(data)
  const handleControl = e => dispatch(openControl(data.idPaciente))

  const phoneText = isDisabledToSee ? "-" : formatPhone(data) || "Sin teléfono."

  return (
    <ListItem style={styles} onContextMenu={handleMenu} onKeyUp={handleEnter} tabIndex="0">
      <ListItemAvatar onClick={handleClick}>
        <Avatar>
          {
            data.url ?
              <img
                src={data.url}
                alt={"Foto de " + data.nombre}
                width={50}
                height={50}
              />
              :
              <ImgProvider
                src={getAvatarUri(auth, data, isProvider)}
                alt={"Foto de " + data.nombre}
                width={50}
                height={50}
              />
          }
        </Avatar>
      </ListItemAvatar>
      {
        !isProvider && <Menu
          data={data}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleEdit={handleEdit}
          handleControl={handleControl}
        />
      }
      <ListItemText onClick={handleClick} primary={data.nombre} secondary={phoneText} />
    </ListItem>
  )
}

ItemP.propTypes = {
  data: PropTypes.object.isRequired,
  handlePacient: PropTypes.func.isRequired,
  handleProvider: PropTypes.func.isRequired,
  isProvider: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
}

export default ItemP

const config = {
  language: "es",
  heading: {
    options: [
      {
        model: "heading1",
        view: "h1",
        title: "Título 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Título 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Título 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Título 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Título 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Título 6",
        class: "ck-heading_heading6",
      },
      { model: "paragraph", title: "Texto", class: "ck-heading_paragraph" },
    ],
  },
  alignment: {
    options: ["left", "center", "right"],
  },
  toolbar: {
    items: [
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "alignment",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "subscript",
      "superscript",
      "code",
      "|",
      "link",
      // "uploadImage",
      "blockQuote",
      "codeBlock",
      "|",
      "bulletedList",
      "numberedList",
      // "todoList",
      // "outdent",
      // "indent",
      "|",
      "undo",
      "redo",
      "|",
      "redo",
    ],
    shouldNotGroupWhenFull: false,
  },
};

export default config;

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import download from 'downloadjs';
import { PDF_SERVICE } from '../../config/axios.js';
import Axios from '../../config/axiosv2.js';
import handler from '../../redux/actions/handler.js';

const fetcher = (body) => Axios.post(`/api/template`, body, {
  baseURL: PDF_SERVICE,
  responseType: 'blob'
});

function useFetch({ data, name }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchPdf = async (body, title = name) => {
    try {
      setLoading(true);
      const { data } = await fetcher(body);
      download(data, `${title}.pdf`, 'application/pdf');
    } catch (e) {
      handler(dispatch)({ message: `Ocurrió un error al descargar el PDF.` })
    } finally {
      setLoading(false)
    }
  }

  // direct click
  const eventClick = () => fetchPdf(data);

  // passing data
  const prepareClick = (data, title) => fetchPdf(data, title);

  return {
    loading,
    eventClick,
    prepareClick
  }
}

export default useFetch;

import { useMemo, useState } from "react";
import useOpen from "../../../../components/hooks/useOpen";

import { sortPrecios } from "../../../../components/precios/util";
import { useAranceles } from "../../../../swr/hooks/useAranceles";
import useMonedas from "../../../../swr/hooks/useMonedas";

const UseTratamientosAranceles = ({ idArancel }) => {
  const { original: monedas } = useMonedas();
  const { data } = useAranceles();
  const [arancelSelected, setArancelSelected] = useState(null);

  const [text, setText] = useState("");
  const modal = useOpen();

  const aranceles = useMemo(
    function () {
      if (!monedas.length) return [];

      return data
        .map((item) => ({
          ...item,
          idArancel: +item.idArancel,
          label: item.Tratamiento.toUpperCase(),
          moneda: monedas.find(
            (moneda) => +moneda.id_moneda === +item.id_moneda
          ),
        }))
        .filter(
          (item) =>
            item.idArancel === idArancel &&
            (text.length ? item.label.includes(text) : true)
        )
        .sort(sortPrecios);
    },
    [text, monedas, idArancel, data]
  );

  const onChangeText = ({ target }) => setText(target.value.toUpperCase());

  // Modal tratamiento
  const onSelectArancel = (arancel) => {
    setArancelSelected(arancel);
    modal.handleOpen();
  };
  const onCloseModal = () => {
    setArancelSelected(null);
    modal.handleClose();
  };

  return {
    text,
    modal,
    selected: arancelSelected || modal.open,
    aranceles,
    onChangeText,
    onOpenModal: modal.handleOpen,
    onCloseModal,
    onSelectArancel,
  };
};

export default UseTratamientosAranceles;

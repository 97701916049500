import useSWR from "swr";
import Axios from "../../config/axios";

const useCamposExtras = () => {
    const { data: response, error, mutate } = useSWR('/campos', Axios.get, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    const data = response ? response.data : null;

    return {
        data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useCamposExtras;
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { ReactComponent as PDF } from '../../assets/picker/pdf.svg';
import { ReactComponent as CSV } from '../../assets/picker/csv.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 420,
    borderRadius: 6,
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    outline: 'none',
    position: 'absolute',
    boxSizing: 'border-box',
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
  },
  div: {
    textAlign: 'center',
    marginTop: 15
  }
}));

function PickerPDFCSV({ open, reporte, disabledCSV, disabledPDF, handleClose, handleSelect }) {
  const classes = useStyles();
  const onSelect = (str) => () => {
    handleSelect(str)
    handleClose();
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.paper}>
        <Grid container>
          <Grid item container justifyContent="center" xs={12} className="mb">
            <Typography variant="h6">Seleccione un formato de descarga</Typography>
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center" xs={12}>
            <SvgIcon className={"picker-icon "+(disabledPDF ? 'picker-icon-disable-pdf' : '')} onClick={disabledPDF ? null : onSelect('pdf')}>
              <PDF />
            </SvgIcon>
            <SvgIcon className={"picker-icon "+(disabledCSV ? 'picker-icon-disable-csv' : '')} onClick={disabledCSV ? null : onSelect('csv')}>
              <CSV />
            </SvgIcon>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.div}>
            {
              disabledPDF ? <strong className="text-ground">Este {reporte ? "reporte" : "listado"} no admite descargas PDF.</strong>:null

            }
            {
              disabledCSV ? <strong className="text-ground">Este {reporte ? "reporte" : "listado"} no admite descargas CSV.</strong>:null
            }
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

PickerPDFCSV.defaultProps = {
  reporte: true,
  disabledPDF: false,
  disabledCSV: false
}

export default PickerPDFCSV
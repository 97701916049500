import { USER_LOADING, USER_LOGIN, USER_LOGOUT, USER_CHANGE, PERMISSION_ERROR, USER_BALANCE, ALERT_ERROR } from '../constans.js';

const initialState = {
  user: null,
  token: null,
  loading: false,
//  session: '',
  permission: null,
  balance: null,
  remember: true,
  alert: null,
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case USER_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case PERMISSION_ERROR:
      return {
        ...state,
        permission: action.payload
      }
    case USER_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        //session: action.payload.Session,
        remember: false,
      }
    case USER_CHANGE:
      return {
        ...state,
        remember: false,
        user: action.payload
      }
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        remember: false
      }
    case ALERT_ERROR:
      return {
        ...state,
        remember: false,
        alert: action.payload
      }
    default:
      return state;
  }
}

import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAnchorEl from '../../hooks/useAnchorEl';

import { updatePregunta, swipePregunta } from '../provider/actions';

const getData = (data) => {
    try {
        const arr = data && data.length ? JSON.parse(data) : null;
        return arr && arr.length ? arr : [];
    } catch (error) {
        return []
    }
}

function UseQuestion(pregunta) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.cuestionario.loading);
    const answers = useMemo(() => getData(pregunta.extradata), [pregunta])
    const format = useMemo(() => ({ title: pregunta.titulo, mode: pregunta.tipo, data: answers, important: !!pregunta.importante }), [pregunta, answers])
    const menu = useAnchorEl();

    const onChangeTitle = (title) => {
        if(title === pregunta.titulo) return;
        dispatch(updatePregunta( pregunta, {...format, title} ))
    }
    const onSwipe = (up) => dispatch(swipePregunta(pregunta, up));
    const onAddAnswer = (str) => dispatch(updatePregunta( pregunta, {...format, data: [...(answers || []), str] } ))
    const onDeleteAnswer = (i) => dispatch(updatePregunta( pregunta, {...format, data: answers.filter((_, index) => index !== i) } ))
    const onUpdateAnswer = (i, data) => dispatch(updatePregunta( pregunta, {...format, data: answers.map((_, index) => index === i ? data : _) } ))

    return {
        loading,
        answers,
        menu,
        onChangeTitle,
        onAddAnswer,
        onUpdateAnswer,
        onDeleteAnswer,
        onSwipe
    }
}

export default UseQuestion
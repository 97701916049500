import React from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';

const ButtonGroups = ({ loading, handleOpen, handleSubmit, handleClose }) => {
  return (
    <Grid container>
      <IconButton color="primary" onClick={handleOpen} disabled={loading} size="large">
        <AddCircleIcon />
      </IconButton>
      <div className="flex grow middle end">
        <Button color="secondary" size="small" className="mr-m" onClick={handleClose}>Cerrar</Button>
        <div className="relative">
          <Button color="primary" size="small" variant="contained" onClick={handleSubmit} disabled={loading}>
            Aplicar
          </Button>
          {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
        </div>
      </div>
    </Grid>
  );
}

export default ButtonGroups;

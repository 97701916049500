import {
  CONTROL,
  CONTROL_LOADING,
  CONTROL_DATA,
  CONTROL_CREATE
} from './constants.js';
import Axiosv2 from '../../../config/axiosv2.js';
import Axios from '../../../config/axios.js';
import handler from '../../../redux/actions/handler.js';
import { parseStricDate } from '../../util/tools.js';

function loader(value){
  return ({
    type: CONTROL_LOADING,
    payload: value
  })
}

export const openControl = (id) => dispatch => {
  dispatch({type: CONTROL, payload: true});
  dispatch(getControl(id));
}

export const closeControl = () => dispatch => {
  dispatch({type: CONTROL, payload: false})
  dispatch({type: CONTROL_DATA, payload: null})
}
export const getControl = (id) => dispatch => {
  dispatch(loader(true))
  Axios.get(`/control/paciente/${id}`)
  .then(res => {
    if(res.data){
      dispatch({
        type: CONTROL_DATA,
        payload: {
          id: res.data.id,
          idPaciente: res.data.idPaciente,
          description: res.data.descripcion,
          date: parseStricDate(res.data.FechaDelControl)
        }
      })
    } else {
      dispatch({
        type: CONTROL_DATA,
        payload: { date: null, idPaciente: id, description: '' }
      })
    }
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const getControlData = (id) =>
  Axios.get(`/control/paciente/${id}`).then((res) => res.data &&
      {
        id: res.data.id,
        idPaciente: res.data.idPaciente,
        description: res.data.descripcion,
        date: parseStricDate(res.data.FechaDelControl),
      }
    );

export const updateControl = (idPaciente, data, cb) => dispatch => {
  dispatch(loader(true))
  Axiosv2.put(`/control/${idPaciente}`, data)
  .then(cb)
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const createControl = (id, data, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post(`/control/paciente/${id}`, data)
  .then(res => {
    dispatch({
      type: CONTROL_CREATE,
      payload: {
        id: res.data.id,
        idPaciente: id,
        date: parseStricDate(res.data.FechaDelControl),
        description: res.data.descripcion
      }
    })
    cb()
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const destroyControl = (id) => dispatch => {
  dispatch(loader(true))
  Axios.delete(`/control/${id}`)
  .then(e => {
    dispatch(closeControl())
    dispatch({type: CONTROL_DATA, payload: null})
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

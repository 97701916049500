import {
    PREGUNTAS_LIST,
    PREGUNTAS_CREATE,
    PREGUNTAS_UPDATE,
    PREGUNTAS_DELETE,
    CUESTIONARIO_LIST,
    CUESTIONARIO_CREATE,
    CUESTIONARIO_UPDATE,
    CUESTIONARIO_DELETE,
    CUESTIONARIO_LOADING,
    CUESTIONARIO_CLEAR,
} from './constants.js';


import axios from '../../../config/axiosv2';
import handler from '../../../redux/actions/handler.js';

const setLoading = (value) => ({ type: CUESTIONARIO_LOADING, payload: value })
const defaultCallback = () => null;

export const getCuestionarios = ( force = false, callback = defaultCallback ) => (dispatch, getState) => {
    dispatch(setLoading(true));

    if(getState().cuestionario.list !== null && !force){
        return dispatch(setLoading(false));
    }
    axios.get('/cuestionarios')
    .then(res => dispatch({ type: CUESTIONARIO_LIST, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const getCuestionariosClinica = () => axios.get(`/cuestionarios/clinica`)
export const getCuestionariosPaciente = (idPaciente) => axios.get(`/cuestionarios/${idPaciente}`)

export const createCuestionario = ( data, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));
    axios.post('/cuestionarios', data)
    .then(res => dispatch({ type: CUESTIONARIO_CREATE, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updateCuestionario = ( id, data, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));
    axios.put(`/cuestionarios/${id}`, data)
    .then(res => dispatch({ type: CUESTIONARIO_UPDATE, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const swipeCuestionario = (idCuestionario, up) => (dispatch) => {
    dispatch(setLoading(true));
    axios.put(`/cuestionarios/${idCuestionario}/swipe/${up ? 'up' : 'down'}`, null)
    .then(() => dispatch(getCuestionarios(true)))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const deleteCuestionario = (id, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));
    axios.delete(`/cuestionarios/${id}`)
    .then(() => dispatch({ type: CUESTIONARIO_DELETE, payload: id }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const getPrintConfiguration = () => axios.get(`/pdf/configuracion`)
export const getPreguntasOnly = (id) => axios.get(`/cuestionarios/${id}/preguntas`)
export const getPreguntas = ( idCuestionario, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));

    axios.get(`/cuestionarios/${idCuestionario}/preguntas`)
    .then(res => dispatch({ type: PREGUNTAS_LIST, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const createPregunta = (idCuestionario, data, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));
    axios.post(`/cuestionarios/${idCuestionario}/preguntas`, data)
    .then(res => dispatch({ type: PREGUNTAS_CREATE, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updatePregunta = (pregunta, data, callback = defaultCallback ) => (dispatch) => {
    dispatch(setLoading(true));
    axios.put(`/cuestionarios/${pregunta.idCuestionario}/preguntas/${pregunta.idPregunta}`, data)
    .then(res => dispatch({ type: PREGUNTAS_UPDATE, payload: res.data }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const swipePregunta = (pregunta, up, callback = defaultCallback) => (dispatch) => {
    dispatch(setLoading(true));
    axios.post(`/cuestionarios/${pregunta.idCuestionario}/preguntas/${pregunta.idPregunta}/swipe/${up ? 'up' : 'down'}`, null)
    .then(() => dispatch(getPreguntas(pregunta.idCuestionario, callback)))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const deletePregunta = (pregunta, callback = defaultCallback) => (dispatch, getState) => {
    dispatch(setLoading(true));
    axios.delete(`/cuestionarios/${pregunta.idCuestionario}/preguntas/${pregunta.idPregunta}`)
    .then(() => dispatch({ type: PREGUNTAS_DELETE, payload: pregunta.idPregunta }))
    .then(callback)
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updateRespuestas = (idCuestionario, idPaciente, data, callback = defaultCallback) => (dispatch) => {
    dispatch(setLoading(true));
    axios.put(`/cuestionarios/${idCuestionario}/respuestas/${idPaciente}`, data)
    .then(res => callback(res.data))
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const clearCuestionarios = () => ({ type: CUESTIONARIO_CLEAR });
import React, { useState, useMemo } from 'react';

import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CirclePicker } from 'react-color';
import { colors } from './tools';
import useAnchorEl from '../hooks/useAnchorEl';
import Menu from './menu';
import { Tooltip } from '@mui/material';

const ColorPicker = (props) => {
  const { color, handleColor } = props;

  const [picker, setPicker] = useState({
    showIcon: false,
    active: false
  });

  const handlePicker = e => {
    if(picker.showIcon){
      setPicker({
        showIcon: true,
        active: !picker.active
      })
    } else {
      setPicker({
        showIcon: true,
        active: true
      })
    }
  }

  const handleClosePicker = (data, event) => {
    handleColor(data, event)
    handlePicker();
  }

  return (
    <div className="color-picker center">
      <div className="my-m">
        <Button
          onClick={handlePicker}
          variant="outlined"
          sx={{
            backgroundColor: color,
            color: 'black',
            minWidth: 140,
            marginRight: 5
          }}
        >
          Color
        </Button>
        {
          picker.showIcon ?
            <Button onClick={handlePicker}>
              {picker.active ?<VisibilityOffIcon/> : <VisibilityIcon/>}
            </Button>
          : null
        }
      </div>
      {
        picker.active ?
          <div className="hue-picker-content mb">
            <CirclePicker
              color={color}
              colors={colors}
              onChange={handleClosePicker}
            />
          </div>
        : null
      }
    </div>
  )
};

export const ColorIconPicker = ({ color, component, handleColor }) => {
  const menu = useAnchorEl();
  
  const style = useMemo(function(){
    return { width: 20, height: 20, border: '1px solid #262626', borderRadius: 20, background: color };
  }, [ color ])

  const onChangePicker = (data) => {
    handleColor(data.hex)
    menu.handleClose();
  }

  return (
    <>
      <Menu open={!!menu.anchorEl} className="pm-0" anchorEl={menu.anchorEl} onClose={menu.handleClose}>
        <div className="hue-picker-content p-m">
          <CirclePicker
            color={color}
            colors={colors}
            onChange={onChangePicker}
          />
        </div>
      </Menu>
      {
        component ?
          <Button
            variant="outlined"
            style={{ backgroundColor: color, minWidth: 140 }}
            onClick={menu.handleOpen}
          >
            Color
          </Button>
          : (
            <Tooltip arrow title="Elegir Color">
              <div className="mx-m pointer" onClick={menu.handleOpen}>
                <div style={style}></div>
              </div>
            </Tooltip>
          )
      }
    </>
  )
}

export default ColorPicker;

import {
  PROVEEDOR_CUENTAS_ERROR,
  PROVEEDOR_CUENTAS_LOADING,
  PROVEEDOR_CUENTAS_LIST,
  PROVEEDOR_CREATE_RECIBO,
  PROVEEDOR_CANCEL_RECIBO,
  PROVEEDOR_CREATE_DOCUMENT,
  PROVEEDOR_UPDATE_DOCUMENT,
  PROVEEDOR_DELETE_DOCUMENT
} from '../constans.js';

const initialState = {
  error: null,
  loading: false,
  documents: null,
  payments: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case PROVEEDOR_CUENTAS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case PROVEEDOR_CUENTAS_LIST:
      return {
        ...state,
        documents: action.payload.documents,
        payments : action.payload.payments,
        error: null
      }
    case PROVEEDOR_CUENTAS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case PROVEEDOR_CREATE_RECIBO:
      return {
        ...state,
        payments: state.payments ? [...state.payments, action.payload] : [action.payload],
        error: null
      }
    case PROVEEDOR_CANCEL_RECIBO:
      return {
        ...state,
        payments: state.payments ? state.payments.filter(item => item.idRecibo !== action.payload) : state.payments,
        error: null
      }
    case PROVEEDOR_DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents ? state.documents.filter(item => item.idFactura !== action.payload) : state.documents,
        error: null
      }
    case PROVEEDOR_CREATE_DOCUMENT:
      return {
        ...state,
        documents: state.documents ? [...state.documents, action.payload] : [action.payload],
        error: null
      }
    case PROVEEDOR_UPDATE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map(item => item.idFactura === action.payload.idFactura ? action.payload : item),
        error: null
      }
    default:
      return state;
  }
}

import React from 'react'
import Cuestionario from './item';

// UI
import List from '@mui/material/List';

const emptyItem = {nombre: 'Sin resultados.', color: -1, idEstado: -1}
const Container = ({ data, onSelect, onSwipe }) => {
    return (
        <List component="ul">
        {
            data.length ?
                data.map((item, index, arr) =>
                    <Cuestionario
                        key={item.idCuestionario}
                        firstIndex={!index}
                        lastIndex={index === arr.length-1}
                        data={item}
                        onSwipe={onSwipe}
                        onSelect={onSelect}
                    />
                )
            : 
            <Cuestionario data={emptyItem} onSelect={() => null} />
        }
        </List>
    )
}

export default Container;
import React, { useContext, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SubMenu, FollowMenu } from './list.js';

import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import AdjustIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {setSingle} from '../../redux/actions/pacienteActions.js'

import {ReactComponent as UserPlus} from '../../assets/svgs/user-plus.svg';
import {ReactComponent as ScrewDriverWrench} from '../../assets/svgs/screwdriver-wrench.svg';
import {ReactComponent as Home} from '../../assets/svgs/home.svg';

import useStyles from './navbar.style.js'
import { iconStyle } from '../util/tools.js';
import options from './tools.js';

// Navbs
import AgendaNavbar from '../agendaglobal/surfaces/navbar';
import FontWrapper from '../../config/font.js';
import { DrawerContext } from './context';

const style = {paddingLeft: 26}
function MainDrawer(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const drawer = useContext(DrawerContext)
  const [open, setOpen] = useState({ index: false, tools: false, reports: false, follow: false });

  const handleClick = cb => () => {
    cb();
    drawer.handleClose();
  }
  const handleRedirect = path => () => {
    drawer.handleClose();
    history.push(path);
  }

  const handleClickFollow = () => setOpen({...open, follow: !open.follow});
  const handleClickIndex = () => setOpen({...open, index: !open.index});
  const handleClickTools = () => setOpen({...open, tools: !open.tools});
  const handleClickReport = () => setOpen({...open, reports: !open.reports});
  const handleClickConfig = () => {
    history.push('/configuracion')
    drawer.handleClose();
  }
  const handleSearch = () => {
    dispatch(setSingle(null))
    history.push('/')
    drawer.handleClose();
  }

  return (
    <Drawer classes={{paper: styles.drawerPaper}} style={{ width: 320, zIndex: 1250 }} anchor="left" open={drawer.open} onClose={drawer.handleClose}>
      <FontWrapper>
      <div style={{width: 250}} role="presentation">
        <List>

          {/* Inicio */}
          <ListItem onClick={handleClickIndex}>
            <ListItemIcon>
              <SvgIcon fontSize="small" style={iconStyle}>
                <Home />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={'Inicio'} />
            {open.index ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open.index} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <ListItem style={style} onClick={handleSearch}>
                <ListItemIcon>
                  <SearchIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Buscar Paciente" />
              </ListItem>

              <ListItem style={style} onClick={handleClick(props.handleOpenPaciente)}>
                <ListItemIcon>
                  <SvgIcon fontSize="small" style={iconStyle}>
                    <UserPlus />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Nuevo Paciente" />
              </ListItem>

              <ListItem style={style} onClick={handleClick(props.handleOpenProvider)}>
                <ListItemIcon>
                  <SvgIcon fontSize="small" style={iconStyle}>
                    <UserPlus />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Nuevo Proveedor" />
              </ListItem>

            </List>
          </Collapse>

          <Divider light/>
          
          <AgendaNavbar handleClose={drawer.handleClose} handlePush={history.push} />

          <Divider light/>

          {/* Herramientas */}
          <ListItem onClick={handleClickTools}>
            <ListItemIcon>
              <SvgIcon fontSize="small" style={iconStyle}>
                <ScrewDriverWrench />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={'Herramientas'} />
            {open.tools ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open.tools} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                options.map(item => 
                  <ListItem style={style} key={item.url} onClick={handleRedirect(item.url)}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              }
              <ListItem style={style} onClick={handleClickConfig}>
                <ListItemIcon>
                  <AdjustIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={'Configuración'} />
              </ListItem>
            </List>
          </Collapse>

          <Divider light/>

          {/* Reportes */}
          <ListItem onClick={handleClickReport}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={'Reportes'} />
            {open.reports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open.reports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                SubMenu.map((item, i) =>
                  <ListItem key={i} style={style} onClick={handleRedirect('/reportes/'+item.path)}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              }
            </List>
          </Collapse>

          <Divider light/>

          {/* Seguimiento */}
          <ListItem onClick={handleClickFollow}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={'Seguimiento'} />
            {open.follow ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open.follow} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                FollowMenu.map((item, i) =>
                  <ListItem key={i} style={style} onClick={handleRedirect('/reportes/'+item.path)}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              }
            </List>
          </Collapse>

        </List>
      </div>
      </FontWrapper>
    </Drawer>
  );
}

export default MainDrawer;
import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

export default function AlertDialog({ open, title, children, loading, description, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        {children}
      </DialogContent>
      {
        loading ?
          <div style={{margin: '0px 20px', minHeight: 70}}>
            <Grid container justifyContent="flex-end">
              <CircularProgress color="primary" />
            </Grid>
          </div>
        :
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
      }
    </Dialog>
  );
}

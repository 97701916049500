import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Typography, Tooltip, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppsIcon from '@mui/icons-material/Apps';

// Modules
import StyckyStyle from '../../components/util/sticky.module.css';
import { titleList as titles } from '../components';

const StickyHeader = ({ menu, container, drawer }) => {
    const history = useHistory();
    const handleBack = () => {
        history.push('/')
    }
    return (
        <div className={StyckyStyle.main}>
            <Container className="h-100 relative" maxWidth="xl" onClick={drawer ? drawer.handleOpen : undefined}>
                <div className="flex grow middle h-100">
                    <div className="flex grow middle text-white pointer">
                        <Tooltip title="Volver atrás" aria-label="Volver atrás" placement="bottom" arrow>
                            <ArrowBackIcon className="pointer mr" onClick={handleBack} color="inherit" />
                        </Tooltip>
                        <div className="flex grow column text-white text-ellipsis">
                            <Typography noWrap variant="h6" color="inherit">
                                {titles[menu.split('-')[0]] || 'Configuración'}
                            </Typography>
                            {
                                menu.includes('-') && (
                                    <Typography noWrap color="inherit">
                                        {titles[container]}
                                    </Typography>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        {
                            drawer &&
                            <Tooltip title="Menú de módulos" aria-label="Menú de módulos" placement="right" arrow>
                                <IconButton className="text-white" onClick={drawer.handleOpen} size="large">
                                    <AppsIcon color="inherit" />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default StickyHeader
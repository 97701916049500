import React from 'react';

import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

// Components
import Appbar from '../../../configuracion/surfaces/appbar';

const GruposHeader = ({ title, onCreate }) => {
    return (
        <Appbar
            title={title}
            rightIcon={
                <Hidden mdDown>
                    <Button color="primary" onClick={onCreate} startIcon={<LibraryAddIcon />}>Nuevo Grupo</Button>
                </Hidden>
            }
            rightIconProps={{className: "min-width-50"}}
        />
    );
}

export default GruposHeader;
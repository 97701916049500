import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Grupo from '../item'
import { Typography } from '@mui/material';

const TablaGrupos = ({ data, provider, handleDestroy, handleClick }) => {
  const isEmpty = data && !data.length;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className="bg-primary">
            <TableCell style={{ width: 50 }}></TableCell>
            <TableCell className="text-white">Nombre</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((grupo) => (
            <Grupo
              key={grupo.idGrupo}
              item={grupo}
              provider={provider}
              handleDestroy={handleDestroy}
              handleClick={handleClick}
            />
          ))}
        </TableBody>
      </Table>
      {isEmpty &&
        <div className="flex center mt">
          <Typography variant="body1" align="center" color="textSecondary">No hay grupos registrados</Typography>
        </div>
      }
    </TableContainer>
  )
};

export default TablaGrupos;

import React from 'react'

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';

const Header = ({ plantilla, plantillas, loading, onChangePlantilla }) => {
    return (
        <div className="flex pt-m between grow right wrap mb-m">
            <div>
                <TextField select label="Seleccionar Plantilla" size="small" value={plantilla.idPlantilla} disabled={loading} onChange={onChangePlantilla}>
                    {
                        plantillas.map(item => 
                            <MenuItem key={item.idPlantilla} value={item.idPlantilla}>
                                {item.label}
                            </MenuItem>
                        )
                    }
                </TextField>
            </div>
        </div>
    )
}

export default Header;
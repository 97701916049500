import { useState, useMemo, useContext } from 'react'

import { FontContext } from '../../../../config/font';
function Usefontsize() {
    const { fontSize, setFontSize } = useContext(FontContext)
    const [ forceRender, setForceRender ] = useState(null);

    const hasChanges = useMemo(function(){
        setForceRender(false);
        return localStorage.getItem('fontsize') !== fontSize
        // eslint-disable-next-line
    }, [forceRender, fontSize]);

    const onChange = (font) => setFontSize(font)
    const onSubmit = () => {
        localStorage.setItem('fontsize', fontSize);
        setForceRender(true)
    }
    return {
        fontSize,
        hasChanges,
        onSubmit,
        onChange
    }
}

export default Usefontsize
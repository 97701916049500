import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ToolTip, { TextFloatConfigButton } from '../components/util/tooltip';

// Component
import Header from '../components/grupos/surfaces/header';
import Table from '../components/grupos/surfaces/table';

import ModalEditGrupo from '../components/modals/editGroups';
import ModalGrupo from '../components/modals/newGroups';

import { Button, CircularProgress } from '@mui/material';
import useOpen from '../components/hooks/useOpen';
import GrupoInactiveModal, { useInactiveGroups } from '../configuracion/components/pacientes/grupos/modal';
import { destroyGroup } from "../redux/actions/grupoActions";
import useGrupos from '../swr/hooks/useGrupos';

const Grupos = ({ title }) => {
  const [single, setSingle] = useState(null);
  const dispatch = useDispatch();
  const { gruposPacOriginal } = useGrupos();
  const grupos = gruposPacOriginal.filter(item => !item.idTipoDeGrupo)

  const nuevo = useOpen();
  const editable = useOpen();

  const handleSelect = (item) => {
    setSingle(item);
    editable.handleOpen()
  }
  const handleDestroy = (item) => {
    dispatch(destroyGroup(item.idGrupo));
  }
  const handleSelectClose = () => {
    editable.handleClose()
    setSingle(null);
  }

  const {
    open,
    inactivos,
    isLoading,
    handleOpen,
    handleClose,
    handleDestroyInactive,
  } = useInactiveGroups();

  return (
    <div>
      <Header title={title} onCreate={nuevo.handleOpen} />
      <Table data={grupos} handleDestroy={handleDestroy} handleClick={handleSelect} />
      <ModalEditGrupo
        single={single}
        open={editable.open}
        handleClose={handleSelectClose}
      />
      <ModalGrupo open={nuevo.open} handleClose={nuevo.handleClose} />
      <ToolTip
        handleOpen={nuevo.handleOpen}
        title="Nuevo grupo"
        color="primary"
      />
      <TextFloatConfigButton>
        <div className="relative">
          <Button variant="text" color="primary" disabled={isLoading} onClick={handleOpen} style={{ textTransform: "none", textDecoration: "underline" }}>
            Eliminar grupos no usados
          </Button>
          {(isLoading) && <div className="re-loading"><CircularProgress size={22} /></div>}
        </div>
      </TextFloatConfigButton>
      <GrupoInactiveModal
        open={open}
        inactivos={inactivos}
        isLoading={isLoading}
        handleClose={handleClose}
        handleDestroyInactive={handleDestroyInactive}
      />
    </div>
  );
}

export default Grupos

import React from 'react';

import Appbar from '../../../../surfaces/appbar';
import Smtp from '../../../../../components/correos/pages/smtp';

const CorreosSMTP = () => {
    return (
        <div className="flex grow column h-100">
            <Appbar title="Smtp" />
            <Smtp />
        </div>
    );
}

export default CorreosSMTP;
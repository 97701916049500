import React from "react";

// Icons
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Modal from "../modals/index";
import Appbar from "../../../../surfaces/appbar";
import useOpen from "../../../../../components/hooks/useOpen";

const AgendasHeader = ({ select, onSelectBack }) => {
  const modal = useOpen();

  const leftIcon = (
    <IconButton size="small" onClick={onSelectBack}>
      <ChevronLeftIcon />
    </IconButton>
  );
  const rightIcon = (
    <IconButton size="small" onClick={modal.handleOpen}>
      <EditIcon />
    </IconButton>
  );

  return (
    <div className="sticky-header bg-white mt-0" style={{ zIndex: 20000, top: 0 }}>
      <div className="flex grow between">
        <Appbar
          title={select.Nombre}
          className="borderBottom-0"
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          leftIconProps={{ className: "min-width-50 mr-m" }}
          rightIconProps={{ className: "min-width-50" }}
        />
        <Modal
          item={select}
          open={modal.open}
          onSubmit={onSelectBack}
          onClose={modal.handleClose}
        />
      </div>
    </div>
  );
};

export default AgendasHeader;

import {
  CONTROL,
  CONTROL_LOADING,
  CONTROL_DATA,
  CONTROL_UPDATE,
  CONTROL_CREATE
} from './constants.js';

const initialState = {
  open: false,
  data: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case CONTROL:
      return {
        ...state,
        open: action.payload
      }
    case CONTROL_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CONTROL_UPDATE:
    case CONTROL_CREATE:
    case CONTROL_DATA:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state;
  }
}

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useOpen from '../../hooks/useOpen';

// Actions
import { getLinkGmail, destroySyncGmail, testSyncGmail } from '../provider/actions.js';
import handler from '../../../redux/actions/handler.js';
import { AuthAxios } from '../../../config/axiosv2.js';

const UseGmail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const timer = useOpen();
    const [interval, setIntervalTime] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const testmodal = useOpen();

    useEffect(function(){
        AuthAxios.get('/sync')
        .then(res => setData(res.data))
        .catch(e => null)
        .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [])

    // Timer
    useEffect(function(){
        let timeout = null;
        if(timer.open){
            timeout = setTimeout(function(){
                timer.handleClose();
                history.push('/')
            }, 1000*120);
            setIntervalTime(setInterval(function(){
                AuthAxios.get('/sync')
                .then(res => {
                    if(res.data){
                        setData(res.data)
                        timer.handleClose();
                    }
                })
                .catch(e => null)
            }, 5000))
        } else {
            clearInterval(interval)
            if(timeout !== null){
                clearTimeout(timeout);
                timeout = null;
            }
        }
        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
        // eslint-disable-next-line
    }, [ timer.open ])

    const onDestroy = () => {
        destroySyncGmail()
        .then(() => setData(null))
        .catch(handler(dispatch))
    }

    const onTest = () => {
        testSyncGmail()
        .then(testmodal.handleOpen)
        .catch(handler(dispatch))
    }
    
    const onSync = () => {
        getLinkGmail()
        .then(res => {
            window.open(res.data)
            timer.handleOpen();
        })
        .catch(handler(dispatch))
    }

    return {
        data,
        loading,
        testmodal,
        onSync,
        onTest,
        onDestroy
    }
}

export default UseGmail;
import { useSelector } from "react-redux";
import useSWR from "swr";
import Axios, { baseURL } from "../../config/axiosv2";

const fetcher = ([url, token]) => Axios.get(url, {
    headers: {
        "x-auth": token,
    },
    baseURL: baseURL.concat('/v3')
});

const useVersion = () => {
    const token = useSelector(state => state.auth.token);
    const { data: response, error, mutate } = useSWR(['/version', token], fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    const data = response ? response.data.version : null;

    return {
        data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useVersion;
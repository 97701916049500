import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

// Hooks
import useAnchorEl from '../../hooks/useAnchorEl';

import useGrupos from '../../../swr/hooks/useGrupos';
import { toColor } from '../../util/tools';

const MIN_VALUE = "DOCTOR_PICKER_MIN_VALUE"
const DoctorPicker = ({ value, defultOptionLabel = "Sin doctor", className, onChange }) => {
    const menu = useAnchorEl();
    const { gruposDoctores: doctores } = useGrupos();

    const emptyValue = value <= 0;

    const onSelectDoctor = ({ target }) => {
        const value = target.value === MIN_VALUE ? -1 : target.value;
        onChange(value)
        menu.handleClose();
    }

    const getIcon = (color) => {
        const isWhite = color === -1;
        if (isWhite) return <CircleIcon style={{ color: "white", stroke: "black" }} />
        return <CircleIcon style={{ color: toColor(color, true) }} />;
    }
    return (
        <>
            <TextField
                select
                fullWidth
                size="small"
                label="Doctor"
                value={emptyValue ? MIN_VALUE : value}
                placeholder={"Seleccionar Doctor"}
                onChange={onSelectDoctor}
                className={className}
                sx={{
                    "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                    }
                }}
            >
                {
                    doctores.map(doctor =>
                        <MenuItem className="flex row min-width-200" key={doctor.idGrupo} value={doctor.idGrupo}>
                            <ListItemIcon>
                                {getIcon(doctor.Color)}
                            </ListItemIcon>
                            <ListItemText sx={{ marginY: 0 }} primary={doctor.Nombre} />
                        </MenuItem>
                    )
                }
                <Divider />
                <MenuItem className="flex row" value={MIN_VALUE}>
                    <ListItemIcon>
                        <ClearIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ marginY: 0 }} primary={defultOptionLabel} />
                </MenuItem>
            </TextField>
        </>
    )
}

export default DoctorPicker;
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { pdf } from '@react-pdf/renderer';

// Icons
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

// Hooks
import useOpen from '../../../../components/hooks/useOpen';
import useFetch from '../../../../components/hooks/useFetchCsv';
import download from '../../../../components/util/download';

// Components
import Appbar from '../../../surfaces/appbar';
import Modal from '../../../../components/precios/modalList';
import DownloadPicker from '../../../../components/util/csvpdfpicker'
import Document from './aranceles/template';

import { headers, makeCsvTemplate } from './template';
import { getPrintConfiguration } from '../../../../components/cuestionario/provider/actions';

const PreciosHeader = ({ select, data, onCreate, onSelectBack }) => {
    const modal = useOpen();
    const picker = useOpen();
    const csv = useFetch();
    const downloadPdf = useOpen();
    const id = useSelector(state => state.auth.user.idClinica);

    const leftIcon = select ? <IconButton onClick={onSelectBack} size="large"><ChevronLeftIcon /></IconButton> : null;
    const rightIcon = (
        select ? (
            <div>
                <IconButton onClick={modal.handleOpen} size="large"><EditIcon /></IconButton>
                <IconButton onClick={picker.handleOpen} size="large"><ShareIcon /></IconButton>
            </div>
        ) : (
            <Hidden mdDown>
                <Button color="primary" onClick={onCreate} startIcon={<LibraryAddIcon />}>Nueva Lista</Button>
            </Hidden>
        )
    );

    const onDownloadPdf = useCallback(async function (data, title) {
        const getBlob = (config) => new Promise((resolve, reject) =>
            pdf(Document({ id, data, title, config }))
                .toBlob()
                .then(resolve)
                .catch(reject)
        );

        try {
            const { data: config } = await getPrintConfiguration()
            const blob = await getBlob(config);
            download({ blob, fileName: `${title}.pdf` })
        } catch (e) {
            alert(e);
        } finally {
            downloadPdf.handleClose();
        }
        // eslint-disable-next-line
    }, [])

    const onSubmmit = () => {
        modal.handleClose();
        onSelectBack();
    }
    const onSelectPicker = (method) => {
        if (method === "csv") {
            csv.prepareClick({ headers, list: data.map(makeCsvTemplate) }, select.Nombre);
        } else if (method === "pdf") {
            downloadPdf.handleOpen();
            onDownloadPdf(data, select.Nombre)
        }
    }
    return (
        <div className="flex">
            <Appbar
                title={select ? select.Nombre : 'Precios'}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                leftIconProps={{ className: "min-width-50 mr-m" }}
                rightIconProps={{ className: "min-width-50" }}
            />
            <Modal item={select} open={modal.open} onClose={modal.handleClose} onSubmmit={onSubmmit} />
            <DownloadPicker {...picker} reporte={false} handleSelect={onSelectPicker} />
        </div>
    );
}

export default PreciosHeader;
import {
  AGENDA_LOADING,
  AGENDA_LIST,
  AGENDA_ERROR,
  AGENDA_SINGLE,
  AGENDA_SELECT,
  AGENDA_LIST_UPDATE,
  AGENDA_LIST_CREATE,
  AGENDA_LIST_HIDEN,
  CITA_NOTE,
  CITA_HOURS,
  CITA_ACTIVE,
  CITAS_CLEAR,
  CITAS_LIST,
  CITAS_MAKE,
  CITAS_UPDATE,
  CITAS_DELETE,
  CITAS_UPDATE_MEDIO,
  CITAS_STATE_UPDATE,
  CITA_MEDIOS,
  CITA_CONTEXT,
  CITA_CHANGE,
  CLEAR_AFTER,
  CITA_MENU_CLOSE
} from '../constans.js';
import {
  getInitialDate,
  getFormatDate,
  parseHour,
  parseStricDate,
  formatSecond
} from '../../components/util/tools.js';

const initialState = {
  list: null,
  loading: false,
  error: null,
  single: null,
  select: null,
  citas: {},
  original: null,
  active: null,
  activeHours: null,
  event: null,
  origin: null,
  context: false,
  note: false
}

function filterList(action, dates, payload, lastIndex, agenda){
  try {
    const day = getFormatDate(getInitialDate(payload.data.Fecha)).concat('-', agenda.Nombre)
    const list = { ...dates };
    let wrap;

    switch (action) {
      case 'MAKE':
        wrap = list[day] ? [...list[day], payload] : [payload]
        break;

      case 'UPDATE':
        wrap = list[day] && list[day].map(item => item.idCita === lastIndex ? payload : item)
        break;

      case 'DELETE':
        wrap = list[day] && list[day].filter(item => item.idCita !== payload.idCita)
        break;

      default: wrap = null
    }
    list[day] = wrap;
    return list;
  } catch (error) {
    console.log(error);
  }
}

function updateList(dates, payload){
  const list = {...dates};

  const keys = Object.keys(list);
  for (let i = 0; i < keys.length; i++) {
    list[keys[i]] = list[keys[i]].map(function(item){
      if(item.idPaciente === payload.idPaciente){
        return {
          ...item,
          paciente: payload.paciente
        }
      }
      return item;
    })
  }

  return list;
}

const replace = (text, target, r) => {
  while(text.indexOf(target) >= 0){
    text = text.replace(target, r)
  }

  return text;
}

const formatCita = (item) => {
  const sTime = parseHour(item.Hora)

  const sHour = `${sTime[0]}${sTime[1]}`
  const sMinutes = `${sTime[2]}${sTime[3]}`

  return {
    idCita: item.idCita,
    idPaciente: item.idPaciente,
    idClinica: item.idClinica,
    idAgenda: item.idAgenda,
    paciente: {...item.paciente, idPaciente: item.idPaciente},
    title: item.InfoExtra,
    start: formatSecond(replace(item.Fecha, '-', '/'), sHour, sMinutes),
    end: formatSecond(replace(item.Fecha, '-', '/'), sHour, sMinutes, item.Duracion),
    allDay: false,
    data: {...item, Fecha: parseStricDate(item.Fecha)},
  }
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case AGENDA_SELECT:
      return {
        ...state,
        select: action.payload
      }
    case AGENDA_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CITA_NOTE:
      return {
        ...state,
        note: action.payload
      }
    case CITA_ACTIVE:
      return {
        ...state,
        active: action.payload
      }
    case CITA_HOURS:
      return {
        ...state,
        activeHours: action.payload
      }
    case AGENDA_LIST:
      return {
        ...state,
        list: (action.payload ? action.payload.filter(item => item.Visible) : action.payload),
        original: action.payload,
        error: null
      }
    case AGENDA_LIST_HIDEN:
      const list = state.list.filter(item => item.idAgenda !== action.payload);
      return {
        ...state,
        list: list,
        single: list[0],
        error: null
      }
    case AGENDA_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map(item => item.idAgenda === action.payload.idAgenda ? action.payload : item),
        original: state.original.map(item => item.idAgenda === action.payload.idAgenda ? action.payload : item),
        single: action.payload,
        error: null
      }
    case AGENDA_LIST_CREATE:
      return {
        ...state,
        list: [...state.list, action.payload],
        original: [...state.original, action.payload],
        error: null
      }
    case AGENDA_SINGLE:
      return {
        ...state,
        single: action.payload,
        error: null
      }
    case CITAS_CLEAR:
      return {
        ...state,
        citas: {}
      }
    case CITAS_DELETE:
      return {
        ...state,
        citas: filterList('DELETE', state.citas, action.payload, null, state.single),
        error: null
      }
    case CITAS_UPDATE:
      return {
        ...state,
        citas: filterList('UPDATE', state.citas, formatCita(action.payload), action.lastIndex, state.single),
        error: null
      }
    case CITAS_UPDATE_MEDIO:
      return {
        ...state,
        citas: updateList(state.citas, action.payload),
        error: null
      }
    case CITAS_MAKE:
      return {
        ...state,
        citas: filterList('MAKE', state.citas, formatCita(action.payload), null, state.single),
        error: null
      }
    case CITAS_STATE_UPDATE:
      return {
        ...state,
        citas: filterList('UPDATE', state.citas, formatCita(action.payload), action.payload.idCita, state.single),
        error: null
      }
    case CITAS_LIST:
      const label = `${getFormatDate(action.date)}-${state.single.Nombre}`
      return {
        ...state,
        citas: {
          ...state.citas,
          [label]: action.payload.map(formatCita)
        }
      }
    case CLEAR_AFTER:
      const citas = {};
      const target = getInitialDate(new Date());
      target.setDate(target.getDate() - 7)
      
      Object.keys(state.citas).map(function(key){
        const str = key.split('-');
        const keydate = new Date(str[2], +str[1], +str[0] + 1);

        if( !(Date.parse(keydate) >= Date.parse(target)) ){
          citas[key] = state.citas[key];
        }

        return null;
      })

      return {
        ...state,
        citas: citas
      }
    case AGENDA_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case CITA_CONTEXT:
      return {
        ...state,
        origin: action.payload.evt ?
        {
          left:action.payload.evt.clientX,
          top:action.payload.evt.clientY
        } : null,
        event: action.payload.cita,
        context: true,
      }
    case CITA_MEDIOS:
      return {
        ...state,
        origin: action.payload.evt ?
        {
          left:action.payload.evt.clientX,
          top:action.payload.evt.clientY
        } : null,
        event: action.payload.cita,
        context: false
      }
    case CITA_MENU_CLOSE:
      return {
        ...state,
        origin: null,
        event: null,
        context: false
      }
    case CITA_CHANGE:
      return {
        ...state,
        context: !state.context
      }
    default:
      return state;
  }
}

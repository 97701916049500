import { mutate } from 'swr';
import { clearCache as clearCacheGlobal } from '../../components/agendaglobal/provider/actions.js';
import { getFormatDateReverse } from '../../components/util/tools.js';
import Axios from '../../config/axios.js';
import AxiosV2 from '../../config/axiosv2.js';
import {
  AGENDA_LIST_CREATE,
  AGENDA_LIST_HIDEN,
  AGENDA_LIST_UPDATE,
  AGENDA_LOADING,
  AGENDA_SELECT,
  AGENDA_SINGLE,
  CITAS_DELETE,
  CITAS_LIST,
  CITAS_MAKE,
  CITAS_STATE_UPDATE,
  CITAS_UPDATE,
  CITAS_UPDATE_MEDIO,
  CITA_ACTIVE,
  CITA_CHANGE,
  CITA_CONTEXT,
  CITA_HOURS,
  CITA_MEDIOS,
  CITA_MENU_CLOSE,
  CITA_NOTE,
  CLEAR_AFTER
} from '../constans.js';
import handler from './handler.js';

function loader(type, val) {
  return ({
    type: type,
    payload: val
  })
}

export const setAgenda = (agenda) => dispatch => {
  dispatch({
    type: AGENDA_SINGLE,
    payload: agenda
  })
}

export const setNote = bool => dispatch => dispatch({ type: CITA_NOTE, payload: bool });

export const clearAllAfterDate = date => ({ type: CLEAR_AFTER, payload: date })

export const getCitas = (agenda, date) => (dispatch) => {
  dispatch(loader(AGENDA_LOADING, true))
  Axios.get('/agenda/' + agenda.idAgenda, { params: { date: date } })
    .then(res =>
      dispatch({
        type: CITAS_LIST,
        payload: res.data,
        date: date
      })
    )
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

const make = (dispatch, url, state, cb) => {
  dispatch(loader(AGENDA_LOADING, true))
  Axios.post(url, state)
    .then(res => {
      dispatch({
        type: CITAS_MAKE,
        payload: res.data
      })
    })
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const makeNota = (state, agenda, callback) => (dispatch) => {
  clearCacheGlobal(agenda.idAgenda, getFormatDateReverse(state.date));
  return make(dispatch, '/notas/agenda/' + agenda.idAgenda, state, callback)
}

export const makeCita = (state, agenda, callback) => (dispatch) => {
  clearCacheGlobal(agenda.idAgenda, getFormatDateReverse(state.date));
  return make(dispatch, '/citas/agenda/' + agenda.idAgenda, state, callback)
}

export const updateNota = (state, cita, refresh, callback) => (dispatch) => {
  dispatch(loader(AGENDA_LOADING, true))
  clearCacheGlobal(cita.idAgenda, cita.start);
  clearCacheGlobal(cita.idAgenda, getFormatDateReverse(state.date));
  Axios.put('/notas/' + cita.idCita, state)
    .then(res => {
      dispatch({
        type: CITAS_UPDATE,
        payload: {
          ...res.data,
          paciente: null
        },
        lastIndex: cita.idCita
      })
    })
    .then(callback)
    .catch((e) => {
      if (e.response && e.response.status === 405) {
        refresh();
        callback();
      }
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const updateCita = (state, cita, refresh, callback) => (dispatch) => {
  dispatch(loader(AGENDA_LOADING, true))
  clearCacheGlobal(cita.idAgenda, cita.start);
  clearCacheGlobal(cita.idAgenda, getFormatDateReverse(state.date));
  Axios.put('/citas/' + cita.idCita, state)
    .then(res => {
      dispatch({
        type: CITAS_UPDATE,
        payload: {
          ...res.data,
          paciente: res.data.paciente || cita.paciente
        },
        lastIndex: cita.idCita
      })
    })
    .then(callback)
    .catch((e) => {
      if (e.response && e.response.status === 405) {
        refresh();
        callback();
      }
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const deleteCita = (cita, cb) => (dispatch) => {
  clearCacheGlobal(cita.idAgenda, cita.start);
  dispatch(loader(AGENDA_LOADING, true))
  Axios.delete('/citas/' + cita.idCita)
    .then(() =>
      dispatch({
        type: CITAS_DELETE,
        payload: cita
      })
    )
    .catch((e) => {
      if (e.response && e.response.status === 405) cb();
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const setActive = cita => dispatch => {
  dispatch({
    type: CITA_ACTIVE,
    payload: cita
  })
}

export const setActiveHours = hours => dispatch => {
  dispatch({
    type: CITA_HOURS,
    payload: hours
  })
}

export const updateState = (cita, state, refresh) => (dispatch) => {
  const path = '/agenda/' + cita.idAgenda + '/cita/' + cita.idCita;
  dispatch(loader(AGENDA_LOADING, true))
  clearCacheGlobal(cita.idAgenda, cita.start);
  Axios.put(path, { state: state })
    .then(res =>
      dispatch({
        type: CITAS_STATE_UPDATE,
        payload: res.data
      })
    )
    .catch((e) => {
      if (e.response && e.response.status === 405) {
        refresh();
      }
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const updateMedio = (cita, medio, refresh) => (dispatch) => {
  dispatch(loader(AGENDA_LOADING, true))
  clearCacheGlobal(cita.idAgenda, cita.start);
  Axios.put('/paciente/medio/' + cita.idPaciente, { medio: medio })
    .then(() => {
      dispatch({
        type: CITAS_UPDATE_MEDIO,
        payload: {
          idCita: cita.idCita,
          idPaciente: cita.idPaciente,
          paciente: {
            ...cita.paciente,
            MedioDeComunicacion: medio
          }
        }
      })
    })
    .catch((e) => {
      if (e.response && e.response.status === 405) {
        refresh();
      }
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const updateConfirm = (cita, refresh) => (dispatch) => {
  dispatch(loader(AGENDA_LOADING, true))
  clearCacheGlobal(cita.idAgenda, cita.start);
  Axios.put('/citas/confirmar/' + cita.idCita)
    .then(res => {
      dispatch({
        type: CITAS_UPDATE,
        payload: res.data,
        lastIndex: cita.idCita
      })
    })
    .catch((e) => {
      if (e.response && e.response.status === 405) {
        refresh();
      }
      handler(dispatch)(e);
    })
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const updateListAgenda = (id, body, cb) => dispatch => {
  dispatch(loader(AGENDA_LOADING, true))
  Axios.put(`/agenda/${id}`, body)
    .then(res => {
      dispatch({
        type: AGENDA_LIST_UPDATE,
        payload: res.data
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const createListAgenda = (body, cb) => dispatch => {
  dispatch(loader(AGENDA_LOADING, true))
  Axios.post(`/agenda`, body)
    .then(res => {
      dispatch({
        type: AGENDA_LIST_CREATE,
        payload: res.data
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const hideListAgenda = (id, cb) => dispatch => {
  dispatch(loader(AGENDA_LOADING, true))
  Axios.delete(`/agenda/${id}`)
    .then(() => {
      dispatch({
        type: AGENDA_LIST_HIDEN,
        payload: id
      })
      cb();
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const setAgendaEdit = agenda => ({ type: AGENDA_SELECT, payload: agenda })

export const contextCitaMenu = (evt, cita) => dispatch => {
  dispatch({
    type: CITA_CONTEXT,
    payload: { evt, cita },
  })
}

export const mediosCitaMenu = (evt, cita) => dispatch => {
  dispatch({
    type: CITA_MEDIOS,
    payload: { evt, cita },
  })
}
export const changeCitaMenu = () => dispatch => dispatch({
  type: CITA_CHANGE
})

export const updateAgendaJourney = (agenda, durations, journey, cb) => dispatch => {
  dispatch(loader(AGENDA_LOADING, true))
  AxiosV2.put(`/agendas/jornada/${agenda.idAgenda}`, { journey, durations })
    .then(() => mutate('/agenda'))
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(AGENDA_LOADING, false)))
}

export const clearAgendas = () => dispatch => {
  dispatch({ type: AGENDA_SINGLE, payload: null })
}
export const clearCitaMenu = () => dispatch => dispatch({ type: CITA_MENU_CLOSE });

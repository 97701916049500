import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import Axios from '../../../../config/axiosv2';
import handler from '../../../../redux/actions/handler';
import useMonedas from '../../../../swr/hooks/useMonedas';

const properties = ['Activa', 'Nombre', 'Simbolo'];
const useMonedasManager = () => {
    const { original: list, isLoading, mutate } = useMonedas();
    const [isSaving, setIsSaving] = useState(false);
    const [monedas, setMonedas] = useState([]);
    const dispatch = useDispatch();

    useEffect(function () {
        if (list) {
            setMonedas(list.sort((a, b) => a.id_moneda - b.id_moneda));
        }
    }, [list])

    const hasChanges = useMemo(function () {
        let changes = false;

        backloop:
        for (const moneda of monedas) {
            const copia = list.find(item => item.id_moneda === moneda.id_moneda);
            for (const prop of properties) {
                if (moneda[prop] !== copia[prop]) {
                    changes = true;
                    break backloop;
                }
            }
        }

        return changes;
    }, [monedas, list])

    const onChange = (id, data) => {
        setMonedas(state => state.map(item => item.id_moneda === id ? data : item));
    }
    const onSubmit = async () => {
        const data = [];

        for (let moneda of monedas) {
            const copia = list.find(item => item.id_moneda === moneda.id_moneda);
            for (const prop of properties) {
                if (moneda[prop] !== copia[prop]) {
                    if (moneda.Nombre.trim().length && moneda.Simbolo.trim().length) {
                        data.push(moneda)
                        break;
                    }
                }
            }
        }

        if (!data.length) return;

        try {
            setIsSaving(true);
            await Promise.all(
                data.map(moneda =>
                    Axios.put(`/monedas/${moneda.id_moneda}`, {
                        name: moneda.Nombre,
                        symbol: moneda.Simbolo,
                        active: moneda.Activa
                    })
                )
            )
            mutate();
        } catch(e) {
            handler(dispatch)(e);
        } finally {
            setIsSaving(false);
        }
    }

    return {
        isLoading: isLoading || isSaving,
        monedas,
        hasChanges,
        onChange,
        onSubmit
    }
}

export default useMonedasManager;
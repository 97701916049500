import React from 'react';

import { Typography, IconButton } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import CamposExtra from '../../paciente/surfaces/camposextra';

const InfoProveedor = ({ proveedor }) => {
    return (
        <>
            <div className="flex grow center mt">
                <Typography variant="subtitle2" className="text-secondary" style={{ fontSize: 12 }}>
                    <strong>CONTACTO</strong>
                </Typography>
            </div>
            {
                proveedor.TelefonoParaMostrar &&
                <div className="flex grow center">
                    <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                        <PhoneIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="body2" align="center" noWrap>
                        {proveedor.TelefonoParaMostrar}
                    </Typography>
                </div>
            }
            {
                proveedor.movilDeEnvio &&
                <div className="flex grow center">
                    <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                        <PhoneAndroidIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="body2" align="center" noWrap>
                        {proveedor.movilDeEnvio} (Movil)
                    </Typography>
                </div>
            }
            {
                proveedor.correoDeEnvio &&
                <div className="flex grow center">
                    <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                        <MailIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="body2" align="center">
                        {proveedor.correoDeEnvio.split('@')[0]} @{proveedor.correoDeEnvio.split('@')[1]}
                    </Typography>
                </div>
            }
            {
                proveedor.DireccionParaMostrar &&
                <div className="flex grow center">
                    <IconButton size="small" className="modal-small-icon text-secondary" color="inherit">
                        <LocationOnIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="body2" align="center">
                        {proveedor.DireccionParaMostrar}
                    </Typography>
                </div>
            }
            <CamposExtra campos={proveedor.fields} nota={proveedor.nota} />
        </>
    )
}

export default InfoProveedor;

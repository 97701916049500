import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Hook
import useAuth from '../components/hooks/useAuth';

// Layout
import Navbar from '../components/layout/navbar';
import { PageLoader } from '../components/util/spinner';

// Pages
import Configuracion from '../pages/configuracion';
import Login from '../pages/login';
import App from './app';

import Forget from '../pages/forget';
import Redirect from '../pages/redir';
import Reset from '../pages/reset';
import TokenAuth from '../pages/tokenauth';

import ModalNextControl from '../components/modals/viewControl';
import ModalNuevoPaciente from '../components/paciente/modals/create';
import ModalNuevoProveedor from '../components/proveedor/modals/create';

import useOpen from '../components/hooks/useOpen';
import Alert from '../components/util/alert';

const AppRouter = () => {
  const user = useSelector(state => state.auth.user);
  const load = useSelector(state => state.auth.remember);

  useAuth();

  const modalPaciente = useOpen();
  const modalProveedor = useOpen();

  if(load) return <PageLoader />

  return (
    <div className="h-100">
      <Alert />
      <Navbar onCreatePaciente={modalPaciente.handleOpen} onCreateProvider={modalProveedor.handleOpen} />
      <ModalNuevoPaciente {...modalPaciente} />
      <ModalNuevoProveedor {...modalProveedor} />
      <ModalNextControl />
      <Suspense fallback={<PageLoader/>}>
      {
        // No hay logeado
        !user ?
          <Switch>
            <Route exact path="/auth" component={TokenAuth} />
            <Route path="/create/:token" component={Reset} />
            <Route path="/reset/:token" component={Reset} />
            <Route path="/forget" component={Forget} />
            <Route path="/login" component={Login} />
            <Route path="*" component={Redirect} />
          </Switch>
        :
          <Switch>
            <Route exact path="/auth" component={TokenAuth} />
            <Route exact path="/configuracion" component={Configuracion} />
              <Route path="*" render={(props) => <App {...props} onCreatePaciente={modalPaciente.handleOpen} onCreateProvider={modalProveedor.handleOpen} />} />
          </Switch>
      }
      </Suspense>
    </div>
  )
}

export default AppRouter

import React, { useEffect, useState } from 'react';

// UI
import { MobileDatePicker } from '@mui/x-date-pickers';
import { models } from './util';

const Picker = ({ date, range, picker, handleDate }) => {
    const model = (models[range] || models[0])
    const [time, setTime] = useState(new Date());
    
    useEffect(function () {
        if (picker.open) setTime(date || new Date())
    }, [picker.open, date])

    const onChange = (time) => setTime(time);

    if (!picker.open) return null;

    return (
        <div>
            <MobileDatePicker
                open
                label="Seleccionar fecha"
                openTo={model.openTo}
                views={model.views}
                value={time}
                onChange={onChange}
                onAccept={handleDate}
                onClose={picker.handleClose}
                slotProps={{ textField: { sx: { display: "none" } } }}
            />
        </div>
    )
}

export default Picker;
import React from 'react'

import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const Option = ({ item, onSelect }) => {
    return (
        <div className="flex column mb-m" key={item.title}>
            <strong key={item.title} className="title">
                {item.title}
            </strong>
            <div className="flex grow wrap">
                {
                    item.options.map(option => (
                        <Tooltip key={option.name} title={option.description} arrow>
                            <Typography variant="body2" onClick={onSelect(option.name)} className="option">
                                {option.name}
                            </Typography>
                        </Tooltip>
                    ))
                }
            </div>
        </div>
    )
}

export default Option;
import {
  PERMISOS_LIST,
  DOCTORES_LIST,
  USUARIOS_LIST,
  USUARIOS_LOADING,
  USUARIOS_UPDATE,
  USUARIOS_CREATE,
  USUARIOS_DESTROY
} from './constants.js';

const initialState = {
  list: null,
  doctores: null,
  loading: false,
  permisos: null
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case USUARIOS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case PERMISOS_LIST:
      return {
        ...state,
        permisos: action.payload,
      }
    case DOCTORES_LIST:
      return {
        ...state,
        doctores: action.payload,
      }
    case USUARIOS_LIST:
      return {
        ...state,
        list: action.payload,
      }
    case USUARIOS_CREATE:
      return {
        ...state,
        list: [...state.list, action.payload],
      }
    case USUARIOS_UPDATE:
      return {
        ...state,
        list: state.list.map(item => item.idUsuario === action.payload.idUsuario ? action.payload : item)
      }
    case USUARIOS_DESTROY:
      return {
        ...state,
        list: state.list.filter(item => item.idUsuario !== action.payload)
      }
    default:
      return state;
  }
}
import { formatNumber } from "../../../../components/util/tools.js";

export const headers = [
    { label: 'Código' },
    { label: 'Tratamiento' },
    { label: 'Costo' }
];

export const makeCsvTemplate = (item) => [
    { label: item.Codigo },
    { label: item.Tratamiento },
    { label: item.EsTitulo ? "" : `${item.moneda.Simbolo}${formatNumber(item.Precio)}` }
]
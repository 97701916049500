import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// UI
import {
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";

// Components
import Appbar from "../../../../surfaces/appbar";

// Hooks
import { updateConfiguration } from "../../../../../redux/actions/authActions";
import useGrupos from "../../../../../swr/hooks/useGrupos";

const labels = {
  MostrarDoctoresComoGrupo: "Mostrar doctores como grupo de pacientes",
  ModificarControlAlAgregarCita: "Mostrar control al agregar una cita",
  MostrarNroDePago: "Mostrar Nro. de pago de pacientes",
  MostrarCitasCanceladasEnLaAgenda: "Mostrar faltas con aviso y citas canceladas",
  PonerNuevoTratamientoComoFinalizado: "Poner tratamiento automáticamente como finalizado",
  FirmarHistoriaConPin: "Firmar historia con pin",
  IdDelPacienteAutomatico: "ID del paciente automático",
  UsarEstadosPersonalizados: "Usar estados personalizados en los Pacientes",
}

const Plus = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const { gruposDoctores: doctores } = useGrupos();

  const [state, setState] = useState({
    MostrarDoctoresComoGrupo: user.MostrarDoctoresComoGrupo,
    ModificarControlAlAgregarCita: user.ModificarControlAlAgregarCita,
    MostrarNroDePago: user.MostrarNroDePago,
    MostrarCitasCanceladasEnLaAgenda: user.MostrarCitasCanceladasEnLaAgenda,
    PonerNuevoTratamientoComoFinalizado: user.PonerNuevoTratamientoComoFinalizado,
    FirmarHistoriaConPin: user.FirmarHistoriaConPin,
    IdDelPacienteAutomatico: user.IdDelPacienteAutomatico,
    UsarEstadosPersonalizados: user.UsarEstadosPersonalizados,
  })

  const disabled = loading || Object.keys(state).every(key => state[key] === user[key]);

  const onCheck = (evt, checked) => {
    const name = evt.target.name;
    setState(oldState => ({
      ...oldState,
      [name]: checked
    }))
  }
  const onSubmit = () => dispatch(updateConfiguration(state));

  return (
    <div className="flex grow column h-100">
      <div>
        <Appbar title="Otras opciones" />

        <div className="flex grow column px my">
          {
            Object.keys(state).map((key) => {
              if (key === "FirmarHistoriaConPin" && doctores.length <= 1) return null;
              return (
                <div className="flex middle">
                  <Checkbox
                    id={`${key}-form`}
                    size="small"
                    color="primary"
                    name={key}
                    checked={state[key]}
                    onChange={onCheck}
                  />
                  <Typography
                    className="pointer"
                    component="label"
                    htmlFor={`${key}-form`}
                    variant="body2"
                  >
                    {labels[key]}
                  </Typography>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="flex grow end right p">
        <div className="relative">
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={disabled}
          >
            Guardar
          </Button>
          {loading && (
            <div className="re-loading">
              <CircularProgress size={22} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plus;

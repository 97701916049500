import { contain } from '../util/tools.js';
import fallbackSrc from '../../assets/fallback.jpeg';

export const useHandleChange = func => (function(e) {
  const target = e.target;
  return func(old => ({
    ...old,
    [target.name]: target.value
  }))
})

export const useHandleDate = (func, _parse) => (function(e) {
  return func(old => ({
    ...old,
    date: _parse ? Date.parse(e) : e
  }))
})

export const useHandleChangeNumber = func => (function(e) {
  const target = e.target;
  return func(old => ({
    ...old,
    [target.name]: target.valueAsNumber
  }))
})

export const useHandleColor = func => (function(e) {
  return func(old => ({
    ...old,
    color: e.hex || e
  }))
})

export const useHandleFile = (func, errFunc) => (function(e) {
  const file = e.target.files[0];
  if(file){
    if(contain(file.name.toLowerCase(), ['jpg', 'jpeg', 'png'])){

      func(old => ({...old, file: file}))
      errFunc({})

    // El archivo no cumple las extensiones soportadas
    } else {
      errFunc({ file: 'Solo se permiten imágenes .jpg y .png'})
    }

    // No hay archivo
  } else {
    func(old => ({...old,file: null}))
  }
})

export const useError = () => (function(e) {
  e.target.onerror = null;
  e.target.src=fallbackSrc;
})

import React from 'react';

// UI
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as HandIcon } from '../../../assets/svgs/hand-paper-solid.svg';

export const CuestionarioIcon = ({ history, paciente }) => {
    const onClick = () => history.push('cuestionario/'+paciente.idPaciente)
    if(!(paciente && paciente.tieneAlertaPorCuestionario)) return null;
    return (
        <IconButton aria-label="delete" onClick={onClick} size="large">
            <SvgIcon fontSize="small">
                <HandIcon />
            </SvgIcon>
        </IconButton>
    );
}

export default CuestionarioIcon
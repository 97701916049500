import React from 'react';

// UI
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LabelIcon from '@mui/icons-material/Label';

// Components
import Modal from './modal';
import MenuDoctores from './menu';

// Hooks
import useOpen from '../../../../../components/hooks/useOpen';
import useAnchorEl from '../../../../../components/hooks/useAnchorEl';

// Util
import { toColor } from '../../../../../components/util/tools';
import { MoreVert } from '@mui/icons-material';
import PINModal from './modalpin';

const GruposDoctores = ({ doctor, isMany }) => {
    const modal = useOpen();
    const pinModal = useOpen();
    const menu = useAnchorEl();
    return <>
        <TableRow hover className="pointer">
            <TableCell style={{ width: 50, color: toColor(doctor.Color, true) }} onClick={modal.handleOpen}>
                <LabelIcon color="inherit" />
            </TableCell>
            <TableCell>
                <Box display="flex" alignItems="center" gap={12} onClick={modal.handleOpen}>
                    <Typography variant="body2">{doctor.Nombre}</Typography>
                    <IconButton size="small" disabled>
                        {
                            // eslint-disable-next-line
                            doctor.Visible == 1 ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )
                        }
                    </IconButton>
                </Box>
            </TableCell>
            <TableCell onClick={isMany ? menu.handleOpen : modal.handleOpen}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton size="small">
                        <MoreVert />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
        <Modal open={modal.open} doctor={doctor} handleClose={modal.handleClose} />
        <PINModal open={pinModal.open} doctor={doctor} handleClose={pinModal.handleClose} />
        <MenuDoctores menu={menu} onUpdate={modal.handleOpen} onUpdatePIN={pinModal.handleOpen} />
    </>;
}

const TableCell = withStyles({
    root: { borderBottom: "none" }
})(MuiTableCell);

const TableRow = withStyles({
    root: { borderBottom: "0.5px solid #ededed" }
})(MuiTableRow);

export default GruposDoctores;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from './dialogMessage.js';

import { cleanWarningError } from '../../redux/actions/authActions';

const AlertWarning = () => {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.auth.alert);
  const handleClose = () => dispatch(cleanWarningError())
  return (
    <Dialog
      open={!!alert}
      title={alert ? (alert.title || `¡Atención!`) : ""}
      description={alert ? alert.error : ""}
      handleClose={handleClose}
      style={{zIndex: 10000000}}
    />
  )
}

export default AlertWarning

import { useState, useEffect } from 'react';
import { isPhone } from '../util/tools';
import UseWidth from './useWidth';

const useMovil = () => {
    const width = UseWidth();
    const [phone, setPhone] = useState(isPhone());
    useEffect(() => {
        setPhone(width < 900)
    }, [ width ])
    return phone;
}

export default useMovil

import {
  PROVEEDOR_LOADING,
  PROVEEDOR_TABLE,
  PROVEEDOR_TABLE_LOADING,
  PROVEEDOR_LIST,
  PROVEEDOR_LIST_LOADING,
  PROVEEDOR_CREATE,
  PROVEEDOR_SINGLE,
  PROVEEDOR_UPDATE,
  PROVEEDOR_UPDATE_LIST,
  PROVEEDOR_CLEAN,
  PROVEEDOR_GRUPO
} from '../constans.js';
import Axios from '../../config/axios';
import AxiosV2 from '../../config/axiosv2';
import handler from './handler.js';

const setTableLoading = (payload) => ({ type: PROVEEDOR_TABLE_LOADING, payload })
const setLoading = (payload) => ({ type: PROVEEDOR_LOADING, payload })

export const updateGroups = (data, cb) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true))
  Axios.put('/proveedores/groups', data)
  .then(res => {
    dispatch({
      type: PROVEEDOR_GRUPO,
      payload: res.data
    })
    cb();
  })
  .catch(handler(dispatch))
  .finally(() => {
    dispatch(setLoading(false))
    dispatch(setTableLoading(false))
  })
}

export const getProveedorList = (params) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true))
  AxiosV2.get('/proveedor/list', { params })
  .then(res => {
    dispatch({
      type: PROVEEDOR_TABLE,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => {
    dispatch(setLoading(false))
    dispatch(setTableLoading(false))
  })
}

export const searchProveedor = (data) => dispatch => {
  dispatch({type: PROVEEDOR_LIST_LOADING, payload: true})
  Axios.get('/proveedores/search', {params: {label: data}})
  .then(res =>{
    dispatch({
      type: PROVEEDOR_LIST,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch({type: PROVEEDOR_LIST_LOADING, payload: false}))
}

export const singleProvider = (data) => dispatch => {
  dispatch(setLoading(true))
  dispatch({ type: PROVEEDOR_SINGLE, payload: true });
  
  Axios.get('/proveedores/'+(typeof data !== 'number' ? data.idProveedor : data))
  .then(res =>{
    dispatch({
      type: PROVEEDOR_SINGLE,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const createProvider = (state, uri, cb) => dispatch => {
  dispatch(setLoading(true))
  Axios.post('/proveedores/', state)
  .then(res =>
    dispatch({
      type: PROVEEDOR_CREATE,
      payload: { ...res.data, url: uri }
    })
  )
  .then(cb)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const updateProvider = (id, state, uri, cb) => dispatch => {
  dispatch(setLoading(true))
  Axios.put('/proveedores/'+id, state)
  .then(res =>
    dispatch({
      type: PROVEEDOR_UPDATE,
      payload: { ...res.data, url: uri }
    })
  )
  .then(cb)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const updateGroupList = (proveedor, grupos) => dispatch => {
  dispatch(setLoading(true))
  AxiosV2.post(`/proveedor/${proveedor.idProveedor}/grupo`, grupos)
    .then((res) => {
      dispatch({
        type: PROVEEDOR_SINGLE,
        payload: {
          ...proveedor,
          gruposproveedores: res.data
        }
      })
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const updateStatus = (id, state) => dispatch => {
  dispatch(setLoading(true))
  dispatch(setTableLoading(true))
  Axios.put('/proveedores/'+id+'/status/'+state)
  .then(() => {
    dispatch({
      type: PROVEEDOR_UPDATE_LIST,
      payload: id
    })
  })
  .catch(handler(dispatch))
  .finally(() => {
    dispatch(setLoading(false))
    dispatch(setTableLoading(false))
  })
}

export const clearProviders = () => ({ type: PROVEEDOR_CLEAN })
export const clearProvider = () => ({ type: PROVEEDOR_SINGLE, payload: null })

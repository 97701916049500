import React, { useMemo, useContext } from 'react';
import clsx from 'clsx';

// UI
import Hidden from '@mui/material/Hidden';

// Components
import { Aside, Sticky, AsideDrawer } from './index';
import { containerList } from '../components';
import { ConfiguracionContext } from '../hooks/context';

// Hooks
import useStyles from './styles';

const BaseComponent = () => (<div><span></span></div>);

function Container() {
    const classes = useStyles();
    const { state, drawer } = useContext(ConfiguracionContext);
    
    const Content = useMemo(() => {
        const { Component } = (containerList.find(item => item.label === state.container) || { Component: BaseComponent })
        return <Component />
    }, [ state.container ])

    return (
        <div className="h-100 flex">
            <Hidden mdDown>
                <Sticky {...state} />
                <div className={classes.drawerPaper}>
                    <Aside />
                </div>
                <main className={clsx(classes.content, classes.contentShift)}>
                    <div className={classes.contentHeight}>
                        {Content}
                    </div>
                </main>
            </Hidden>
            <Hidden mdUp>
                <Sticky {...state} drawer={drawer} />
                <div className="flex h-100 grow column">
                    <AsideDrawer />
                    <div className={classes.contentHeight}>
                        {Content}
                    </div>
                </div>
            </Hidden>
        </div>
    );
}

export default Container
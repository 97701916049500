import React from 'react'

// Components
import Loading from '../../util/circleLoading';
import ToolTip from '../../util/tooltip.js';
import Header from '../surfaces/header';
import Question from '../surfaces/questions';
import ModalQuestion from '../surfaces/questions/modal';

// UI
import { Grid } from '@mui/material';

// Data
import useQuestions from '../hooks/useQuestions';


const CuestionarioContainer = ({ select, loading, questions, onSelectBack, onUpdate: onUpdateCuestionario, onDestroy: onDestroyCuestionario }) => {
    const { modal, question, onSelect, onUpdate, onCreate, onDestroy, onClose } = useQuestions(select);
    return !select ? <Loading text="Cargando cuestionario" />
        : (
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Header single={select} length={questions.length} onCreateQuestion={modal.handleOpen} onUpdate={onUpdateCuestionario} onSelectBack={onSelectBack} onDestroy={onDestroyCuestionario} />
                </Grid>
                <div className="flex grow column mb">
                    {questions.map((question, index) =>
                        <Question
                            data={question}
                            index={index}
                            lastIndex={index === questions.length - 1}
                            nextTitle={questions[index + 1] ? questions[index + 1].tipo === "title" : null}
                            key={question.idPregunta}
                            onSelect={onSelect}
                            onDestroy={onDestroy}
                        />)}
                </div>
                <ToolTip handleOpen={modal.handleOpen} title="Nueva Pregunta" color="primary" />
                <ModalQuestion open={modal.open} loading={loading} single={question} handleClose={onClose} handleUpdate={onUpdate} handleCreate={onCreate} />
            </Grid>
        )
}

export default CuestionarioContainer;
import React from 'react';

// UI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import CamposHeader from './header';
import ModalCampos from './modal';
import FieldForm from './item'
import ToolTip from '../../../../../components/util/tooltip';

// Hooks
import useCampos from '../hooks/useCampos'
import { Typography } from '@mui/material';

const Campos = ({ title, idSuperCategoria }) => {
    const { modal, state, loading, hasChanges, onChange, onSwipe, onDestroy, onSubmit } = useCampos(idSuperCategoria);
    const isEmpty = state && !state.length;
    return (
        <div className="flex column">
            <CamposHeader title={title} onCreate={modal.handleOpen} />
            <div className="flex grow column my">
                {state.map((field, index) =>
                    <FieldForm
                        key={index}
                        index={index}
                        firstIndex={!index}
                        lastIndex={index === state.length - 1}
                        field={field}
                        onChange={onChange}
                        onSwipe={onSwipe}
                        onDestroy={onDestroy}
                    />
                )}
            </div>
            {isEmpty ?
                <div className="flex center">
                    <Typography variant="body1" align="center" color="textSecondary">No hay campos registrados</Typography>
                </div>
                :
                <div className="flex grow end right px">
                    <div className="relative">
                        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!hasChanges || loading}>
                            Guardar
                        </Button>
                        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                    </div>
                </div>
            }
            <ModalCampos {...modal} idSuperCategoria={idSuperCategoria} />
            <ToolTip handleOpen={modal.handleOpen} title="Nuevo campo" color="primary" />
        </div>
    );
}

export default Campos;
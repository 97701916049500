import React from 'react';

import Typography from '@mui/material/Typography';
import Menu from '../../../util/menu'
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import ModalConfirm from '../../../util/confirmDialog';

// Hooks
import useOpen from '../../../hooks/useOpen';

const EstadoSelect = ({ anchorEl, istitle, handleUpdate, handleDestroy, handleClose }) => {
    const modal = useOpen();
    const handleClick = () => { handleDestroy(); modal.handleClose(); };
    const handleModalClose = () => { modal.handleClose(); handleClose(); };
    return (
        <>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleUpdate}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Editar</Typography>
                </MenuItem>
                <MenuItem onClick={istitle ? handleDestroy : modal.handleOpen}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Eliminar</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <CancelIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Cancelar</Typography>
                </MenuItem>
            </Menu>
            <ModalConfirm
                open={modal.open}
                exit={true}
                text="Eliminar"
                title="Si continua se borrarán todas las respuestas realizadas a esta pregunta. Esta acción es irreversible"
                handleClick={handleClick}
                handleClose={handleModalClose}
            />
        </>
    )
}

export default EstadoSelect;
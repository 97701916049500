import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import handler from "../../redux/actions/handler";
import useRoles from "../../swr/hooks/useRoles";

const usePermisos = () => {
    const dispatch = useDispatch()
    const TipoDeUsuario = useSelector(state => state.auth.user.TipoDeUsuario);
    const { data: roles } = useRoles();

    const getPermisoAvailable = useCallback(code => {
        const isAdmin = !TipoDeUsuario;
        return isAdmin || (roles || []).some((item) => item.idAccion === code);
    }, [roles, TipoDeUsuario])

    const is = ({ code }) => !getPermisoAvailable(code);
    const has = ({ code }) => {
        if (getPermisoAvailable(code)) return true;
        warning()
    }

    const warning = (message = "No tienes permisos para realizar esta accion") => {
        handler(dispatch)({ message })
    }

    return {
        is,
        has,
        warning
    }
}

export default usePermisos;
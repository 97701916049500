import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { isAllowed } from '../../util/tools';
import { makeLogin } from '../../../redux/actions/authActions';

const UsernameRegex = /[^A-Za-zá-üÀ-ÿ0-9_.:\u00f1\u00d1 @]+/g
const defaultState = { username: "", password: "" };

const useLogin = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.user);
    const loading = useSelector(state => state.auth.loading)
    const [ state, setState ] = useState(defaultState);
    const [ error, setError ] = useState(defaultState);

    useEffect(function(){
        if(auth){
            if(history.location.state && history.location.state.continueTo){
                history.push(history.location.state.continueTo)
            } else {
                history.push("/")
            }
        }
    }, [ history, auth ])

    const onChange = ({ target }) => {
        setState(state => ({ ...state, [target.name]: target.value }));
        setError(state => ({...state, [target.name]: "" }));
    }

    const onSubmit = e => {
        e.preventDefault();
        
        const errors = {...error};
        const name = state.username.replace(' :', ':').replace(': ', ':').trim(); // Split "Fernando:User" and gets Fernando
        const allow = isAllowed(state.password);

        if(name.length < 4){
            errors.username = 'El nombre es muy corto.';
        }
        if(state.password.trim().length < 6){
            errors.password = 'La contraseña es muy corta.';
        }
        if(UsernameRegex.test(name)){
            errors.username = 'El nombre contiene carácteres no permitidos.';
        }
        if(!allow.sucess){
            errors.password = allow.error;
        }
        
        if(errors.password.length || errors.username.length){
            return setError(errors);
        }
        
        dispatch(makeLogin(state));
    }

    return {
        state,
        error,
        loading,
        onChange,
        onSubmit,
    }
}

export default useLogin
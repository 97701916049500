import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { destroySyncSmtp, createSyncSmtp, updateSyncSmtp, testSyncSmtp, getSyncSmtp } from '../provider/actions.js';
import handler from '../../../redux/actions/handler.js';
import useOpen from '../../hooks/useOpen.js';

const defaultState = { host: "", email: "", password: "", secure: false, port: "465" }
const UseSMTP = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState(defaultState);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const testmodal = useOpen();
    const editable = useOpen();

    useEffect(function(){
        getSyncSmtp()
        .then(res => setData(res.data))
        .catch(handler(dispatch))
        .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [])
    
    const onChangeCheck = ({ target }) => setState({ ...state, error: null, secure: target.checked })
    const onChange = ({ target }) => setState({ ...state, error: null, [target.name]: target.value })
    const onSubmit = (e) => {
        e.preventDefault();
        
        let error = null;
        if(state.host.length < 4){ error = "El host debe ser mayor a 4 carácteres";}
        if(state.email.length < 4){ error = "El nombre debe ser mayor a 4 carácteres";}
        if(state.password.length < 6){ error = "La contraseña no debe ser menor a 6 carácteres";}
        if(state.password.length > 50){ error = "La contraseña no debe ser mayor a 50 carácteres";}
        if(!state.port){ error = "Debe especificar un puerto";}

        if(error){
            setState({...state, error})
        } else {
            if(!editable.open){
                onSyncData({host:state.host, email:state.email, password:state.password, port:""+state.port, secure: !!state.secure});
            } else {
                onUpdateData({host:state.host, email:state.email, password:state.password, port:""+state.port, secure: !!state.secure});
            }
            editable.handleClose();
        }
    }

    const onComplete = ({ name, host, port, secure }) => setState({ ...state, name, host, port, secure, error: null })
    
    const onSyncData = (data) => {
        setLoading(true);
        createSyncSmtp(data)
        .then(res => setData(res.data))
        .catch(handler(dispatch))
        .finally(() => setLoading(false))
    }

    const onUpdateData = (data) => {
        setLoading(true);
        updateSyncSmtp(data)
        .then(res => setData(res.data))
        .catch(handler(dispatch))
        .finally(() => setLoading(false))
    }

    const onTest = () => {
        testSyncSmtp()
        .then(testmodal.handleOpen)
        .catch(handler(dispatch))
    }

    const onDestroy = () => {
        destroySyncSmtp()
        .catch(handler(dispatch))
        setData(null)
        onCancel();
    }

    const onEdit = () => {
        setState({ password: "", host: data.SmtpHost, email: data.Nombre, secure: data.secure, port: data.Puerto });
        editable.handleOpen();
    }
    const onCancel = () => {
        setState(defaultState);
        editable.handleClose();
    }

    return {
        data,
        state,
        loading,
        editable: editable.open,
        testmodal,
        onChangeCheck,
        onComplete,
        onChange,
        onSubmit,
        onTest,
        onEdit,
        onCancel,
        onDestroy,
    }
}

export default UseSMTP;
import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import handler from '../../../redux/actions/handler';
import axios from '../../../config/axiosv2';
import { setEstado, getEstados, createEstado, updateEstado, updateEstadoOrden, destroyEstado } from '../provider/actions';

import useOpen from '../../hooks/useOpen';
import { toColor } from '../../util/tools';

export default function UseEstados() {
    const dispatch = useDispatch();
    const { list, single, loading } = useSelector(state => state.states);
    const modal = useOpen()

    const data = useMemo(function(){
        if(!(list && list.length)){
            return [];
        }
        return list.sort((a, b) => a.indiceDeOrden - b.indiceDeOrden)
    }, [list])

    const onCreate = (data, cb) => dispatch(createEstado(data, cb));
    const onUpdate = (id, data, cb) => dispatch(updateEstado(id, data, cb));
    const onDestroy = (id, cb) => dispatch(destroyEstado(id, cb));
    const onSwipe = (id, up) => dispatch(updateEstadoOrden(id, up));
    const onSelect = (item) => {
        dispatch(setEstado(item))
        modal.handleOpen();
    }
    const onDestroyCache = () => {
        axios.post('/cache', {url: "ESTADOS"})
        .then(() => dispatch(getEstados()))
        .catch(handler(dispatch))
    }
    const getComputedStyle = useCallback(color => {
        return { width: 16, height: 16, color: toColor(color), backgroundColor: toColor(color) }
    }, [])
    
    return {
        list: data,
        modal,
        single,
        loading,
        onSwipe,
        onSelect,
        onCreate,
        onUpdate,
        onDestroy,
        onDestroyCache,
        getComputedStyle
    }
}
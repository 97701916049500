import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPreguntas } from '../../cuestionario/provider/actions';

function usePreguntas(single) {
    const dispatch = useDispatch();
    const { questions } = useSelector(state => state.cuestionario);

    useEffect(function() {
        if(single){ dispatch(getPreguntas(single.idCuestionario)) }
        //eslint-disable-next-line
    }, [ single ])

    const data = useMemo(function(){
        if(!single) return [];
        return (questions || []).filter(item => item.idCuestionario === single.idCuestionario)
    }, [ single, questions ])

    return data
}

export default usePreguntas
import React from 'react';
import clsx from 'clsx';

import SvgIcon from '@mui/material/SvgIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import {ReactComponent as ClipBoard} from '../../../assets/svgs/clipboard-list-check.svg';

const Cuestionarios = ({ label, active, onSelect }) => {
    const onClick = () => onSelect(label);
    return (
        <ListItemButton className={clsx({ active })} onClick={onClick}>
            <ListItemIcon>
                <SvgIcon fontSize="small">
                    <ClipBoard />
                </SvgIcon> 
            </ListItemIcon>
            <ListItemText disableTypography>
                <Typography>Cuestionarios</Typography>
            </ListItemText>
        </ListItemButton>
    );
}

export default Cuestionarios;
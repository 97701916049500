import React from 'react'
import clsx from 'clsx';

// UI
import { Typography } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';

// Components
import { TextInputField } from './textwrapper';

// Hooks
import useOpen from '../../../hooks/useOpen';

const Title = ({ title, istitle, onChange }) => {
    const editable = useOpen();
    const onBlur = ({ target }) => {
        editable.handleClose();
        const value = target.value.trim();
        if(value.length){
            onChange(value);
        }
    }
    return (
        !editable.open ?
            <div className={clsx(istitle ? "bg-primary" : "", "flex grow middle")} onClick={editable.handleOpen}>
            {
                istitle ? 
                    <div className="flex grow text-white middle">
                        <BookmarkIcon />
                        <Typography variant="subtitle1" className="mx-m">{title}</Typography>
                    </div>
                :
                    <Typography><strong>{title}</strong></Typography>
            }
            </div>
        :
            <TextInputField
                size="small"
                fullWidth
                autoFocus
                defaultValue={title}
                onBlur={onBlur}
                className="text-white"
            />
    )
}

export default Title;
import { useEffect, useMemo, useState } from "react";
import useGrupos from "../../../swr/hooks/useGrupos";
import { Laboratorio } from "../util";

const useSelectGroups = (open, selectedList, isProvider) => {
  const { gruposPacientes, gruposProveedores, isLoading } = useGrupos();
  const [list, setList] = useState({});

  useEffect(() => {
    if (open && selectedList && selectedList.length) {
      const keys = {};
      selectedList.forEach((group) => (keys[group.idGrupo] = true));
      setList(keys);
    }
  }, [open, selectedList]);

  const groups = useMemo(() => {
    return (
      isProvider
        ? [Laboratorio, ...gruposProveedores]
        : gruposPacientes
    ).map((grupo) => ({
      ...grupo,
      checked: list[grupo.idGrupo],
    }));
  }, [isProvider, list, gruposPacientes, gruposProveedores]);

  const onSelect = ({ idGrupo }) => {
    setList((oldState) => ({
      ...oldState,
      [idGrupo]: oldState[idGrupo] ? false : true,
    }));
  };
  const onClean = () => setList({});

  return {
    list: groups,
    loading: isLoading,
    onSelect,
    onClean,
  };
};

export default useSelectGroups;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Grid } from '@mui/material';
import Modal from '../util/modal.js'
import NuevoGrupoForm from '../grupos/newForm.js';

import { createGroup } from '../../redux/actions/grupoActions.js';
import { useHandleChange, useHandleColor } from '../hooks/useChange.js';

const defaultState = {
  name: '',
  color: '#ffffff'
}

const NuevoGrupo = ({ open, handleClose, alternative }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(defaultState);
  const [error, setError] = useState(null)
  const loading = useSelector(state => state.group.loading);

  const handleChange = useHandleChange(setState)
  const handleColor = useHandleColor(setState);

  const onClose = () => {
    setState(defaultState)
    setError(null);
    handleClose();
  }

  const onSubmit = e => {
    e.preventDefault();
    if(state.name.trim().length <= 1){
      return setError('El nombre del grupo es muy corto.')
    }
    
    setError(null);
    dispatch(createGroup({...state, alter: alternative}, onClose, alternative));
  }

  const Buttons = (
    <Grid item container justifyContent="flex-end" className="mb-m mt">
      <Button size="small" color="secondary" onClick={handleClose} className="mr-m">
        Cancelar
      </Button>
      <div className="relative">
        <Button size="small" color="primary" variant="contained" onClick={onSubmit} disabled={loading}>
          Guardar
        </Button>
        {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
      </div>
    </Grid>
  )

  return (
    <Modal open={open} buttons={Buttons} handleClose={onClose}>
      <NuevoGrupoForm
        state={state}
        error={error}
        alternative={alternative}
        handleColor={handleColor}
        handleChange={handleChange}
        handleSubmit={onSubmit}
      />
    </Modal>
  )
}

export default NuevoGrupo

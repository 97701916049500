import { SWRConfig } from "swr"

const SWRContainer = ({ children }) => {
    return (
        <SWRConfig
            value={{
                provider: () => new Map(),
                refreshInterval: 0,
                errorRetryCount: 5,
                revalidateOnFocus: false,
            }}
        >
            {children}
        </SWRConfig>
    )
}

export default SWRContainer
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './skeleton.css';

const ItemLoading = () => {
  return [
    <li key={1} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={2} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={3} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={4} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={5} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={6} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>,
    <li key={7} className="_list">
      <div className="_avatar">
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </div>
      <div className="_body">
        <Skeleton animation="wave" height={25} width="70%" />
        <Skeleton animation="wave" height={16} width="50%" />
      </div>
    </li>
  ];
}

export default ItemLoading

import React from 'react';

import Menu from '../../util/menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const MenuCuestionario = ({ anchorEl, handleUpdate, handleRename, handleClose }) => {
    return (
        <Menu
            open={anchorEl !== null}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl}
            transformOrigin={{horizontal:0, vertical: -15}}
            onClose={handleClose}
        >
            <MenuItem onClick={handleRename}>
                <Typography>Cambiar Nombre</Typography>
            </MenuItem>

            <MenuItem onClick={handleUpdate}>
                <Typography>Editar Preguntas</Typography>
            </MenuItem>
        </Menu>
    )
};

export default MenuCuestionario;

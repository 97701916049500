import React from "react";
import { useSelector } from "react-redux";

// UI
import { Box } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { Block } from "@mui/icons-material";

// Hooks
import useAnchorEl from "../../../../../components/hooks/useAnchorEl";

import Menu from "./menu";

const Usuarios = ({ user, disabled, administradores }) => {
  const menu = useAnchorEl(true);
  const userLogin = useSelector((state) => state.auth.user);
  const isAdmin = !userLogin.TipoDeUsuario;

  const onOpenMenu = (e) => {
    if (isAdmin || user.idUsuario === userLogin.idUsuario) {
      menu.handleOpen(e);
    }
  };

  return (
    <>
      <TableRow hover className="pointer" onClick={onOpenMenu}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              className={disabled && "text-ground"}
            >
              {user.Nombre}
            </Typography>
            {isAdmin &&
              (disabled && (
                <>
                  <Block color="secondary" fontSize="small" className="mx-m" />
                  <Typography
                    variant="body2"
                    className={"text-ground"}
                  >
                    (No disponible)
                  </Typography>
                </>
              ))}
          </Box>
        </TableCell>
      </TableRow>
      <Menu user={user} administradores={administradores} {...menu} />
    </>
  );
};

const TableCell = withStyles({
  root: { borderBottom: "none" },
})(MuiTableCell);

const TableRow = withStyles({
  root: { borderBottom: "0.5px solid #ededed" },
})(MuiTableRow);

export default Usuarios;

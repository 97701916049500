import React from "react";

import Appbar from "../../../surfaces/appbar";
import Plantilla from "./surfaces/container";
import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import useOpen from "../../../../components/hooks/useOpen";
import { useLocation } from "react-router-dom";

const PlantillasWhastapp = () => {
  const location = useLocation();
  const modal = useOpen(location.state && location.state.modal);
  return (
    <div>
      <Appbar
        title="Indicaciones"
        className="mb-m"
        rightIconComponent={
          <Tooltip arrow placement="left" title="Nueva Plantilla">
            <IconButton color="primary" onClick={modal.handleOpen} size="large">
              <Add
                style={{
                  backgroundColor: "#014990",
                  color: "white",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
          </Tooltip>
        }
      />
      <Plantilla modal={modal} />
    </div>
  );
};

export default PlantillasWhastapp;

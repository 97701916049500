import {
  AGENDA_LOADING,
  CITAS_UPDATE_MEDIO,
  PACIENTE_LOADING,
  PACIENTE_UPDATE
} from '../../../redux/constans';
import {
  ESTADOS_CREATE,
  ESTADOS_DELETE,
  ESTADOS_LIST,
  ESTADOS_LOADING,
  ESTADOS_SINGLE,
  ESTADOS_UPDATE
} from './constants.js';


import axios from '../../../config/axiosv2';
import handler from '../../../redux/actions/handler.js';

const setLoading = (value) => ({ type: ESTADOS_LOADING, payload: value })
const defaultCallback = () => null;

export const getEstados = () => (dispatch) => {
  dispatch(setLoading(true))
  axios.get("/estados")
  .then(res => dispatch({ type: ESTADOS_LIST, payload: res.data }))
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const createEstado = (data, callback = defaultCallback) => (dispatch) => {
  dispatch(setLoading(true))
  axios.post("/estados", data)
  .then(res => dispatch({ type: ESTADOS_CREATE, payload: res.data }))
  .then(callback)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const updateEstadoOrden = (id, up) => (dispatch) => {
  dispatch(setLoading(true))
  axios.put(`/estados/${up ? "up" : "down"}/${id}`)
  .then(() => dispatch(getEstados()))
  .catch(e => {
    handler(dispatch)(e)
    dispatch(setLoading(false))
  })
}

export const updateEstado = (id, data, callback = defaultCallback) => (dispatch) => {
  dispatch(setLoading(true))
  axios.put(`/estados/${id}`, data)
  .then(res => dispatch({ type: ESTADOS_UPDATE, payload: res.data }))
  .then(callback)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const updateEstadoPacientes = (pacientes, estado) => {
  return Promise.all(pacientes.map(paciente => axios.put(`/estados/${estado}/paciente/${paciente.idPaciente}`)))
}

export const destroyEstado = (id, callback = defaultCallback) => (dispatch) => {
  dispatch(setLoading(true))
  axios.delete(`/estados/${id}`)
  .then(() => dispatch({ type: ESTADOS_DELETE, payload: id }))
  .then(callback)
  .catch(handler(dispatch))
  .finally(() => dispatch(setLoading(false)))
}

export const updatePacienteEstado = (paciente, idEstado, callback = defaultCallback) => (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch({ type: PACIENTE_LOADING, payload: true })
  dispatch({ type: AGENDA_LOADING, payload: true })

  axios.put(`/estados/${idEstado}/paciente/${paciente.idPaciente}`)
  .then(res => {
    dispatch({
      type: CITAS_UPDATE_MEDIO,
      payload: { idPaciente: paciente.idPaciente, paciente: res.data }
    })
    const data = getState().pacient.single;
    if(data){
      dispatch({
        type: PACIENTE_UPDATE,
        payload: {...data, idEstadoPersonalizado: res.data.idEstadoPersonalizado }
      })
    }
  })
  .then(callback)
  .catch(handler(dispatch))
  .finally(() => {
    dispatch(setLoading(false))
    dispatch({ type: PACIENTE_LOADING, payload: false })
    dispatch({ type: AGENDA_LOADING, payload: false })
  })
}

export const setEstado = (data) => ({ type: ESTADOS_SINGLE, payload: data })
export const clearEstados = () => ({ type: ESTADOS_LIST, payload: null })
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// UI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Components
import useMovil from '../../../../../components/hooks/useMovil';
import Modal from '../../../../../components/util/modal';
import Axios from '../../../../../config/axiosv2';
import handler from '../../../../../redux/actions/handler';
import useCamposExtras from '../../../../../swr/hooks/useCamposExtras';

const Campos = ({ idSuperCategoria, open, handleClose }) => {
    const dispatch = useDispatch();
    const { mutate } = useCamposExtras();
    const [isSaving, setIsSaving] = useState(false);
    const [ state, setState ] = useState({ label: '', error: false })
    const isPhone = useMovil();

    const onChange = ({ target }) => {
        setState({ error: false, [target.name]: target.value });
    }
    const onClose = () => {
        setState({ label: '' })
        handleClose();
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        if(!state.label.trim().length){
            return setState({...state, error: true})
        }

        try {
            setIsSaving(true)
            await Axios.post(`/campos/${idSuperCategoria}`, { value: state.label })
            mutate();
            onClose()
        } catch (error) {
            handler(dispatch)(error)
        } finally {
            setIsSaving(false)
        }
        
    }

    const Buttons = (
        <Grid item container justifyContent="flex-end" className="mb-m mt">
            <Button color="secondary" onClick={onClose} className="mr-m">
                Cancelar
            </Button>
            <div className="relative">
                <Button color="primary" variant="contained" onClick={onSubmit} disabled={isSaving}>
                    Guardar
                </Button>
                {isSaving && <div className="re-loading"><CircularProgress size={22} /></div>}
            </div>
        </Grid>
    )

    return (
        <Modal open={open} buttons={Buttons} handleClose={onClose}>
            <Grid component="form" className="modal-form" container onSubmit={onSubmit}>
                <Grid item xs={12} className="mb">
                    <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
                        Nuevo Campo
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="search"
                        size="small"
                        name="label"
                        autoComplete="off"
                        label="Nombre del campo"
                        value={state.label}
                        error={state.error}
                        helperText={state.error ? 'El campo no puede estar vacio' : ''}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}

export default Campos;
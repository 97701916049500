import React, { createContext } from 'react';
import useOpen from '../hooks/useOpen.js';

export const DrawerContext = createContext();
function NavbarProvider({ children }) {
  const menu = useOpen();
  return (
      <DrawerContext.Provider value={menu}>
          {children}
      </DrawerContext.Provider>
  );
}

export default NavbarProvider;
import {
  CUENTAS_COBRAR,
  CUENTAS_COBRAR_LOADING,
  CUENTAS_PAGOS,
  CUENTAS_PAGOS_LOADING,
  PROVEEDORES_PAGOS,
  PROVEEDORES_PAGOS_LOADING,
  CUENTAS_PAGAR_LOADING,
  CUENTAS_PAGAR,
  CUENTAS_INGRESOS_LOADING,
  CUENTAS_INGRESOS,
  CUENTAS_PRESTACIONES_LOADING,
  CUENTAS_PRESTACIONES,
  PRESTACIONES_SEARCH_LOADING,
  PRESTACIONES_SEARCH,
  PACIENTES_NUEVOS,
  PACIENTES_NUEVOS_LOADING,
  PACIENTES_CITAS,
  PACIENTES_CITAS_LOADING,
  PACIENTES_FALTARON,
  PACIENTES_FALTARON_LOADING,
  PACIENTES_NUEVOS_LIST_LOADING,
  PACIENTES_NUEVOS_LIST,
  PACIENTES_DEUDORES,
  PACIENTES_DEUDORES_LOADING,
  PACIENTES_VINIERON,
  PACIENTES_VINIERON_LOADING,
  PACIENTES_INGRESOS,
  PACIENTES_INGRESOS_LOADING,
  PACIENTES_EGRESOS_LOADING,
  PACIENTES_EGRESOS,
  CONTROL_REPORTE,
  CONTROL_LOADING,
  CLEAN_ALL_REPORTS
} from '../constans.js';

const initialState = {
  cobrar: {},
  pagar: {},
  nuevos: null,
  proveedores: null,
  prestaciones: {},
  pacientes: {},
  citas: {},
  deudores: {},
  control: {},
  faltaron: null,
  vinieron: null,
  pagos: null,
  egresos: null,
  ingresos: null,
  generado: null,
  search: {loading: false, list: null},
  loading: false
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case CUENTAS_PAGOS_LOADING:
    case CUENTAS_COBRAR_LOADING:
    case PROVEEDORES_PAGOS_LOADING:
    case CUENTAS_PAGAR_LOADING:
    case CUENTAS_INGRESOS_LOADING:
    case CUENTAS_PRESTACIONES_LOADING:
    case PACIENTES_NUEVOS_LOADING:
    case PACIENTES_CITAS_LOADING:
    case PACIENTES_FALTARON_LOADING:
    case PACIENTES_VINIERON_LOADING:
    case CONTROL_LOADING:
    case PACIENTES_NUEVOS_LIST_LOADING:
    case PACIENTES_DEUDORES_LOADING:
    case PACIENTES_INGRESOS_LOADING:
    case PACIENTES_EGRESOS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CUENTAS_COBRAR:
      return {
        ...state,
        cobrar: action.payload,
      }
    case CUENTAS_PAGOS:
      return {
        ...state,
        pagos: action.payload,
      }
    case PROVEEDORES_PAGOS:
      return {
        ...state,
        proveedores: action.payload,
      }
    case CUENTAS_PAGAR:
      return {
        ...state,
        pagar: action.payload,
      }
    case CUENTAS_INGRESOS:
      return {
        ...state,
        generado: action.payload,
      }
    case CUENTAS_PRESTACIONES:
      return {
        ...state,
        prestaciones: action.payload,
      }
    case PRESTACIONES_SEARCH_LOADING:
      return {
        ...state,
        search: {...state.search, loading:action.payload}
      }
    case PRESTACIONES_SEARCH:
      //const newList = state.search.list ? [...state.search.list, ...action.payload] : action.payload
      return {
        ...state,
        search: {loading: false, list: action.payload}
      }
    case PACIENTES_NUEVOS:
      return {
        ...state,
        pacientes: action.payload,
      }
    case PACIENTES_CITAS:
      return {
        ...state,
        citas: action.payload,
      }
    case PACIENTES_FALTARON:
      return {
        ...state,
        faltaron: action.payload,
      }
    case PACIENTES_VINIERON:
      return {
        ...state,
        vinieron: action.payload,
      }
    case PACIENTES_NUEVOS_LIST:
      return {
        ...state,
        nuevos: action.payload,
      }
    case PACIENTES_DEUDORES:
      return {
        ...state,
        deudores: {
          list: action.payload.list,
          count: Math.ceil(action.payload.count / action.payload.limit),
          limit: action.payload.limit
        },
      }
    case CONTROL_REPORTE:
      return {
        ...state,
        control: {
          list: action.payload.List,
          count: Math.ceil(action.payload.Count / action.payload.Limit),
          limit: action.payload.Limit
        }
      }
    case PACIENTES_INGRESOS:
      return {
        ...state,
        ingresos: action.payload
      }
    case PACIENTES_EGRESOS:
      return {
        ...state,
        egresos: action.payload
      }
    case CLEAN_ALL_REPORTS:
      return initialState;
    default:
      return state;
  }
}

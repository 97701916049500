import axios from 'axios';

import { parseStricDate as fixDate, getZero, months, week } from '../../util/tools';
import { baseURL } from '../hooks/context';
import { toLetter } from './util';
const options = {0: 'Efectivo', 4: 'Transferencia'}

function getSaldo(data, money){
    let saldo = 0;
    let array = [...(data.tratamientos || []), ...(data.recibos || [])]
    for (let i = 0; i < array.length; i++) {
      if(array[i].idRecibo){
        if(money){
          saldo-=(array[i].moneda_de_los_tratamientos === money) ? array[i].ImporteDeLosTratamientos : 0;
        } else {
          saldo-=array[i].ImporteDeLosTratamientos;
        }
      } else {
        if(array[i].Estado !== 1){
          if(money){
            saldo+=(array[i].id_moneda === money) ? array[i].Importe : 0;
          } else {
            saldo+=array[i].Importe;
          }
        }
      }
    }
    return saldo.toFixed(2);
}

export const template = ({ item, state, monedas, reprint }) => {
  const date = fixDate(item.Fecha);
  const moneda = monedas.find(e => e.id_moneda === item.moneda_del_recibo) || monedas[0];
  const vars = [
      { name: "#NombrePaciente#", value: state.pacient.single.nombre },
      { name: "#ddddd#", value: week[date.getDay()] },
      { name: "#dd#", value: getZero(+date.getDate()) },
      { name: "#mmmmm#", value: months[+date.getMonth()] },
      { name: "#mm#", value: getZero(+date.getMonth() + 1) },
      { name: "#aaaa#", value: date.getFullYear() },
      { name: "#aa#", value: (""+date.getYear()).slice(1) },
      { name: "#nroPago#", value: item.NroRecibo },
      { name: "#FormaDePago#", value: options[item.FormaDePago] }, 
      { name: "#Concepto#", value: item.Concepto || "" }, 
      { name: "#moneda#", value: moneda.Simbolo },
      { name: "#_moneda#", value: moneda.Nombre },
      { name: "#importe#", value: (+item.ImporteDeLosTratamientos).toFixed(2) }, 
      { name: "#_importe#", value: toLetter(+item.ImporteDeLosTratamientos) },
      { name: "#saldo#", value: reprint ? "(Reimpresión)" : getSaldo(state.account, moneda.id_moneda) }
  ]
  return vars;
}

export const getVars = (props) => {
  return axios.put(baseURL, template(props), { responseType: 'blob', headers: {'x-auth': props.state.auth.token} })
}
import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useOpen from '../../hooks/useOpen';

// Components
import handler from '../../../redux/actions/handler';
import axios from '../../../config/axiosv2';

// data
import { plantillas, variables } from '../data/correo.json';

const UsePlantillaCorreo = () => {
    const dispatch = useDispatch();
    const ref = useRef();
    const [loading, setLoading] = useState(true);
    const [nochanges, setNoChanges] = useState(true);
    const [state, setState] = useState(null);
    const [plantilla, setPlantilla] = useState(plantillas[3]);

    const success = useOpen();

    useEffect(function(){
        setLoading(true)
        axios.get(`/plantillas/correo/${plantilla.idPlantilla}`, { params: {idPaciente: -1} })
        .then(res => {
            if(res.data){
                setState({ subject: res.data.asunto, body: res.data.plantilla })
            }
        })
        .catch(handler(dispatch))
        .finally(()=> setLoading(false))
        // eslint-disable-next-line
    }, [ plantilla ])

    const options = useMemo(function(){
        if(plantilla.idPlantilla === 2 || plantilla.idPlantilla === 6) return variables;

        return variables.slice(0, 2)
    }, [ plantilla ])

    const onChangePlantilla = ({ target }) => {
        setPlantilla(plantillas.find(item => item.idPlantilla === (+target.value)))
        setState(null);
        setNoChanges(true)
    }
    const onChange = (label, value) => {
        setState({...state, [label]: value })
        setNoChanges(false)
    };
    const onSubmit = () => {
        if(!state.body.trim().length && !state.subject.trim().length) return;
        
        setLoading(true)
        axios.put(`/plantillas/correo/${plantilla.idPlantilla}`, state)
        .then(() => {
            success.handleOpen()
            setNoChanges(true)
        })
        .catch(handler(dispatch))
        .finally(()=>setLoading(false))
    }
    
    const onSelect = (item) => () => {
        const replaceText = (txt) => {
            const { selectionStart: start, selectionEnd: end } = ref.current;
            if(start === end){
                if(!start){
                    return item + " " + txt;
                } else {
                    return txt.slice(0, start)+` ${item} `+txt.slice(start)
                }
            } else {
                return txt.slice(0, start)+` ${item} `+txt.slice(end)
            }
        }
        onChange('body', replaceText(state.body))
    }

    return {
        ref,
        state,
        success,
        plantilla,
        plantillas,
        nochanges,
        options,
        loading,
        onChange,
        onSelect,
        onChangePlantilla,
        onSubmit
    }
};

export default UsePlantillaCorreo;
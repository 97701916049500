import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsuarios } from '../provider/actions';

const useUsuarios = () => {
    const dispatch = useDispatch();
    const list = useSelector(state => state.users.list);
    const [usuarios, setUsuarios] = useState(null)

    useEffect(function(){
        dispatch(getUsuarios());
        // eslint-disable-next-line
    }, [])

    useEffect(function(){
        if(list) setUsuarios(list);
    }, [ list ])

    return usuarios
}

export default useUsuarios;
import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom';
import useOpen from '../../components/hooks/useOpen';

const Context = React.createContext();
const defaultState = {
    menu: '',
    container: '',
}

function Provider({ children }) {
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = React.useState(defaultState);
    const drawer = useOpen(true);

    useEffect(function(){
        if(location.state && location.state.path){
            const path = location.state.path;
            setState({ menu: path, container: path })
        }
        // eslint-disable-next-line
    }, [])

    const onSelectMenu = (menu, autoclose = true) => {
        setState({ menu, container: menu })
        if(autoclose) drawer.handleClose();
    };
    const onSelectContainer = (container, autoclose = true) => {
        setState({ ...state, container });
        if(autoclose) drawer.handleClose();
    }

    return (
        <Context.Provider value={{ state, drawer, history, onSelectMenu, onSelectContainer }}>
            {children}
        </Context.Provider>
    )
}


export const ConfiguracionContext = Context;
export default Provider;
import React from 'react';
import Fonts from '../data/fonts.json'

// UI
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

// Context
import { Context } from '../hooks/context';

const Header = () => {
    const { state, onChangeSize, onChangeFont, onChangeMargin, onChangeFontFamily } = React.useContext(Context);
    const onChangeRelative = (label) => ({ target }) => onChangeMargin({ ...state.margin, [label]: +target.value })
    const onChangeDimensions = (label) => ({ target }) => onChangeSize({ ...state.size, [label]: +target.value })
    const onChangeFonts = ({ target }) => onChangeFont(+target.value)
    const onPick = ({ target }) => onChangeFontFamily(target.value)

    return (
        <div className="flex column">
            <div className="flex grow center wrap between">
                <div>
                    <TextField id="Ancho" label="Ancho (cm)" type="number" value={state.size.width} error={state.size.width <= 0} onChange={onChangeDimensions('width')} />
                </div>
                <div>
                    <TextField id="Alto" label="Alto (cm)" type="number" value={state.size.height} error={state.size.height <= 0} onChange={onChangeDimensions('height')} />
                </div>
                <div style={{ minWidth: 182 }}>
                    <TextField id="font" label="Fuente" fullWidth select value={state.family} onChange={onPick}>
                        {Fonts.map(font => <MenuItem key={font.value} value={font.value}>{font.name}</MenuItem>)}
                    </TextField>
                </div>
                <div>
                    <TextField id="Lineas" label="Tmño. Fuente" type="number" value={state.font} error={state.font <= 0} onChange={onChangeFonts} />
                </div>
            </div>
            <div className="flex grow center wrap between mt-m">
                <div>
                    <TextField id="Margen Sup." label="Margen Superior" type="number" value={state.margin.top} error={state.margin.top < 0} onChange={onChangeRelative('top')} />
                </div>
                <div>
                    <TextField id="Margen Izq." label="Margen Izquierdo" type="number" value={state.margin.left} error={state.margin.left < 0} onChange={onChangeRelative('left')} />
                </div>
                <div>
                    <TextField id="Margen Der." label="Margen Derecho" type="number" value={state.margin.right} error={state.margin.right < 0} onChange={onChangeRelative('right')} />
                </div>
                <div>
                    <TextField id="interline" label="Interlineado" type="number" value={state.margin.bottom} error={state.margin.bottom < 0} onChange={onChangeRelative('bottom')} />
                </div>
            </div>
        </div>
    )
}

export default Header;
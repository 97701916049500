import {
  HISTORIA_LOADING,
  HISTORIA_LIST,
  HISTORIA_CREATE,
  HISTORIA_DELETE,
  HISTORIA_UPDATE
} from '../constans.js';
import Axios from '../../config/axios.js';
import handler from './handler.js';

function loader(val){
  return ({
    type: HISTORIA_LOADING,
    payload: val
  })
}

export const getHistoria = (id) => dispatch => {
  dispatch(loader(true))
  Axios.get('/historia/paciente/'+id)
  .then(res =>{
    dispatch({
      type: HISTORIA_LIST,
      payload: res.data
    })
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const makeHistoria = (id, body, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post('/historia/paciente/'+id, body)
  .then(res =>{
    dispatch({
      type: HISTORIA_CREATE,
      payload: res.data
    })
    cb();
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const updateHistoria = (id, state, cb) => dispatch => {
  dispatch(loader(true))
  Axios.put('/historia/'+id, state)
  .then(res =>{
    dispatch({
      type: HISTORIA_UPDATE,
      payload: res.data
    })
    cb();
  })
  .catch(handler(dispatch))
  .finally(() => dispatch(loader(false)))
}

export const delHistoria = (id, cb) => dispatch => {
  dispatch(loader(true))
  Axios.delete('/historia/'+id)
  .then(res =>{
    dispatch({
      type: HISTORIA_DELETE,
      payload: id
    })
  })
  .catch(handler(dispatch))
  .finally(() => {
    cb();
    dispatch(loader(false))
  })
}

export const clearHistoria = () => dispatch => {
  dispatch({
    type: HISTORIA_LIST,
    payload: null
  })
}

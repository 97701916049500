import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ModalConfirm = ({ open, title, text, exit, handleClose, handleClick }) => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-custom-title"
      aria-describedby="alert-dialog-custom-description"
      style={{minWidth: 300, zIndex: 2000}}
    >
      <DialogTitle id="alert-dialog-custom-title">¡Atención!</DialogTitle>
      <DialogContent>
        <strong>{title}</strong>
      </DialogContent>
      <DialogActions>
        {
          exit?
          <Button onClick={handleClose} size="small" color="secondary">
            Cancelar
          </Button> : null
        }
        <Button onClick={handleClick || handleClose} size="small" variant="contained" color="primary" autoFocus>
          {text}
        </Button>
      </DialogActions>
    </Dialog>
)

ModalConfirm.defaultProps = {
  text: "Aceptar",
  exit: false
}

export default ModalConfirm;

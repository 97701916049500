import React, { useState } from 'react'

// UI
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Container = (props) => {

    const [image] = useState({
        backgroundImage: 'url(https://gacetadental.com/wp-content/uploads/2019/05/clinica-dental-madrid.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    })
  
    return (
        <div className="flex grow h-100">
            <Grid container component="main">
                <Grid item xs={false} sm={4} md={7} style={image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className="flex column justify">
                        {props.children}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Container

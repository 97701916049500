import React from "react";
import useIndicaciones from "../hooks/useIndicaciones";
import Alert from "../../../../../components/util/dialogMessage";
import Loading from "../../../../../components/util/circleLoading";
import Header from "./header";
import Editor from "./editor";
import Modal from "./modal";
import { Button, CircularProgress } from "@mui/material";
import IndicacionesEmpty from "./empty";
import useWidth from "../../../../../components/hooks/useWidth";

const IndicacionesPlantillas = ({ modal }) => {
  const {
    data,
    html,
    success,
    plantilla,
    isDisabled,
    isLoading,
    editable,
    onSubmit,
    onDestroy,
    onChangeHTML,
    onUpdateClose,
    onUpdatePlantilla,
    onChangePlantilla,
    onSubmitPlantilla,
  } = useIndicaciones();

  const width = useWidth();

  if (!data) return <Loading text="Cargando Template" />;
  
  if (!plantilla) {
    return (
      <>
        <IndicacionesEmpty handleOpen={modal.handleOpen} />;
        <Modal
          open={modal.open}
          handleClose={modal.handleClose}
          handleSubmit={onSubmitPlantilla}
        />
      </>
    );
  }

  const forceWidth =
    width < 950
      ? { width: width - 10, padding: "0px 0.2rem", overflow: "hidden" }
      : { padding: "0px 1rem" };

  return (
    <>
      <div className="flex grow column my-m" style={forceWidth}>
        <div className="flex column">
          <Header
            editable
            plantilla={plantilla}
            plantillas={data}
            onUpdate={onUpdatePlantilla}
            onChangePlantilla={onChangePlantilla}
          />
          <Editor data={html} onChange={onChangeHTML} />
        </div>
        <Footer
          onDestroy={onDestroy}
          onSubmit={onSubmit}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </div>
      {editable && (
        <Modal
          open
          handleClose={onUpdateClose}
          plantilla={editable}
          handleSubmit={onSubmitPlantilla}
        />
      )}
      <Alert
        {...success}
        title="¡Atención!"
        description={`La plantilla "${plantilla.nombre}" fue actualizada con éxito.`}
      />
      <Modal
        open={modal.open}
        handleClose={modal.handleClose}
        handleSubmit={onSubmitPlantilla}
      />
    </>
  );
};

const Footer = ({ onDestroy, onSubmit, isLoading, isDisabled }) => (
  <div className="flex grow between mb-m">
    <div className="relative">
      <Button color="secondary" onClick={onDestroy}>
        Eliminar Plantilla
      </Button>
    </div>
    <div className="relative">
      <Button
        color="primary"
        variant="contained"
        disabled={isDisabled}
        onClick={onSubmit}
      >
        Guardar
      </Button>
      {isLoading && (
        <div className="re-loading">
          <CircularProgress size={22} />
        </div>
      )}
    </div>
  </div>
);

export default IndicacionesPlantillas;

import React from 'react';

// UI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

// Util
import { TipoDeUsuario } from '../../usuarios/util';

const Tipos = Object.keys(TipoDeUsuario).slice(1);
const UsuariosPermisos = ({ onSelectTipo }) => {
    const onClick = (tipo) => () => onSelectTipo(tipo);
    return (
        <Table>
            <TableHead>
                <TableRow className="bg-primary">
                    <TableCell className="text-white">
                        <Typography variant="body2"><strong>Tipos de usuarios</strong></Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Tipos.map((key, i) =>
                    <TableRow key={i} hover className="pointer" onClick={onClick({ label: TipoDeUsuario[key], type: key })}>
                        <TableCell>
                            <Typography variant="body2">{TipoDeUsuario[key]}</Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}

export default UsuariosPermisos;
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateGroupList } from '../../../redux/actions/pacienteActions';
import SelectGroups from '../../grupos/surfaces/modal';

const GroupsSelect = ({ open, paciente, handleClose }) => {
    const dispatch = useDispatch();
    const onSubmit = (groups) => {
        dispatch(updateGroupList(paciente, groups.map(item => ({ idGrupo: item.idGrupo }))))
    }
    const onClose = () => {
        handleClose();
    }
    return (
        <SelectGroups
            open={open}
            title={`Grupos de ${paciente.nombre}`}
            addedList={paciente.grupospacientes}
            handleClose={onClose}
            handleSubmit={onSubmit}
        />
    )
}

export default GroupsSelect;


export const colors = [
  '#FFFFFF', '#DDDDDD', '#C0C0C0', '#FD4700', '#FF807F', '#FFC0BE',
  '#FC8004', '#FEC085', '#FFDFC1', '#FEFD03', '#FEFF81', '#FEFFBE',
  '#01FF00', '#7FFF82', '#C1FFC0', '#16F6F5','#81FFFC','#BFFFFF',
  '#2E8BF5', '#7F82F7', '#C0C0FC', '#DD40DD', '#FF7FFD', '#FCC2FC',
];

const lettersAllowed = {
  "0": 1, "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1, "8": 1, "9": 1, "#": 1, "&": 1, "(": 1, ")": 1, "*": 1, "+": 1, ",": 1, "-": 1, ".": 1, "/": 1, "?": 1, "@": 1,
  "a": 1,"b": 1,"c": 1,"d": 1,"e": 1,"f": 1,"g": 1,"h": 1,"i": 1,"j": 1,"k": 1,"l": 1,"m": 1,"n": 1,"o": 1,"p": 1,"q": 1,"r": 1,"s": 1,"t": 1,"u": 1,"v": 1,"w": 1,"x": 1,"y": 1,"z": 1,
  "ñ": 1, "_": 1
};

export const isAllowed = (str) => {
  const noncharacter = str.toLowerCase().split('').filter(item => {
    return !lettersAllowed[item]
  })[0];
  return { sucess: !noncharacter, error: `Esta utilizando un simbolo no permitido, los unicos posibles son: _.-()/@,*?+#&` }
}

/*
* Funciones auxiliares para las fechas
* Meses del ano*
* Dias de la semana *
* Dias del mes *
* Obtener el dia con 0's
* Obtener el dia lunes
* Parsear la fecha de la cita.
*/

export const filterString = {'year': 'año','month': 'mes', 'day': 'dia'};
export const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio','Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export const monthsShort = ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.']
export const week = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
export const weekShort = ['Dom.', 'Lun.', 'Mar.', 'Mie.', 'Jue.', 'Vie.', 'Sab.'];
export const daysOfMonth = (mes, año) => new Date(año, mes, 0).getDate();
export const daysOf = d => new Date(d.getFullYear(), d.getMonth()+1, 0).getDate()
export const getZero = n => n <= 9 ? '0'+n : n
export const addZero = n => n <= 9 ? '0'+n : n

export const getMonday = (d) => {
  const day = d.getDay()
  const diff = d.getDate() - day + (day === 0 ? -6:1);
  d.setDate(diff)
  return d;
}
export const getInitialDate = (date) => {
  if(date.getDay() === 1) return date;
  return getMonday(new Date(date.getTime()))
}
export const getFormatDate = (date, plus = 0, str = '-') => `${getZero(date.getDate())}${str}${getZero(+date.getMonth() + plus)}${str}${date.getFullYear()}`
export const getFormatDateReverse = (str) => {
  return new Date(
    +str.slice(6),
    +str.slice(3,5),
    +str.slice(0,2),
  );
}
export const formatSecond = (body, hour, min, plus = 0) => {
  const d = new Date(body+' 00:00:00');

  const minutes = Number(min) + plus;
  d.setHours(hour);
  d.setMinutes(minutes);

  return d;
}

export function getHoursAndMinutes(h) {
  const time = h < 1000 ? '0' + h : '' + h;

  let hour = +(time[0] + '' + time[1]);
  let min = +(time[2] + '' + time[3]);

  while (min >= 60) {
    hour++;
    min -= 60;
  }

  return {
    hour,
    min,
  };
}

function getAge(birthDate, today) {
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const getYears = (d) => {
  const now = new Date();
  const age = getAge(d, now);

  if(age <= 0){
    return '';
  }

  return `(${age} años) ${(now.getMonth() === d.getMonth()) && (now.getDate() === d.getDate()) ? `🎂` : ''}`
}

export const parseStricDate = (time) => {
  return new Date( +time.slice(0,4), +time.slice(5,7) - 1, +time.slice(8, 10) )
}

export const parseFullDate = (time) => {
  return new Date(+time.slice(0, 4), +time.slice(5, 7) - 1, +time.slice(8, 10), +time.slice(11, 13), +time.slice(14, 16), +time.slice(17, 19))
}

/*
* Convierte la fecha en el siguiente formato
* Para b, 'dd/mm/yy'
* Sin b, 'dd-mm-yy'
*/
export const parseDate = (timeLine, b) => {
  const parser = b ? '/' : '-';
  return (timeLine.slice(8,10).concat(parser, timeLine.slice(5,7), parser, timeLine.slice(0,4)))
}

export const parseDateReverse = (time, separator = "-") => {
  const date = time.getFullYear ? time : new Date(time);
  return `${date.getFullYear()}${separator}${getZero(+date.getMonth() + 1)}${separator}${getZero(date.getDate())}`
}

/*
* Convierte el color rgb a hexageximal
* Su única función sirve dentro de toColor
* Usarse por fuera no devuelve un color correcto.
*/
export const toHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


/*
* Convierte el color de INT32 a RGB o HEX
*/
export const toColor = (num, alt)  => {
    if(!num) return '#ffffff'

    num >>>= 0;
    let b = num & 0xFF,
        g = (num & 0xFF00) >>> 8,
        r = (num & 0xFF0000) >>> 16
    return alt ? toHex(r,g,b) : "rgb(" + [r, g, b].join(",") + ")";
}

/*
* Función de formato, para el número de teléfono
*/
export const formatPhone = (item) => {
  const Cel = (item['MovilDeEnvio'] || item['movilDeEnvio']) || '';
  const Tel = (item['TelefonoParaMostrar'] || item['telefonoParaMostrar']) || ''

  if(Cel && Tel){
    return `${Cel} / ${Tel}`
  } else if (Cel){
    return Cel;
  } else if (Tel){
    return Tel;
  }

  return ''
}

/*
* Función de formato, para las cantidades con decimales
*/
export const formatNumber = e => {
  return Number(e).toLocaleString('de-DE',{minimumFractionDigits: 2})
}

/*
* Obtiene las url de las imágenes
*/
export const baseImageUrl = "https://odontosys";
export const getUri = (id, ext, auth, original) => {
  const host = baseImageUrl+(original ? '' : '-resized')+".s3.us-east-2.amazonaws.com/";

  return host.concat(
    String(auth.idClinica).padStart(5, '0'),
    '/ima/',
    String(id).padStart(8, '0'),
    '.',
    ext
  )
}
/*
* Obtiene las url de los clientes / proveedores (para provider = true)
*/
export const getAvatarUri = (auth, obj, isProvider) => {
  return `${baseImageUrl}-resized.s3.us-east-2.amazonaws.com/`.concat(
    String(auth.idClinica).padStart(5, '0'),
    '/fotos/',
    isProvider ? (obj.idProveedor + 20000000) : (obj.idPaciente + 10000000),
    '.jpg?version=' + obj.versionFotoPerfil
  );
}

/*
* Función auxiliar
* Devuelve true si un conjunto (array)
* Contiene el valor especficado.
*/
export const contain = (str, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if(str.includes(arr[i])){
      return true;
    }
  }

  return false;
}

export const validateImage = (file) => {
  // Lista definida.
  let ext = file.type;
  if (!ext.length) {
    const parts = file.name.split('.');
    ext = parts[parts.length - 1] || "";
  }
  return contain(ext.toLowerCase(), ['png', 'jpg', 'jpeg', 'tif', 'tiff']);
}
export const ops = {vertical: 'top',horizontal: 'right'};

/*
* Función auxiliar
* Formatea la fecha para no restarse un día
*/
export const helperDate = time => {
  if(time.includes('T')){
    return Date.parse(time);
  }

  const d = new Date();

  d.setDate(time.slice(8,10))
  d.setMonth(Number(time.slice(5,7)) - 1)
  d.setYear(time.slice(0,4))

  return d;
}

/*
* Función auxiliar
* Formatea la hora militar para devolver un String
* 700 cambia a '0700'
*/
const wrapHour = (h) => {
  const n = Number(h);
  return String(n < 1000 ? '0'+n : n);
}

export const printHour = (time) => {
  const h = wrapHour(time);
  return `${h[0]}${h[1]}:${h[2]}${h[3]} ${+h > 1200 ? 'p.m' : 'a.m'}.`;
}
export { wrapHour };

const parseHour = time => (time < 1000 ? '0' : '').concat(time);
export { parseHour };

/*
* Convierte una hora militar
* Devuelve un objeto con el titulo de la hora
*/
export const getWrappedHour = (hora) => {
  const sHour = wrapHour(hora)
  return {
    title: `${sHour.slice(0,1).replace('0', '')}${sHour.slice(1,2)}:${sHour.slice(2,4)} ${Number(sHour.slice(0,2)) >= 12 ? 'PM' : 'AM'}`,
    value: `${sHour.slice(0,1).replace('0', '')}${sHour.slice(1,2)}:${sHour.slice(2,4)}`
  }
}

const toNext = n => {
  if((n - Math.trunc(n)) > 0.6){
    return Math.ceil(n)
  } else {
    return Math.floor(n)
  }
}

export const getWrappedDifference = (hora, plus) => {
  const sHour = wrapHour(hora)

  const hour = sHour.slice(0,2);
  const minutes = sHour.slice(2,4);

  const realMinutes = (+minutes) + (+plus);
  if(realMinutes < 60){
    return getWrappedHour(''.concat(hour, (realMinutes < 10 ? '0'+realMinutes : realMinutes)))
  } else {
    const div = realMinutes / 60;
    const realHour = Number(hour) + Math.trunc(div);

    let wrapperResult = String(Math.trunc(toNext((div - Math.trunc(div)) * 60)));
    if(wrapperResult.length > 2){
      wrapperResult = wrapperResult[wrapperResult.length - 2] +''+ wrapperResult[wrapperResult.length - 1]
    } else if(wrapperResult.length < 2){
      wrapperResult = '0'+wrapperResult;
    }

    return {
      title: `${realHour}:${wrapperResult} ${realHour >= 12 ? 'PM':'AM'}`,
      value: `${realHour}:${wrapperResult}`
    };
  }
}

export const getWrappedMinutes = v => {
  return v - (Math.trunc(v / 100) * 100);
}

export const mappingValues = (step) => {
  let options = { '30': 28, '15': 56, '10': 84 }
  let length = options[step]

  if(!length){
    step = 30;
    length = options[step];
  }

  return Array.from({length}, (_,i) => {
    let hour = 7;
    let min = (i * step);
    while(min >= 60){
        hour++;
        min-=60;
    }
    return {
      title: `${hour}:${getZero(min)} ${hour >= 12 ? 'PM':'AM'}`,
      value: `${hour}:${getZero(min)}`
    };
  })
}

export const formatReport = (date, t) => {
  if(date.year && !date.month && !t){
    return `en el ${date.year}`;
  } else if(t){
    return `– Comparación ${date.year-1} - ${date.year}`
  }

  return `${date.day ? `el ${date.day} de` : 'en'} ${months[date.month-1]} de ${date.year}`
}

export const iconStyle = {width: '0.95rem', marginLeft: '0.1rem'}

export const getCorrectPosition = ({x,y,width,height}) => {
  const docX = window.innerWidth;
  const docY = window.innerHeight;

  // Out of limits
  if(y + height > docY) y = y - height - 20;
  if(x + width > docX) x = x - width - 20;

  // Out of Start limits
  if(y < 0) y = 20;
  if(x < 0) x = 20;

  return {
    left: x,
    top: y
  }
}

export const slice = (label, limit = 0) => {
  if(label.length >= limit){
    label = label.slice(label, limit-2).concat('..')
  }

  return label;
}

const serial = n => {
  return (`${n}`).length === 1 ? '0'+n : n;
}

const getDuration = (state) => {
  try {
    if(!state) return null;
    if(!state.start || !state.end) return null;
    if(!state.start.value || !state.end.value) return null;
  } catch (e) {
    return null
  }

  const start = state.start.value.split(':');
  const end = state.end.value.split(':');

  const actualDate = new Date();
  actualDate.setHours(+start[0])
  actualDate.setMinutes(+start[1]);
  actualDate.setSeconds(0)

  const finalDate = new Date();
  finalDate.setHours(+end[0])
  finalDate.setMinutes(+end[1]);
  finalDate.setSeconds(0)

  const values = [
    finalDate.getHours()-actualDate.getHours(),
    finalDate.getMinutes()-actualDate.getMinutes()
  ]

  if(values[1] < 0){
    values[1] += 60;
    values[0] -= 1;
  }

  return values;
}

export { getDuration };

export const getHourValue = (time) => +time.value.replace(":", "")

export const getDistance = (time, state) => {
  const values = getDuration(state);
  if(!values) return null;


  const timeValues = time.value.split(':');
  const timeEnd = [
    +timeValues[0] + (+values[0]),
    +timeValues[1] + (+values[1])
  ];

  if(timeEnd[1] < 0){
    timeEnd[0] -= 1;
    timeEnd[1] += 60;
  }

  if(timeEnd[1] >= 60){
    timeEnd[0] += 1;
    timeEnd[1] -= 60;
  }

  return {
    title: `${timeEnd[0]}:${serial(timeEnd[1])} ${timeEnd[0] >= 12 ? 'PM':'AM'}`,
    value: `${timeEnd[0]}:${serial(timeEnd[1])}`
  }
}

export function TicksToUTC(ticks, timeGMT = true){
    const epochTicks = 621355968000000000;
    const maxDateMilliseconds = 8640000000000000;

    if (isNaN(ticks)) return `------------`;
    const ticksSinceEpoch = ticks - epochTicks;
    const millisecondsSinceEpoch = ticksSinceEpoch / 10000;
    if (millisecondsSinceEpoch > maxDateMilliseconds) return 0;

    const date = new Date(millisecondsSinceEpoch);
    return getFormatDate(date, 1, '/') + (timeGMT ? ` - ${getZero(date.getHours())}:${getZero(date.getMinutes())} ${date.getHours() >= 12 ? 'pm' : 'am'}.` : '');
}

export const areaCode = {
  us: 1,
  do: 1,
  uy: 598,
  mx: 52,
  cr: 506,
  pe: 51,
  ec: 593,
  ar: 54,
}

export const wsLink = "https://wa.me/";

export const getWhatsappLink = (phoneStr, pais = areaCode.uy) => {
  if (phoneStr.length < 7) {
    return wsLink;
  }

  const dirtyPhoneStr = phoneStr.replaceAll("-", "").replaceAll(" ", "");
  const blankSpace = dirtyPhoneStr.indexOf("(");
  const movil = dirtyPhoneStr.slice(0, blankSpace === -1 ? undefined : blankSpace);

  if (movil.startsWith("+")) {
    return `${wsLink}${movil}`;
  }

  if (pais === areaCode.uy) {
    if (movil.length === 9 && movil.startsWith("09")) {
      return `${wsLink}+${pais}${movil.slice(1)}`;
    }
    if (movil.length === 11 && movil.startsWith("598")) {
      return `${wsLink}+${movil}`;
    }
    return wsLink;
  }

  const length = pais.toString().length;
  if (movil.substring(0, length) === pais.toString()) {
    return `${wsLink}+${pais}${movil.substring(length)}`;
  }

  return `${wsLink}+${pais}${movil}`;
}

// return a promise
export function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

export const mbSize = Math.pow(1024, 2);
export const mbMaxSize = 25;

export function isPhone() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
}

export function isIphone() {
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
}


export const stateDate = (state, cb) => (range, date) => {
  let time = null;
  let chose = range.toLowerCase().includes('elegir');
  if (date && date.getTime && chose) {
    if (!isNaN(date.getTime())) {
      time = date.getTime();
    } else {
      alert('Date is NaN')
    }
  }
  if(!time && chose){
    return () => ({
      type: 'NONE',
      payload: null
    });
  }

  return cb({ ...state, range, date: time })
}

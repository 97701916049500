import React from 'react'

import { Grid, TextField, Typography } from '@mui/material';

import Avatar from '../../paciente/forms/file';
import Fields from '../../paciente/forms/fields';

import useMovil from '../../hooks/useMovil';

const ProveedorFormulario = ({ state, error, url, editable, handleFile, handleChange, handleSubmit }) => {

    const hnadleChangeFields = (fields) => {
        handleChange({
            target: {
                name: 'fields',
                value: fields
            }
        })
    }

    const isPhone = useMovil();

    return (
        <Grid component="form" container onSubmit={handleSubmit}>

            <Grid item xs={12}>
                <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? 'center' : undefined}>
                    {editable ? 'Editar Proveedor' : 'Nuevo Proveedor'}
                </Typography>
            </Grid>

            <Avatar
                url={url || ""}
                file={state.file}
                error={error.file}
                handleFile={handleFile}
            />

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="name"
                    size="small"
                    type="search"
                    autoComplete="off"
                    label="Nombre del proveedor"
                    value={state.name}
                    error={Boolean(error.name)}
                    helperText={error.name || ''}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} className="my">
                <TextField
                    fullWidth
                    type="search"
                    autoComplete="off"
                    size="small"
                    label="Teléfono móvil"
                    name="movil"
                    value={state.movil}
                    error={Boolean(error.movil)}
                    helperText={error.movil || ''}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    type="search"
                    autoComplete="off"
                    size="small"
                    label={"Teléfono principal"}
                    name="phone"
                    value={state.phone}
                    error={Boolean(error.phone)}
                    helperText={error.phone || ''}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} className="my">
                <TextField
                    fullWidth
                    type="search"
                    autoComplete="off"
                    size="small"
                    label="Correo electrónico"
                    name="email"
                    value={state.email}
                    error={Boolean(error.email)}
                    helperText={error.email || ''}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} className="mb">
                <TextField
                    fullWidth
                    type="search"
                    autoComplete="off"
                    size="small"
                    label={"Dirección"}
                    name="direction"
                    value={state.direction}
                    error={Boolean(error.direction)}
                    helperText={error.direction || ''}
                    onChange={handleChange}
                />
            </Grid>

            <Fields
                state={state}
                idSuperCategoria={2}
                handleChange={hnadleChangeFields}
            />

            <Grid item xs={12}>
                <TextField
                    autoComplete="new-aditional-note"
                    multiline
                    minRows={4}
                    maxRows={4}
                    size="small"
                    variant="outlined"
                    label="Nota adicional"
                    name="note"
                    fullWidth
                    value={state.note}
                    error={Boolean(error.note)}
                    helperText={error.note || ''}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}

export default ProveedorFormulario

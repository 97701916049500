import React from 'react'
import { esES } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';

export const Theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
  palette: {
    primary: {
      main: '#014990',
      light: '#00AEEF'
    },
    secondary: {
      dark: '#56004E',
      main: '#B5004F',
      light: '#7E8083'
    },
    background: {
      default: "#ededed"
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          "& .MuiSelect-select": {
            paddingTop: 9,
            paddingBottom: 9
          },
          "& .MuiListItemText-root": {
            margin: 0
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-displayedRows': {
            marginBottom: 0
          }
        }
      }
    },
  }
}, esES)

export const DatePickerStyle = {
  '& input': {
    height: 41,
    paddingTop: 0,
    paddingBottom: 0,
  }
}

const Theming = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <ThemeProvider theme={Theme}>
        {props.children}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default Theming

import useSWR from "swr";
import Axios from "../../config/axios";

const useMonedas = () => {
    const { data: response, error, mutate } = useSWR('/monedas', Axios.get, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    const data = response ? response.data : [];

    return {
        data: data.filter(item => item.Activa),
        original: data,
        isLoading: !response,
        isError: error,
        mutate
    }
}

export default useMonedas;
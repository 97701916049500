import React, { useEffect, useContext } from 'react';
import { FontContext } from '../../config/font';

import FullModal from './fullmodal';
import Modal from '@mui/material/Modal';
import useMovil from '../hooks/useMovil';
import useStyles from './modal.style';

const ModalForm = ({
  open,
  children,
  buttons,
  fullModal,
  BackdropProps,
  handleClose,
}) => {
  const classes = useStyles();
  const phone = useMovil();
  const { fontClassName } = useContext(FontContext);

  const onPopState = (e) => {
    e.preventDefault();
    handleClose();
  };

  useEffect(() => {
    if (open) {
      window.history.pushState(
        null,
        null,
        window.top.location.pathname + window.top.location.search
      );
      window.addEventListener("popstate", onPopState);
    }

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
    // eslint-disable-next-line
  }, [open]);

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      handleClose();
    }
  };

  if (phone || fullModal) {
    return (
      <FullModal
        {...{ open, children, buttons, BackdropProps }}
        handleClose={handleClose}
      />
    );
  }

  return (
    <Modal
      BackdropProps={BackdropProps}
      className={fontClassName}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.root} onKeyDown={onKeyDown} tabIndex="0">
        <div className="relative">
          <div
            tabIndex="0"
            className={classes.paper}
            style={{ paddingBottom: buttons ? 100 : 10 }}
          >
            {children}
          </div>
          {buttons ? <div className="modal-buttons">{buttons}</div> : null}
        </div>
      </div>
    </Modal>
  );
};

export default ModalForm

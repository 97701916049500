import React from 'react';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TimelapseIcon from '@mui/icons-material/Timelapse';

export const list = [
  {
    row: false,
    resource: <Divider key={35} />
  },
  {
    row: false,
    resource: <ListSubheader key={36} component="div" className="menu-subtitle">Concurrencia</ListSubheader>
  },
  {
    row: true,
    icon: <CheckCircleOutlineIcon fontSize="small" />,
    label: "Vino a la cita",
    state: 1
  },
  {
    row: true,
    icon: <ErrorOutlineIcon fontSize="small" />,
    label: "Llegó tarde",
    state: 5
  },
  {
    row: true,
    icon: <NotInterestedIcon fontSize="small" />,
    label: "Faltó",
    state: 3
  },
  {
    row: true,
    icon: <HighlightOffIcon fontSize="small" />,
    label: "Faltó y aviso",
    state: 2
  },
  {
    row: true,
    icon: <RemoveCircleOutlineIcon fontSize="small" />,
    label: "Canceló el Dr.",
    state: 4
  },
  {
    row: true,
    icon: <WatchLaterOutlinedIcon fontSize="small" />,
    label: "Se le atiende tarde",
    state: 6
  }
]

export const sublist = [
  {
    row: false,
    resource: <ListSubheader key={97} component="div" className="menu-subtitle">Paciente</ListSubheader>
  },
  {
    row: true,
    icon: <TimelineIcon fontSize="small" />,
    label: "Cuentas"
  },
  {
    row: true,
    icon: <TimelapseIcon fontSize="small" />,
    label: "Historia"
  },
  {
    row: true,
    icon: <WallpaperIcon fontSize="small" />,
    label: "Imágenes",
    link: "imagenes"
  },
  {
    row: true,
    icon: <MenuBookIcon fontSize="small" />,
    label: "Cuestionario"
  },
]

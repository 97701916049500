import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ToolTip from '../components/util/tooltip.js';
import ModalPacienteDetalle from '../components/modals/viewPacient.js';
import ModalProveedorDetalle from '../components/modals/viewProvider.js';
import Search from '../components/paciente/search.js'
import { searchPaciente, setSingle } from '../redux/actions/pacienteActions.js';
import { searchProveedor, singleProvider } from '../redux/actions/providerActions.js';

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
    '& > *': {
      maxWidth: '500px',
      margin: '20px auto',
      justifyContent: 'center',
      display: 'flex',
    },
  },
});

const Home = ({ handleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [state, setState] = useState('');

  const handleChecked = () => setChecked(old => !old);

  const LIMIT_SEARCH = 2;
  const handleChange = e => setState(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();
    if(state.length < LIMIT_SEARCH) return;

    document.activeElement.blur();
    if(checked){
      dispatch(searchPaciente(state))
    } else {
      dispatch(searchProveedor(state))
    }
  }

  const handleProvider = item => dispatch(singleProvider(item))
  const handleSelect = item => dispatch(setSingle(item))

  return (
    <div>
      <ModalPacienteDetalle open />
      <ModalProveedorDetalle open />
      <form className={classes.root} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleChecked}
                name="checkedSearch"
                color="primary"
              />
            }
            label={"Buscando "+(checked ? 'pacientes' : 'proveedores')}
          />
        </div>
        <Search
          limit={LIMIT_SEARCH}
          label={state}
          checked={checked}
          handleClick={handleSubmit}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleProvider={handleProvider}
        />
      </form>
      <ToolTip
        handleOpen={handleOpen}
        title="Nuevo paciente"
        color="primary"
      />
    </div>
  )
}

export default Home

import React from 'react';

import Multiple from './rows/multi';
import Single from './rows/single';

const Content = ({ index, item, height, bottom, preview, num, family, onSelect }) => {
    const onSelectItem = (target) => {
        // Empty line
        if(!item){
            onSelect({ index, item: null, target })
        } else {
            onSelect({ index, item, target })
        }
    }
    const onSelectSingle = () => onSelect({ target: item, item, index })

    return (
        <div className={preview ? "noline" : "line"} style={{ minHeight: height+2, marginBottom: bottom }}>
            {
                item ?
                    item.length ?
                        <Multiple item={item} font={family} preview={preview} num={num} height={height} onSelect={onSelectItem} />
                    :
                        <Single item={item} font={family} preview={preview} num={num} height={height} onSelect={onSelectSingle} />
                :
                    <Multiple item={[]} font={family} preview={preview} num={num} height={height} onSelect={onSelectItem} />
            }
        </div>
    )
}

export default Content;
import React from 'react';
import clsx from 'clsx';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';

// Hooks
import useOpen from '../../../components/hooks/useOpen';
import { useSelector } from 'react-redux';

const Avanzado = ({ label, active, title, submenu, onSelect, onSelectPanel }) => {
    const userLogin = useSelector(state => state.auth.user);
    const list = useOpen();

    const onClick = () => {
        onSelect(label, false)
        if (list.open && active) list.handleClose();
        else list.handleOpen()
    }
    const onClickPanel = (panel) => () => onSelectPanel(panel);
    return (
        <>
            <ListItemButton className={clsx({ active })} onClick={onClick}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText disableTypography>
                    <Typography>Avanzado</Typography>
                </ListItemText>
                {(list.open && active) ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={list.open && active} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="px-m">
                    <ListItemButton className={clsx({ active: submenu === `${title}-usuarios` })} onClick={onClickPanel(`${title}-usuarios`)}>
                        <ListItemText disableTypography>
                            <Typography>Usuarios</Typography>
                        </ListItemText>
                    </ListItemButton>
                    {
                        (userLogin.usuarios > 1) ?
                            <ListItemButton disabled={userLogin.TipoDeUsuario} className={clsx({ active: submenu === `${title}-permisos` })} onClick={onClickPanel(`${title}-permisos`)}>
                                <ListItemText disableTypography>
                                    <Typography>Permisos</Typography>
                                </ListItemText>
                            </ListItemButton>
                            : null
                    }
                    <ListItemButton className={clsx({ active: submenu === `${title}-doctores` })} onClick={onClickPanel(`${title}-doctores`)}>
                        <ListItemText disableTypography>
                            <Typography>Doctores</Typography>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton className={clsx({ active: submenu === `${title}-fuente` })} onClick={onClickPanel(`${title}-fuente`)}>
                        <ListItemText disableTypography>
                            <Typography>Fuente</Typography>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton className={clsx({ active: submenu === `${title}-monedas` })} onClick={onClickPanel(`${title}-monedas`)}>
                        <ListItemText disableTypography>
                            <Typography>Monedas</Typography>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton className={clsx({ active: submenu === `${title}-plus` })} onClick={onClickPanel(`${title}-plus`)}>
                        <ListItemText disableTypography>
                            <Typography>Más</Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}

export default Avanzado;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import List from '@mui/material/List';

// Hooks
import useOpen from '../../../../components/hooks/useOpen';

// Components
import Lista from './item';
import Header from './header';
import Aranceles from './aranceles/treatments';
import ToolTip from '../../../../components/util/tooltip';
import Modal from '../../../../components/precios/modalList';

// Actions
import { getArancelesList } from '../../../../redux/actions/arancelActions';
import { localeDoctorSort } from '../../../../components/grupos/util';

const Precios = () => {
    const modal = useOpen();
    const dispatch = useDispatch();

    const list = useSelector(state => state.arancel.all);

    const [listas, setListas] = useState([]);
    const [select, setSelect] = useState(null);

    useEffect(function(){
        dispatch(getArancelesList());
        //eslint-disable-next-line
    }, [])

    useEffect(function(){
        if(list){
            setListas(list.sort(localeDoctorSort));
        }
    }, [ list ])

    const onSelectBack = () => setSelect(null)
    const onSelect = (lista) => setSelect(lista);

    return select ? <Aranceles item={select} onSelect={onSelect} /> : (
        <div className="flex grow column h-100">
            <Header onSelectBack={onSelectBack} onCreate={modal.handleOpen} />
            <List component="ul">
                {listas.map((item) => <Lista key={item.idArancel} item={item} onSelect={onSelect} />)}
            </List>
            <ToolTip handleOpen={modal.handleOpen} title="Nueva Lista" color="primary" />
            <Modal open={modal.open} onClose={modal.handleClose} />
        </div>
    );
}

export default Precios;
import React, { useState, useEffect } from "react";
import Modal from "../../../../../components/util/modal.js";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Axios from "../../../../../config/axiosv2.js";
import { useDispatch } from "react-redux";
import handler from "../../../../../redux/actions/handler.js";
import useOpen from "../../../../../components/hooks/useOpen.js";

const ModalCrearPlantillaIndicacion = ({
  open,
  plantilla,
  handleSubmit,
  handleClose,
}) => {
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const loading = useOpen();
  const isEmpty = !text.trim().length;
  const isLonger = text.trim().length > 120;

  const onChange = (evt) => setText(evt.target.value);
  const onClose = () => {
    handleClose();
    setText("");
    loading.handleClose();
  };
  const onSubmit = () => {
    if (isEmpty || isLonger) return;

    loading.handleOpen();
    const body = { title: text }

    const getRequest = () => (
      !plantilla ? Axios.post("/plantillas/historia", body)
      : Axios.put(`/plantillas/historia/${plantilla.id}`, body)
    );

    getRequest()
      .then((res) => handleSubmit(res.data))
      .catch(handler(dispatch))
      .finally(onClose);
  };

  useEffect(() => {
    if (open && plantilla) {
      setText(plantilla.nombre);
    }
  }, [plantilla, open]);

  return (
    <Modal open={open} handleClose={onClose} alternativeCloseButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{plantilla ? "Modificar" : "Nueva"} Plantilla</Typography>
        </Grid>

        <Grid item xs={12} className="my">
          <TextField
            autoComplete="off"
            label="Nombre"
            name="plantilla"
            onChange={onChange}
            error={isLonger}
            helperText={
              isEmpty
                ? "El nombre no puede estar vacio"
                : isLonger
                ? "El nombre no puede ser mayor a 120 caracteres"
                : ""
            }
            value={text}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className="mt"
      >
        <Button color="secondary" className="mr" onClick={onClose}>
          Cancelar
        </Button>
        <div className="relative">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={isEmpty || isLonger}
          >
            {plantilla ? "Actualizar" : "Guardar"}
          </Button>
          {loading.open && (
            <CircularProgress size={24} className="re-loading" />
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default ModalCrearPlantillaIndicacion;

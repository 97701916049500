import { useState, useMemo, useEffect, useCallback, createContext } from 'react';

// Components
import Modal from '../components/modal/index';
import Loading from '../../util/circleLoading.js';

// Axios
import axios from 'axios';
import handler from '../../../redux/actions/handler';

// Util
import { getData, strongerify, unstrongify } from '../components/util'

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useOpen from '../../hooks/useOpen'

// Styles
import '../styles/template.css';
import { useHistory } from 'react-router-dom';
import { PDF_SERVICE } from '../../../config/axios.js';

const toLines = (height, font, mtop) =>  Math.floor( ((height * 37)-mtop) / (font+4.5) )-1;
const defaultState = {
    items: {},
    lines: toLines(9, 16, 18),
    font: 16,
    family: 'Arial',
    size: { width: 21, height: 9 },
    margin: { top: 18, left: 18, right: 18, bottom: 1 },
    logo: null
}

export const baseURL = `${PDF_SERVICE}/recibos`

export const Context = createContext();
const Provider = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const [ state, setState ] = useState(defaultState);
    const [ data, setData ] = useState(null);
    const load = useOpen(true);

    useEffect(function(){
        axios.defaults.headers.common['x-auth'] = token;
        axios.get(baseURL)
        .then((response) => response.data.data ? JSON.parse(response.data.data) : null)
        .then(data => data && data.items ? setState(unstrongify(data)) : null)
        .catch(handler(dispatch))
        .finally(load.handleClose)
        // eslint-disable-next-line
    }, [])

    const addItem = useCallback(({ index, item }) => {
        setState(oldState => ({
            ...oldState,
            items: {
                ...oldState.items,
                [index]: item
            }
        }))
    }, [])

    const onSubmit = () => {
        axios.defaults.headers.common['x-auth'] = token;
        axios.post(baseURL, getData(state, strongerify))
        .then(() => history.push('/'))
        .catch(handler(dispatch))
    }

    const onSelectItem = useCallback(data => setData(data), [])
    const onChangeFontFamily = useCallback(family => setState(state => ({...state, family})), []);
    const onChangeFont = useCallback(font => setState(state => ({...state, font})), []);
    const onChangeMargin = useCallback(margin => {
        setState(prevState => ({
                ...prevState,
                margin,
                lines: toLines(prevState.size.height, prevState.font, margin.top)
            })
        )
    }, []);
    const onChangeSize = useCallback(size => {
        setState(prevState => ({
                ...prevState,
                size,
                lines: toLines(size.height, prevState.font, prevState.margin.top)
            })
        )
    }, []);

    const getPositiveValue = useCallback((n, value = 1) => (n <= 0 ? value : n), [])

    const style = useMemo(function(){
        return {
            width: getPositiveValue(state.size.width, 2)+"cm",
            height: getPositiveValue(state.size.height, 2)+"cm",
            paddingTop: getPositiveValue(state.margin.top, 0)+"px",
            paddingRight: getPositiveValue(state.margin.right, 0)+"px",
            paddingLeft: getPositiveValue(state.margin.left, 0)+"px",
            paddingBottom: getPositiveValue(state.margin.bottom, 0)+"px"
        }
        // eslint-disable-next-line
    }, [ state.size, state.margin ])

    const onChangeLogo = useCallback(logo => {
        setState(prevState => ({ ...prevState, logo }))
    }, []);

    const onClose = () => setData(null)
    const onComplete = (item) => addItem({ item, index: data.index });

    return (
        <Context.Provider
            value={{
                state,
                style,
                getPositiveValue,
                onSelectItem,
                onChangeSize,
                onChangeFont,
                onChangeMargin,
                onChangeFontFamily,
                onChangeLogo,
                onSubmit
            }}
        >
            {load.open ? <Loading text="Cargando Template" /> : children}
            <Modal
                open={!!data}
                target={data ? data.target : null}
                item={data ? data.item : null}
                onClose={onClose}
                onSubmit={onComplete}
            />
        </Context.Provider>
    )
}

export default Provider;

import React, { useContext, useMemo } from 'react'

// Hooks
import Provider, { Context } from './hooks/context';
import useOpen from '../hooks/useOpen';

// UI
import Button from '@mui/material/Button'

// Components
import Content from './components/rowmanager.js'
import Header from './components/header.js'
import Preview from './components/preview/index.js';

const Template = () => {
    const { state, style, onSelectItem, getPositiveValue, onSubmit } = useContext(Context);
    const preview = useOpen();

    const data = useMemo(function(){
        const items = [];
        const height = getPositiveValue(state.font, 10);
        for(let i = 0; i < state.lines; i++){
            items.push(
                <Content
                    key={i}
                    index={i}
                    item={state.items[i]}
                    family={state.family}
                    height={height}
                    bottom={style.paddingBottom}
                    onSelect={onSelectItem}
                />
            )
        }
        return (
            <div className="flex column" style={{...style, paddingBottom: 0}}>
                {items}
            </div>
        );
        // eslint-disable-next-line
    }, [ state.items, state.lines, state.font, state.family, style ])

    return (
        preview.open ?
            <Preview onBack={preview.handleClose} />
        :
            <div className="px">
                <Header />
                <div className="flex grow center my">
                    {data}
                </div>
                <div className="flex grow end wrap right py-m">
                    <div>
                        <Button color="primary" size="small" variant="contained" onClick={preview.handleOpen}>
                            Vista Previa
                        </Button>
                        <Button className="mx-m" color="primary" size="small" variant="contained" onClick={onSubmit}>
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
    )
}

const Page = ({ history }) => (
    <Provider history={history}>
        <Template />
    </Provider>
)

export default Page;
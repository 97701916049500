import React from 'react';
import { useSelector } from 'react-redux';
import useMovil from '../../../../../components/hooks/useMovil';

// UI
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Components
import { TipoDeUsuario } from '../util';

const FormModalUsuario = ({ state, error, select, disabled, handleChange, handleOpenAgenda, handleSubmit }) => {
  const { idUsuario, TipoDeUsuario: Tipo } = useSelector(state => state.auth.user);
  const isPhone = useMovil();
  return (
    <Grid
      component="form"
      className="modal-form"
      container
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} className="mb">
        <Typography
          variant={isPhone ? "h6" : undefined}
          align={isPhone ? "center" : undefined}
        >
          {select ? "Modificar" : "Nuevo"} usuario
        </Typography>
      </Grid>

      <Grid item xs={12} className="my-m">
        <TextField
          fullWidth
          required
          type="search"
          size="small"
          name="name"
          label="Nombre"
          autoComplete="off"
          value={state.name}
          error={error.name}
          helperText={error.name || ""}
          onChange={handleChange}
        />
      </Grid>

      {select ? null : (
        <Grid item xs={12} className="my-m">
          <TextField
            fullWidth
            required
            type="password"
            size="small"
            name="password"
            label="Contraseña"
            autoComplete="new-password"
            value={state.password}
            error={error.password}
            helperText={error.password || ""}
            onChange={handleChange}
          />
        </Grid>
      )}

      <Grid item xs={12} className="my-m">
        <TextField
          fullWidth
          required
          select
          size="small"
          name="type"
          label="Tipo de Usuario"
          disabled={
            (select && idUsuario === select.idUsuario) || disabled
          }
          value={state.type}
          onChange={handleChange}
        >
          {Object.keys(TipoDeUsuario).map((key) => (
            <MenuItem key={key} value={key}>
              {TipoDeUsuario[key]}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {
        select && state.type !== 0 && Tipo === 0 ? (
          <Grid item xs={12} className="my-m">
            <Typography variant="body2">
              Haz click <strong className="text-primary pointer" onClick={handleOpenAgenda}>aqui</strong> para modificar las agendas a las que este usuario podrá tener acceso.
            </Typography>
          </Grid>
        ) : null
      }
    </Grid>
  );
}

export default FormModalUsuario

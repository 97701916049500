export const CUESTIONARIO_LIST = "CUESTIONARIO_LIST";
export const CUESTIONARIO_LOADING = "CUESTIONARIO_LOADING";
export const CUESTIONARIO_CREATE = "CUESTIONARIO_CREATE";
export const CUESTIONARIO_UPDATE = "CUESTIONARIO_UPDATE"
export const CUESTIONARIO_DELETE = "CUESTIONARIO_DELETE"
export const CUESTIONARIO_CLEAR = "CUESTIONARIO_CLEAR"

export const PREGUNTAS_LIST = "PREGUNTAS_LIST";
export const PREGUNTAS_CREATE = "PREGUNTAS_CREATE"
export const PREGUNTAS_UPDATE = "PREGUNTAS_UPDATE"
export const PREGUNTAS_DELETE = "PREGUNTAS_DELETE"
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeLoginToken, makeLogout } from '../../redux/actions/authActions.js';

function Auth(){
  const dispatch = useDispatch();
  const Load = useSelector(state => state.auth.remember);
  const Token = useSelector(state => state.auth.token);

  useEffect(function(){
    if(!Token && Load){
      const Auth = JSON.parse(localStorage.getItem('x-auth'));
      if(Auth){
        dispatch(makeLoginToken(Auth))
      } else {
        dispatch(makeLogout())
      }
    }
    // eslint-disable-next-line
  }, [])
}

export default Auth

import React from 'react';

import Appbar from '../../../surfaces/appbar';
import Plantilla from '../../../../components/plantillas/pages/correo';

const PlantillasCorreo = () => {
    return (
        <div className="flex grow column">
            <Appbar title="Plantillas Correos" className="mb-m" />
            <Plantilla />
        </div>
    );
}

export default PlantillasCorreo;
import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Hooks
import useSmtp from '../hooks/useSmtp.js';

// Components
import Loader from '../../util/circleLoading';
import Dialog from '../../util/dialogMessage.js';
import Form from '../form/smtp';

// Images
import MailDoneImage from '../../../assets/svgs/undraw_mail_done.png';

const CorreoSmtp = () => {
    const { data, state, loading, editable, testmodal, onTest, onEdit, onChange, onChangeCheck, onComplete, onSubmit, onCancel, onDestroy } = useSmtp();
    return (
        <div className="flex grow center h-100">
            <div className="">
            {
                loading ? <Loader text="Cargando Servicios SMTP." /> :
                <div className="flex column">
                    {!data || editable ?
                        <Form
                            state={state}
                            onChange={onChange}
                            editable={editable}
                            onComplete={onComplete}
                            onChangeCheck={onChangeCheck}
                            onDestroy={onDestroy}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                        />
                        :
                        <div className="flex column center">
                            <img src={MailDoneImage} alt="Mail Syncronized" height={200} className="bg-none" />
                            <Typography>Sincronizado: <span className="text-primary">{data.Nombre}</span></Typography>
                            <Button variant="contained" color="primary" className="my-m" onClick={onEdit}>Modificar</Button>
                            <div style={{width: 320, height: 5}}>
                                <Divider />
                            </div>
                            <Button variant="contained" color="secondary" className="my-m" onClick={onTest}>Prueba de Configuración</Button>
                        </div>
                    }
                </div>
            }
            <Dialog
                open={testmodal.open}
                title="Atención!"
                description={<span>Parece que todo quedo pronto.<br/>Deberias recibir un correo con asunto 'Prueba de configuración Odontosys' en tu cuenta de correo</span>}
                handleClose={testmodal.handleClose}
            />
            </div>
        </div>
    );
}

export default CorreoSmtp;
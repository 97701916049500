import React from 'react';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import TrashIcon from '@mui/icons-material/Delete';
import TopArrow from '@mui/icons-material/ExpandLess';
import BottomArrow from '@mui/icons-material/ExpandMore';

import useMovil from '../../../../../components/hooks/useMovil'

const CamposForm = ({ field, index, lastIndex, onChange, onSwipe, onDestroy }) => {
    const onClickDestroy = () => onDestroy(field);
    const onSwipeUp = () => onSwipe(field.id, true);
    const onSwipeDown = () => onSwipe(field.id, false);
    const isPhone = useMovil();
    return (
        <div className="flex middle grow mb pl-m">
            <div className="flex grow">
                <TextField
                    fullWidth
                    size="small"
                    autoComplete="off"
                    id={String(field.id)}
                    label={`Campo extra #${index+1}`}
                    value={field.value}
                    onChange={onChange}
                    error={!field.value.trim().length}
                    helperText={!field.value.trim().length ? 'El campo no puede estar vacio' : ''}
                />
            </div>
            <div className="flex">
                {
                    index ?
                        <IconButton size="small" className={isPhone ? undefined : "mx-m"} onClick={onSwipeDown}>
                            <TopArrow />
                        </IconButton>
                    : null
                }
                {
                    !lastIndex &&
                    <IconButton size="small" className={isPhone ? undefined : "mx-m"} onClick={onSwipeUp}>
                        <BottomArrow />
                    </IconButton>
                }
                <IconButton size="small" className={isPhone ? undefined : "mx-m"} onClick={onClickDestroy}>
                    <TrashIcon />
                </IconButton>
            </div>
        </div>
    );
}

export default CamposForm;
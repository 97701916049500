import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, Grid } from '@mui/material';

// Hooks
import useOpen from '../hooks/useOpen';

// Components
import Modal from '../util/modal';
import Loading from '../util/circleLoading';
import { getDeuda } from '../../redux/actions/authActions.js';

const parse = (id) => `reminder-${id}`;

const UserReminder = () => {
    const modal = useOpen();
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user);
    const balance = useSelector(state => state.auth.balance);

    useEffect(() => {
        if(user.estado > 1){
            const reminder = JSON.parse(localStorage.getItem(parse(user.idClinica)));
            if(reminder){
                if(Date.now() > Date.parse(reminder)){
                    modal.handleOpen();
                }
            } else {
                modal.handleOpen();
            }
        }
        // eslint-disable-next-line
    }, [ user ])

    useEffect(function() {
        if(modal.open){
            dispatch(getDeuda())
        }
        //eslint-disable-next-line
    }, [ modal.open ])

    const onClose = () => {
        if(!balance) return;
        const date = new Date();
        date.setHours(date.getHours() + 4);
        localStorage.setItem(parse(user.idClinica), Date.parse(date));
        modal.handleClose();
    }

    return (
        <Modal open={modal.open} handleClose={onClose}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>Recordatorio de Pago</Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        balance ?
                            <Grid item xs={12}>
                                <div className="mb" dangerouslySetInnerHTML={{ __html: balance }}></div>
                                <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
                                    <Button color="primary" variant="contained" size="small" onClick={onClose}>
                                        Aceptar
                                    </Button>
                                </Grid>
                            </Grid>
                        :
                        <Loading text="Preparando informe" />
                    }
                </Grid>
            </Grid>
        </Modal>
    )
}

export default UserReminder

import React from 'react';

// UI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Hooks
import useMonedas from '../hooks/useMonedas';

// Components
import Appbar from '../../../surfaces/appbar';
import MonedasForm from './item';

const Monedas = () => {
    const { isLoading, monedas, hasChanges, onChange, onSubmit } = useMonedas();
    return (
        <div className="flex grow column">
            <Appbar title="Monedas" />
            <div className="flex grow column">
            {
                monedas.map((item, index) => <MonedasForm key={item.id_moneda} item={item} index={index} onChange={onChange} />)
            }
            </div>
            <div className="flex grow end right p">
                <div className="relative">
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={!hasChanges || isLoading}>
                        Guardar
                    </Button>
                    {isLoading && <div className="re-loading"><CircularProgress size={22} /></div>}
                </div>
            </div>
        </div>
    );
}

export default Monedas;
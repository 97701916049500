import React from 'react';
import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

const Footer = ({ state, modal, handleClose, handleSubmit }) => {
    const loading = useSelector(state => state.agenda.loading)
    return (
        <Grid item container justifyContent="space-between" alignItems="center">
            <Grid container item xs={2} md={3} alignItems="center">
                {
                    state.id ?
                        <Tooltip title="Eliminar agenda" aria-label="Eliminar agenda" placement="right" arrow>
                            <IconButton onClick={modal.handleOpen} size="large">
                                <TrashIcon />
                            </IconButton>
                        </Tooltip>
                        : <div></div>
                }
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" item xs>
                <Button
                    color="secondary"
                    onClick={handleClose}
                    className="mr-m"
                    size="small"
                >
                    Cerrar
                </Button>
                <div className="relative">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                        size="small"
                    >
                        {state.id ? 'Actualizar' : 'Guardar'}
                    </Button>
                    {loading && <div className="re-loading"><CircularProgress size={22} /></div>}
                </div>
            </Grid>
        </Grid>
    );
}

export default Footer;

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMenu from '../hooks/useAnchorEl';
import useMovil from '../hooks/useMovil';
import useOpen from '../hooks/useOpen';

import { Assignment, ChevronLeft, LabelOutlined as Label, MoreVert } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Grid, Hidden, IconButton, Typography } from '@mui/material';

import { clear, setSingle, updateStatus } from '../../redux/actions/pacienteActions';
import { openControl } from '../control/provider/actions';

import ModalUpdatePaciente from '../paciente/modals/edit';
import GruposPaciente from '../paciente/surfaces/grupos';
import CircleLoading from '../util/circleLoading';
import ImgProvider from '../util/image';
import Modal from '../util/modal';
import { areaCode, getAvatarUri, getWhatsappLink } from '../util/tools';

// surfaces
import CuestionarioIcon from '../cuestionarioPaciente/surfaces/icon';
import { EstadoIcon } from '../estados/surfaces/icon';
import usePermisos from '../hooks/usePermiso';
import ModalCitasPaciente from '../paciente/surfaces/citas';
import ModalGruposPaciente from '../paciente/surfaces/groups';
import InfoPaciente from '../paciente/surfaces/info';
import MenuPacienteOpciones from '../paciente/surfaces/menu';
import { ButtonModules } from '../paciente/surfaces/modules';
import Rotate from '../paciente/surfaces/rotate';
import Sticky from '../paciente/surfaces/sticky';

const ModalPacienteView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.pacient.loading);
  const single = useSelector(state => state.pacient.single);
  const auth = useSelector(state => state.auth.user);
  const pais = auth.pais;
  const estadosPersonalizados = auth.UsarEstadosPersonalizados;

  const menuOpciones = useMenu();
  const menuCitas = useOpen();
  const editable = useOpen();
  const groups = useOpen();
  const isPhone = useMovil();

  const permisos = usePermisos();
  const isDisabledToSee = permisos.is({ code: 1025 });

  const wsLink = useMemo(function () {
    if (!single || single === true) return '';

    return getWhatsappLink(single.movilDeEnvio, areaCode[pais]);
  }, [single, pais])

  useEffect(function () {
    if (single && props.open && !editable.open) {
      if (single.editable) editable.handleOpen();
    }
    //eslint-disable-next-line
  }, [single])

  const handleClose = () => {
    if (groups.open) {
      groups.handleClose()
    } else if (editable.open) {
      editable.handleClose()
    } else {
      menuOpciones.handleClose();
      if (props.onClose) {
        props.onClose();
      } else {
        dispatch(setSingle(null))
      }
    }
  }

  const handleRedirect = dir => () => history.push(dir.concat(single.idPaciente));
  const handleControl = () => dispatch(openControl(single.idPaciente))
  const handleDelete = () => {
    dispatch(updateStatus(single.idPaciente, 'deleted'))
    dispatch(clear())
  }

  if (!single) return null;

  if (single === true && props.open) {
    return (
      <Modal open handleClose={handleClose}>
        <CircleLoading text="Buscando paciente" />
      </Modal>
    )
  }

  if (isPhone) {
    if (editable.open) {
      return <ModalUpdatePaciente {...editable} paciente={single} />
    }
    if (menuCitas.open) {
      return <ModalCitasPaciente {...menuCitas} paciente={single} />
    }
    if (groups.open) {
      return <ModalGruposPaciente {...groups} paciente={single} />
    }
  }

  return (
    <Modal open={Boolean(single && props.open)} handleClose={handleClose}>
      <ModalUpdatePaciente {...editable} paciente={single} />
      <ModalGruposPaciente {...groups} paciente={single} />
      <ModalCitasPaciente {...menuCitas} paciente={single} />
      <MenuPacienteOpciones
        {...menuOpciones}
        wsLink={wsLink}
        paciente={single}
        handleEdit={editable.handleOpen}
        handleCitas={menuCitas.handleOpen}
        handleDelete={handleDelete}
        handleRedirect={handleRedirect}
      />
      <Grid container className="app-x-overflow">

        <Hidden mdUp>
          <Sticky
            onClose={handleClose}
            onRedirect={handleRedirect}
            onControl={handleControl}
            Typography={<Typography noWrap><strong>{single.nombre}</strong></Typography>}
            Icon={
              isDisabledToSee ? null : (<IconButton className="text-white" onClick={menuOpciones.handleOpen} size="large">
                <MoreVert />
              </IconButton>)
            }
          />
          <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end" style={{ height: 100 }}>
            {
              isDisabledToSee ? null : (
                <Box className="flex right" style={{ marginRight: -12 }}>
                  <IconButton color="primary" onClick={groups.handleOpen} size="large">
                    <Label />
                  </IconButton>
                </Box>
              )
            }
          </Grid>
        </Hidden>

        <Hidden mdDown>
          <Grid item container justifyContent="space-between" alignItems="center" className="relative mb-m">
            <Grid item container alignItems="center" xs>
              <Typography display="inline" className="pl mx-m">
                {single.nombre} {single.Sexo ? `(${single.Sexo})` : ''}
              </Typography>
              {
                isDisabledToSee ? null : (
                  <Hidden mdDown>
                    <CuestionarioIcon history={history} paciente={single} />
                  </Hidden>
                )}
            </Grid>
            {
              isDisabledToSee ? null : (
                <>
                  <Grid item>
                    <IconButton color="primary" onClick={groups.handleOpen} size="large">
                      <Label />
                    </IconButton>
                    <IconButton onClick={menuOpciones.handleOpen} size="large">
                      <MoreVert />
                    </IconButton>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item container alignItems="center" justifyContent="center" xs={12}>
                      <CuestionarioIcon history={history} paciente={single} />
                    </Grid>
                  </Hidden>
                  <div className="lineborder">
                    <IconButton size="small">
                      <ChevronLeft onClick={handleClose} />
                    </IconButton>
                  </div>
                </>
              )
            }
          </Grid>
        </Hidden>

        <Grid item container xs={12}>
          <Rotate disabled={isDisabledToSee} paciente={single}>
            <ImgProvider
              src={single.url || getAvatarUri(auth, single)}
              alt={"Imagen de paciente " + single.nombre}
              width={90}
              height={90}
            />
            {loading && <div className="re-loading">
              <CircularProgress />
            </div>}
          </Rotate>
          <Grid item xs={12}>
            <InfoPaciente paciente={single} wsLink={wsLink} />
          </Grid>
          {estadosPersonalizados && (
            <Grid item xs={12} container justifyContent="center" className="mt-m">
              <EstadoIcon paciente={single} label />
            </Grid>
          )}
          <Grid item container justifyContent="center" className="mb-m">
            <GruposPaciente paciente={single} onClick={groups.handleOpen} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider className="mb-m" />
          <Grid item container xs={12}>
            <ButtonModules onClick={handleRedirect} />
            <Grid item xs={12} className="px-m pt-m">
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="contained"
                startIcon={<Assignment fontSize="small" />}
                onClick={handleControl}
              >
                Próximo Control
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/*<LinkPaciente paciente={single} user={auth} />*/}
      </Grid>
    </Modal>
  );
}

export default ModalPacienteView;

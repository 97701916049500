import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

// Components
import Loader from '../components/util/circleLoading';

// actions
import { authToken } from '../redux/actions/authActions';

const routes = {
    'root': `/`,
    'print-plantilla': ({ idCuestionario }) => `/pdf/cuestionarios/${idCuestionario}`,
    'print-resumen-cuestionarios': ({ idPaciente }) => `/pdf/cuestionarios/resumen/paciente/${idPaciente}`,
    'print-cuestionario': ({ idPaciente, idCuestionario }) => `/pdf/cuestionarios/${idCuestionario}/paciente/${idPaciente}`,
    'config-cuestionarios': () => ({ pathname: "configuracion", state: { path: "cuestionarios" }}),
    'config-estados': () => ({ pathname: "configuracion", state: { path: "pacientes-estados" }})
}

const TokenAuth = ({ history, location }) => {
    const dispatch = useDispatch();
    
    useEffect(function(){
        const { token, url, ...params } = queryString.parse(location.search)
        const callback = () => history.push(routes[url] ? routes[url](params) : routes['root'])
        dispatch(authToken(token, callback))
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="pb">
            <div className="mt">
                <Loader text="Cargando sesión." />
            </div>
        </div>
    );
}

export default TokenAuth;
import React from 'react'
import useEstados from '../hooks/useEstados';

// Component
import Header from './header';
import Estado from './item.js';
import Modal from './modal';
import ToolTip from '../../util/tooltip.js';

// Extra data
const emptyItem = {nombre: 'Sin resultados.', color: -1, idEstado: -1}

function Container({ title }) {
    const { list, modal, onDestroyCache, onSwipe, onDestroy, onSelect } = useEstados();
    return (
        <div>
            <Header title={title} onCreate={modal.handleOpen} onUpdate={onDestroyCache} />

            <div className="flex grow column my">
            {
                list.length ?
                    list.map((item, index) =>
                        <Estado
                            key={item.idEstado}
                            data={item}
                            index={index}
                            lastIndex={index === list.length-1}
                            onSelect={onSelect}
                            onSwipe={onSwipe}
                            onDestroy={onDestroy}
                        />
                    )
                :
                    <Estado data={emptyItem} onSelect={() => null} />
            }
            </div>

            <ToolTip handleOpen={modal.handleOpen} title="Nuevo Estado" color="primary" />
            <Modal {...modal} />
        </div>
    )
}

export default Container;
import { makeLogout } from './authActions.js';
import {
  ALERT_ERROR,
  PERMISSION_ERROR
} from '../constans.js'

const handler = (dispatch) => (error) => {
  try {  
    const { request, message, response } = error;
    const status = request ? request.status : 1;

    if(!status){
      dispatch({ type: ALERT_ERROR, payload: {error: 'Error de conexión, vuelve a intentarlo.'} })
    } else if(status === 403){
      dispatch(makeLogout())
      const payload = (response && response.data) ? response.data : {error: message};
      dispatch({ type: ALERT_ERROR, payload: payload })
    } else if(status === 428){
      dispatch({type: PERMISSION_ERROR, payload: "No tiene permisos para realizar esta accion"})
    } else {
      const payload = (response && response.data) ? response.data : {error: message};
      dispatch({ type: ALERT_ERROR, payload })
    }
  } catch(e){
    dispatch({ type: ALERT_ERROR, payload: {title: 'Ocurrió un problema interno...', error: e.message} })
  }
}

export default handler;
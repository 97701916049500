import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useOpen from '../../../../../components/hooks/useOpen';
import { useHandleColor } from '../../../../../components/hooks/useChange';

import Form from '../form/index'
import Footer from '../form/footer';
import Modal from '../../../../../components/util/modal';
import Dialog from '../../../../../components/util/confirmDialog';

import { updateListAgenda, createListAgenda, hideListAgenda } from '../../../../../redux/actions/agendaActions';
import { toColor } from '../../../../../components/util/tools';

const defaultState = {
    name: '',
    color: '',
    id: 0
}

const ModalAgenda = ({ item, open, onSubmit, onClose }) => {
    const [state, setState] = useState(defaultState)
    const [error, setError] = useState({})

    const dispatch = useDispatch();
    const modal = useOpen();

    useEffect(function () {
        if (open && item) {
            setState({
                name: item.Nombre,
                color: toColor(item.Color, true),
                id: item.idAgenda
            })
        }
        //eslint-disable-next-line
    }, [open, item])

    const handleColor = useHandleColor(setState)

    const handleChange = (evt) => {
        setState({ ...state, [evt.target.name]: evt.target.value });
        setError({})
    }

    const handleDelete = () => {
        dispatch(hideListAgenda(state.id, handleClose))
        modal.handleClose();
    }

    const handleClose = () => {
        onClose();
        setState(defaultState)
    }

    const handleSuccess = () => {
        if (onSubmit) onSubmit();
        handleClose();
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (!state.name.trim().length) {
            return setError({ name: 'El nombre no puede estar vacio.' })
        }

        if (state.id) {
            dispatch(updateListAgenda(state.id, state, handleSuccess))
        } else {
            dispatch(createListAgenda(state, handleSuccess))
        }
    }

    return (
        <Modal
            open={open}
            buttons={<Footer state={state} modal={modal} handleSubmit={handleSubmit} handleClose={handleClose} />}
            handleClose={handleClose}
        >
            <Form
                state={state}
                error={error}
                handleColor={handleColor}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <Dialog
                exit
                open={modal.open && state.id}
                title={`Estas a punto de eliminar ${state.name}`}
                handleClose={modal.handleClose}
                handleClick={handleDelete}
            />
        </Modal>
    )
}

export default ModalAgenda;

import { useCallback, useState } from "react";
import useSWR from "swr";
import Axios from "../../../../../config/axiosv2";
import { useDispatch, useSelector } from "react-redux";
import handler from "../../../../../redux/actions/handler";
import { INDICACIONES_LIST } from "../../../../../components/historia/provider/constants";
import useOpen from "../../../../../components/hooks/useOpen";

const options = {
  revalidateOnFocus: false,
};

export const LAST_PLANTILLA = "plantillas-last";
export const URL = "plantillas/historia";
const getFirstPlantilla = (data) => {
  if (data && data[0]) return data[0];
  return null;
};

const useIndicaciones = () => {
  const defaultPlantilla = useSelector((state) =>
    getFirstPlantilla(state.history.indicaciones)
  );
  const [html, setHtml] = useState(
    defaultPlantilla ? defaultPlantilla.descripcion : ""
  );
  const [plantilla, setPlantilla] = useState(defaultPlantilla);
  const [editable, setEditable] = useState(null);
  const loading = useOpen();
  const success = useOpen();
  const dispatch = useDispatch();

  const onChangeHTML = (data) => setHtml(data);
  const onChangePlantilla = (plantilla) => {
    setPlantilla(plantilla);
    setHtml(plantilla ? plantilla.descripcion : "");
    localStorage.setItem(LAST_PLANTILLA, JSON.stringify(plantilla));
  };
  const onUpdatePlantilla = () => {
    setEditable(plantilla);
  };
  const onUpdateClose = () => {
    setEditable(null);
  };

  const onError = (e) => handler(dispatch)(e);
  const onSuccess = useCallback(
    (response) => {
      if (!plantilla) {
        onChangePlantilla(response.data[0]);
      }
      dispatch({
        type: INDICACIONES_LIST,
        payload: response.data.sort((a, b) => a.id - b.id),
      });
    },
    // eslint-disable-next-line
    [plantilla]
  );

  const { data: response, mutate } = useSWR(URL, Axios.get, {
    ...options,
    onSuccess,
    onError,
  });

  const isDisabled = plantilla ? html === plantilla.descripcion : true;

  const onDestroy = () => {
    const data = response.data.filter((t) => t.id !== plantilla.id);
    Axios.delete(`${URL}/${plantilla.id}`)
      .then(() => {
        mutate({ data });
        onChangePlantilla(data[data.length - 1]);
      })
      .catch(onError)
      .finally(loading.handleClose);
  };

  const onSubmitPlantilla = (plantilla) => {
    const data = [
      ...response.data.filter((t) => t.id !== plantilla.id),
      plantilla,
    ].sort((a, b) => a.id - b.id);
    mutate({ data });
    onChangePlantilla(plantilla);
  };

  const onSubmit = () => {
    loading.handleOpen();
    const onSuccess = () => {
      success.handleOpen();
      const data = { ...plantilla, descripcion: html };
      onSubmitPlantilla(data);
    };
    Axios.put(`${URL}/${plantilla.id}`, { html })
      .then(onSuccess)
      .catch(onError)
      .finally(loading.handleClose);
  };

  return {
    html,
    success,
    editable,
    plantilla: response ? plantilla : null,
    data: response ? response.data : null,
    isLoading: loading.open,
    isDisabled,
    onSubmit,
    onDestroy,
    onChangeHTML,
    onUpdateClose,
    onUpdatePlantilla,
    onChangePlantilla,
    onSubmitPlantilla,
  };
};

export default useIndicaciones;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import AppsIcon from '@mui/icons-material/Apps';
import MenuItem from '@mui/material/MenuItem';
import Menu from '../util/menu'
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ops } from './tools.js'
import {
  headerSection,
  headerTitle,
  headerAppButton,
  headerAppItems,
  headerLabel,
  headerActive
} from './header.module.css';

const HeaderMenu = ({title, label, list, app, sticky, objectType, width, padding, handleClick, handleClickTitle, handleBack}) => {
  // Menú de módulos
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const styleConfig = {
    maxWidth: width ?? 960,
    paddingRight: (padding ?? 20),
    paddingLeft: (padding ?? 20)
  }

  return (
    <div className={sticky ? 'sticky-header' : headerSection} style={styleConfig}>
      <div className="flex grow column middle">
        <div className="flex w-100 grow middle">
          <div className={headerTitle}>
            <Tooltip title="Volver atrás" aria-label="Volver atrás" placement="bottom" arrow>
              <ArrowBackIcon className="pointer mr" onClick={handleBack} fontSize="large" style={{ color: "#757575" }} />
            </Tooltip>
            <Typography variant="h5" className={headerLabel} onClick={handleClickTitle || handleMenu}>
              {title} {label ? ' - '+label : ''}
            </Typography>
          </div>
          {
            app ?
              <div className={headerAppButton}>
                <Tooltip title="Menú de módulos" aria-label="Menú de módulos" placement="right" arrow>
                  <IconButton
                    aria-label="Opciones del menú del usuario"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    size="large">
                    <AppsIcon color="primary"/>
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={ops}
                  transformOrigin={ops}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={headerAppItems}
                >
                  {
                    list.map((item, i) => (
                      item.resource ? item.resource :
                      <MenuItem className={item.active ? headerActive : ''} key={i} onClick={handleClick(item, handleClose)}>
                        {objectType ? item.label : item[0].toUpperCase().concat(item.slice(1))}
                      </MenuItem>
                    ))
                  }
                </Menu>
              </div>
            : null
          }
        </div>
        <Hidden smUp>
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography variant="h5" onClick={handleClickTitle || handleMenu}>
              {title} {label ? ' - '+label : ''}
            </Typography>
          </div>
        </Hidden>
      </div>
    </div>
  );
}

HeaderMenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  objectType: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  handleClickTitle: PropTypes.func
}

export default HeaderMenu

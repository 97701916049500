import { clearAllDates } from '../../components/agendaglobal/provider/actions';
import { clearCuestionarios } from "../../components/cuestionario/provider/actions";
import { clearEstados, getEstados } from "../../components/estados/provider/actions";
import Axios, { generateToken } from '../../config/axios.js';
import Axiosv2, { setCredentials } from '../../config/axiosv2';
import { ALERT_ERROR, PACIENTE_CLEAR_ALL, PERMISSION_ERROR, USER_BALANCE, USER_CHANGE, USER_LOADING, USER_LOGIN, USER_LOGOUT } from '../constans.js';
import { clearAgendas } from './agendaActions.js';
import { clearAranceles } from './arancelActions.js';
import { clearCuentas } from './cuentaActions.js';
import handler from './handler.js';
import { clearHistoria } from './historiaActions.js';
import { clearImagenes } from './imagenActions.js';
import { clearProviders } from './providerActions.js';
import { cleanAllReports } from './reporteActions.js';

// STORED DATA
import { mutate } from 'swr';
import { COBRAR_DATA, COBRAR_LABEL, COBRAR_PAGE } from '../../components/reportes/cuentas/hooks/useCuentasCobrar.js';
import { DEUDORES_DATA, DEUDORES_LABEL, DEUDORES_RANGE } from '../../components/reportes/pacientes/hooks/useDeudores.js';
import { NUEVOS_DATA, NUEVOS_LABEL, NUEVOS_PAGE, NUEVOS_VIEW } from '../../components/reportes/pacientes/hooks/usePacientesNuevos.js';
import { REPORTE_ESTADO, REPORTE_FECHA, REPORTE_GRUPO, REPORTE_METODO, REPORTE_MONEDA, REPORTE_RANGO } from '../../components/reportes/util/context.js';

function loader(val) {
  return ({
    type: USER_LOADING,
    payload: val
  })
}

export const resetPassword = (userName) => Axios.post('/mail/password/reset', { userName })

export const getDeuda = () => dispatch => {
  Axios.post('/auth/balance')
    .then(res =>
      dispatch({
        type: USER_BALANCE,
        payload: res.data
      })
    )
    .catch(handler(dispatch))
}

export const updateProfileDel = () => (dispatch, getState) => {
  const { user, token } = getState().auth;
  Axios.delete('/auth/profile/image')
    .then(() => {
      dispatch({
        type: USER_CHANGE,
        payload: {
          ...user,
          fotoDePerfil: 0
        }
      })
      updateUser(token, {
        ...user,
        fotoDePerfil: 0
      })
    })
    .catch(handler(dispatch))
}

export const updateProfile = (file, url, cb) => (dispatch, getState) => {
  const { user, token } = getState().auth;
  dispatch(loader(true))
  Axios.post('/auth/profile/image', file)
    .then(() => {
      updateUser(token, { ...user, fotoDePerfil: 1 })
      dispatch({ type: USER_CHANGE, payload: { ...user, url, fotoDePerfil: 1 } })
    })
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const updateConfiguration = (state) => (dispatch, getState) => {
  const { user, token } = getState().auth;
  dispatch(loader(true))
  Axiosv2.put("/configuracion", state)
    .then(() => {
      updateUser(token, { ...user, ...state });
      dispatch({ type: USER_CHANGE, payload: { ...user, ...state } });
    })
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)));
}

function updateUser(token, user) {
  localStorage.setItem('x-auth', JSON.stringify({ token, user }))
}

export const changePasswordFromToken = (data, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post('/auth/reset/', data)
    .then(() => cb ? cb() : null)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const changePassword = (id, data, cb) => dispatch => {
  dispatch(loader(true))
  Axios.post('/auth/change/' + id, data)
    .then(cb)
    .catch(handler(dispatch))
    .finally(() => dispatch(loader(false)))
}

export const init = () => dispatch => {
  dispatch(getEstados());
}

export const makeLogin = (body) => async dispatch => {
  try {
    dispatch(loader(true))

    // Fetch Request
    const { data } = await Axiosv2.post('/auth/login', body)

    // Set credentials
    generateToken(data.token);
    setCredentials(data.token);

    // Login
    dispatch({ type: USER_LOGIN, payload: data })

    localStorage.setItem('x-auth', JSON.stringify(data))

    dispatch(init());
  } catch (e) {
    handler(dispatch)(e)
  } finally {
    dispatch(loader(false))
  }
}

const defaultCallback = () => { };
export const authToken = (token, cb = defaultCallback) => async dispatch => {
  try {
    dispatch(loader(true))

    // Fetch Request
    const { data } = await Axiosv2.post('/auth/token', { token })

    // Login
    dispatch({ type: USER_LOGIN, payload: data })

    // Set credentials
    generateToken(data.token);
    setCredentials(data.token);

    localStorage.setItem('x-auth', JSON.stringify(data))

    dispatch(init());
  } catch (e) {
    handler(dispatch)(e)
  } finally {
    dispatch(loader(false))
    cb();
  }
}


// Recuperar la última sesión
export const makeLoginToken = ({ token }) => async dispatch => {
  try {
    dispatch(loader(true))

    // Fetch Request
    const { data } = await Axiosv2.post('/auth/token', { token })

    // Login
    dispatch({ type: USER_LOGIN, payload: { user: data.user, token } })

    // Set credentials
    generateToken(token);
    setCredentials(token);

    dispatch(init());
  } catch (e) {
    handler(dispatch)(e)
  } finally {
    dispatch(loader(false))
  }
}

export const cleanWarningError = () => ({ type: ALERT_ERROR, payload: null })
export const cleanAuthorizeError = () => ({ type: PERMISSION_ERROR, payload: null })

// Cerrar sesión
export const makeLogout = () => dispatch => {
  mutate(
    () => true, // which cache keys are updated
    undefined,   // update cache data to `undefined`
    { revalidate: false } // do not revalidate
  );

  dispatch({ type: USER_LOGOUT })
  dispatch({ type: PACIENTE_CLEAR_ALL })
  dispatch(clearAgendas());
  dispatch(clearHistoria());
  dispatch(clearCuentas());
  dispatch(clearImagenes());
  dispatch(clearAranceles());
  dispatch(clearProviders());
  dispatch(clearAllDates());
  dispatch(clearCuestionarios());
  dispatch(clearEstados());
  dispatch(cleanAllReports());
  generateToken(null)
  setCredentials(null);

  localStorage.removeItem(NUEVOS_VIEW);
  localStorage.removeItem(NUEVOS_PAGE);
  localStorage.removeItem(NUEVOS_LABEL);
  localStorage.removeItem(NUEVOS_DATA);

  localStorage.removeItem(COBRAR_DATA);
  localStorage.removeItem(COBRAR_LABEL);
  localStorage.removeItem(COBRAR_PAGE);

  localStorage.removeItem(DEUDORES_LABEL);
  localStorage.removeItem(DEUDORES_RANGE);
  localStorage.removeItem(DEUDORES_DATA);

  localStorage.removeItem('x-auth')
  localStorage.removeItem('preselect-agenda');
  localStorage.removeItem('range-day-picker');
  localStorage.removeItem('filter-pacientes');

  localStorage.removeItem(REPORTE_GRUPO);
  localStorage.removeItem(REPORTE_ESTADO);
  localStorage.removeItem(REPORTE_METODO);
  localStorage.removeItem(REPORTE_MONEDA);
  localStorage.removeItem(REPORTE_RANGO);
  localStorage.removeItem(REPORTE_FECHA);
}

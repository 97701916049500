import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../util/confirmDialog.js';

// Actions
import { cleanAuthorizeError } from '../../redux/actions/authActions.js';

const RolAlert = () => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.permission);
  const handleClose = e => dispatch(cleanAuthorizeError())

  return (
    <Modal
      open={!!error}
      title={error}
      handleClose={handleClose}
    />
  )
}

export default RolAlert

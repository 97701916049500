import React from 'react';

import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ReplayIcon from '@mui/icons-material/Replay';

// Components
import Appbar from '../../../configuracion/surfaces/appbar';

const HeaderEstados = ({ title, onUpdate, onCreate }) => {
    return (
        <Appbar
            title={title}
            rightIconComponent={
                <div className="flex middle">
                    <Hidden mdDown>
                        <Button color="primary" className="mx" onClick={onCreate} startIcon={<LibraryAddIcon />}>Nuevo estado</Button>
                    </Hidden>
                    <IconButton onClick={onUpdate} size="large">
                        <ReplayIcon />
                    </IconButton>
                </div>
            }
        />
    );
}

export default HeaderEstados;
import {
    ARANCEL_ITEM_SELECT,
    ARANCEL_LISTS,
    ARANCEL_LIST_CREATE,
    ARANCEL_LIST_DELETE,
    ARANCEL_LIST_EDIT,
    ARANCEL_LIST_ERROR,
    ARANCEL_LIST_LOADING,
    ARANCEL_LIST_SELECT,
    ARANCEL_LIST_UPDATE,
    ARANCEL_SINGLE,
} from '../constans';

const initialState = {
    all: null,
    list: null,
    loading: false,
    single: null,
    select: null,
    editable: null,
    editableList: null,
    error: ''
};

function getListWithOutDefault(list, id, ok = false) {
    list = list.filter((item) => item.idArancel !== id);

    if (ok) {
        list = list.map((item) => ({ ...item, Predeterminado: false }));
    }

    return list;
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ARANCEL_LIST_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ARANCEL_SINGLE:
            return {
                ...state,
                single: action.payload
            };
        case ARANCEL_LISTS:
            return {
                ...state,
                all: action.payload
            };
        case ARANCEL_ITEM_SELECT:
            return {
                ...state,
                editable: action.payload
            };
        case ARANCEL_LIST_EDIT:
            return {
                ...state,
                editableList: action.payload
            };
        case ARANCEL_LIST_SELECT:
            return {
                ...state,
                select: action.payload
            };
        case ARANCEL_LIST_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case ARANCEL_LIST_UPDATE:
            let listUpdated = getListWithOutDefault(
                state.all,
                action.payload.idArancel,
                action.payload.Predeterminado
            );
            return {
                ...state,
                all: [...listUpdated, action.payload],
                select: action.payload
            };
        case ARANCEL_LIST_DELETE:
            return {
                ...state,
                select: state.all.filter((item) => item.idArancel !== action.payload)[0]
            };
        case ARANCEL_LIST_CREATE:
            let listCreated = getListWithOutDefault(
                state.all,
                action.payload.idArancel,
                action.payload.Predeterminado
            );
            return {
                ...state,
                all: [...listCreated, action.payload],
                select: action.payload
            };
        default:
            return state;
    }
}

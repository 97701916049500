import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useOpen from '../../../../../components/hooks/useOpen';
import Axios from '../../../../../config/axiosv2';
import handler from '../../../../../redux/actions/handler';
import useCamposExtras from '../../../../../swr/hooks/useCamposExtras';

const UseCAmpos = (idSuperCategoria) => {
    const modal = useOpen();
    const { data: fields, isLoading, mutate } = useCamposExtras();
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState([]);

    useEffect(function () {
        if (fields) {
            setState(
                fields
                    .sort((a, b) => a.indiceDeOrden - b.indiceDeOrden)
                    .filter(item => item.idSuperCategoria === idSuperCategoria)
                    .map(field => ({ id: field.idTipoDeCampo, value: field.subCategoria }))
            )
        }
    }, [fields, idSuperCategoria])

    const hasChanges = useMemo(function () {
        let changes = false;

        for (const field of state) {
            const copia = fields.find(item => item.idSuperCategoria === idSuperCategoria && item.idTipoDeCampo === +field.id);

            if (copia && field.value !== copia.subCategoria) {
                changes = true;
                break;
            }
        }

        return changes;
    }, [state, fields, idSuperCategoria])

    const onChange = ({ target }) => {
        setState(state.map(item => item.id === +target.id ? ({ id: item.id, value: target.value }) : item))
    }

    const onDestroy = async (field) => {
        try {
            setIsSaving(true)
            await Axios.delete(`/campos/${idSuperCategoria}/${field.id}`)
            mutate();
        } catch (e) {
            handler(dispatch)(e)
        } finally {
            setIsSaving(false)
        };
    }

    const onSwipe = async (id, toDown) => {
        try {
            setIsSaving(true)
            await Axios.put(`/campos/${idSuperCategoria}/${toDown ? "up" : "down"}/${id}`)
            mutate();
        } catch (e) {
            handler(dispatch)(e)
        } finally {
            setIsSaving(false)
        };
    }

    const onSubmit = async () => {
        let array = [];
        let error = null

        for (const field of state) {
            const copia = fields.find(item => item.idSuperCategoria === idSuperCategoria && item.idTipoDeCampo === +field.id);

            if (!field.value.trim().length) {
                error = true;
                break;
            }
            if (copia && field.value !== copia.subCategoria) {
                array.push(field);
            }
        }

        if (error || !array.length) return

        try {
            setIsSaving(true)
            await Axios.put(`/campos/${idSuperCategoria}`, array)
            mutate();
        } catch (e) {
            handler(dispatch)(e)
        } finally {
            setIsSaving(false)
        };
    }

    return {
        modal,
        state,
        loading: isLoading || isSaving,
        hasChanges,
        onChange,
        onSwipe,
        onDestroy,
        onSubmit
    }
}

export default UseCAmpos;
import React, { useMemo } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TrashIcon from '@mui/icons-material/Delete';
import TopArrow from '@mui/icons-material/ExpandLess';
import BottomArrow from '@mui/icons-material/ExpandMore';
import Dialog from '../../util/dialog'

// Tools
import { toColor } from '../../util/tools';
import { dialogOptions } from './modal';
import useOpen from '../../hooks/useOpen';

const EstadosItem = ({ data, index, lastIndex, onSelect, onSwipe, onDestroy }) => {
    const dialog = useOpen();

    const onClick = () => onSelect(data);
    const onOpenDialog = (e) => {
        e.stopPropagation();
        dialog.handleOpen()
    }
    const onClickDestroy = () => {
        onDestroy(data.idEstado);
        dialog.handleClose();
    }
    const onSwipeUp = (e) => {
        e.stopPropagation();
        onSwipe(data.idEstado, true);
    }
    const onSwipeDown = (e) => {
        e.stopPropagation();
        onSwipe(data.idEstado, false);
    }
    
    const computedStyle = useMemo(() => {
        return {
            width: 20,
            height: 20,
            marginRight: 20,
            color: toColor(data.color),
            backgroundColor: toColor(data.color)
        }
    }, [ data.color ])

    return (
        <div className="flex middle grow px-m">
            <ListItem className="px-0" button onClick={onClick}>
                <Avatar style={computedStyle}>.</Avatar>
                <ListItemText disableTypography>
                    <Typography variant="body2">{data.nombre}</Typography>
                </ListItemText>
                <div className="flex right">
                    {
                        index ?
                            <IconButton size="small" className="mx-m" onClick={onSwipeDown}>
                                <TopArrow />
                            </IconButton>
                        : null
                    }
                    {
                        !lastIndex &&
                        <IconButton size="small" className="mx-m" onClick={onSwipeUp}>
                            <BottomArrow />
                        </IconButton>
                    }
                    <IconButton size="small" className="mx-m" onClick={onOpenDialog}>
                        <TrashIcon />
                    </IconButton>
                </div>
            </ListItem>
            <Dialog
                open={dialog.open}
                data={dialog.open ? dialogOptions : {}}
                callback={onClickDestroy}
                handleClose={dialog.handleClose}
            />
        </div>
    );
}

export default EstadosItem;
import React from 'react'
import { IconButton, TextField, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckOutlined';

// Hooks
import useOpen from '../../../hooks/useOpen';

// UI
import MoreVertIcon from '@mui/icons-material/Close';

function Textwrapper({ children, value, index, onUpdate, onDelete }) {
    const { open, handleClose, handleOpen } = useOpen();
    const onDestroy = () => onDelete(index);
    const onBlur = ({ target }) => {
        handleClose();
        if(target.value.trim().length){
            onUpdate(index, target.value.trim());
        }
    }
    return (
        <div className="flex grow">
            {
                !open ?
                    <div className="flex grow">
                        <div className="flex grow">
                            {children(handleOpen)}
                        </div>
                        <div className="flex-end" style={{marginRight: -12}}>
                            <IconButton onClick={onDestroy} size="large">
                                <MoreVertIcon />
                            </IconButton>
                        </div>
                    </div>
                :
                    <TextInputField defaultValue={value} type="text" size="small" fullWidth autoFocus onBlur={onBlur} />
            }
        </div>
    );
}

export const TextInputField = (props) => (
    <TextField
        {...props} 
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" className="pointer">
              <CheckIcon />
            </InputAdornment>
          ),
        }}
    />
)

export default Textwrapper;
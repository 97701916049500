import { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Context = createContext();
const blackList = [ "cuentas",  "historia", "imagenes", "cuestionario" ];

const useHistoryList = (props) => {
    const history = useHistory();
    const [backStack, setBackStack] = useState([history.location]);

    useEffect(() => {
        history.listen((location, action) => {
            // dont list the pacient module history
            const path = (location && location.pathname) ? location.pathname.toLowerCase(): null;
            if (path && blackList.some(loc => path.includes(loc))){
                return;
            }

            setBackStack(backStack => {
                const lastPath = (backStack[backStack.length - 1]);
                if (location && lastPath && lastPath.pathname === location.pathname){
                    return backStack;
                }
                switch (action) {
                    case 'POP':
                        return backStack.slice(0, backStack.length - 1);
                    case 'PUSH':
                        return [...backStack, location];
                    case 'REPLACE':
                        return [...backStack.slice(0, backStack.length - 1), location];
                    default:
                        return backStack
                }
            });
        });
    }, [history]);
    
    const onGoBack = useCallback(() => {
        if(backStack[backStack.length - 1] === history.location){
            return history.push('/');
        }
        history.push((backStack[backStack.length - 1] || { pathname: '/' }).pathname);
        //eslint-disable-next-line
    }, [backStack, history.location])

    return (
        <Context.Provider value={onGoBack}>
            {props.children}
        </Context.Provider>
    );
};

export const LocationContext = Context;
export default useHistoryList;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import TrashIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import Modal from "../util/modal";
import Form from "./form";

import Axios from "../../config/axios";
import handler from "../../redux/actions/handler";
import { useAranceles } from "../../swr/hooks/useAranceles";
import useMonedas from "../../swr/hooks/useMonedas";
import { useHandleChange, useHandleChangeNumber } from "../hooks/useChange";

const defaultState = {
  description: "",
  cost: "0",
  code: "",
  id: 0,
  title: false,
};

const ModalArancel = ({ item, open, idArancel, onClose }) => {
  const dispatch = useDispatch();
  const { data: monedas } = useMonedas();
  const [moneda, setMoneda] = useState("");
  const { mutate } = useAranceles();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(defaultState);
  const [error, setError] = useState({});

  useEffect(() => {
    if (monedas.length && !moneda) {
      setMoneda(+monedas[0].id_moneda);
    }
  }, [monedas, moneda]);

  useEffect(
    function () {
      if (open && item) {
        setState({
          code: item.Codigo || "",
          cost: item.Precio || 0,
          description: item.Tratamiento,
          id: item.idTratamiento,
          title: !!item.EsTitulo,
        });
        setMoneda(+item.id_moneda);
      }
      //eslint-disable-next-line
    },
    [open, item]
  );

  const handleClose = () => {
    onClose();
    setState(defaultState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = {};
    if (!state.description.trim().length) {
      error.description = "La descripcion no puede estar vacia.";
    }
    if (state.description.trim().length >= 150) {
      error.description =
        "La descripción excede el largo máximo permitido. (150 caracteres).";
    }
    if (isNaN(+state.cost)) {
      error.cost = "El precio no es valido.";
    }

    const namecode = +state.code.replace(new RegExp(".", "g"), "")
    if (state.code.trim().length < 1 || namecode < 0 || isNaN(namecode)) {
      error.code = "El formato valido es: `1X.XX` ";
    }
    if (state.code.trim().length > 10) {
      error.code = "El formato es muy largo";
    }

    setError(error);
    if (Object.keys(error).length) {
      return
    }

    try {
      setLoading(true);
      const cost = +state.cost > 0 ? state.cost : 0;
      if (state.id) {
        await Axios.put(`/aranceles/${state.id}`, {
          ...state,
          coin: moneda,
          cost,
        });
      } else {
        await Axios.post(`/aranceles/${idArancel}`, {
          ...state,
          coin: moneda,
          cost,
        });
      }
      handleClose();
    } catch (e) {
      dispatch(handler)(e);
    } finally {
      mutate();
      setLoading(false);
    }
  };

  const handleChange = useHandleChange(setState);
  const handleNumber = useHandleChangeNumber(setState);
  const handleCheck = () => setState({ ...state, title: !state.title });
  const handleMoneda = ({ target }) => setMoneda(target.value)

  const handleDelete = async () => {
    try {
      setLoading(true);
      await Axios.delete(`/aranceles/${state.id}`);
      handleClose();
    } catch (e) {
      dispatch(handler)(e);
    } finally {
      mutate();
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open && !!monedas.length}
      handleClose={handleClose}
      buttons={
        <Grid container justifyContent="space-between">
          {state.id ? (
            <Grid item xs={2}>
              <Tooltip
                title="Eliminar Item"
                aria-label="Eliminar Item"
                placement="right"
                arrow
              >
                <IconButton onClick={handleDelete}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <Grid item container xs justifyContent="flex-end" gap={3}>
            <Button color="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <div className="relative">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
              >
                {state.id ? "Actualizar" : "Guardar"}
              </Button>
              {loading && (
                <div className="re-loading">
                  <CircularProgress size={24} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      }
    >
      <Form
        state={state}
        error={error}
        moneda={moneda}
        monedas={monedas}
        handleMoneda={handleMoneda}
        handleChange={handleChange}
        handleNumber={handleNumber}
        handleCheck={handleCheck}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default ModalArancel;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ListPaciente from './list.js';

const SearchPaciente = ({
  label,
  limit,
  checked,
  noMenu,
  handleClick,
  handleChange,
  handleSelect,
  handleProvider
}) => {

  const helperText = useMemo(()=>{
    const trimmed = label.trim().length;

    if(trimmed < limit){
      return ''.concat(
        'Faltan al menos ',
        (limit-trimmed),
        ' letras para comenzar la búsqueda'
      );
    }
    return window.innerWidth < 768 ? "Presione la LUPA para buscar" :  'Presione ENTER para buscar';
    //eslint-disable-next-line
  }, [label])

  return (
    <div style={{display: 'block'}}>
      <TextField
        autoComplete="off"
        label={"Nombre del "+(checked ? 'paciente' : 'proveedor')}
        fullWidth
        variant='standard'
        name="search"
        value={label}
        onChange={handleChange}
        helperText={helperText}
        InputProps={{
          tabIndex: "1",
          endAdornment: (
            <InputAdornment position="end" onClick={handleClick} style={{cursor: 'pointer'}}>
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
      />
      <ListPaciente
        checked={checked}
        noMenu={noMenu}
        handlePacient={handleSelect}
        handleProvider={handleProvider}
      />
    </div>
  )
};

SearchPaciente.propTypes = {
  label: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleProvider: PropTypes.func.isRequired,
}

export default SearchPaciente;

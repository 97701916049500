import React, { useState } from 'react';
import Logo from '../assets/logoAlt.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { resetPassword } from '../redux/actions/authActions.js';
import { style } from '../components/auth/reset.js';

const Forget = ({ history }) => {
  // State
  const [state, setState] = useState({user: '', error: false, loading: false })
  const { user, error, loading, sucess } = state;

  // Handles
  const handleBack = e => history.push('/login');
  const handleError = e => {
    const message = e.response ? (e.response.data ? e.response.data.error : e.message) : e.message;
    setState(old => ({...old, error: message}));
  }
  const handleSuccess = (res) => {
    setState({user: '', error: '', sucess: res.data});
    setTimeout(function(){
      history.push('/login');
    }, 5000)
  }
  const handleChange = e => {
    setState({
      user: e.target.value,
      error: false,
      loading: false
    })
  }
  const handleSubmit = e => {
    e.preventDefault();

    if(user.length < 4) return setState({user, error:'El usuario no es valido.'})

    // Do fetch
    setState({...state, loading: true})
    resetPassword(user).then(handleSuccess).catch(handleError).finally(
      () => setState(old => ({...old, loading: false}))
    )
  }

  return (
    <div className="flex grow center h-100">
      <div style={style.container}>
        <div className="flex grow column">
          <div style={style.text} className="mt-m">
            <img src={Logo} width={50} heigth={50} alt="Logo Odontosys" style={{backgroundColor: 'white'}} />
          </div>
          <div style={style.text}>
            <h3 style={{margin: 0}}>Recuperar contraseña</h3>
          </div>
          {
            sucess ? 
              <div style={style.text} className="text-success my">
                <strong>Te enviamos un correo a {sucess}.</strong>
              </div>
            :
            <form style={{padding: '15px 30px'}} onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                size="small"
                label="Usuario"
                value={user}
                error={!!error}
                disabled={loading}
                onChange={handleChange}
                helperText={error ? error : ''}
              />
              <div className="flex grow center end mt-m">
                <Button size="small" color="secondary" style={{marginRight: 10}} onClick={handleBack} disabled={loading}>CANCELAR</Button>
                <div className="relative">
                  <Button
                    type="submit"
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                  >
                    recuperar
                  </Button>
                  {
                    loading && <div className="re-loading"><CircularProgress size={22} /></div>
                  }
                </div>
              </div>
          </form>
          }
        </div>
      </div>
    </div>
  );
}

export default Forget;

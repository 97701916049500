import { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Context } from '../../util/context.js';

// Hooks
import useFetchPdf from '../../../hooks/useFetchPdf.js';
import useFetchCsv from '../../../hooks/useFetchCsv.js';

// Actions
import { setSingle } from '../../../../redux/actions/pacienteActions.js';
import { setNuevosList, getNuevosList } from '../../../../redux/actions/reporteActions.js';
import { views, viewFilter, headers, makeTemplate } from '../../pacientes/nuevos/template';
import { formatTime } from '../../shared/util'
import { stateDate } from '../../../util/tools.js';

export const NUEVOS_VIEW = `Pacientes-nuevos-lista-view`;
export const NUEVOS_PAGE = `Pacientes-nuevos-lista-page`;
export const NUEVOS_LABEL = `Pacientes-nuevos-lista-item`;
export const NUEVOS_DATA = 'Pacientes-nuevos-lista-data';

const usePacientesNuevos = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(+(localStorage.getItem(NUEVOS_PAGE) || 0))
    const [view, setView] = useState(+(localStorage.getItem(NUEVOS_VIEW) || views[0].value));
    const [active, setActive] = useState(localStorage.getItem(NUEVOS_LABEL));
    const { range, rangeDate: date, handleReset } = useContext(Context);

    const data = useSelector(state => state.report.nuevos)
    const loading = useSelector(state => state.report.loading);
    const token = useSelector(state => state.auth.token);

    const title = useMemo(() => `Pacientes Nuevos ${formatTime(range, date)}`, [ date, range ]);
    const pdf = useFetchPdf({name: title})
    const csv = useFetchCsv()

    const [state, setState] = useState({ data: [], list: [], count: 0 });

    useEffect(function () {
        const storedData = localStorage.getItem(NUEVOS_DATA);
        if (storedData && storedData.length) {
            dispatch(setNuevosList(JSON.parse(storedData)))
        }
        return () => handleReset();
        // eslint-disable-next-line
    }, [])

    useEffect(function(){
        dispatch(stateDate({}, getNuevosList)(range, date));
        setPage(0);
        // eslint-disable-next-line
    }, [range, date]);

    useEffect(function(){
        const array = (data || []).filter(viewFilter(view))
        setState({
            data: array,
            list: array.slice(page * 12, (page * 12) + 12),
            count: Math.ceil(array.length / 12),
        })
    }, [ view, data, page ])
    
    const handleView = (view) => {
        setView(view)
        localStorage.setItem(NUEVOS_VIEW, view);
        handleChangePage(null, 0)
    }
    const handleSelect = (id) => {
        setActive(id)
        localStorage.setItem(NUEVOS_LABEL, id);
    };
    const handleChangePage = (_, page) => {
        if(loading) return;
        setPage(page);
        localStorage.setItem(NUEVOS_PAGE, page);
    }
    const handleClick = item => dispatch(setSingle(item));
    const handleDataPDF = (allpages) => {
        const titleStr = allpages ? title : `${title} pag. ${page+1}`;
        return [{ list: (allpages ? state.data : state.list).map(makeTemplate(view)), title: titleStr, token, headers: headers }, titleStr]
    }
    const handleDataCSV = (allpages) => [{ list: (allpages ? state.data : state.list).map(makeTemplate(view)), headers: headers }, (allpages ? title : `${title} pag. ${page+1}`)]

    return {
        ...state,
        limit: 12,
        pdf,
        csv,
        page,
        view,
        title,
        active,
        loading,
        handleView,
        handleClick,
        handleSelect,
        handleChangePage,
        handleDataPDF,
        handleDataCSV,
    }
}

export default usePacientesNuevos;
import React from 'react';

// Components
import ContextProvider from '../configuracion/hooks/context';
import { Container } from '../configuracion/surfaces';

import '../configuracion/config.css'

const Configuracion = () => {
  return (
    <div className="h-100 relative">
      <div className="h-100 page">
        <ContextProvider>
          <Container />
        </ContextProvider>
      </div>
      <div className="background" />
    </div>
  );
}

export default Configuracion;
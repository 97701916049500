import { getZero, weekShort, parseStricDate, parseDate } from '../../../util/tools.js';

export const views = [
    { label: "Todos", value: 0 },
    { label: "Sin cita", value: 1 },
    { label: "Sin Próxima cita", value: 2 },
]

export const viewFilter = (view) => item => {
    if(view === 2) return !item.nextDate;
    else if(view) return (!item.nextDate && !item.lastDate);
    return true;
}

export const headers = [
    { label: "Fecha Ing.", size: 90, alt: true },
    { label: 'Pacientes', long: true, alt: true },
    { label: "Última cita", size: 120 },
    { label: "Próxima cita", size: 120 },
]

export const makeTemplate = (view) => (item) => {
    const date = parseStricDate(item.Fecha);
    const check = item.lastDate ? (item.lastDate.Concurrio === 5 || item.lastDate.Concurrio === 3 || item.lastDate.Concurrio === 1):null;
    return [
        {label: `${weekShort[date.getDay()]} ${getZero(date.getDate())}`, size: 90, alt: true},
        {label: item.Nombre.slice(0, 60), long: true, alt: true},
        {label: (view !== 1 && item.lastDate) ? `${parseDate(item.lastDate.Fecha, true)} ${check ? "✓" : ""}` : '-------------', size: 120},
        {label: (!view && item.nextDate) ? parseDate(item.nextDate.Fecha, true) : '-------------', size: 120},
    ]
}
import Axios from 'axios';
import { baseURL } from './axiosv2';

// Axios
const client = Axios.create({
  baseURL: baseURL.concat('/v1'),
  timeout: 60000
})

// Set the authentication token
const generateToken = token => {
  if(token){
    client.defaults.headers.common['x-auth'] = token;
  } else {
    delete client.defaults.headers.common['x-auth'];
  }
}

// export const PDF_SERVICE = "http://localhost:8000/pdf";
export const PDF_SERVICE = "https://service.odontosys.com/pdf"; 

export { generateToken };
export default client;

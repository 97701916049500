import React from 'react';

import { Container, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Modules
import StyckyStyle from '../../util/sticky.module.css';

const StickyHeader = ({ Typography, Icon, onClose }) => {
    return (
        <div className={StyckyStyle.mainsmall}>
            <Container className="h-100 relative" maxWidth="lg">
                <div className="flex grow middle h-100">
                    <div className="flex grow middle text-white pointer">
                        <Tooltip title="Volver atrás" aria-label="Volver atrás" placement="bottom" arrow>
                            <ArrowBackIcon className="pointer mr" color="inherit" onClick={onClose} />
                        </Tooltip>
                        <div className="flex grow column text-white text-ellipsis">
                            {Typography}
                        </div>
                    </div>
                    <div>
                        <Tooltip title="Menú de módulos" aria-label="Menú de módulos" placement="right" arrow>
                            {Icon}
                        </Tooltip>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default StickyHeader
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import styles from '../../../../../components/cuentas/surfaces/form/template.style';
import { formatNumber } from '../../../../../components/util/tools';
import { ProxyURL } from '../../../../../config/axiosv2';

const ListaTratamientos = ({ id, data, title, config }) => {
    const imgSrc = ProxyURL.concat(("" + id).padStart(5, '0'), '/encabezado.png');
    return (
        <Document>
            <Page size="A4" wrap style={styles.page}>
                <View style={styles.header} fixed>
                    <View style={styles.headerContent}>
                        <View style={{ ...styles.brand, marginTop: +config[0].mTop }}>
                            {config[2] ? <Image source={imgSrc} style={styles.logo} /> : null}
                        </View>
                        {config[0].Print ? <View style={{ marginTop: 8 }}><View style={styles.separator} /></View> : null}
                    </View>
                </View>
                <View style={styles.body}>
                    <View style={styles.title}>
                        <Text>{title}</Text>
                    </View>
                    {
                        data.map((item, i) => {
                            return (
                                <View key={item.idTratamiento} style={(item.EsTitulo && i) ? {marginTop: 10} : undefined}>
                                    <View style={styles.item} break={false}>
                                        <Text style={styles.code}>
                                            {item.Codigo}
                                        </Text>
                                        <Text style={styles.grow}>
                                            {item.Tratamiento}
                                        </Text>
                                        {
                                            item.EsTitulo ? null : (
                                                <View style={styles.amount}>
                                                    <Text>
                                                        {item.moneda.Simbolo}{formatNumber(item.Precio)}
                                                    </Text>
                                                </View>
                                            )
                                        }
                                    </View>
                                    {item.EsTitulo ? <View style={[styles.separator, { marginTop: 2.5, backgroundColor: '#014990' }]} /> : null}
                                </View>
                            )
                        })
                    }
                </View>
                <View style={styles.prefooter}>
                    <View style={styles.prefooterText}>
                        <Text>
                            {config[0].FooterText}
                        </Text>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <View style={styles.footerContent}>
                        {config[0].Print ? <View style={{ marginBottom: 10 }}><View style={styles.separator} /></View> : null}
                        {config[1].map((item, i) => <Text style={styles.footerText} key={i}>{item}</Text>)}
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default ListaTratamientos;

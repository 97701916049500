import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOpen from '../../hooks/useOpen';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cropper from '../../crop/surfaces/modal';
import Form from '../../paciente/forms';
import Modal from '../../util/modal';

import handler from '../../../redux/actions/handler';
import { updatePaciente } from '../../../redux/actions/pacienteActions';
import useGrupos from '../../../swr/hooks/useGrupos';
import getScaledImg from '../../crop/util/normalize';
import { getAvatarUri, parseDateReverse, validateImage } from '../../util/tools';

const ModalUpdatePaciente = ({ paciente, open, handleClose }) => {
    const user = useSelector(state => state.auth.user);
    const isIDAutomaticoRequired = user && user.IdDelPacienteAutomatico && open;
    const loading = useSelector(state => state.pacient.loading);
    const { gruposPacientes } = useGrupos();
    const dispatch = useDispatch();
    const [state, setState] = useState()
    const [error, setError] = useState({})
    const [checked, setChecked] = useState(false);
    const [tempFile, setTempFile] = useState(null);
    const crop = useOpen();


    useEffect(function () {
        if (paciente && open) {
            const grupos = paciente.grupospacientes.map(item => gruposPacientes.find(grupo => grupo.idGrupo === item.idGrupo));

            const date = new Date(
                +paciente.FechaNacimiento.slice(0, 4),
                +paciente.FechaNacimiento.slice(5, 7) - 1,
                +paciente.FechaNacimiento.slice(8, 10)
            );

            setState({
                name: paciente.nombre,
                sex: paciente.Sexo,
                date: date,
                movil: paciente.movilDeEnvio,
                phone: paciente.TelefonoParaMostrar,
                email: paciente.correoDeEnvio,
                dentist: paciente.idParaElDentista,
                doctor: user.MostrarDoctoresComoGrupo ? -1 : paciente.idDoctor ? paciente.idDoctor : -1,
                note: paciente.nota,
                direction: paciente.DireccionParaMostrar,
                groups: grupos.filter(item => item),
                fields: paciente.fields || {}
            })

            setChecked(date.getFullYear() !== 2100);
        }
        //eslint-disable-next-line
    }, [user, open, paciente])

    const handleFile = e => {
        const file = e.target.files ? e.target.files[0] : null;

        if (!file) {
            return;
        } else if (!validateImage(file)) {
            return setError({ file: 'El formato del archivo no es soportado.' });
        }

        getScaledImg(file)
            .then(image => {
                setTempFile(image);
                crop.handleOpen();
                setError({ file: undefined });
            })
            .catch(handler(dispatch))
    }
    const handleCheck = () => setChecked(old => !old);
    const handleChange = ({ target }) => {
        setError({});
        setState({ ...state, [target.name]: target.value })
    }
    const handleFileSubmit = (file) => {
        setState({ ...state, file })
    }
    const handleFileClose = () => {
        crop.handleClose();
        setTempFile(null);
    }
    const handleModalClose = () => {
        setState()
        setChecked(false);
        handleClose();
    }
    const handleSubmit = e => {
        e.preventDefault();
        const {
          name,
          phone,
          movil,
          doctor,
          direction,
          email,
          note,
          file,
          sex,
          date,
          dentist,
          groups,
          fields,
        } = state;

        if (name.trim().length < 4) {
            setError({ name: 'El nombre es muy corto.' })
        } else if (name.trim().length > 150) {
            setError({ name: 'El nombre es muy largo.' })
        } else if (phone.trim().length > 40) {
            setError({ phone: 'El teléfono particular es muy largo.' })
        } else if (movil.trim().length > 50) {
            setError({ movil: 'El teléfono móvil es muy largo.' })
        } else if (email.trim().length > 50) {
            setError({ email: 'El correo electrónico excede el máximo permitido.' })
        } else if (direction.trim().length > 100) {
            setError({ direction: 'La descripción excede el máximo permitido.' })
        } else if (note.trim().length > 500) {
            setError({ note: 'La nota adicional excede el máximo permitido.' })
        } else if (dentist.trim().length > 100) {
            setError({ dentist: 'El dentista excede el máximo permitido.' })
        } else if (checked && date && date.getFullYear() < 1900) {
            setError({ date: "La fecha no es valida" })
        } else if (checked && date && date.getFullYear() >= 2100) {
            setError({ date: "La fecha no es valida" })
        } else {
            setError({})

            const pacienteData = new FormData()
            if (checked) pacienteData.append("date", parseDateReverse(state.date));
            if (file) pacienteData.append("file", file.blob);
            pacienteData.append("name", name)
            pacienteData.append("phone", phone)
            pacienteData.append("movil", movil)
            pacienteData.append("direction", direction)
            pacienteData.append("email", email)
            pacienteData.append("doctor", doctor)
            pacienteData.append("note", note)
            pacienteData.append("sex", sex)
            pacienteData.append("dentist", dentist)
            pacienteData.append("fields", JSON.stringify(fields))
            pacienteData.append("groups", JSON.stringify(groups));
            dispatch(updatePaciente(pacienteData, paciente.idPaciente, state.file ? state.file.url : '', handleModalClose))
        }
    }


    const ButtonComponent = (
        <div className="btn-center">
            <Button
                color="secondary"
                className="mr-m"
                onClick={handleModalClose}
            >Cerrar</Button>
            <div className="relative">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                >Guardar</Button>
                {
                    loading && <div className="re-loading"><CircularProgress size={22} /></div>
                }
            </div>
        </div>
    )

    const url = useMemo(() => {
        if (!paciente) return '';
        return `url(${getAvatarUri(user, paciente)})`;
        // eslint-disable-next-line
    }, [paciente])

    if(!state) return null;

    return (
        <Modal
            open={open}
            handleClose={handleModalClose}
            buttons={ButtonComponent}
        >
            <Form
                editable
                url={url}
                state={state}
                error={error}
                checked={checked}
                idDentista={paciente.idParaElDentista}
                idDentistaAutomatico={isIDAutomaticoRequired}
                handleChange={handleChange}
                handleCheck={handleCheck}
                handleFile={handleFile}
                handleSubmit={handleSubmit}
            />
            <Cropper
                open={crop.open}
                file={tempFile}
                handleError={setError}
                handleSubmit={handleFileSubmit}
                handleClose={handleFileClose}
            />
        </Modal>
    )
}

export default ModalUpdatePaciente

import React from 'react'

// UI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// Components
import Title from './title';
import Container from './container';
import Controls from './controls';

// Hooks
import useQuestion from '../../hooks/useQuestion';

const QuestionContainer = ({ data, index, lastIndex, nextTitle, onSelect, onDestroy }) => {
    const { answers, menu, loading, onChangeTitle, onSwipe, onAddAnswer, onUpdateAnswer, onDeleteAnswer } = useQuestion(data);
    const handleUpdate = () => { menu.handleClose(); onSelect(data); }
    const handleDestroy = () => { menu.handleClose(); onDestroy(data) };

    const isTitle = data.tipo === "title";
    const isFirstIndexNoTitle = !index && !isTitle

    return (
        <Paper elevation={12} variant="outlined" className={"lineground " + (isTitle ? "bg-primary" : "")}
            sx={{
                display: "flex",
                flexGrow: 1,
                position: "relative",
                ml: isTitle ? 2 : 8,
                mr: 2,
                px: 6,
                mt: isTitle ? 0 : 2.5,
                mb: nextTitle ? 10 : 0
            }}
        >
            {(isTitle || isFirstIndexNoTitle) ? null : (
                <div>
                    <div className="connect-step" style={{ marginLeft: -20 }} />
                    <div className="connect-line" style={{ marginLeft: 0 }} />
                    {(nextTitle || lastIndex) && <div className="connect-break connect-break-white" style={{ left: -30 }} />}
                </div>
            )}
            <div className="bg-primary lineborder lineborder-hover lineborder-left"></div>
            <Grid container xs={12} sx={isTitle ? {} : { pt: 3, pb: 1.5 }}>
                <Grid container xs>
                    <Title
                        data={data}
                        index={index}
                        menu={menu}
                        loading={loading}
                        isTitle={isTitle}
                        lastIndex={lastIndex}
                        onSwipe={onSwipe}
                        onChangeTitle={onChangeTitle}
                        handleUpdate={handleUpdate}
                        handleDestroy={handleDestroy}
                    />
                    {
                        isTitle ? null :
                            <Container
                                data={data}
                                menu={menu}
                                answers={answers}
                                onSelect={onSelect}
                                onDestroy={onDestroy}
                                onAddAnswer={onAddAnswer}
                                onUpdateAnswer={onUpdateAnswer}
                                onDeleteAnswer={onDeleteAnswer}
                            />
                    }
                </Grid>
                <Grid item container justifyContent="flex-end" alignItems="center" xs={4}>
                    <Controls
                        istitle={isTitle}
                        index={index}
                        data={data}
                        loading={loading}
                        lastIndex={lastIndex}
                        handleSwipe={onSwipe}
                        handleOpen={menu.handleOpen}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default QuestionContainer;
import React from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import Cropper from 'react-easy-crop'

const CropperContainer = ({
    file,
    crop,
    zoom,
    onCropChange,
    onCropComplete,
    onZoomChange
}) => {
    return (
        <>
            <Grid item xs={12} className="relative" style={{ minHeight: 400 }}>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <div style={{ position: 'absolute', top: 10, left: -3, right: -3, bottom: 10 }}>
                        <Cropper
                            zoomWithScroll
                            aspect={1}
                            crop={crop}
                            zoom={zoom}
                            image={file}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="overline">ZOOM</Typography>
                <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={onZoomChange}
                />
            </Grid>
        </>
    )
}

export default CropperContainer
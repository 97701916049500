import { useState } from 'react';

function useFetch(separator = '","'){
    const [loading, setLoading] = useState(false);

    const prepareClick = (data, title) => {
        setLoading(true)

        const csv = [
            `%22${encodeURIComponent(data.headers.map(item => item.label).join(separator))}%22`,
            ...data.list.map(item => `%22${encodeURIComponent(item.map(a => a.label).join(separator))}%22`)
        ].join('\r\n')

        onDownload(csv, title)
    }

    const onDownload = (csv, filename) => {
        const link = document.createElement('a');
        link.download = filename + '.csv'
        link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + (csv);
        link.click();
        link.remove()
        setLoading(false)
    }

    return {
        loading,
        prepareClick
    }
}

export default useFetch;

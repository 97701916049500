import React from 'react';
import clsx from 'clsx';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as CorreoIcon } from '../../../assets/svgs/correo.svg'
// Hooks
import useOpen from '../../../components/hooks/useOpen';

const Correos = ({ label, title, active, submenu, onSelect, onSelectPanel }) => {
    const list = useOpen();

    const onClick = () => {
        onSelect(label, false)
        if (list.open && active) list.handleClose();
        else list.handleOpen()
    }
    const onClickPanel = (panel) => () => onSelectPanel(panel);
    return (
        <>
            <ListItemButton className={clsx({ active })} onClick={onClick}>
                <ListItemIcon>
                    <SvgIcon fontSize="small">
                        <CorreoIcon />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography>Correo Saliente</Typography>
                </ListItemText>
                {(list.open && active) ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={list.open && active} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="px-m">
                    <ListItemButton className={clsx({ active: submenu === `${title}-gmail` })} onClick={onClickPanel(`${title}-gmail`)}>
                        <ListItemText disableTypography>
                            <Typography>Gmail</Typography>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton className={clsx({ active: submenu === `${title}-smtp` })} onClick={onClickPanel(`${title}-smtp`)}>
                        <ListItemText disableTypography>
                            <Typography>SMTP</Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}

export default Correos;
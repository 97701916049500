import { Check, Circle } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    Snackbar,
    Typography,
} from "@mui/material";
import React from "react";
import useMovil from "../../../../components/hooks/useMovil";
import Loading from "../../../../components/util/circleLoading";
import Modal from "../../../../components/util/modal";
import { toColor } from "../../../../components/util/tools";
import useAgendasHabilitadas from "../hooks/useAgendasHabilitadas";

const ModalAgendasHabilitadas = ({ open, usuario, handleClose }) => {
  const {
    agendas,
    isSaving,
    isSuccess,
    hasChanges,
    onSubmit,
    onReset,
    onCheckAgenda,
    onCloseSnackbar,
  } = useAgendasHabilitadas(
    !!(open && usuario) ? usuario.idUsuario : null,
    handleClose
  );
  const isPhone = useMovil();

  const Buttons = (
    <Grid item container justifyContent="flex-end" xs={12}>
      <Button color="secondary" size="small" className="mr-m" onClick={onReset}>
        Cancelar
      </Button>
      <div className="relative">
        <Button
          onClick={onSubmit}
          disabled={!hasChanges || isSaving}
          color="primary"
          variant="contained"
          size="small"
        >
          Guardar
        </Button>
        {isSaving && (
          <div className="re-loading">
            <CircularProgress size={22} />
          </div>
        )}
      </div>
    </Grid>
  );

  return (
    <>
      <Modal open={open} buttons={Buttons} handleClose={onReset}>
        <Box display="flex" flexDirection="column" gap={6} minHeight={300}>
          <Box>
            <Typography
              variant={isPhone ? "h6" : undefined}
              align={isPhone ? "center" : undefined}
            >
              Agendas Habilitadas -{" "}
              <strong>{usuario ? usuario.Nombre : ""}</strong>
            </Typography>
          </Box>
          <Box>
            <Divider />
            {agendas ? (
              agendas.map((agenda) => (
                <AgendaItem
                  agenda={agenda}
                  key={agenda.idAgenda}
                  onCheck={onCheckAgenda}
                />
              ))
            ) : (
              <Loading text="Cargando Agendas" />
            )}
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseSnackbar}
      >
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Cambios guardados correctamente
        </Alert>
      </Snackbar>
    </>
  );
};

const AgendaItem = ({ agenda, onCheck }) => {
  const { Color, idAgenda, Nombre, checked } = agenda;
  const onChange = () => onCheck(idAgenda);
  return (
    <>
      <Box display="flex" py={2}>
        <Box display="flex" flexGrow={1} gap={4} alignItems="center">
          <Circle sx={{ color: toColor(Color) }} />
          <Typography variant="body1">{Nombre}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox color="primary" checked={checked} onChange={onChange} />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default ModalAgendasHabilitadas;

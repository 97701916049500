import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CircleLoading from "../../util/circleLoading";
import Modal from "../../util/modal.js";

import handler from "../../../redux/actions/handler.js";
import useAgendas from "../../../swr/hooks/useAgendas.js";
import { biggerThanToday, formatCita } from "../../agendaglobal/util.js";
import useMovil from "../../hooks/useMovil.js";
import { getFormatDate, printHour, toColor, week } from "../../util/tools";
import { getCitasPaciente } from "../provider/actions";

const Cita = ({ start, Hora, agenda }) => {
  const isPhone = useMovil();
  const [style] = useState({
    background: toColor(agenda.Color, true),
    color: "transparent",
    marginRight: 3,
    borderRadius: 10,
    height: 15,
    minWidth: 15,
    display: "inline-flex",
    alignContent: "center",
    justifyContent: "center",
  });
  return (
    <Grid item container alignItems="flex-start" className={isPhone ? "mb" : ""}>
      <p>
        {getFormatDate(start, 1, "/")}{" "}
      </p>
      <strong style={{ paddingLeft: 20, paddingRight: 20, width: 120 }}>
        ({week[start.getDay()]})
      </strong>
      <p>
        Hora: {printHour(Hora)}
      </p>
      <span className="px flex middle">
        (<span style={style}>.</span>
        {agenda.Nombre})
      </span>
    </Grid>
  );
};

const sortCitas = (a, b) => a.start.getTime() - b.start.getTime();

const ModalCitasPaciente = ({ open, handleClose, paciente }) => {
  const isPhone = useMovil();
  const dispatch = useDispatch();
  const { data: agendas } = useAgendas();
  const [citas, setCitas] = useState(null);

  const getAgenda = useCallback(
    (id) => agendas.find((item) => item.idAgenda === id),
    [agendas]
  );

  useEffect(() => {
    if (!open) return;

    getCitasPaciente(paciente.idPaciente)
      .then((res) => setCitas(res.data.map(formatCita).filter(biggerThanToday).sort(sortCitas)))
      .catch(dispatch(handler));
    // eslint-disable-next-line
  }, [paciente.idPaciente, open]);

  const onClose = () => {
    handleClose();
    setCitas(null);
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <Grid container className={isPhone ? "" : "px"}>
        <Grid item container className="mb">
          <Typography>Citas pendientes</Typography>
        </Grid>
        {citas === null ? (
          <CircleLoading text="Buscando citas" />
        ) : !citas.length ? (
          <Grid item>
            <p>No tiene ninguna cita pendiente.</p>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            {citas.map((item) => (
              <Cita
                key={item.idCita}
                {...item}
                agenda={getAgenda(item.idAgenda)}
              />
            ))}
          </Grid>
        )}
        <Grid item container justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={onClose}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalCitasPaciente;

import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";

import config from "./config";
import "./editor.css";

const IndicacionPlantillaEditor = ({ data, disabled, onChange }) => {
  const toolbarRef = useRef();
  const editorRef = useRef();

  const onChangeEditor = (_event, editor) => {
    onChange(editor.getData());
  }

  return (
    <div className="py-m relative">
      <div ref={toolbarRef} />
      <div
        className="flex center column"
        style={{ padding: "40px 0px", backgroundColor: "#f9f9f9" }}
      >
        <div className="document-editor bg-white" style={{ maxWidth: 1000 }}>
          <CKEditor
            editor={DecoupledEditor}
            data={data}
            config={config}
            disabled={disabled}
            onReady={(editor) => {
              editorRef.current = editor;
              toolbarRef.current.textContent = "";
              toolbarRef.current.appendChild(editor.ui.view.toolbar.element);
            }}
            onChange={onChangeEditor}
          />
        </div>
      </div>
    </div>
  );
};

export default IndicacionPlantillaEditor;

import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MUIListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/Check';
import { withStyles } from '@mui/styles';
import { ListItemButton } from '@mui/material';

const ListaForm = ({ item, onSelect }) => {
    const onClick = () => onSelect(item);
    return (
        <ListItemButton className="pt-m pb-m" onClick={onClick}>
            <ListItemText disableTypography>
                <Typography variant="body2">{item.Nombre}</Typography>
            </ListItemText>
            {item.Predeterminado ?
                <Tooltip placement="bottom" arrow title="Prederminado">
                    <ListItemIcon className="flex center">
                        <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                </Tooltip>
                : null}
        </ListItemButton>
    );
}

const ListItemText = withStyles({
    root: {
        flexGrow: 0
    }
})(MUIListItemText)

export default ListaForm;
import React from 'react';

import Accordion from './accordion';
import { Permisos } from '../../usuarios/util';

const ListPermisos = ({ tipo, permisos, onTogglePermiso }) => {
    return (
        <div className="flex grow column">
            {Object.keys(Permisos).map((permiso, item) =>
                <Accordion
                    key={item}
                    tipo={tipo}
                    state={permisos}
                    title={permiso}
                    onTogglePermiso={onTogglePermiso}
                />
            )}
        </div>
    );
}

export default ListPermisos;
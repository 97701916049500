import React from 'react';
import { Grid, ListItemIcon, ListItemText, MenuItem, SvgIcon, Tooltip } from '@mui/material';
import { Redo, Undo } from '@mui/icons-material';

// Hooks
import useAnchorEl from '../../hooks/useAnchorEl.js';

// Components
import Menu from '../../util/menu'
import { useDispatch } from 'react-redux';
import { rotateImage } from '../../../redux/actions/pacienteActions.js';

const RotateMenu = ({ paciente, disabled, children }) => {
    const dispatch = useDispatch();
    const menu = useAnchorEl();

    const onRotate = (mode) => () => {
        dispatch(rotateImage(paciente, mode))
        menu.handleClose()
    }
    return (
        <>
            <Grid item container justifyContent="center" alignItems="center" xs={12}>
                <Tooltip arrow placement="bottom" title={disabled ? "Imagen del paciente" : "Rotar Imagen"}>
                    <div className="pointer relative" onClick={disabled ? undefined : menu.handleOpen}>
                        {children}
                    </div>
                </Tooltip>
            </Grid>
            <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={menu.handleClose}
            >
                <MenuItem onClick={onRotate(-90)}>
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <Undo />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={'Girar a la izquierda'} />
                </MenuItem>
                <MenuItem onClick={onRotate(90)}>
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <Redo />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={'Girar a la derecha'} />
                </MenuItem>
            </Menu>
        </>
    )
}

export default RotateMenu;

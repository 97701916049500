import React from 'react'

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ColorIconPicker } from '../../util/colorPicker.js';
import useMovil from '../../hooks/useMovil.js';

const Form = ({ state, single, handleColor, handleChange, handleSubmit }) => {
    const length = state.name.trim().length;
    const isPhone = useMovil();
    return (
        <Grid component="form" className="modal-form" container onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography variant={isPhone ? 'h6' : undefined} align={isPhone ? "center" : undefined}>
                    {single ? 'Editar Estado' : 'Nuevo Estado'}
                </Typography>
            </Grid>
            <Grid item xs={12} className="my">
                <TextField
                    required
                    fullWidth
                    size="small"
                    name="name"
                    label="Nombre"
                    type="search"
                    autoComplete="off"
                    value={state.name}
                    error={(single && !length) || length > 100}
                    helperText={length > 100 ? "El largo permitido es 100 caracteres." : ""}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item container xs={12} justifyContent="center" alignItems="center">
                <ColorIconPicker
                    component
                    color={state.color}
                    handleColor={handleColor}
                />
            </Grid>
        </Grid>
    )
}

export default Form;